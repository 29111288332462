import React from 'react';
import {
  SaveIcon,
  ArchiveIcon,
  DocumentRemoveIcon,
  ArrowNarrowLeftIcon,
  ChevronRightIcon
  } from '@heroicons/react/outline';

import { editApplication } from '../../store/application/index'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function Applications({applications }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOnSave = x => {
    if (!x.isSaved) {
      const savedApplication = { ...x, isSaved: true };
      dispatch(editApplication(savedApplication, x._id, history))
    }
    else alert("Already Saved");
  }
  const handleRemoveFromSave = x => {
    if (x.isSaved) {
      const savedApplication = { ...x, isSaved: false };
      dispatch(editApplication(savedApplication, x._id, history))
    }
  }
  const handleOnArchive = x => {
    if (!x.isArchived) {
      const savedApplication = { ...x, isArchived: true };
      dispatch(editApplication(savedApplication, x._id, history))
    }
    else alert("Already Archived");
  }
  const handleRestoreArchive = x => {
    if (x.isArchived) {
      const savedApplication = { ...x, isArchived: false };
      dispatch(editApplication(savedApplication, x._id, history))
    }
  }

  console.log("applications", applications);
  return (
    <div className='lg:w-3/4'> {applications?.map(x =>
      <div
        key={x._id}
        className="lg:p-4 sm:p-1.5 lg:my-4 sm:my-2 bg-white group border-b-2"
      >
        <div className='flex'>
          <div
            className="inset-0 object-cover rounded-full w-12 h-12 sm:w-8 sm:h-8 sm:mt-3 mr-3 ring-2 ring-cyan-300"
            key={x._id}
            style={{
              backgroundImage: `url(${x.profile?.profileImg[0]?.fullUrl})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}
          ></div>
          <div className='w-11/12'>
            <div >
              <span className='text-2xl sm:text-lg sm:font-medium'>{x.profile?.fullName}</span>
              <div className='flex text-gray-400 text-sm'> {x.job?.positionTitle} {x.profile?.jobTitle ? `| ${x.profile?.jobTitle}` : ""} {x.job?.requisitionNumber ? `| ${x.job?.requisitionNumber}` : ""}</div>
            </div>
            <div className='flex flex-wrap gap-1 mt-1'>
              {x.profile?.skills.map(x => {
                return (<span className='px-2 sm:px-1 border-2 rounded-lg lg:mr-2 bg-gray-50 sm:text-sm'>{x}</span>);
              })}
            </div>
            {(!x.isSaved && !x.isArchived) &&
              <div className='mt-4 flex gap-3'>
                <button className='flex lg:px-3 sm:px-1 sm:py-0 lg:py-1.5 items-center py-1 border-2 border-black rounded-3xl gap-2 sm:gap-0.5'
                  onClick={() => handleOnSave(x)}>
                  <SaveIcon
                    className="w-8 h-6 sm:w-4 sm:h-5"
                    aria-hidden="true"

                  />
                  <p className='text-xl sm:text-sm'>Save</p>
                </button>
                <button className='flex lg:px-3 sm:px-1 sm:py-0 lg:py-1.5 items-center py-1 border-2 border-black rounded-3xl gap-2 sm:gap-0.5'
                  onClick={() => handleOnArchive(x)}>
                  <ArchiveIcon
                    className="w-8 h-6 sm:w-4 sm:h-5"
                    aria-hidden="true"
                  />
                  <p className='text-xl sm:text-sm'>Archive</p>
                </button>
                <button className='flex lg:px-3 sm:px-1 sm:py-0 lg:py-1.5 items-center py-1 border-2 border-red-700 rounded-3xl gap-2 sm:gap-0.5'>
                  <DocumentRemoveIcon
                    className="w-8 h-6 sm:w-4 sm:h-5 text-red-700"
                    aria-hidden="true"
                  />
                  <p className='text-xl sm:text-sm text-red-700'>Remove</p>
                </button>
              </div>}
              {x.isSaved && <div className='mt-4 flex'>
                  <button className='flex lg:px-3 sm:px-1 sm:py-0 lg:py-1.5 items-center py-1 border-2 border-red-700 rounded-3xl gap-2 sm:gap-0.5'
                    onClick={() => handleRemoveFromSave(x)}>
                    <DocumentRemoveIcon
                      className="w-8 h-6 sm:w-4 sm:h-5 text-red-700"
                      aria-hidden="true"
                    />
                    <p className='text-xl sm:text-sm text-red-700'>Remove from saved</p>
                  </button></div>}
                {x.isArchived && <div className='mt-4 flex'>
                  <button className='flex lg:px-3 sm:px-1 sm:py-0 lg:py-1.5 items-center py-1 border-2 rounded-3xl gap-2 sm:gap-0.5'
                    onClick={() => handleRestoreArchive(x)}>
                    <ArrowNarrowLeftIcon
                      className="w-8 h-6 sm:w-4 sm:h-5 "
                      aria-hidden="true"
                    />
                    <p className='text-xl sm:text-sm '>Restore</p>
                  </button></div>}
          </div>
          <div className='flex items-center'><ChevronRightIcon className='w-8 h-8'/></div>
        </div>
      </div>
    )}</div>
  )
}

export default Applications