import React from 'react';
import { Link, useHistory } from 'react-router-dom'

const UIComponents = () => {

    const onInputClicked = () => {

    }

    return <div className='p-4 flex flex-col'>
        <Link to="/input-fields-list">
            <label onClick={onInputClicked} className='hover:underline cursor-pointer text-xl'>Input Fields</label>
        </Link>

        <Link to="/dropdown-fields-list">
            <label onClick={onInputClicked} className='hover:underline cursor-pointer text-xl'>Dropdown Fields</label>
        </Link>

        <Link to='/search-selection-list'>
            <label onClick={onInputClicked} className='hover:underline cursor-pointer text-xl'>Search selection</label>
        </Link>

        <Link to='/multiselection-list'>
            <label className='hover:underline cursor-pointer text-xl'>MultiSelectionList</label>
        </Link>

        <Link to='/toast-component'>
            <label className='hover:underline cursor-pointer text-xl'>Toast Component</label>
        </Link>

    </div>
}

export default UIComponents;