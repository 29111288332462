import React, {useState} from "react";
import Profile from '../../src/assets/images/Profile.svg'
import DropdownItem from "./DropdownItem";
import ArrowDown from '../assets/images/ArrowDown.svg'

const SearchSelection = ({ items }) => {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [isListVisible, setListVisible] = useState(false)
    const [selectedItem, setSelectedItem] = useState()
    const [selectedItemId, setSelectedItemId] = useState()
    const [highlightingArray, sethighlighting] = useState(items)
    const [text, setText] = useState();
    const [filteredItem, setFilteredItems] = useState(items)


    const showVisible = (value) => {
        setText(value.target.value)
        setListVisible(true)

        // console.log(value.target.value)
        setSelectedItem(value.target.value)

        if (value.target.value.length > 0) {
            var tempArray = items.filter((item) => {
                // console.log(item.name.toLowerCase() + " " + value.target.value.toLowerCase())
                // console.log(item.name.toLowerCase() === value.target.value.toLowerCase())
                return item.name.toLowerCase().includes(value.target.value.toLowerCase())
            })

            setFilteredItems(tempArray)
        }
    }

    const setSelection = (item) => {
        setSelectedItem(item.name)
        setListVisible(false)

        setSelectedItemId(item.id)
    }

    const [isFocused, setFocused] = useState()

    const handleInputFocus = () => {
        setFocused(true)
        setListVisible(true)
    }

    const handleInputBlur = () => {
        setFocused(false)
    }

    return <div className="p-4 w-full z-[-100]">
        <div className="relative">
            <div className="flex flex-row items-center">
                <p className='text-sm font-semibold text-black font-roboto'>Default state *</p>

            </div>

            <div className={classNames('mt-1 border-1px rounded flex flex-row w-full border-bg_gray666 items-center justify-between py-2 px-4 bg-white')}>
                {
                    <div className="hidden">
                        <img src={Profile} className='w-18px h-18px mr-2' alt="Profile"/>
                    </div>
                }

                <input className='w-full border-0 focus:outline-none font-normal font-roboto text-lg placeholder:700 placeholder:text-yellow-600 text-black' placeholder='Placeholder Text goes here' onBlur={handleInputBlur} onFocus={handleInputFocus} onChange={(text) => {showVisible(text)}} value={selectedItem}/>
                {
                    <div className="">
                        <img src={ArrowDown} className='w-18px h-18px ml-2' alt="ArrowDown"/>
                    </div>
                }


            </div>

            {
                isListVisible &&
                <div className="border-1px rounded border-bg_gray666 mt-4px max-h-60 overflow-y-scroll absolute w-full z-10 bg-white">
                    {
                        filteredItem.map(item => {
                            return <DropdownItem key={item.id} item={item} onSelect={setSelection} selectedItem={selectedItem} highLightText={text} selectedItemId={selectedItemId} />
                        })
                    }
                </div>
            }


        </div>
    </div>
}

export default SearchSelection