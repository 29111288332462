import React, { useState } from "react";
import Eye from '../assets/images/Eye.svg'
import Info from '../assets/images/Info.svg'
import Dropdown from "./Dropdown";

const DropdownComponent = ({ type }) => {

    const items = [{ id: 1, name: "Item 1" }, { id: 2, name: "Item 2" }, { id: 3, name: "Item 3" }, { id: 4, name: "Item 4" }, { id: 5, name: "Item 5" }, { id: 6, name: "Item 6" }, { id: 7, name: "Item 7" }, { id: 8, name: "Item 8" }]

    

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return <div className="flex flex-col w-full">
        <Dropdown items={items} className='z-11' />

        <Dropdown items={items} className='z-10' type='error'/>

    </div>
}

export default DropdownComponent;