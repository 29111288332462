import React, {useState, useRef} from "react";
import Tick from '../assets/images/Tick.svg';
import Checkbox from '@mui/material/Checkbox'

const DropdownItemWithCheckbox = ({item, selectedItem, onSelect, highLightText, selectedItemId, onItemSelected, selectedList}) => {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [isSelected, setSelected] = useState(false)
    const [isHighlight, setHighlight] = useState(false)
    const checkboxRef = useRef()

    // const onStateChanged = (state, item) => {
    //     console.log(state.target.checked)
    //     onItemSelected(state, item)
    // }

    const onItemClick = () => {
        onItemSelected(checkboxRef.current.checked, item)
    }

    const highLightItems = (itemText, highLight) => {

        const parts = itemText.name.split(new RegExp(`(${highLight})`, 'gi'));

        return <span key={itemText.id}>
            <div className="flex items-center">
            {/* onChange={(state) => {onStateChanged(state, item)} }  */}
            <input type='checkbox' className="appearance-none text-gray333 rounded mr-2 focus:ring-disable_bg_color focus:ring-offset-0" checked={selectedList.some(itm=>itm.id === item.id)} onClick={(event) => {
                event.stopPropagation()
                onItemClick()}} ref={checkboxRef} />
            {
                highLight ? parts.map((item, i) => {
                    return <span key={i} className={classNames(item.toLowerCase() === highLight.toLowerCase() ? 'text-lg font-roboto text-black font-bold'  : 'text-lg font-roboto text-black font-normal')} >
                        {item}
                    </span>
                   
                }) : <label className="text-lg font-roboto text-black font-normal">{item.name}</label> 
                
            }
             </div>
        </span> 

    }

    return <div key={item.id} className={classNames(isHighlight ? 'px-2 h-40px flex flex-row items-center justify-between bg-disable_bg_color' : 'px-2 h-40px flex flex-row items-center justify-between')} onMouseEnter={() => {setHighlight(true)}} onMouseLeave={() => {setHighlight(false)}} onClick={() => {onSelect(item)}}>
    {
        selectedItem && selectedItem === item.id ? 
        <div>
            <input type='checkbox' />
            <label className="text-lg font-roboto text-black font-bold">{item.name}</label>
        </div>
        :  highLightItems(item, highLightText) 
    }
    
    
    {/* {
        selectedItem && selectedItemId === item.id && <img src={Tick} className='h-10 w-14px mr-0.5' />
    } */}
    
</div>
}

export default DropdownItemWithCheckbox