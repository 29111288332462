import React, {useState} from "react";
import DropdownItem from "./DropdownItem";
import ArrowDown from '../assets/images/ArrowDown.svg'
import Profile from '../../src/assets/images/Profile.svg'

const Dropdown = ({items}) => {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [isListVisible, setListVisible] = useState(false)
    const [selectedItem, setSelectedItem] = useState()


    const showVisible = () => {
        setListVisible(!isListVisible)
    }

    const setSelection = (item) => {
        setSelectedItem(item)
        setListVisible(false)
    }
    return <div className="p-4 w-full z-[-100]">
    <div className="relative">
        <div className="flex flex-row items-center">
            <p className='text-sm font-semibold text-black font-roboto'>Default state *</p>

        </div>

        <div className={classNames('mt-1 border-1px rounded flex flex-row w-full border-bg_gray666 items-center justify-between py-2 px-4 bg-white')} onClick={showVisible}>
            {
                <div className="hidden">
                    <img src={Profile} className='w-18px h-18px mr-2' alt="Profile"/>
                </div>
            }

            <label className='w-full border-0 focus:outline-none text-lg font-roboto text-lg placeholder:700 placeholder:text-yellow-600 bg-white text-black' placeholder='Placeholder Text goes here'>{selectedItem ? selectedItem.name : 'Dropdown Item'}</label>
            {
                <div className="">
                    <img src={ArrowDown} className='w-18px h-18px ml-2' alt="ArrowDown"/>
                </div>
            }


        </div>

        {
            isListVisible &&
            <div className="border-1px rounded border-bg_gray666 mt-4px max-h-60 overflow-y-scroll absolute w-full z-10 bg-white">
                {
                    items.map(item => {
                        return <DropdownItem key={item.id} item={item} onSelect={setSelection} selectedItem={selectedItem} />
                    })
                }
            </div>
        }


    </div>
</div>
}

export default Dropdown;