export const VIEW_JOBS = 'view_jobs'
export const VIEW_JOB = 'view_job'
export const CREATE_JOB = 'create_job'
export const LOADING_JOBS = 'loading_jobs'
export const GET_RECRUITER_JOBS = 'get_recruiter_jobs'
export const SET_RECRUITER_JOBS_FILTERED = 'set_recruiter_jobs_filtered'
export const JOB_ERROR = 'job_error'
export const SET_LOADING = 'set_loading'
export const SET_SKILL_BASE_JOB = 'get_skill_base_job'
export const ZIP_REC_JOB = 'get_zip_rec_job'
export const SET_JOB_TITLE_BASE_JOB = 'get_job_title_base_job'
