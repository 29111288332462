import React, { useState } from 'react'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { LocationSearchInputCities } from '../test-locations/LocationSearchInputCities'

export default function AllFilterComponent({filterChangeHandler, filter, setFilter, clearFilterHandler}) {
    const [expSal, setExpSal] = useState(0);
    const onApplyClickHandler = () => {
        filterChangeHandler(filter);
    }
    const handleProgramChange = (selectedOption) => {
        setFilter({
            ...filter,
            program: selectedOption,
          })
    }
    const handleJobTypeChange = (selectedOption) => {
        setFilter({
            ...filter,
            jobType: selectedOption,
          })
    }

    const animatedComponents = makeAnimated();
    const programOptions = [
        { value: 'AJCC', label: 'AJCC' },
        { value: 'APC', label: 'APC' },
        { value: 'Hire-LA', label: 'Hire LA ' },
        { value: 'LACI-Fellowship', label: 'LACI Fellowship' },
        { value: 'Nuleep-Hexagon', label: 'Nuleep Hexagon ' },
        { value: 'Tech-Talent-Pipeline', label: 'Tech Talent Pipeline' },
        { value: 'USC-Greif-Center-Fellowship', label: 'USC Greif Center Fellowship' },
        { value: 'Youth-Source-Center', label: 'Youth Source Center' }
    ]
    const jobTypeOptions = [
        { value: 'Full-Time', label: 'Full-Time' },
        { value: 'Part-Time', label: 'Part-Time' },
        { value: 'Contractor', label: 'Contractor' },
        { value: 'Seasonal', label: 'Seasonal' },
        { value: 'Internship', label: 'Internship' },
        { value: 'Fellowship', label: 'Fellowship' },
    ]
    return (
        <div className='grid gap-4 sm:mt-6'>
            <div>
                <span className='text-lg font-medium'>Requisition Number </span> <br />
                <input
                    type='text'
                    value={filter.requisitionNumber}
                    onChange={(e) =>
                        setFilter({
                          ...filter,
                          requisitionNumber: e.target.value,
                        })}
                    placeholder='Enter Requisition Number'
                    className='border-gray-300 rounded-md w-full mt-2' />
                    
            </div>
            <div>
                <span className='text-lg font-medium'>Job Type </span> <br />
                <Select
                    components={animatedComponents}
                    isMulti
                    options={jobTypeOptions}
                    onChange={handleJobTypeChange}
                    defaultValue={filter.jobType}
                    className="w-full border-gray-300 rounded-md shadow-sm sm:text-md"
                />
                {/* <select
                    id="jobType"
                    name="jobType"
                    onChange={(e) =>
                        setFilter({
                          ...filter,
                          jobType: e.target.value,
                        })}
                    value={filter.jobType}
                    autoComplete="jobType"
                    className="w-full border-gray-300 rounded-md shadow-sm mt-2"
                >
                    <option>Select</option>
                    <option value="Full-Time">Full-Time</option>
                    <option value="Part-Time">Part-Time</option>
                    <option value="Contractor">Contractor</option>
                    <option value="Seasonal">Seasonal</option>
                    <option value="Internship">Internship</option>
                    <option value="Fellowship">Fellowship</option>
                </select> */}
            </div>
            <div>
                <label
                    htmlFor="location"
                    className="text-lg sm:text-sm font-medium"
                >
                    Location
                </label>
                <LocationSearchInputCities
                    id="location"
                    name="location"
                    autoComplete="address-level2"
                    value={filter.location}
                    onChange={(e) =>
                        setFilter({
                          ...filter,
                          location: e,
                        })
                      }
                />
            </div>
            <div>
                <label className="block text-lg sm:text-sm font-medium">
                    Program
                </label>
                <div className="mt-1">
                    {/* <select
                        id="program"
                        name="program"
                        autoComplete="program"
                        className="w-full border-gray-300 rounded-md shadow-sm sm:text-md"
                        onChange={(e) =>
                            setFilter({
                              ...filter,
                              program: e.target.value,
                            })}
                        value={filter.program}
                    >
                        <option>Select</option>
                        <option value="AJCC">AJCC</option>
                        <option value="APC">APC </option>
                        <option value="Hire-LA">Hire LA </option>
                        <option value="LACI-Fellowship">LACI Fellowship</option>
                        <option value="Nuleep-Hexagon">Nuleep Hexagon </option>
                        <option value="Tech-Talent-Pipeline">Tech Talent Pipeline</option>
                        <option value="USC-Greif-Center-Fellowship">USC Greif Center Fellowship</option>
                        <option value="Youth-Source-Center">Youth Source Center</option>
                    </select> */}
                    <Select
                        components={animatedComponents}
                        isMulti
                        options={programOptions}
                        onChange={handleProgramChange}
                        defaultValue={filter.program}
                        className="w-full border-gray-300 rounded-md shadow-sm sm:text-md"
                    />
                </div>
            </div>
            <div>
                <label className="block text-lg sm:text-sm font-medium">
                    Date Posted
                </label>
                <div className='grid gap-4 grid-cols-2 mt-2'>
                    <div>
                        <input type="radio" id="any-time" value="any-time" name="datePosted" />
                        <label for="any-time" className='align-middle'> Any Time</label>
                    </div>
                    <div>
                        <input type="radio" id="past-week" value="past-week" name="datePosted"  />
                        <label for="past-week" className='align-middle'> Past Week</label>
                    </div>
                    <div>
                        <input type="radio" id="past-24-hours" value="past-24-hours" name="datePosted"  />
                        <label for="past-24-hours" className='align-middle'> Past 24 Hours</label>
                    </div>
                    <div>
                        <input type="radio" id="past-month" value="past-month" name="datePosted"  />
                        <label for="past-month" className='align-middle'> Past Month</label>
                    </div>
                </div>
            </div>
            <div>
                <label className="block text-lg sm:text-sm font-medium">
                    Expected Salary
                </label>
                <input
                    type="range"
                    className=" h-[4px] w-full cursor-pointer  border-transparent text-red-500"
                    id="customRange1"
                    onChange={(e)=> console.log(e.target.value)}
                    min="0"
                    max="10" />
                <div className='flex w-full gap-20 text-center'>
                    <span className='px-4 py-2 border-2 rounded-full w-full'> $0 </span>
                    <span className='px-4 py-2 border-2 rounded-full w-full'> $10K </span>
                </div>   
            </div>
            <div>
                <label className="block text-lg sm:text-sm font-medium">
                    Experience level
                </label>
                <div className='grid gap-4 grid-cols-2 mt-2'>
                    <div>
                        <input type="checkbox" id="internship" name="internship" className='rounded'/>
                        <label for="internship" className='align-middle'> Internship</label>
                    </div>
                    <div>
                        <input type="checkbox" id="entry-level" name="entry-level" className='rounded' />
                        <label for="entry-level" className='align-middle'> Entry level</label>
                    </div>
                    <div>
                        <input type="checkbox" id="associate" name="associate" className='rounded' />
                        <label for="associate" className='align-middle'> Associate</label>
                    </div>
                    <div>
                        <input type="checkbox" id="mid-senior-level" name="mid-senior-level" className='rounded' />
                        <label for="mid-senior-level" className='align-middle'> Mid-senior level</label>
                    </div>
                    <div>
                        <input type="checkbox" id="director" name="director"  className='rounded'/>
                        <label for="director" className='align-middle'> Director</label>
                    </div>
                    <div>
                        <input type="checkbox" id="executive" name="executive" className='rounded' />
                        <label for="executive" className='align-middle'> Executive</label>
                    </div>
                </div>
            </div>
            <div className='grid gap-4 grid-cols-2'>
                <button className='p-2  rounded-full border-2' onClick={clearFilterHandler}>Clear All</button>
                <button className='p-2  rounded-full border-2' onClick={onApplyClickHandler}>Apply</button>
            </div>
        </div>
    )
}
