import React, {useState, useCallback, useMemo} from "react";
import Tick from '../assets/images/Tick.svg';

const DropdownItem = ({item, selectedItem, onSelect, highLightText, selectedItemId}) => {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }


    const [isSelected, setSelected] = useState(false)
    const [isHighlight, setHighlight] = useState(false)

    const highLightItems = (itemText, highLight) => {

        const parts = itemText.name.split(new RegExp(`(${highLight})`, 'gi'));

        return <span>
            {
                highLight ? parts.map((item, i) => {
                    return <span key={i} className={classNames(item.toLowerCase() === highLight.toLowerCase() ? 'text-lg font-roboto text-black font-bold'  : 'text-lg font-roboto text-black font-normal')} >
                        {item}
                    </span>
                }) : <label className="text-lg font-roboto text-black font-normal">{item.name}</label> 
            }
        </span> 

    }

    return <div key={item.id} className={classNames(isHighlight ? 'px-4 h-40px flex flex-row items-center justify-between bg-disable_bg_color' : 'px-4 h-40px flex flex-row items-center justify-between')} onMouseEnter={() => {setHighlight(true)}} onMouseLeave={() => {setHighlight(false)}} onClick={() => {onSelect(item)}}>
    {
        selectedItem && selectedItem === item.id ? <label className="text-lg font-roboto text-black font-bold">{item.name}</label> 
        :  highLightItems(item, highLightText) 
    }
    
    
    {
        selectedItem && selectedItemId === item.id && <img src={Tick} className='h-10 w-14px mr-0.5' alt="Tick"/>
    }
    
</div>
}

export default DropdownItem;