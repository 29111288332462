import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as jobActions from '../../store/job'
import {
  BriefcaseIcon, 
  DocumentTextIcon,
  ChevronDownIcon,
  UserCircleIcon
} from '@heroicons/react/solid'
import {
FilterIcon,
OfficeBuildingIcon,
LocationMarkerIcon,
CalendarIcon,
CurrencyDollarIcon,
XIcon,
SaveIcon,
ArchiveIcon,
DocumentRemoveIcon,
ArrowNarrowLeftIcon
} from '@heroicons/react/outline';
import {useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom'
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./style.css"
import AllFilterComponent from "./AllFilterComponent";
import {SET_RECRUITER_JOBS_FILTERED} from '../../store/job/types'
import * as applicationActions from '../../store/application'
import noJobsImg from '../../assets/images/No-Jobs.png'
import { editApplication } from '../../store/application/index'

const RecruiterApplicationContainer = ({
  getAllRecruiterJobs,
  getRecruiterApplications,
  jobs,
  application,
}) => {
  const history = useHistory();
  const dispatch = useDispatch()

  useEffect(() => {
    getAllRecruiterJobs()
    getRecruiterApplications()
  }, [getAllRecruiterJobs, getRecruiterApplications])
  const [itemSel, setItemSel] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const filterInitialState = {
    requisitionNumber: '',
    jobType: [],
    program: [],
    location:''
  }
  const pageFilterInitialState = {
    requisitionNumber: '',
    jobType: '',
    program: '',
    location:''
  }
  const [filter, setFilter] = useState(filterInitialState);
  const [pageFilter, setPageFilter] = useState(pageFilterInitialState);
  const handleExpand = (ix) => {
    if (itemSel.some((x) => x.key === ix)) {
      setItemSel(itemSel.filter((x) => x.key !== ix))
    } else {
      setItemSel([...itemSel, { key: ix }])
    }
  }
  useEffect(() => {
    console.log(itemSel)
  }, [itemSel])

  // useEffect(() => {
  //   pageFilterChangeHandler(pageFilter);
  // }, [pageFilter])

  const pageFilterChangeHandler = () => {
    resetFilter();
    let filteredJobs = jobs.recruiterJobs;
    if(pageFilter.requisitionNumber) {
      filteredJobs = filteredJobs.filter(job => job.requisitionNumber === pageFilter.requisitionNumber)
    }
    if(pageFilter.jobType) {
      filteredJobs = filteredJobs.filter(job => job.jobType === pageFilter.jobType)
    }
    if(pageFilter.program) {
      filteredJobs = filteredJobs.filter(job => job.program === pageFilter.program)
    }
    if(pageFilter.location) {
      filteredJobs = filteredJobs.filter(job => job.location === pageFilter.location)
    }
    dispatch({
      type: SET_RECRUITER_JOBS_FILTERED,
      payload: filteredJobs
    })
  }

  const filterChangeHandler = filter => {
    resetPageFilter();
    let filteredJobs = jobs.recruiterJobs;
    if(filter.requisitionNumber) {
      filteredJobs = filteredJobs.filter(job => job.requisitionNumber === filter.requisitionNumber)
    }
    if(filter.jobType.length > 0) {
      filteredJobs = filteredJobs.filter(job => {
        if(filter.jobType.map(({ value }) => value).includes(job.jobType)) {
          return job;
        }
      })
    }
    if(filter.program.length > 0) {
      filteredJobs = filteredJobs.filter(job => {
        if(filter.program.map(({ value }) => value).includes(job.program)) {
          return job;
        }
      })
    }
    if(filter.location) {
      filteredJobs = filteredJobs.filter(job => job.location === filter.location)
    }
    dispatch({
      type: SET_RECRUITER_JOBS_FILTERED,
      payload: filteredJobs
    })
    setIsModalOpen(false);
  }
  const resetPageFilter = () => setPageFilter({...pageFilterInitialState});
  const resetFilter = () => setFilter({...filterInitialState});
  const clearFilterHandler = () => {
      dispatch({
        type: SET_RECRUITER_JOBS_FILTERED,
        payload: jobs.recruiterJobs
      })
      setIsModalOpen(false);
      resetFilter();
      resetPageFilter();
  }

  const renderJobs = () => {
    const results = []
    jobs.recruiterJobsFiltered?.forEach((job, index) => {
      const applications = application?.recruiterApplications?.filter(x => x.job._id === job?._id);
      results.push(
        <div key={job._id} className={`${(itemSel.some((x) => x.key === index) && job?.applications.length > 0) ? 'bg-dash' : 'bg-white'}  rounded-lg border mb-5`}>
          <div className={`flex cursor-pointer rounded-lg`} >
            <div className="lg:w-96% py-4 pl-4 sm:px-3 sm:py-1.5">
              <div className="lg:flex items-center mb-2 w-full justify-between">
                <div className='lg:flex items-center'>
                  <div to={`/jobs/${job._id}/view`} onClick={() => {
                    dispatch({ type: 'View Jobs', jobId: job._id })
                  }} className="lg:text-lg font-medium truncate hover:underline">
                    {job.positionTitle}
                  </div>
                  <p className="text-sm text-gray-400 truncate lg:border-l-2 lg:pl-2 lg:ml-2">
                    Requisition Number : {job.requisitionNumber}
                  </p>
                </div>
                <>
                  {job.jobType &&
                    <div className="flex flex-shrink-0 lg:ml-2 items-end lg:mt-0 mt-2">
                      <p className="inline-flex px-2 text-sm lg:p-1 lg:font-semibold leading-5 text-gray-400 border-2 rounded-full">
                        {job.jobType}
                      </p>
                    </div>
                  }</>
              </div>
              {job?.program && <div className={`${itemSel.some((x) => x.key === index) ? 'hidden' : 'mb-2'}`}>
                <span className='rounded-lg bg-dash text-cyan-800 text-sm px-3 py-1 '>
                  {job?.program}
                </span>
              </div>}
              <div className="flex xs:block sm:justify-between gap-4 mb-2">
                <div className="flex items-center text-lg sm:text-sm text-gray-500">
                  <OfficeBuildingIcon
                    className="flex-shrink-0 mr-1.5 h-6 w-6 sm:h-6 sm:w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {job.department ? (
                    <p>{job.department}</p>
                  ) : (
                    <p className="text-gray-400">No department listed</p>
                  )}
                </div>
                <div className="flex items-center xs:ml-0 text-lg sm:text-sm text-gray-500 sm:mt-0 sm:ml-6">
                  <LocationMarkerIcon
                    className="flex-shrink-0 mr-1.5 h-6 w-6 xs:sm:h-6 sm:w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  {job?.location}
                </div>
                <div className="flex items-center text-lg sm:text-sm text-gray-500 sm:mt-0">
                  <CalendarIcon
                    className="flex-shrink-0 mr-1.5 h-6 w-6 sm:h-6 sm:w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <p>
                    Closing on{' '}
                    <time
                      dateTime={new Date(job.closingDate).toLocaleDateString()}
                    >
                      {new Date(job.closingDate).toLocaleDateString()}
                    </time>
                  </p>
                </div>
                {job.salary &&
                  <div className="flex items-center xs:ml-0 text-lg sm:text-sm text-gray-500 sm:mt-0 sm:ml-6">
                    <CurrencyDollarIcon
                      className="flex-shrink-0 mr-1.5 h-6 w-6 sm:h-6 sm:w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {job?.salary}
                  </div>
                }
              </div>
              {job.applications.length > 0 ?
                <div className={`${itemSel.some((x) => x.key === index) ? 'hidden' : ''} flex mt-2`}>
                  {job?.applications.map(x => {
                    const application = applications?.filter(p => p.profile._id === x.profile._id);
                    console.log(application);
                    if (application.length > 0 && application[0].profile?.profileImg[0] && application[0].profile?.profileImg[0]?.fullUrl) {
                      return <div
                        className="inset-0 object-cover rounded-full w-6 h-6"
                        key={application._id}
                        style={{
                          backgroundImage: `url(${application[0].profile?.profileImg[0]?.fullUrl})`,
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat'
                        }}
                      >
                      </div>
                    }
                    return null;
                  })}
                  <p className='text-gray-400 ml-2' >Applicants</p>
                </div> : <div className='flex mt-2 '>
                  <UserCircleIcon
                    className="flex-shrink-0 mr-1.5 h-6 w-6 sm:h-6 sm:w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <p className='text-gray-400'>No Applicants</p>
                </div>
              }
            </div>
            {job.applications.length > 0 &&
              <div className={`${itemSel.some((x) => x.key === index) ? 'acco-btn-active' : ''} acco-btn lg:flex lg:items-center ml-auto mr-0`}>
                <button
                  onClick={() => {
                    handleExpand(index)
                  }}
                  type="button"
                  className="group rounded-md inline-flex items-center text-sm font-medium border-0  lg:px-3 lg:py-2 sm:p-1"
                >
                  <svg
                    className="h-8 w-8"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div> 
            }
          </div>
          <div className="mt-4 sm:w-full sm:overflow-x-auto cus-scroll">
            {itemSel.some((x) => x.key === index) ? (
              <div className="divide-y divide-gray-200 m-4">
                {/* <thead className="bg-gray-100">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      Position
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      Closing Date
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead> */}
                <>
                  {job?.applications.length !== 0 ? (
                    renderApplications(job)
                  ) : (
                    <div className="text-center">
                      {' '}
                      <div colSpan="5" className="pt-5 text-gray-500">
                        No applications
                      </div>
                    </div>
                  )}
                </>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>,
      )
    })
    return results
  }

  const renderApplications = (job) => {
    console.log(job)
    const handleOnSave = x => {
      if (!x.isSaved) {
        const savedApplication = { ...x, isSaved: true };
        dispatch(editApplication(savedApplication, x._id, history))
      }
    }
    const handleRemoveFromSave = x => {
      if (x.isSaved) {
        const savedApplication = { ...x, isSaved: false };
        dispatch(editApplication(savedApplication, x._id, history))
      }
    }
  
    const handleOnArchive = x => {
      if (!x.isArchived) {
        const savedApplication = { ...x, isArchived: true };
        dispatch(editApplication(savedApplication, x._id, history))
      }
    }

    const handleRestoreArchive = x => {
      if (x.isArchived) {
        const savedApplication = { ...x, isArchived: false };
        dispatch(editApplication(savedApplication, x._id, history))
      }
    }

    const applications = application?.recruiterApplications?.filter(x => x.job._id === job?._id);
    const results = []
    job &&
      applications?.forEach(x => {
        results.push(
          <div
            key={x._id}
            className="rounded-lg lg:p-4 sm:p-1.5 lg:my-4 sm:my-2 bg-white group"
          >
            <div className='flex'> 
              <div
                className="inset-0 object-cover rounded-full w-12 h-12 sm:w-8 sm:h-8 sm:mt-3 mr-3 ring-2 ring-cyan-300"
                key={x._id}
                style={{
                  backgroundImage: `url(${x.profile?.profileImg[0]?.fullUrl})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat'
                }}
              ></div>
              <div className='w-11/12'>
                <div>
                  <div className='w-11/12'>
                    <span className='text-2xl sm:text-lg sm:font-medium'>{x.profile?.fullName}</span>
                    <div className='flex text-gray-400 text-sm'> {x.job?.positionTitle} | {x.profile?.jobTitle} </div>
                  </div>
                  
                </div>
                <div className='flex flex-wrap gap-1 mt-1'>
                  {x.profile?.skills.map(x => {
                    return (<span className='px-2 sm:px-1 border-2 rounded-lg lg:mr-2 bg-gray-50 sm:text-sm'>{x}</span>);
                  })}
                </div>
                {(!x.isSaved && !x.isArchived) &&
                  <div className='mt-4 flex gap-3'>
                    <button className='flex lg:px-3 sm:px-1 sm:py-0 lg:py-1.5 items-center py-1 border-2 border-black rounded-3xl gap-2 sm:gap-0.5'
                      onClick={() => handleOnSave(x)}>
                      <SaveIcon
                        className="w-8 h-6 sm:w-4 sm:h-5"
                        aria-hidden="true"
                      />
                      <p className='text-xl sm:text-sm'>Save</p>
                    </button>
                    <button className='flex lg:px-3 sm:px-1 sm:py-0 lg:py-1.5 items-center py-1 border-2 border-black rounded-3xl gap-2 sm:gap-0.5'
                      onClick={() => handleOnArchive(x)}>
                      <ArchiveIcon
                        className="w-8 h-6 sm:w-4 sm:h-5"
                        aria-hidden="true"
                      />
                      <p className='text-xl sm:text-sm'>Archive</p>
                    </button>
                    <button className='flex lg:px-3 sm:px-1 sm:py-0 lg:py-1.5 items-center py-1 border-2 border-red-700 rounded-3xl gap-2 sm:gap-0.5'>
                      <DocumentRemoveIcon
                        className="w-8 h-6 sm:w-4 sm:h-5 text-red-700"
                        aria-hidden="true"
                      />
                      <p className='text-xl sm:text-sm text-red-700'>Remove</p>
                    </button>
                  </div>}
                {x.isSaved && <div className='mt-4 flex'>
                  <button className='flex lg:px-3 sm:px-1 sm:py-0 lg:py-1.5 items-center py-1 border-2 border-red-700 rounded-3xl gap-2 sm:gap-0.5'
                    onClick={() => handleRemoveFromSave(x)}>
                    <DocumentRemoveIcon
                      className="w-8 h-6 sm:w-4 sm:h-5 text-red-700"
                      aria-hidden="true"
                    />
                    <p className='text-xl sm:text-sm text-red-700'>Remove from saved</p>
                  </button></div>}
                {x.isArchived && <div className='mt-4 flex'>
                  <button className='flex lg:px-3 sm:px-1 sm:py-0 lg:py-1.5 items-center py-1 border-2 rounded-3xl gap-2 sm:gap-0.5'
                    onClick={() => handleRestoreArchive(x)}>
                    <ArrowNarrowLeftIcon
                      className="w-8 h-6 sm:w-4 sm:h-5 "
                      aria-hidden="true"
                    />
                    <p className='text-xl sm:text-sm '>Restore</p>
                  </button></div>}
              </div>
              {x.isSaved &&
                <div className="flex flex-shrink-0 lg:ml-2 items-start lg:mt-0 mt-2">
                  <p className="inline-flex px-2 text-sm lg:p-1 lg:font-semibold leading-5 bg-green-200 text-green-600 border-2 border-gray-600 rounded-full">
                    Saved
                  </p>
                </div>
              }
              {x.isArchived &&
                <div className="flex flex-shrink-0 lg:ml-2 items-start lg:mt-0 mt-2">
                  <p className="inline-flex px-2 text-sm lg:p-1 lg:font-semibold leading-5 text-gray-400 border-2 rounded-full">
                    Archived
                  </p>
                </div>
              }
            </div>
            
            
            {/* <td className="px-6 py-4 text-sm font-medium text-gray-500 whitespace-nowrap">
              {index + 1}
            </td>
            <td className="px-6 py-4 text-sm font-medium text-gray-500 whitespace-nowrap">
              {app?.profile?.fullName}
            </td>
            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
              {job?.positionTitle}
            </td>
            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
              {app?.status}
            </td>
            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
              {new Date(job?.closingDate).toLocaleDateString()}
            </td>
            <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
              <Link
                to={`/applications/${app._id}`}
                className="text-teal-600 hover:text-teal-900"
              >
                View
              </Link>
            </td> */}
          </div>,
        )
      })
    return results
  }
  return (
    <div>
      <div>
        <main className="px-4 lg:max-w-7xl sm:px-6 lg:px-8">
          
          <div>
            <div className="flex w-full lg:justify-between gap-6 sm:gap-2 bg-dash rounded-lg p-6 sm:px-4 sm:py-2">
              <div className="lg:w-full w-1/2 px-6 py-3 sm:px-1 sm:py-0 border rounded-lg bg-white">
                <div className='flex'>
                  <div className='rounded-full lg:w-24 lg:h-24 sm:w-10 sm:h-10 sm:mx-2 sm:mt-2 p-6 sm:p-2 ring-4 ring-white bg-dash '>
                    <DocumentTextIcon
                      className="w-12 h-12 sm:w-6 sm:h-6 text-primaryAA"
                      aria-hidden="true"
                    />
                  </div>
                  <div className='lg:ml-5 lg:p-5 sm:pt-3 lg:border-l-2 lg:border-gray-300'>
                    <p className='text-4xl sm:text-2xl font-medium ml-4 sm:ml-0'>{application.recruiterApplications?.length}</p>
                    <p className='text-lg ml-4 sm:hidden'>Total Applications</p>
                  </div>
                </div>
                <div className='text-sm text-gray-400 ml-4 sm:pb-1 lg:hidden md:hidden'>Total Applications</div>
              </div>
              <div className="lg:w-full w-1/2 px-6 py-3 sm:px-1 sm:py-0 border rounded-lg bg-white">
                <div className='flex'>
                  <div className='rounded-full lg:w-24 lg:h-24 sm:w-10 sm:h-10 sm:mx-2 sm:mt-2 p-6 sm:p-2 ring-4 ring-white bg-dash '>
                    <BriefcaseIcon
                      className="w-12 h-12 sm:w-6 sm:h-6 text-primaryAA"
                      aria-hidden="true"
                    />
                  </div>
                  <div className='lg:ml-5 lg:p-5 sm:pt-3 lg:border-l-2 lg:border-gray-300'>
                    <p className='text-4xl sm:text-2xl font-medium ml-4 sm:ml-0'>{jobs.recruiterJobs?.length}</p>
                    <p className='text-lg ml-4 sm:hidden'>Open Jobs</p>
                  </div>
                </div>
                <div className='text-sm text-gray-400 ml-4 sm:pb-1 lg:hidden md:hidden'>Open Jobs</div>
              </div>
              {/* <div className="w-full p-4 border rounded-lg bg-white">
                <p>{jobs.recruiterJobs?.length}</p>
                <p>Open Jobs</p>
              </div> */}
            </div>
            <div className='flex sm:block'>
              <div className='flex pt-4 gap-1 sm:hidden'>
                <div className='items-center align-middle px-3'>
                  <input
                    type="text"
                    name="requisitionNumber"
                    id="requisitionNumber"
                    placeholder="Requisition Number"
                    className="border-2 h-12 border-cyan-700 rounded-3xl shadow-sm focus:ring-cyan-700 focus:border-cyan-700"
                    onChange={(e) =>
                      setPageFilter({
                        ...pageFilter,
                        requisitionNumber: e.target.value,
                      })
                    }
                    value={pageFilter.requisitionNumber}
                  />
                </div>
                <div className='relative px-3 items-center align-middle w-full'>
                  <select
                    type="text"
                    name="jobType"
                    id="jobType"
                    placeholder="Job Type"
                    className="border-2 h-12 w-full border-cyan-700 rounded-3xl shadow-sm  focus:ring-cyan-700 focus:border-cyan-700"
                    onChange={(e) =>
                      setPageFilter({
                        ...pageFilter,
                        jobType: e.target.value,
                      })
                    }
                    value={pageFilter.jobType}
                  >
                    <option value="">Select Job Type &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                    <option value="Full-Time">Full-Time</option>
                    <option value="Part-Time">Part-Time</option>
                    <option value="Contractor">Contractor</option>
                    <option value="Seasonal">Seasonal</option>
                    <option value="Internship">Internship</option>
                    <option value="Fellowship">Fellowship</option>
                  </select>
                  <ChevronDownIcon
                    className=" text-primaryAA w-10 h-8 pointer-events-none sm:w-6 sm:h-6 absolute top-1/2 transform -translate-y-1/2 right-3.5"
                    aria-hidden="true"
                  />
                </div>
                <div className='relative px-3 items-center align-middle '>
                  <input
                    type="text"
                    name="location"
                    id="location"
                    placeholder="Location"
                    onChange={(e) =>
                      setPageFilter({
                        ...pageFilter,
                        location: e.target.value,
                      })
                    }
                    value={pageFilter.location}
                    className="border-2 h-12 border-cyan-700 rounded-3xl shadow-sm focus:ring-cyan-700 focus:border-cyan-700"
                  />
                  <ChevronDownIcon
                    className=" text-primaryAA w-10 h-8 pointer-events-none sm:w-6 sm:h-6 absolute top-1/2 transform -translate-y-1/2 right-2"
                    aria-hidden="true"
                  />
                </div>
                <div className='relative px-3 items-center align-middle w-full'>
                  <select
                    name="program"
                    id="program"
                    placeholder="Program"
                    className="border-2 h-12 border-cyan-700 rounded-3xl shadow-sm focus:ring-cyan-700 focus:border-cyan-700"
                    onChange={(e) =>
                      setPageFilter({
                        ...pageFilter,
                        program: e.target.value,
                      })
                    }
                    value={pageFilter.program}
                  >
                    <option value="">Select Program</option>
                    <option value="AJCC">AJCC</option>
                    <option value="APC">APC </option>
                    <option value="Hire-LA">Hire LA </option>
                    <option value="LACI-Fellowship">LACI Fellowship</option>
                    <option value="Nuleep-Hexagon">Nuleep Hexagon </option>
                    <option value="Tech-Talent-Pipeline">Tech Talent Pipeline</option>
                    <option value="USC-Greif-Center-Fellowship">USC Greif Center Fellowship</option>
                    <option value="Youth-Source-Center">Youth Source Center</option>
                  </select>
                  <ChevronDownIcon
                    className=" text-primaryAA w-10 h-8 pointer-events-none sm:w-6 sm:h-6 absolute top-1/2 transform -translate-y-1/2 right-3.5"
                    aria-hidden="true"
                  />
                </div>
                <button className='px-4 py-2 ml-3 font-medium text-white bg-teal-600 border border-transparent rounded-md shadow-sm text-md hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500'
                  onClick={pageFilterChangeHandler}>
                  Apply
                </button>
                <div className='border-l'></div>
              </div>
              <div className='lg:ml-5 pt-4'>
                <button className='flex lg:px-3 lg:py-1.5 items-center py-1 lg:border-2 lg:border-black rounded-3xl gap-2 sm:ml-auto sm:mr-0'
                  onClick={() => setIsModalOpen(true)}>
                  <FilterIcon
                    className="w-8 h-6 sm:w-4 sm:h-5"
                    aria-hidden="true"
                  />
                  <p className='text-xl sm:hidden'>All Filters</p>
                  <p className='text-lg lg:hidden md:hidden'>Filters</p>
                </button>
                <SlidingPane
                  closeIcon={<div className='flex items-center justify-between'>
                    <div className='text-xl font-bold w-11/12'>Filter by</div>
                    <div className="w-1/12"><XIcon className='w-6 h-6' aria-hidden="true" /></div>
                  </div>}
                  overlayClassName="sm:hidden"
                  isOpen={isModalOpen}
                  width="30%"
                  onRequestClose={() => {
                    setIsModalOpen(false);
                  }}
                >
                  <AllFilterComponent
                    filterChangeHandler={filterChangeHandler}
                    filter={filter}
                    setFilter={setFilter}
                    clearFilterHandler={clearFilterHandler}
                  />
                  <br />
                </SlidingPane>
                <SlidingPane
                  closeIcon={<div className='flex items-center justify-between sm:mt-12 sm:pb-2 border-b-1px'>
                    <div className='text-xl font-bold w-11/12'>Filter by</div>
                    <div className="w-1/12"><XIcon className='w-6 h-6' aria-hidden="true" /></div>
                  </div>}
                  overlayClassName="lg:hidden"
                  isOpen={isModalOpen}
                  width="100%"
                  onRequestClose={() => {
                    setIsModalOpen(false);
                  }}
                >
                  <AllFilterComponent
                    filterChangeHandler={filterChangeHandler}
                    filter={filter}
                    setFilter={setFilter}
                    clearFilterHandler={clearFilterHandler}
                  />
                  <br />
                </SlidingPane>
              </div>
            </div>
           

            <div
              className={`mt-4 overflow-hidden bg-white sm:rounded-md`}
            >
              {jobs.recruiterJobsFiltered?.length !== 0 ? (
                <div>{renderJobs()}</div>
              ) : (
                <div className="">
                  <div className="text-center  text-4xl xs:text-2xl sm:text-3xl pt-12">
                    <h1 className="mb-3 xs:mb-0 sm:mb-0">
                      You have no jobs posted.
                    </h1>
                    <h1>
                      Post jobs{' '}
                      <Link
                        to="/jobs/new"
                        className="text-teal-600 underline cursor-pointer"
                      >
                        here.
                      </Link>
                    </h1>
                  </div>
                  <img
                    src={noJobsImg}
                    width="400"
                    heigh=""
                    className="m-auto mt-1"
                  />
                </div>
              )}
              {/* <ul className="divide-y divide-gray-200">{renderJobs()}</ul> */}
            </div>

            {/* <p className="mt-12 text-3xl font-bold leading-tight text-gray-700 none">
              Individual Applications
            </p> */}
            {/* <div className="flex flex-col mt-4 none">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Position
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Closing Date
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{renderApplications()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </main>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  jobs: state.jobs,
  application: state.application,
})

export default connect(mapStateToProps, {
  ...jobActions,
  ...applicationActions,
})(RecruiterApplicationContainer)
