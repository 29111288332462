import React from "react";

const ToastComponent = () => {
    return <div>
        <div className="flex border-1px border-statusInfo h-19px w-19px bg-status100 ">
            
        </div>
    </div>
}

export default ToastComponent