import React from 'react';
import InputField from './InputField';

const InputFieldsList = () => {
    return <div className='p-5'>
        <div>
            <h1>
                Input type Default
            </h1>
            <InputField type='Default' />

            <InputField type='Disabled' />

            <InputField type='Error' />
        </div>
    </div>
}

export default InputFieldsList