import React from 'react';
import Navbar from '../../components/layouts/navbar';
import Footer from '../../components/layouts/Footer';

const PrivacyPolicy = () => {
    return (
        <div>
            <article class="prose lg:prose-xl mx-auto px-4 lg:px-8 mb-48 mt-8">
                <h2>Privacy policy</h2>
                <p class>
                    Protecting your private information is our priority. This
                    Statement of Privacy applies to www.nuleep.com, Nuleep’s
                    mobile application, Nuleep Partners, and Nuleep, Inc. , and
                    governs data collection and usage. For the purposes of this
                    Privacy Policy, unless otherwise noted, all references to
                    Nuleep, Inc. include www.nuleep.com and Nuleep. The Nuleep
                    website is a Career Mobile Application and Website site. By
                    using the Nuleep website and application, you consent to the
                    data practices described in this statement.
                </p>
                <p>
                    Nuleep, Inc. has a commitment to be transparent about data
                    collection and how the data is used and with whom it may be
                    shared with.
                </p>
                <p>
                    We are a job platform where we help our users build their
                    career with their lifestyle in one place. Our users leverage
                    our automated referral system to find and share
                    opportunities with their network. Our users share and engage
                    to connect professionally.
                </p>
                <h3>Collection of your Personal Information</h3>
                <p>
                    In order to better provide you with products and services
                    offered on our Site and Application, Nuleep, Inc. may
                    collect personally identifiable information, such as your:
                </p>
                <ul>
                    <li>First and Last Name</li>
                    <li>Mailing Address</li>
                    <li>E-mail Address</li>
                    <li>Phone Number</li>
                    <li>Employer</li>
                    <li>Job Title</li>
                    <li>Home Address</li>
                    <li>Photos and Files</li>
                    <li>Contacts</li>
                </ul>
                <p>
                    If you purchase Nuleep's products and services, we collect
                    billing and credit card information. This information is
                    used to complete the purchase transaction. Nuleep, Inc. may
                    also collect anonymous demographic information, which is not
                    unique to you, such as your:
                </p>
                <ul>
                    <li>Age</li>
                    <li>Gender</li>
                    <li>Household Income</li>
                    <li>Interests</li>
                </ul>
                <p>
                    Please keep in mind that if you directly disclose personally
                    identifiable information or personally sensitive data
                    through Nuleep's public message boards, this information may
                    be collected and used by others.
                </p>
                <p>
                    We do not collect any personal information about you unless
                    you voluntarily provide it to us. However, you may be
                    required to provide certain personal information to us when
                    you elect to use certain products or services available on
                    the Site. These may include: (a) registering for an account
                    on our Site or Application; (b) entering a sweepstakes or
                    contest sponsored by us or one of our partners; (c) signing
                    up for special offers from selected third parties; (d)
                    sending us an email message; (e) submitting your credit card
                    or other payment information when ordering and purchasing
                    products and services on our Site or Application. To wit, we
                    will use your information for, but not limited to,
                    communicating with you in relation to services and/or
                    products you have requested from us. We also may gather
                    additional personal or non-personal information in the
                    future.
                </p>
                <h3>Use of your Personal Information</h3>
                <p>
                    Nuleep, Inc. , collects and uses your personal information
                    to operate its website(s), applications and deliver the
                    services you have requested.
                </p>
                <p>
                    Nuleep, Inc. , may also use your personally identifiable
                    information to inform you of other products or services
                    available from Nuleep and its affiliates.
                </p>
                <h3>Logins</h3>
                <p>
                    You may have an account with a supported third party single
                    sign-on service to use our Services. As such, the Personal
                    Data we collect also depends on which third party accounts
                    you choose to use, their privacy policy, and what your
                    privacy settings with those services allow us to see when
                    you use their services to access Nuleep, Inc. , services.
                </p>
                <ul>
                    <li>
                        If you choose to link your Google account to the
                        Services, we will collect your Google email address and
                        an authentication token provided by Google.
                    </li>
                    <li>
                        If you choose to link your Linkedin account to the
                        Services, we will collect your LinkedIn profile
                        information and an authentication token provided by
                        Linkedin and Microsoft.
                    </li>
                    <li>
                        If you choose to link your Facebook account to the
                        Services, we will collect a unique user ID provided by
                        Facebook and, if permitted by you, your Facebook
                        registered email address.
                    </li>
                </ul>
                <h3>Sharing Information with Third Parties</h3>
                <p>
                    Nuleep, Inc. , may sell, rent, or lease customer information
                    to third parties for the following reasons:
                </p>
                <p>As a line of business revenue.</p>
                <p>
                    Nuleep, Inc. , may, from time to time, contact you on behalf
                    of external business partners about a particular offering
                    that may be of interest to you. In those cases, your unique
                    personally identifiable information (e-mail, name, address,
                    telephone number) is transferred to the third party. Nuleep
                    may share data with trusted partners to help perform
                    statistical analysis, send you email or postal mail, provide
                    customer support, or arrange for deliveries. All such third
                    parties are prohibited from using your personal information
                    except to provide these services to Nuleep, Inc. ,, and they
                    are required to maintain the confidentiality of your
                    information.
                </p>
                <p>
                    Nuleep, Inc. , may disclose your personal information,
                    without notice, if required to do so by law or in the good
                    faith belief that such action is necessary to: (a) conform
                    to the edicts of the law or comply with legal process served
                    on Nuleep or the site; (b) protect and defend the rights or
                    property of Nuleep; and/or (c) act under exigent
                    circumstances to protect the personal safety of users of
                    Nuleep, or the public.
                </p>
                <h3>Tracking User Behavior</h3>
                <p>
                    Nuleep, Inc. , may keep track of the websites, pages, and
                    applications our users visit within Nuleep, in order to
                    determine what Nuleep services are the most popular. This
                    data is used to deliver customized content and advertising
                    within Nuleep to customers whose behavior indicates that
                    they are interested in a particular subject area.
                </p>
                <h3>Automatically Collected Information</h3>
                <p>
                    Information about your computer hardware and software may be
                    automatically collected by Nuleep, Inc. . This information
                    can include: your IP address, browser type, domain names,
                    access times and referring website addresses. This
                    information is used for the operation of the service, to
                    maintain quality of the service, and to provide general
                    statistics regarding use of the Nuleep, Inc. , website and
                    application.
                </p>
                <h3 id="cookies">Use of Cookies</h3>
                <p>
                    The Nuleep website may use "cookies" to help you personalize
                    your online experience. A cookie is a text file that is
                    placed on your hard disk by a web page server. Cookies
                    cannot be used to run programs or deliver viruses to your
                    computer. Cookies are uniquely assigned to you, and can only
                    be read by a web server in the domain that issued the cookie
                    to you.
                </p>
                <p>
                    One of the primary purposes of cookies is to provide a
                    convenience feature to save you time. The purpose of a
                    cookie is to tell the Web server that you have returned to a
                    specific page. For example, if you personalize Nuleep pages,
                    or register with Nuleep site or services, a cookie helps
                    Nuleep to recall your specific information on subsequent
                    visits. This simplifies the process of recording your
                    personal information, such as billing addresses, shipping
                    addresses, and so on. When you return to the same Nuleep
                    website, the information you previously provided can be
                    retrieved, so you can easily use the Nuleep features that
                    you customized.
                </p>
                <p>
                    You have the ability to accept or decline cookies. Most Web
                    browsers automatically accept cookies, but you can usually
                    modify your browser setting to decline cookies if you
                    prefer. If you choose to decline cookies, you may not be
                    able to fully experience the interactive features of the
                    Nuleep services or websites you visit.
                </p>
                <h3>Links</h3>
                <p>
                    Nuleep, Inc. , website and applications contain links to
                    other sites. Please be aware that we are not responsible for
                    the content or privacy practices of such other sites. We
                    encourage our users to be aware when they leave our site and
                    to read the privacy statements of any other site that
                    collects personally identifiable information.
                </p>
                <h3>Security of your Personal Information</h3>
                <p>
                    Nuleep, Inc. , secures your personal information from
                    unauthorized access, use, or disclosure. Nuleep, Inc. , uses
                    the following methods for this purpose:
                </p>
                <ul>
                    <li>TLS Protocol</li>
                    <li>AES-256 Encryption</li>
                    <li>SMB 3.0</li>
                </ul>

                <p>
                    When personal information (such as a credit card number) is
                    transmitted to other websites, it is protected through the
                    use of encryption, such as the TLS protocol.
                </p>
                <p>
                    We strive to take appropriate security measures to protect
                    against unauthorized access to or alteration of your
                    personal information. Unfortunately, no data transmission
                    over the Internet or any wireless network can be guaranteed
                    to be 100% secure. As a result, while we strive to protect
                    your personal information, you acknowledge that: (a) there
                    are security and privacy limitations inherent to the
                    Internet which are beyond our control; and (b) security,
                    integrity, and privacy of any and all information and data
                    exchanged between you and us through this Site cannot be
                    guaranteed.
                </p>
                <h3>Children Under Thirteen</h3>
                <p>
                    Nuleep, Inc. , does not knowingly collect personally
                    identifiable information from children under the age of
                    thirteen. If you are under the age of thirteen, you must ask
                    your parent or guardian for permission to use the website or
                    application.
                </p>
                <h3>Opt-Out & Unsubscribe from Third Party Communications</h3>
                <p>
                    We respect your privacy and give you an opportunity to
                    opt-out of receiving announcements of certain information.
                    Users may opt-out of receiving any or all communications
                    from third-party partners of Nuleep by contacting us here:
                </p>
                <ul>
                    <li>Web page: www.nuleep.com/contacts</li>
                    <li>Email: info@nuleep.com</li>
                </ul>
                <h3>E-mail Communications</h3>
                <p>
                    From time to time, Nuleep, Inc. , may contact you via email
                    for the purpose of providing announcements, promotional
                    offers, alerts, confirmations, surveys, and/or other general
                    communication. In order to improve our Services, we may
                    receive a notification when you open an email from Nuleep,
                    Inc. , or click on a link therein.
                </p>
                <p>
                    If you would like to stop receiving marketing or promotional
                    communications via email from Nuleep, Inc. , you may opt-out
                    of such communications by replying STOP by contacting
                    info@nuleep.com.
                </p>
                <h3>External Data Storage Sites</h3>
                <p>
                    We may store your data on servers provided by third party
                    hosting vendors with whom we have contracted.
                </p>
                <h3>Changes to this Statement</h3>
                <p>
                    Nuleep, Inc. , reserves the right to change this Privacy
                    Policy from time to time. We will notify you about
                    significant changes in the way we treat personal information
                    by sending a notice to the primary email address specified
                    in your account, by placing a prominent notice on our site,
                    and/or by updating any privacy information on this page.
                    Your continued use of the Site and/or Services available
                    through this Site after such modifications will constitute
                    your: (a) acknowledgment of the modified Privacy Policy; and
                    (b) agreement to abide and be bound by that Policy.
                </p>
                <h3>Contact Information</h3>
                <p>
                    Nuleep, Inc. , welcomes your questions or comments regarding
                    this Statement of Privacy. If you believe that Nuleep, Inc.
                    , has not adhered to this Statement, please contact Nuleep,
                    Inc. , at:
                </p>
                <p>Nuleep, Inc.</p>
                <p>1812 Burbank Blvd. #7216</p>
                <p>Burbank, California 91506</p>
                <p>Email Address:</p>
                <p>info@nuleep.com</p>
                <p>Effective as of July 01, 2021</p>
            </article>
        </div>
    );
};

export default PrivacyPolicy;
