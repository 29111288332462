import React, { useState } from 'react';
import Profile from '../../src/assets/images/Profile.svg'
import DropdownItem from "./DropdownItem";
import ArrowDown from '../assets/images/ArrowDown.svg'
import Cancel from '../assets/images/Close.svg';
import DropdownItemWithCheckbox from './DropdownItemWithCheckbox';

const MultiSelectionComponent = ({ items }) => {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [isListVisible, setListVisible] = useState(true)
    const [selectedItem, setSelectedItem] = useState()
    const [highlightingArray, sethighlighting] = useState(items)
    const [selectedItemId, setSelectedItemId] = useState()
    const [selectedValue, setSelectedValue] = useState()
    const [selectedList, setSelectedList] = useState([])
    const [filteredList, setFilteredList] = useState(items)

    const showVisible = (text) => {
        // setListVisible(true)
        setSelectedValue(text.target.value)

        setFilteredList(items.filter((item) => {
            return item.name.toLowerCase().includes(text.target.value.toLowerCase())
        }))
    }

    const setSelection = (item) => {

        setSelectedItemId(item.id)
        setSelectedItem(item)
        // setListVisible(false)
        setSelectedValue(item.name)
        selectedList.push(item)

        setSelectedValue('')
    }

    const [isFocused, setFocused] = useState()

    const handleInputFocus = () => {
        setFocused(true)
    }

    const handleInputBlur = () => {
        setFocused(false)
    }

    const deleteItems = (item) => {
        setSelectedList(selectedList.filter(removableItem => {
            return removableItem.id !== item.id
        }))
    }

    const onItemSelected = (state, item) => {
        if (state) {
            setSelectedItemId(item.id)
            setSelectedItem(item)
            // setListVisible(false)
            setSelectedValue(item.name)
            selectedList.push(item)

            setSelectedValue('')
        }
        else {
            setSelectedList(selectedList.filter(removableItem => removableItem.id !== item.id))
        }
    }

    return <div className="p-4 w-full z-[-100]">
        <div className="relative">
            <div className="flex flex-row items-center">
                <p className='text-sm font-semibold text-black font-roboto'>Default state *</p>

            </div>

            <div className={classNames('mt-1 border-1px rounded flex flex-row w-full border-bg_gray666 items-center justify-between pt-2 px-2 bg-white')}>
                {
                    <div className="hidden">
                        <img src={Profile} className='w-18px h-18px mr-2' alt="Profile"/>
                    </div>
                }

                {
                    <div className='flex flex-row flex-wrap w-full'>
                        {
                            selectedList.map(items => {
                                return <div key={items.id} className='bg-disable_bg_color px-2 py-0.5 flex flex-row items-center rounded mr-2 mb-2'>
                                    <label className='font-normal text-base'>{items.name}</label>
                                    <img src={Cancel} className='w-9px h-9px ml-8.5px' onClick={() => { deleteItems(items) }}  alt="Cancel"/>
                                </div>
                            })
                        }
                        <input className='flex-1 border-0 focus:outline-none font-normal font-roboto text-lg placeholder:700 placeholder:text-yellow-600 text-black pb-2' placeholder='Placeholder Text goes here' onChange={(text) => { showVisible(text) }} value={selectedValue} />
                    </div>
                }

                {
                    <div className="">
                        <img src={ArrowDown} className='w-18px h-18px ml-2' alt="ArrowDown"/>
                    </div>
                }


            </div>

            {
                isListVisible &&
                <div className="border-1px rounded border-bg_gray666 mt-4px max-h-60 overflow-y-scroll absolute w-full z-10 bg-white">
                    {
                        filteredList.map(item => {
                            return <DropdownItemWithCheckbox key={item.id} item={item} onSelect={setSelection} selectedItem={selectedItem} selectedItemId={selectedItemId} highLightText={selectedValue} onItemSelected={onItemSelected} selectedList={selectedList} />
                        })
                    }
                </div>
            }


        </div>
    </div>
}

export default MultiSelectionComponent;