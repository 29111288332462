import React, { useState } from 'react';
import Profile from '../../src/assets/images/Profile.svg'
import Eye from '../assets/images/Eye.svg'
import Info from '../assets/images/Info.svg'
import PropTypes from 'prop-types';
import Warning from '../assets/images/Warning.svg';


const InputField = (props) => {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [isFocused, setFocused] = useState()

    const handleInputFocus = () => {
        setFocused(true)
    }

    const handleInputBlur = () => {
        setFocused(false)
    }
    return <div className='flex flex-col w-full'>

        {
            props.type === 'Disabled' ? <div>
                <div className='flex flex-row items-center'>
                    <p className='text-sm font-semibold text-black font-roboto '>Default state *</p>
                    <img src={Info} className='w-15px h-15px ml-5px' alt="Info"/>
                </div>

                {
                    props.type !== 'Default' ? <p className='text-sm font-normal text-gray333 mt-1 font-roboto'>Description</p> : null
                }


                <div className={classNames('mt-1 border-1px rounded flex flex-row w-full border-bg_gray666 items-center justify-between py-2 px-4 text-black bg-disable_bg_color')}>
                    {
                        <div>
                            <img src={Profile} className='w-18px h-18px mr-2' alt="Profile" />
                        </div> 
                    }

                    <label className='w-full border-0 focus:outline-none font-normal font-roboto text-lg placeholder:700 placeholder:text-yellow-600 bg-disable_bg_color text-inactive' placeholder={props.placeholder} onBlur={handleInputBlur} onFocus={handleInputFocus}>Text input</label>
                    {
                        <div>
                            <img src={Eye} className='w-18px h-18px ml-2' alt="Eye"/>
                        </div> 
                    }


                </div>
            </div> 
            : 

            props.type === 'Error' ?
            <div>
                {/* <div className='flex flex-row items-center'>
                    <p className='text-sm font-semibold text-black font-roboto'>Default state *</p>
                    <img src={Info} className='w-15px h-15px ml-5px' />
                </div>

                {
                    props.type !== 'Default' ? <p className='text-sm font-normal text-gray333 mt-1 font-roboto'>Description</p> : null
                } */}


                {/* <div className={classNames('mt-1 border-1px border-error rounded flex flex-row w-full items-center justify-between py-2 px-4 text-black')}>
                    {
                        props.type !== 'Default' ? <div>
                            <img src={Profile} className='w-18px h-18px mr-2' />
                        </div> : null
                    }

                    <input className='w-full border-0 focus:outline-none font-normal font-roboto text-lg placeholder:700 placeholder:text-yellow-600 text-inactive' placeholder='Placeholder Text goes here' onBlur={handleInputBlur} onFocus={handleInputFocus} />
                    {
                        props.type !== 'Default' ? <div>
                            <img src={Eye} className='w-18px h-18px ml-2' />
                        </div> : null
                    }
                </div> */}
                <div className='flex flex-row items-center'>
                        <div className='w-20px h-20px flex items-center justify-center'>
                        <img src={Warning} className='h-13.33px 14.89px' alt="Warning"/>
                        </div>
                        <label className='font-Roboto font-normal text-error'>Error Message</label>
                    </div>
            </div> : 
            <div>
            {/* <div className='flex flex-row items-center'>
                <p className='text-sm font-semibold text-black font-roboto'>Default state *</p>
                <img src={Info} className='w-15px h-15px ml-5px' />
            </div>

            {
                props.type !== 'Default' ? <p className='text-sm font-normal text-gray333 mt-1 font-roboto'>Description</p> : null
            } */}


            <div className={classNames(isFocused ? 'mt-1 border-1px border-primaryAA rounded flex flex-row w-full items-center justify-between py-2 px-4 text-black' : 'mt-1 border-1px  rounded flex flex-row w-full border-bg_gray666 items-center justify-between py-2 px-4 text-black')}>
                {
                    props.type !== 'Default' ? <div>
                        <img src={props.leftIcon} className='w-18px h-18px mr-2' alt="LeftIcon"/>
                    </div> : null
                }

                <input className='w-full border-0 focus:outline-none font-normal font-roboto text-lg placeholder:700 placeholder:text-yellow-600 text-inactive' placeholder={props.placeholder} onBlur={handleInputBlur} onFocus={handleInputFocus} onChange={(text) => {
                    console.log(text.target.value)
                    props.textChange(text.target.value)
                }}
                onKeyDown= {(event) => props.onKeyDown(event)} />
                {
                    props.type !== 'Default' ? <div>
                        <img src={Eye} className='w-18px h-18px ml-2 hidden' alt="Eye" />
                    </div> : null
                }


            </div>
        </div>
        }


    </div>
}

InputField.prototype = {
    type: PropTypes.oneOf(['Default', 'Disabled', 'Error',])
}
export default InputField;