import React from "react";
import { Link } from "react-router-dom";
import Footer_logo from "../../assets/images/footer-logo.png";
import NuleepFooterLogo from '../../assets/SVG/NuleepFooterLogo.svg';
import Insta from '../../assets/SVG/Insta.svg'
import LinkedIn from '../../assets/SVG/LinkedId.svg'
import Facebook from '../../assets/SVG/Facebook.svg'

function Footer() {
  return (
    <div>
      <div
        className="footer-sec pt-16 pb-10"
        style={{ backgroundColor: "#000000" }}
      >
        <div className="container m-auto  md:w-auto  pr-3.5 pl-72px pr-72px sm:px-25px">
          <div className="flex flex-wrap">
            <div className="footer-left lg:w-6/12 md:mb-10 sm:mb-10">
              <div className="logo-footer">
                <img src={NuleepFooterLogo} className="" alt="Nuleep"></img>
              </div>
              
            </div>

            {/* <div className="footer-right lg:w-6/12 xs:text-center xs:w-full" style={{visibility:'hidden'}}>
              <div className="emil-footer mb-5 flex xs:flex-wrap xs:justify-center">
                <input
                  type="text"
                  name="name"
                  placeholder="Email Address"
                  className="w-8/12 xs:w-full rounded-xl h-12 xs:mb-4"
                ></input>
                <span
                  className="pl-3 inline-block w-full xs:pl-0"
                  style={{ maxWidth: "12rem" }}
                >
                  <button className="rounded-xl text-xl text-white font-medium bg-teal-600 h-12 w-full">
                    Join Today!
                  </button>
                </span>
              </div>
              <p className="text-lg font-normal text-white">
                Join the Nuleep Community!
              </p>
              <p className="text-lg font-normal text-white">
                Get updates, connect with mentors, and behind the scenes access
                by joining us.
              </p>
            </div> */}
          </div>

          <div className="flex flex-row">
              <div className="grid grid-cols-2 xs:grid-cols-1 xs:w-full flex-1">
                <div className="footer-col-1">
                  <ul className="mt-4">
                    <li>
                      <a
                        href="/about"
                        className="text-white font-roboto text-base font-normal text-bg_skyblue"
                      >
                        About Us
                      </a>
                    </li>
                    <li>
                      <a
                        href="/privacypolicy"
                        className="text-white font-roboto text-base font-normal text-bg_skyblue"
                      >
                        Privacy policy
                      </a>
                    </li>
                    {/* <li>
                      <a href="#" className="text-lg font-normal text-white">
                        Policy
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="/termsofuse"
                        className="text-white font-roboto text-base font-normal text-bg_skyblue"
                      >
                        Terms
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:info@nuleep.com"
                        className="text-white font-roboto text-base font-normal text-bg_skyblue"
                      >
                        Contact Us
                      </a>
                    </li>
                    {/* <li>
                      <a href="#" className="text-lg font-normal text-white">
                        Help
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="footer-col-1 address-footer">
                  <h1 className="text-white font-roboto text-base font-normal">
                    Address
                  </h1>
                  <p className="text-white font-roboto text-base font-normal mt-16px">
                  8200 Wilshire Blvd Beverly Hills, CA 90211
                  </p>
                </div>
              </div>

              <div className="flex-1 sm:hidden"></div>
              <div className="flex-1 sm:hidden"></div>


              <div className="footer-rights mt-14 flex-1">
            <div className="grid-cols-2 sm:grid-cols-1 sm:text-center ">
              <div className="flex flex-col">
              <div className="social-media-logo">
                <div className="media-icon">
                  
                  <a
                    href="https://www.instagram.com/nuleep/"
                    className="inline-block mr-4"
                    target="_blank"
                  >
                   <img src={Insta} alt="insta"/>
                  </a>
                  <a
                    href="https://www.instagram.com/nuleep/"
                    className="inline-block mr-4"
                    target="_blank"
                  >
                   <img src={LinkedIn}  alt="linkedin"/>
                  </a>
                  <a
                    href="https://www.facebook.com/nuleep1"
                    className="inline-block"
                    target="_blank"
                  >
                    <img src={Facebook}  alt="facebbok"/>
                    {/* <svg
                      width="35"
                      height="35"
                      viewBox="0 0 35 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.5">
                        <path
                          d="M29.5576 3.98123H5.00126C4.41071 3.98123 3.93359 4.45061 3.93359 5.0316V29.1901C3.93359 29.771 4.41071 30.2404 5.00126 30.2404H29.5576C30.1482 30.2404 30.6253 29.771 30.6253 29.1901V5.0316C30.6253 4.45061 30.1482 3.98123 29.5576 3.98123ZM26.4747 11.6456H24.3427C22.6712 11.6456 22.3475 12.4268 22.3475 13.5757V16.1064H26.3379L25.8174 20.0683H22.3475V30.2404H18.187V20.0716H14.707V16.1064H18.187V13.1851C18.187 9.79436 20.2923 7.94637 23.3685 7.94637C24.8432 7.94637 26.1077 8.05469 26.4781 8.10393V11.6456H26.4747Z"
                          fill="white"
                        />
                      </g>
                    </svg> */}
                  </a>
                </div>
              </div>

              <div className="footer-copyrights sm:text-center">
                <p className="text-base font-normal text-white">
                  Copyright © 2021 Nuleep Inc.
                </p>
              </div>
              </div>
              
              
            </div>
          </div>
              <div>
                
              </div>
              </div>
          
        </div>
      </div>
    </div>
  );
}
export default Footer;
