import React from "react";
import SearchSelection from "./SearchSelection";

const SearchSelectionList = () => {

    const items = [{ id: 1, name: "Item 1" }, { id: 2, name: "Item 2" }, { id: 3, name: "Item 3" }, { id: 4, name: "Item 4" }, { id: 5, name: "Item 5" }, { id: 6, name: "Item 6" }, { id: 7, name: "Item 7" }, { id: 8, name: "Item 8" }]

    return <div>
        <SearchSelection items={items} />
    </div>
}

export default SearchSelectionList;