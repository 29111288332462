import React, {useState} from "react";

const TestComponent = () => {

    const [searchQuery, setQuery] = useState();
    const [items, setItems] = useState([{ id: 1, name: "Item 1" }, { id: 2, name: "Item 2" }, { id: 3, name: "Item 3" }, { id: 4, name: "Item 4" }, { id: 5, name: "Item 5" }, { id: 6, name: "Item 6" }, { id: 7, name: "Item 7" }, { id: 8, name: "Item 8" }])

    const filterText = (text) => {
        for (let i=0; i<items.length; i++) {
            getHighlightedText(items[i].name, text.target.value)
        }
    }

    // const getHighlightedText = (text, highlight) => {
    //     // Split on highlight term and include term into parts, ignore case
    //     const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    //     return <span> { parts.map((part, i) => 
    //         <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {} }>
    //             { part }
    //         </span>)
    //     } </span>;
    // }

    const getHighlightedText = (text, highlight) => {
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

        // console.log(highlight)
        // console.log(parts)
        return parts.map((part, i) => {

            if (part.toLowerCase() === highlight.toLowerCase()) {
                console.log(part)
            }
            else {
                console.log(part)
            }
            return part
        })

            // <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {} }>
            //     { part }
            // </span>)
    }

    return <div>
        {/* <input value={searchQuery} onChange={(text) => {
            setQuery(text.target.value)
            filterText(text)
            }}  placeholder='Input Text'/> */}

            <input type="checkbox" />
    </div>
}

export default TestComponent