import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../components/layouts/defaultNavbar'
import Footer from '../../components/layouts/Footer'
import compass1 from '../../assets/landingPages/compass1.png'
import computer1 from '../../assets/landingPages/computer1.png'
import shuttle1 from '../../assets/landingPages/shuttle1.png'
// import image6 from '../../assets/landingPages/image6.png';
// import netflix from '../../assets/landingPages/netflix.png';
import amazon from '../../assets/landingPages/amazon.png'
import disney from '../../assets/landingPages/disney.png'
import att from '../../assets/landingPages/att.png'
import facebook from '../../assets/landingPages/facebook.png'
import hero2 from '../../assets/landingPages/hero2.png'
import hero1 from '../../assets/landingPages/hero1.png'
import { mailChimpApi } from '../../store/auth/index'
import { useDispatch } from 'react-redux'
import JoinCommunity from '../../components/JoinCommunity';
import PartnerHomeModel from '../../assets/images/Partner-Home-model.png';
import Leaf from '../../assets/SVG/Leaf.svg';
import ReadyToEngage from '../../assets/images/Ready-to-engage.png';
import BuildTeam from '../../assets/SVG/BuildTeam.svg';
import CareerDevelopment from '../../assets/SVG/CareerDevelopment.svg'
import Networking from '../../assets/SVG/Networking.svg';
import CarrerJourney from '../../assets/SVG/CarrerJourneyMap.svg';
import TeamBuilding from '../../assets/SVG/TeamBuildingPlatform.svg';
import ExperientalLearning from '../../assets/SVG/ExperientialLearning.svg';
import DataDriven from '../../assets/SVG/DataDrivenReport.svg';
import Leadership from '../../assets/SVG/LeaderDashboard.svg';
import HumanRelation from '../../assets/SVG/HumanRelationship.svg';
import Meta from '../../../src/assets/SVG/Meta.svg';
import Nike from '../../../src/assets/SVG/Nike.svg';
import AILA from '../../../src/assets/SVG/AILA.svg';
import Seed from '../../../src/assets/SVG/seed.svg';
import LACI from '../../../src/assets/SVG/LACI.svg';
import WomenEnterpreneurs from '../../../src/assets/SVG/women-enterpreneurs.svg';
import { Field, Form } from 'react-final-form'
import NuleepBlack from '../../../src/assets/SVG/NuleepBlack.svg'
import { singleSignin } from '../../store/auth'
import Signup from '../auth/Signup'
import ForgotPassword from '../auth/ForgotPassword'
import PartnersSignup from '../auth/partners/PartnersSignup'

import CommunityHands from '../../../src/assets/SVG/Community-Hands-1.svg';
import CommunityHandsOverlay from '../../../src/assets/SVG/Community-Hands-Overlay.svg';

import {
  UserGroupIcon,
  MapIcon,
  BeakerIcon,
  CalculatorIcon,
  TrendingUpIcon,
  ShareIcon,
} from '@heroicons/react/outline'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BetaSignage from '../../components/layouts/betaSignage'

const PartnersLandingPage = () => {
  let history = useHistory()
  let dispatch = useDispatch()

  const [showTransparentLayer, setTransparentLayer] = useState(false)
  const [loginVisible, setLoginVisible] = useState(false)
  const [isSignupClicked, setSignUpClicked] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubscribeSubmit = (data) => {
     dispatch(mailChimpApi({email_address:data.emailAddress,
    status:"subscribed"})).then((res)=>{
      if(res.data.statusCode === 200){
        toast.success(res.data.success);
      }else toast.error(res.data.message);
    }).catch((res)=>{
      toast.error(res.message)});
  };

  const pageCall = (type) => {
    history.push({
      pathname: '/partnersSignup',
    })
  }

  const closeLoginSection = () => {
    setForgotPassword(false)
    setLoginVisible(false)
    setSignUpClicked(false)
    setTransparentLayer(false)
  }

  const renderAlert = () => {
    // if (this.props.errorMessage) {
    //   return (
    //     <p className="mt-4 font-bold tracking-wide text-red-400">
    //       {this.props.errorMessage}
    //     </p>
    //   )
    // }
  }

  const onSignInClicked = (e) => {
    setForgotPassword(false)
    setSignUpClicked(false)
    setTransparentLayer(true)
    setLoginVisible(true)
  }

  const onSignUpClicked = () => {
    setForgotPassword(false)
    setTransparentLayer(true)
    setSignUpClicked(true)
  }

  const forgotPasswordAction = () => {
    setLoginVisible(false)
    setSignUpClicked(false)
    setTransparentLayer(true)
    setForgotPassword(true)
  }

  return (
    <div style={{ height: '100vh', overflowY: showTransparentLayer ? 'hidden' : 'scroll' }}>
      <Navbar signInEvent={onSignInClicked} singUpEvent={onSignUpClicked} />

      <ToastContainer />
      {/* <BetaSignage /> */}
      <div className="font-nunito">
        {/* Hero section:  */}
        <section className="mx-4 flex container mx-auto max-w-7xl">
          <div className="mx-auto md:flex flex w-full sm:flex-col">
            {/* text */}
            <div className="flex-4 flex pt-60px sm:pt-0 items-center mb-102px">
              <div className="text-center pr-20px md:text-left">
                <h2 className="mt-3 text-64px font-bold leading-normal text-gray-600 font-roboto" style={{ fontSize: '64px', fontWeight: 700, background: 'linear-gradient(90deg, #10E9D4 1.14%, #FC52FF 197.16%)', webkitBackgroundClip: 'text', webkitTextFillColor: 'transparent' }}>
                  Hire Train & Inspire your Teams
                </h2>
                <p className="mt-3 text-xl leading-normal text-gray-600 font-roboto font-normal text-xl text-black">
                  Engage and train the best teams with top resources and real people.
                  Match skills, build experiences, and create growth in your community.
                </p>

                <p className="mt-3 text-xl leading-normal text-gray-600 font-roboto font-normal text-xl text-black">
                  Partner with us to showcase your authentic story and connect with the best team for your company.
                </p>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    onSignUpClicked()
                    // pageCall('recruiter')
                    // history.push({
                    //   pathName: "/signup",
                    //   state: { type: "jobSeeker", status: true },
                    // });
                  }}
                  style={{ backgroundColor: '#000000' }}
                  className="inline-block px-16 py-4 mt-5 text-xl font-bold tracking-wider text-bg_skyblue mt-50px rounded-full"
                >
                  Start free trial
                </a>
                {/* <Link
                  to="/signup"
                  style={{ backgroundColor: "#16ACB2" }}
                  className="inline-block px-16 py-4 mt-5 text-xl font-bold tracking-wider text-white rounded-full"
                >
                  Sign up today
                </Link> */}
              </div>
            </div>
            {/* Image */}
            <div
              className="flex flex-2 sm:justify-center mb-25px sm:mb-78px"

            >

              <div className="sm:mr-0">
                <div className='flex flex-col'>
                  <img src={PartnerHomeModel} className="" alt="hero" />
                  <img src={Leaf} style={{ width: 189, height: 127 }} className='mt-18.5px ml-22px hidden' />
                </div>

              </div>
            </div>
          </div>
        </section>

        {/* what we do */}
        <section className='pt-60px' >
          <div className='bg-black w-full h-full flex justify-center'>
            <div className="bg-black flex relative w-full h-full flex-col max-w-7xl">

              {/* image2 */}
              <div className="relative flex flex-row w-full justify-center" >
                <div className="absolute -top-100px flex-1 container sm:flex sm:justify-center">
                  <img src={ReadyToEngage} style={{ borderTopRightRadius: 100, borderBottomLeftRadius: 100 }} className="max-w-none h-350px sm:w-350px sm:h-330px" alt="hero" />
                </div>
                <div className='flex flex flex-col mt-63px md:ml-20% sm:mt-287px sm:items-center sm:px-10px'>
                  <h1 className='text-white font-roboto font-normal' style={{ fontSize: 80 }}>70%</h1>
                  <p className='text-white font-roboto font-normal text-32px'>of the workforce will be</p>
                  <p className='text-white font-roboto font-normal text-32px'>GEN Z and Millennials by 2030</p>
                </div>
              </div>

              <div className='flex-1 items-center sm:mx-10px mt-10px ml-72px mt-72px flex flex-row'>
                <div className='w-full'>
                  <h1 className='text-white font-roboto text-6xl mt-8' style={{ fontSize: '64px', fontWeight: 700, background: 'linear-gradient(90deg, #10E9D4 1.14%, #FC52FF 197.16%)', webkitBackgroundClip: 'text', webkitTextFillColor: 'transparent' }}>Are you ready to engage, build and promote the newest generations?</h1>
                  <div className='flex flex-row'>
                    <div className='flex-1'>
                      <p className='font-roboto text-3238px font-normal text-white mt-15px'>
                        We are a leadership and workforce platform that navigates the next generation in building skills and experiences [Gen Z and Gen ALPHA].
                        People and data are key in how we drive change in education, jobs, and the workplace.
                        This year, we partnered with <u><a href='https://www.joinai.la/' target="_blank" >AILA</a></u> to release the latest AI data and tech jobs by interviewing top companies and jobseekers. By leveraging research, our industry leaders have the latest trends and data on technology, training, and workforce in our communities.
                      </p>
                    </div>

                  </div>
                  <a href="/about" className='flex mt-50px mb-78px'>
                    <div className='text-xl text-black-500 border-b-2 border-black-500 rounded-full bg-white px-6 py-3'>
                      <p>Learn more about Nuleep</p>
                    </div>
                  </a>
                </div>
                <div className='flex-1'></div>

              </div>
              {/* text */}
              <div className='flex'>

              </div>

            </div>
          </div>


        </section>

        {/* How it works */}
        <section>
          <div
            style={{}}
            className="text-center flex flex-col items-center bg-grayFAFAFA pb-60px"
          >
            <div className="text-lg md:max-w-3xl w-full">
              <h3
                style={{ color: '#155E75' }}
                className="pt-16 mx-auto text-2xl font-roboto text-5xl font-medium text-center"
              >
                We enable top talent ready to
                hit the ground running
              </h3>

            </div>
            <div className="flex-wrap items-center gap-4 px-4 mt-96px md:flex md:flex-nowrap md:justify-between max-w-7xl">
              <div className="max-w-sm py-10 mx-auto mt-8 bg-white rounded-md shadow">
                <img
                  className="block mx-auto"
                  src={BuildTeam}
                  alt="compass icon"
                />
                <h3 className="text-2xl mt-16px font-nunito font-semibold">
                  Building Teams
                </h3>
                <p className="px-8 mt-3 text-base font-normal font-roboto">
                  Build teams that innovate and create impact. Leadership starts on Day 1. Provide Inclusion, Belonging, and Culture together with top experts.
                </p>
              </div>
              <div className="max-w-sm py-10 mx-auto mt-8 bg-white rounded-md shadow">
                <img
                  className="block mx-auto"
                  src={CareerDevelopment}
                  alt="computer icon"
                />
                <h3 className="text-2xl mt-16px font-nunito font-semibold">
                  Career Development
                </h3>
                <p className="px-8 mt-3 text-base font-normal font-roboto">
                  Provide experiential learning programs to your teams. Customize your team's experience with explorations of diverse pathways and growth opportunities.
                </p>
              </div>
              <div className="max-w-sm py-10 mx-auto mt-8 bg-white rounded-md shadow">
                <img
                  className="block mx-auto"
                  src={Networking}
                  alt="shuttle icon"
                />
                <h3 className="text-2xl mt-16px font-nunito font-semibold">
                  Networking
                </h3>
                <p className="px-8 mt-3 text-base font-normal font-roboto">
                  Be a part of our community and create connections that matter for your teams, company, and communities with real people and technology.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Our Services*/}
        <section className='pt-72px'>
          <div className="px-4 mx-auto flex flex-col items-center">
            <div className="max-w-3xl text-center md:text-left flex flex-col items-center">
              <h3 style={{ color: '#155E75' }} className="font-nunito font-light text-5xl text-black">
                Our <span className='font-nunito text-5xl font-medium'>Services</span>
              </h3>
            </div>
            {/* UserGroupIcon, MapIcon, BeakerIcon, CalculatorIcon,
                            TrendingUpIcon, ShareIcon, */}
            <div className="flex flex-wrap sm:grid-cols-2 md:grid-cols-3 mt-11 bg-bg_skyblue">
              <div className="sm:flex-50% flex-33% relative text-center flex flex-col bg-bg_skyblue pr-px">
                <div className='w-full h-full mr-px flex flex-col bg-white'>
                  <div
                    style={{}}
                    className="inset-x-0 flex items-center justify-center w-16 h-16 mx-auto rounded-md "
                  >
                    <img src={TeamBuilding} className="" />
                  </div>
                  <div className="pt-12 mb-12">
                    <h3 className="font-semibold text-2xl font-nunito">
                      Team Building Platform
                    </h3>
                  </div>
                </div>
              </div>
              <div className="sm:flex-50% flex-33% relative text-center flex flex-col bg-bg_skyblue sm:pr-0 pr-px">
                <div className='w-full h-full flex flex-col bg-white'>
                  <div
                    style={{}}
                    className="inset-x-0 flex items-center justify-center w-16 h-16 mx-auto rounded-md"
                  >
                    <img src={CarrerJourney} className="" />
                  </div>
                  <div className="pt-12 mb-12">
                    <h3 className="font-semibold text-2xl font-nunito">
                      Career Journey Map
                    </h3>
                  </div>
                </div>
              </div>
              <div className="sm:flex-50% flex-33% relative text-center flex flex-col bg-bg_skyblue sm:justify-center sm:pr-px">
                <div className='w-full h-full flex flex-col bg-white sm:mt-px pt-12 '>
                  <div
                    style={{}}
                    className="inset-x-0 flex items-center justify-center w-16 h-16 mx-auto rounded-md"
                  >
                    <img src={ExperientalLearning} className="" />
                  </div>
                  <div className="pt-12 mb-12">
                    <h3 className="font-semibold text-2xl font-nunito">
                      Experiential Learning
                    </h3>
                  </div>
                </div>
              </div>
              <div className="sm:flex-50% flex-33% relative text-center flex flex-col bg-bg_skyblue pr-px sm:pr-0">
                <div className='w-full h-full flex flex-col bg-white mt-px pt-12 '>
                  <div
                    style={{}}
                    className="inset-x-0 flex items-center justify-center w-16 h-16 mx-auto rounded-md"
                  >
                    <img src={DataDriven} className="h-62px" />
                  </div>
                  <div className="pt-12 mb-12">
                    <h3 className="font-semibold text-2xl font-nunito">
                      Data Driven Reports
                    </h3>
                  </div>
                </div>
              </div>
              <div className="sm:flex-50% flex-33% relative text-center flex flex-col pr-px  bg-bg_skyblue">
                <div className='w-full h-full flex flex-col bg-white mt-px pt-12 '>
                  <div
                    style={{}}
                    className="inset-x-0 flex items-center justify-center w-16 h-16 mx-auto rounded-md"
                  >
                    <img src={Leadership} className="" />{' '}
                  </div>
                  <div className="pt-12 mb-12">
                    <h3 className="font-semibold text-2xl font-nunito">
                      Leadership, Culture, and Impact
                    </h3>
                  </div>
                </div>
              </div>
              <div className="sm:flex-50% flex-33% relative text-center flex flex-col bg-white pt-12 mt-px">
                <div
                  style={{}}
                  className="inset-x-0 flex items-center justify-center w-16 h-16 mx-auto rounded-md"
                >
                  <img src={HumanRelation} className="" />
                </div>
                <div className="pt-12 mb-12">
                  <h3 className="font-semibold text-2xl font-nunito">
                    Human Relationships
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Netflix Testinominal */}
        {/* <section>
                        <div className="px-4 mx-auto mt-48 xl:px-0 max-w-7xl ">
                            <img
                                src={image6}
                                className="object-cover w-full mx-auto h-96 rounded-xl"
                                alt="testimonial bg"
                            />
                            <div className="max-w-3xl p-6 px-12 text-left shadow -mt-80 md:-mt-80 ">
                                <img src={netflix} alt="netflix logo" />
                                <h2 className="py-4 text-xl tracking-normal text-white md:text-3xl">
                                    Nuleep has completely changed the direction
                                    of my career. I have never felt more in
                                    control of where I am and where I need to
                                    go.
                                </h2>
                                <h3 className="text-lg tracking-wider text-white md:text-xl">
                                    Devin Johnson
                                </h3>
                                <h3 className="text-lg tracking-wider text-white md:text-xl">
                                    Marketing Manager, Netflix
                                </h3>
                            </div>
                        </div>
                    </section> */}

        {/* patners logos */}
        <section className="px-4 mx-auto lg:px-0 mt-36 bg-grayFAFAFA">
          <div className='max-w-7xl mx-auto  py-16'>
            <p className="mx-auto text-5xl tracking-wider text-center text-gray-400">
              Our Partnerships
            </p>
            <p className='mx-auto text-32px mt-22px tracking-wider text-center text-gray-400 max-w-6xl'>
              Trusted by the most innovative and impactful
              companies and employees
            </p>

            <div className='flex flex-col'>
              <div className="flex-wrap flex justify-center items-center mt-12 md:flex">
                <div className='h-98px bg-white lock mx-auto mt-12 md:mt-0 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                  <img
                    className="block mx-auto md:mt-0"
                    src={Meta}
                    alt=""
                  ></img>
                </div>
                <div className='h-98px bg-white lock mx-auto mt-12 md:mt-0 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                  <img
                    className="block mx-auto md:mt-0"
                    src={amazon}
                    alt=""
                  ></img>
                </div>
                <div className='h-98px bg-white lock mx-auto mt-12 md:mt-0 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                  <img
                    className="block mx-auto md:mt-0"
                    src={disney}
                    alt=""
                  ></img>
                </div>
                <div className='h-98px bg-white lock mx-auto mt-12 md:mt-0 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                  <img className="block mx-auto md:mt-0" src={att} alt=""></img>
                </div>

                <div className='h-98px bg-white lock mx-auto mt-12 md:mt-12 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                  <img
                    className="block mx-auto md:mt-0"
                    src={AILA}
                    alt=""
                  ></img>
                </div>
                <div className='h-98px bg-white lock mx-auto mt-12 md:mt-12 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                  <img
                    className="block mx-auto md:mt-0"
                    src={Seed}
                    alt=""
                  ></img>
                </div>
                <div className='h-98px bg-white lock mx-auto mt-12 md:mt-12 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                  <img
                    className="block mx-auto md:mt-0"
                    src={LACI}
                    alt=""
                  ></img>
                </div>
                <div className='h-98px bg-white lock mx-auto mt-12 md:mt-12 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                  <img className="block mx-auto md:mt-0" src={WomenEnterpreneurs} alt=""></img>
                </div>
              </div>
            </div>

          </div>
        </section>

        {/* Community Partners community */}
        <section className="mt-102px" style={{}}>
          <div
            style={{ height: '739px' }}
            className="relative"
          >
            <div className='w-full' style={{ display: 'flex', height: 739, backgroundImage: `url(${CommunityHands})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} >
              <div className='w-full h-full sm:px-32px sm:flex-col px-72px items-center flex' style={{ backgroundImage: `url(${CommunityHandsOverlay})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                <div className='flex flex-1 flex-col'>
                  <div className='flex-1 sm:pr-0 pr-150px sm:flex sm:flex-col sm:justify-center'>
                    <h2 className='text-white text-5xl font-roboto font-medium'>Join the Nuleep Partner community</h2>
                    <p className='text-white font-roboto font-normal text-3xl mt-16px'>
                      Get updates on latest events, workshops and more!
                    </p>
                  </div>
                  <div className='flex-1 sm:hidden'></div>

                </div>
                <div className='flex flex-1 flex-col sm:w-full'>
                  <div className='flex flex-1 w-full'>
                  <form className="sm:flex"onSubmit={handleSubmit(onSubscribeSubmit)}>
                    <div style={{ backgroundColor: '#FFFFFF66' }} className='px-32px py-32px rounded-2xl flex flex-col sm:w-full'>
                      <div>
                        <input type='email' placeholder='Enter your email' style={{border:`${errors.emailAddress?'red':'white'} solid 2px`}}
                        {...register("emailAddress", { required: true })} id="emailAddress"
                        name="emailAddress" className='text-roboto text-2xl px-24px py-4 rounded-full sm:w-full w-full' />
                      </div>
                      <div className='flex w-full items-center mt-4'>
                        <div className='py-4 w-full bg-black rounded-full flex justify-center font-roboto font-normal '>
                        <button type="submit"  className='text-bg_skyblue font-roboto font-normal text-2xl'>Subscribe</button>
                        </div>
                      </div>

                      <p className='text-white font-roboto font-normal text-base mt-4'>We care about protection of your data. Read our <Link
                        to="/privacypolicy"
                        className=" font-medium underline"
                      >
                      Privacy Policy
                    </Link></p>
                    </div>
                  </form>
                  </div>
                  <div className='flex-1 flex sm:hidden'></div>
                </div>
              </div>
            </div>
          </div>


        </section>

        {/* Newsletter */}
        {/* <JoinCommunity /> */}


      </div>

      {
        <div className='flex absolute top-0 w-full h-full z-10 bg-bgBlackTransparent77 overflow-hidden' onClick={closeLoginSection} style={{ right: showTransparentLayer ? 0 : '-100%', width: showTransparentLayer ? '100%' : 0, backgroundColor: '#000000BB' }}>
          {
            loginVisible && <div className='flex absolute top-0 bg-white h-full' style={{ right: loginVisible ? 0 : -590, transition: '2s' }} onClick={(e) => e.stopPropagation()}>
              <div className="flex min-h-screen bg-white justify-center">
                <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                  <div className="w-full max-w-sm mx-auto lg:w-96">
                    <div>
                      <Link to="/">
                        <img className="w-auto h-12" src={NuleepBlack} alt="Nuleep Logo" />
                      </Link>
                      <h2 className="text-3244px font-semibold text-gray-900 font-nunito">
                        Sign in to your account
                      </h2>
                      <p className="mt-2 text-sm text-gray-600 hidden">
                        Or{' '}
                        <Link
                          to="/signup"
                          className="font-medium text-teal-600 hover:text-teal-500 hidden"
                        >
                          Signup for a new account
                        </Link>
                      </p>
                    </div>

                    <div className="">
                      <div className="mt-6">
                        <Form
                          onSubmit={({ email, password }) => {
                            if (!email || !password) {
                              return null
                            }
                            email = email.toLowerCase()
                            //1208 - Allow for credentials to work for all login pages.
                            singleSignin({
                              email,
                              password,
                              history,
                              type: 'recruiter',
                              dispatch
                            })
                          }}
                        >
                          {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Email address
                              </label>
                              <Field name="email">
                                {(props) => (
                                  <div>
                                    <input
                                      id="email"
                                      type="text"
                                      className="block w-full px-3 py-2 mt-1 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                                      name={props.input.email}
                                      value={props.input.value}
                                      onChange={props.input.onChange}
                                    />
                                    {props.meta.touched && props.meta.error && (
                                      <span className="block mt-2 text-red-500">
                                        {props.meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                              <div className="mt-6">
                                <label
                                  htmlFor="password"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Password
                                </label>
                              </div>

                              <Field name="password">
                                {(props) => (
                                  <div>
                                    <input
                                      id="password"
                                      type="password"
                                      className="w-full p-2 mt-1 border border-gray-300 rounded-lg"
                                      name={props.input.password}
                                      value={props.input.value}
                                      onChange={props.input.onChange}
                                    />
                                    {props.meta.touched && props.meta.error && (
                                      <span className="block mt-2 text-red-500">
                                        {props.meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                              {renderAlert()}
                              <div className="flex items-center justify-between mt-4">
                                <div className="flex items-center">
                                  <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="w-4 h-4 text-teal-600 border-gray-300 rounded focus:ring-teal-500"
                                  />
                                  <label
                                    htmlFor="remember-me"
                                    className="block ml-2 text-sm text-gray-900"
                                  >
                                    Remember me
                                  </label>
                                </div>

                                <div className="text-sm">
                                  <div
                                    to='/'
                                    onClick={forgotPasswordAction}
                                    className="font-medium text-teal-600 hover:text-teal-500 cursor-pointer"
                                  >
                                    Forgot your password?
                                  </div>
                                </div>
                              </div>
                              <button
                                className="flex justify-center w-full px-4 py-2 mt-4 text-sm font-medium text-bg_skyblue bg-black border border-transparent rounded-full shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                type="submit"
                              >
                                Log in
                              </button>
                              <Link
                                to={'/'}
                                className="inline-block pb-2 mt-4 tracking-wide text-center text-gray-400 underline hidden"
                              >
                                Go back
                              </Link>
                            </form>
                          )}
                        </Form>

                        <div className='flex flex-row mt-8 justify-end items-center'>
                          <div>
                            <p className='font-roboto font-semibold text-sm'>Don't have an account?</p>
                          </div>
                          <div className='ml-4 border border-black rounded-full px-6 py-5px cursor-pointer' onClick={onSignUpClicked}>
                            <p className='font-nunito font-medium text-lg'>Sign Up</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden flex-1 w-0">
                  <img
                    className="absolute inset-0 object-cover w-full h-full"
                    src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                    alt=""
                  />
                </div>
              </div>
            </div>
          }
          {
            isSignupClicked && <div className='flex absolute top-0 bg-white h-full' style={{ right: isSignupClicked ? 0 : -590, transition: '2s' }} onClick={(e) => e.stopPropagation()}>
              {/* <Signup signInAction={onSignInClicked} /> */}
              <div className='pt-4 overflow-y-auto'>
                <PartnersSignup signInAction={onSignInClicked} />
              </div>

            </div>
          }
          {
            forgotPassword && <div className='flex absolute top-0 bg-white h-full' style={{ right: forgotPassword ? 0 : -590, transition: '2s' }} onClick={(e) => e.stopPropagation()}>
              <ForgotPassword />
            </div>
          }
        </div>
      }
      <Footer />
    </div>
  )
}

export default PartnersLandingPage