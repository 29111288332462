import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import Navbar from '../../components/layouts/defaultNavbar';
import Footer from '../../components/layouts/Footer';
import { useDispatch } from 'react-redux';

import { Field, Form } from 'react-final-form'
import NuleepBlack from '../../../src/assets/SVG/NuleepBlack.svg'
//Images
import MissionPicture from '../../assets/landingPages/AboutMission.png';
import Grace from '../../assets/images/Grace.png';
import Luis from '../../assets/images/Luis.png';
import Mitchell from '../../assets/images/Mitchell.png';
import JoinCommunity from '../../components/JoinCommunity';
import PurposeModel from '../../assets/images/PurposeModel.png';
import CommunityBlack from '../../assets/SVG/Community-Black.svg';
import InclusionBlack from '../../assets/SVG/Inclusion-Black.svg';
import LeadersBlack from '../../assets/SVG/Leaders-Black.svg';
import MentorsBlack from '../../assets/SVG/Mentorship-Black.svg';
import SkillGrowthBlack from '../../assets/SVG/SkillGrowth-Black.svg';
import TeamWorkBlack from '../../assets/SVG/Teamwork-Black.svg';
import CommunityHands from '../../../src/assets/SVG/Community-Hands-1.svg';
import CommunityHandsOverlay from '../../../src/assets/SVG/Community-Hands-Overlay.svg';
import ContactUs from '../../assets/SVG/Contact-US.svg'
import TeamBgLinear from '../../assets/SVG/team-bg-linear.svg';
import YousunDo from '../../assets/images/Yousun.png';
import Ashley from '../../assets/images/Ashley.png';
import Erika from '../../assets/images/Erika.png';

import {
    UserIcon,
    AdjustmentsIcon,
    UserGroupIcon,
    MapIcon,
    LightBulbIcon,
    GlobeIcon,
} from '@heroicons/react/outline';
import { singleSignin } from '../../store/auth'
import { useHistory } from 'react-router-dom'
import Signup from '../auth/Signup'
import ForgotPassword from '../auth/ForgotPassword'
import { ToastContainer, toast } from 'react-toastify'
import { mailChimpApi } from '../../store/auth/index'

const About = () => {

    let history = useHistory()
    const dispatch = useDispatch()
    const [showTransparentLayer, setTransparentLayer] = useState(false)
    const [loginVisible, setLoginVisible] = useState(false)
    const [isSignupClicked, setSignUpClicked] = useState(false)
    const [forgotPassword, setForgotPassword] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubscribeSubmit = (data) => {
        dispatch(mailChimpApi({email_address:data.emailAddress,
       status:"subscribed"})).then((res)=>{
         if(res.data.statusCode === 200){
           toast.success(res.data.success);
         }else toast.error(res.data.message);
       }).catch((res)=>{
         toast.error(res.message)});
     }; 
    
    const onSignInClicked = (e) => {
        setForgotPassword(false)
        setSignUpClicked(false)
        setTransparentLayer(true)
        setLoginVisible(true)
    }

    const onSignUpClicked = () => {
        setForgotPassword(false)
        setTransparentLayer(true)
        setSignUpClicked(true)
    }

    const closeLoginSection = () => {
        setForgotPassword(false)
        setLoginVisible(false)
        setSignUpClicked(false)
        setTransparentLayer(false)
    }

    const forgotPasswordAction = () => {
        setLoginVisible(false)
        setSignUpClicked(false)
        setTransparentLayer(true)
        setForgotPassword(true)
    }

    const renderAlert = () => {
        // if (this.props.errorMessage) {
        //   return (
        //     <p className="mt-4 font-bold tracking-wide text-red-400">
        //       {this.props.errorMessage}
        //     </p>
        //   )
        // }
    }

    return (
        <div style={{ overflowY: showTransparentLayer ? 'hidden' : 'scroll' }}>
            <Navbar signInEvent={onSignInClicked} singUpEvent={onSignUpClicked} />
            <ToastContainer />
            <div className="md:max-w-sm max-w-7xl mx-auto md:max-w-full font-nunito bg-grayFAFAFA">
                {/* Mission Section */}
                <section className="px-4 mx-auto max-w-7xl sm:mx-15px">
                    <div className="items-center mt-12 md:flex sm:my-20 rounded-tl-3xl lg:rounded-bl-3xl md:rounded-bl-3xl sm:rounded-tr-3xl bg-white">

                        {/* Text */}
                        <div className="mt-5 md:w-1/2 md:ml-8">
                            <div className="mx-auto text-center md:max-w-lg md:text-left">
                                <p className="font-nunito font-normal text-6xl">
                                    Our Purpose
                                </p>
                                <p className='mt-3 text-3xl font-nunito font-normal'>is to build and connect<br />leaders and communities.</p>

                                <p className="mt-3 font-normal font-robototext-gray-600">
                                    Senior UX Designer. Chief Financial Officer. Head of Growth. Software Developer. AI Expert. Student. Sales Manager. You’re probably wondering what we all have in common: Purpose, Growth, and Impact. At Nuleep, teams focus on building skills, professional relationships, and toolkits to share and optimize together as a community.
                                    <br />
                                    <br />

                                    Our Partner companies lead in growth, inclusion, and impact. Leadership and recruiters utilize best practices, lived experiences, and data to make decisions at work. Our experts are from EY, Disney, AMGEM, top organizations, and our communities.
                                    <br /><b>You’re in the right place to build your future teams.</b>


                                </p>
                            </div>
                        </div>

                        {/* Image */}
                        <div className="md:w-1/2 flex justify-end lg:rounded-tr-3xl md:rounded-tr-3xl sm:rounded-bl-3xl rounded-br-3xl">
                            <div>
                                <img
                                    src={PurposeModel}
                                    className="lg:rounded-tr-3xl md:rounded-tr-3xl sm:rounded-bl-3xl rounded-br-3xl"
                                    alt=""
                                ></img>
                            </div>

                        </div>
                    </div>
                </section>

                {/* Values */}
                <section className="px-4 mx-auto mt-20 sm:py-px sm:px-0 py-72px flex flex-col items-center bg-grayFAFAFA">
                    <p className="text-xl font-light font-medium text-5xl font-roboto">
                        Our Values
                    </p>
                    <h2 className="mt-3 text-4xl leading-normal text-center text-gray-600 font-baskerville">
                        Grow together with the Nuleep Community
                    </h2>
                    <div className="flex flex-wrap w-85% sm:grid-cols-2 md:grid-cols-3 mt-40px bg-disable_bg_color">

                        <div className="flex flex-col text-center sm:flex-50% flex-33% pr-px ">
                            <div className='flex flex-col w-full h-full py-12 bg-grayFAFAFA'>
                                <div className="inset-x-0 flex items-center justify-center w-16 h-16 mx-auto rounded-full">
                                    <img src={TeamWorkBlack} className="" alt=""/>
                                </div>
                                <div className="pt-12">
                                    <h3 className="font-nunito font-semibold text-center text-2xl">
                                        Teamwork
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col text-center sm:flex-50% flex-33% sm:pr-0 pr-px">
                            <div className='flex flex-col w-full h-full py-12 bg-grayFAFAFA'>
                                <div className="inset-x-0 flex items-center justify-center w-16 h-16 mx-auto">
                                    <img src={LeadersBlack} className="" alt=""/>
                                </div>
                                <div className="pt-12 mb-12">
                                    <h3 className="font-nunito font-semibold text-center text-2xl">
                                        Leadership
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col text-center sm:flex-50% flex-33% sm:pt-px sm:pr-px">
                            <div className='flex flex-col w-full h-full py-12 bg-grayFAFAFA'>
                                <div className="inset-x-0 flex items-center justify-center w-16 h-16 mx-auto">
                                    <img src={SkillGrowthBlack} className="" alt=""/>
                                </div>
                                <div className="pt-12 mb-12">
                                    <h3 className="font-nunito font-semibold text-center text-2xl">
                                        Skills Growth
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col text-center sm:flex-50% flex-33% sm:pr-0 pr-px pt-px">
                            <div className='flex flex-col w-full h-full py-12 bg-grayFAFAFA'>
                                <div className="inset-x-0 flex items-center justify-center w-16 h-16 mx-auto">
                                    <img src={MentorsBlack} className="" alt=""/>
                                </div>
                                <div className="pt-12 mb-12">
                                    <h3 className="font-nunito font-semibold text-center text-2xl">
                                        Mentorship
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col text-center sm:flex-50% flex-33% pr-px pt-px">
                            <div className='flex flex-col w-full h-full py-12 bg-grayFAFAFA'>
                                <div className="inset-x-0 flex items-center justify-center w-16 h-16 mx-auto">
                                    <img src={CommunityBlack} className="" alt=""/>
                                </div>
                                <div className="pt-12 mb-12">
                                    <h3 className="font-nunito font-semibold text-center text-2xl">
                                        Community
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col text-center sm:flex-50% flex-33% pt-px">
                            <div className='flex flex-col w-full h-full py-12 bg-grayFAFAFA'>
                                <div className="inset-x-0 flex items-center justify-center w-16 h-16 mx-auto">
                                    <img src={InclusionBlack} className="" alt=""/>
                                </div>
                                <div className="pt-12 mb-12">
                                    <h3 className="font-nunito font-semibold text-center text-2xl">
                                        Inclusion & Impact
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Meet our team */}
                <section className="flex flex-col py-72px">
                    <div className="flex flex-col">
                        <div className="space-y-12 flex flex-col items-center">
                            {/* Text */}
                            <div className="flex flex-col items-center">
                                <p className="text-xl font-light font-medium text-5xl font-roboto">
                                    Our Team
                                </p>
                            </div>

                            <div className='flex flex-row w-full justify-center w-85%'>
                                <div className='flex flex-row justify-center flex-wrap'>
                                    <div className='flex flex-col lg:p-50px md:p-50px sm:p-0 sm:flex-50% flex-33%'>
                                        <div className='p-32px relative flex flex-col items-center justify-center'>

                                            <img
                                                className="rounded-full"
                                                src={Grace}
                                                alt=""
                                            />
                                        </div>

                                        <div className="flex flex-col items-center mt-4">
                                            <h3 className="text-2xl font-light flex justify-center space-x-5 font-roboto">
                                                Grace Park
                                            </h3>
                                            <p className='font-roboto font-bold text-base text-center'>
                                                CEO
                                            </p>
                                        </div>
                                    </div>

                                    <div className='sm:flex-50% flex-33% flex flex-col lg:p-50px md:p-50px sm:p-0'>
                                        <div className='p-32px relative flex flex-col items-center justify-center'>
                                            <img
                                                className="rounded-full"
                                                src={Luis}
                                                alt=""
                                            />

                                        </div>

                                        <div className="flex flex-col items-center mt-4">
                                            <h3 className="text-2xl font-light flex justify-center space-x-5 font-roboto">
                                                Luis Reyes
                                            </h3>
                                            <p className='font-roboto font-bold text-base text-center'>
                                                CTO
                                            </p>
                                        </div>
                                    </div>
                                    <div className='sm:flex-50% flex-33% flex flex-col lg:p-50px md:p-50px sm:p-0'>
                                        <div className='p-32px relative flex flex-col items-center justify-center'>

                                            <img
                                                className="rounded-full"
                                                src={Erika}
                                                alt=""
                                            />
                                        </div>

                                        <div className="flex flex-col items-center mt-4">
                                            <h3 className="text-2xl font-light flex justify-center space-x-5 font-roboto">
                                                Dr. Erika Page
                                            </h3>
                                            <p className='font-roboto font-bold text-base text-center'>
                                                Chief DEIB & Workforce Advisor
                                            </p>
                                        </div>
                                    </div>

                                    <div className='sm:flex-50% flex-33% flex flex-col lg:p-50px md:p-50px sm:p-0'>
                                        <div className='p-32px relative flex flex-col items-center justify-center'>

                                            <img
                                                className="rounded-full"
                                                src={Mitchell}
                                                alt=""
                                            />
                                        </div>

                                        <div className="flex flex-col items-center mt-4">
                                        <h3 className="text-2xl font-light flex justify-center text-center space-x-5 font-roboto">
                                                Mitchell Sekiya
                                            </h3>
                                            <p className='font-roboto font-bold text-base'>
                                                CDO
                                            </p>
                                        </div>
                                    </div>
                                    <div className='sm:flex-50% flex-33% flex flex-col lg:p-50px md:p-50px sm:p-0'>
                                        <div className='p-32px relative flex flex-col items-center justify-center'>

                                            <img
                                                className="rounded-full"
                                                src={YousunDo}
                                                alt=""
                                            />
                                        </div>

                                        <div className="flex flex-col items-center mt-4">
                                            <h3 className="text-2xl font-light flex justify-center space-x-5 font-roboto">
                                                Yousun Do
                                            </h3>
                                            <p className='font-roboto font-bold text-base'>Senior Manager</p>
                                            <p className="text-center text-black text-base font-normal font-roboto flex justify-center">
                                                Startup Head of South Korea
                                            </p>
                                        </div>
                                    </div>

                                    <div className='sm:flex-50% flex-33% flex flex-col lg:p-50px md:p-50px sm:p-0'>
                                        <div className='p-32px relative flex flex-col items-center justify-center'>


                                            <img
                                                className="rounded-full"
                                                src={Ashley}
                                                alt=""
                                            />
                                        </div>

                                        <div className="flex flex-col items-center mt-4">
                                            <h3 className="text-2xl font-light flex justify-center space-x-5 font-roboto">
                                                Ashley Kim
                                            </h3>
                                            <p className='font-roboto font-bold text-base text-center'>Marketing & <br /> Business Operations</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Contact us*/}
                <section className="flex items-center pt-80px">
                    <div className="flex flex-col items-center w-full">
                        <div className="flex flex-col items-center w-full">
                            <div>
                                <p className="text-xl font-light font-medium text-5xl font-roboto">
                                    Contact Us
                                </p>
                            </div>
                            <div className="flex flex-row  mt-59px rounded-[45px] bg-grayFAFAFA" style={{ borderTopRightRadius: 45, borderBottomRightRadius: 45, width: '80%' }}>
                                {/* Join our team */}
                                <div className="flex flex row w-full">
                                    <div className='flex-3' style={{ backgroundImage: `url(${ContactUs})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>

                                    </div>
                                    <div className='flex-2 flex flex-col ml-40px py-18px pr-72px'>
                                        <h3 className="text-2xl font-roboto font-bold">
                                            Join Our Team
                                        </h3>
                                        <dl className="mt-2 text-base text-gray-600 sm:ml-0">
                                            <div>
                                                <dt className="sr-only">Email</dt>
                                                <dd className='font-roboto text-2xl font-normal'>jane@nuleep.com</dd>
                                            </div>
                                        </dl>
                                        <div className="sm:ml-0 mt-45px">
                                            <h3 className="text-2xl font-roboto font-bold">
                                                Partnerships & Media Inquiries
                                            </h3>
                                            <dl className="mt-2 text-base text-gray-600">
                                                <div>
                                                    <dt className="sr-only">Email</dt>
                                                    <dd className='font-roboto text-2xl font-normal'>info@nuleep.com</dd>
                                                </div>
                                                <div className="mt-1">
                                                    <dt className="sr-only">
                                                        Phone number
                                                    </dt>
                                                    <dd className='font-roboto text-2xl font-normal'>+1 (213) 528-8030</dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>

                                </div>

                                {/* Say hello */}

                            </div>
                        </div>
                    </div>
                </section>

                {/* Community Partners community */}
                <section className="mt-102px" style={{}}>
                    <div
                        style={{ height: '739px' }}
                        className="relative"
                    >
                        <div className='w-full' style={{ display: 'flex', height: 739, backgroundImage: `url(${CommunityHands})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} >
                            <div className='w-full h-full sm:px-32px sm:flex-col px-72px items-center flex' style={{ backgroundImage: `url(${CommunityHandsOverlay})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                                <div className='flex flex-1 flex-col'>
                                    <div className='flex-1 sm:pr-0 pr-150px sm:flex sm:flex-col sm:justify-center'>
                                        <h2 className='text-white text-5xl font-roboto font-medium'>Join the Nuleep Partner community</h2>
                                        <p className='text-white font-roboto font-normal text-3xl mt-16px'>
                                            Get updates on latest events, workshops and more!
                                        </p>
                                    </div>
                                    <div className='flex-1 sm:hidden'></div>

                                </div>
                                <div className='flex flex-1 flex-col sm:w-full'>
                                    <div className='flex flex-1 w-full'>
                                    <form className="sm:flex"onSubmit={handleSubmit(onSubscribeSubmit)}>
                                        <div style={{ backgroundColor: '#FFFFFF66' }} className='px-32px py-32px rounded-2xl flex flex-col sm:w-full'>
                                            <div>
                                            <input type='email' placeholder='Enter your email' style={{border:`${errors.emailAddress?'red':'white'} solid 2px`}}
                                                {...register("emailAddress", { required: true })} id="emailAddress"
                                                name="emailAddress" className='text-roboto text-2xl px-24px py-4 rounded-full sm:w-full w-full' />
                                            </div>
                                            <div className='flex w-full items-center mt-4'>
                                                <div className='py-4 w-full bg-black rounded-full flex justify-center font-roboto font-normal '>
                                                <button type="submit" className='text-bg_skyblue font-roboto font-normal text-2xl'>Subscribe</button>
                                                </div>
                                            </div>

                                            <p className='text-white font-roboto font-normal text-base mt-4'>We care about protection of your data. Read our Privacy Policy</p>
                                        </div>
                                    </form>
                                    </div>
                                    <div className='flex-1 flex sm:hidden'></div>
                                </div>
                            </div>
                        </div>
                    </div>


                </section>

                {/* Newsletter */}
                {/* <JoinCommunity/> */}
            </div>

            {
                <div className='flex absolute top-0 w-full h-full z-10 bg-bgBlackTransparent77 overflow-hidden' onClick={closeLoginSection} style={{ right: showTransparentLayer ? 0 : '-100%', width: showTransparentLayer ? '100%' : 0, backgroundColor: '#000000BB' }}>
                    {
                        loginVisible && <div className='flex absolute top-0 bg-white h-full' style={{ right: loginVisible ? 0 : -590, transition: '2s' }} onClick={(e) => e.stopPropagation()}>
                            <div className="flex min-h-screen bg-white justify-center">
                                <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                                    <div className="w-full max-w-sm mx-auto lg:w-96">
                                        <div>
                                            <Link to="/">
                                                <img className="w-auto h-12" src={NuleepBlack} alt="Nuleep Logo" />
                                            </Link>
                                            <h2 className="text-3244px font-semibold text-gray-900 font-nunito">
                                                Sign in to your account
                                            </h2>
                                            <p className="mt-2 text-sm text-gray-600 hidden">
                                                Or{' '}
                                                <Link
                                                    to="/signup"
                                                    className="font-medium text-teal-600 hover:text-teal-500 hidden"
                                                >
                                                    Signup for a new account
                                                </Link>
                                            </p>
                                        </div>

                                        <div className="">
                                            <div className="mt-6">
                                                <Form
                                                    onSubmit={({ email, password }) => {
                                                        if (!email || !password) {
                                                            return null
                                                        }
                                                        email = email.toLowerCase()
                                                        //1208 - Allow for credentials to work for all login pages.
                                                        singleSignin({
                                                            email,
                                                            password,
                                                            history,
                                                            dispatch
                                                        })
                                                    }}
                                                >
                                                    {({ handleSubmit }) => (
                                                        <form onSubmit={handleSubmit}>
                                                            <label
                                                                htmlFor="email"
                                                                className="block text-sm font-medium text-gray-700"
                                                            >
                                                                Email address
                                                            </label>
                                                            <Field name="email">
                                                                {(props) => (
                                                                    <div>
                                                                        <input
                                                                            id="email"
                                                                            type="text"
                                                                            className="block w-full px-3 py-2 mt-1 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                                                                            name={props.input.email}
                                                                            value={props.input.value}
                                                                            onChange={props.input.onChange}
                                                                        />
                                                                        {props.meta.touched && props.meta.error && (
                                                                            <span className="block mt-2 text-red-500">
                                                                                {props.meta.error}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </Field>
                                                            <div className="mt-6">
                                                                <label
                                                                    htmlFor="password"
                                                                    className="block text-sm font-medium text-gray-700"
                                                                >
                                                                    Password
                                                                </label>
                                                            </div>

                                                            <Field name="password">
                                                                {(props) => (
                                                                    <div>
                                                                        <input
                                                                            id="password"
                                                                            type="password"
                                                                            className="w-full p-2 mt-1 border border-gray-300 rounded-lg"
                                                                            name={props.input.password}
                                                                            value={props.input.value}
                                                                            onChange={props.input.onChange}
                                                                        />
                                                                        {props.meta.touched && props.meta.error && (
                                                                            <span className="block mt-2 text-red-500">
                                                                                {props.meta.error}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </Field>
                                                            {renderAlert()}
                                                            <div className="flex items-center justify-between mt-4">
                                                                <div className="flex items-center">
                                                                    <input
                                                                        id="remember-me"
                                                                        name="remember-me"
                                                                        type="checkbox"
                                                                        className="w-4 h-4 text-teal-600 border-gray-300 rounded focus:ring-teal-500"
                                                                    />
                                                                    <label
                                                                        htmlFor="remember-me"
                                                                        className="block ml-2 text-sm text-gray-900"
                                                                    >
                                                                        Remember me
                                                                    </label>
                                                                </div>

                                                                <div className="text-sm">
                                                                    <div
                                                                        to='/'
                                                                        onClick={forgotPasswordAction}
                                                                        className="font-medium text-teal-600 hover:text-teal-500 cursor-pointer"
                                                                    >
                                                                        Forgot your password?
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button
                                                                className="flex justify-center w-full px-4 py-2 mt-4 text-sm font-medium text-bg_skyblue bg-black border border-transparent rounded-full shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                                                type="submit"
                                                            >
                                                                Log in
                                                            </button>
                                                            <Link
                                                                to={'/'}
                                                                className="inline-block pb-2 mt-4 tracking-wide text-center text-gray-400 underline hidden"
                                                            >
                                                                Go back
                                                            </Link>
                                                        </form>
                                                    )}
                                                </Form>

                                                <div className='flex flex-row mt-8 justify-end items-center'>
                                                    <div>
                                                        <p className='font-roboto font-semibold text-sm'>Don't have an account?</p>
                                                    </div>
                                                    <div className='ml-4 border border-black rounded-full px-6 py-5px cursor-pointer' onClick={onSignUpClicked}>
                                                        <p className='font-nunito font-medium text-lg'>Sign Up</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hidden flex-1 w-0">
                                    <img
                                        className="absolute inset-0 object-cover w-full h-full"
                                        src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        isSignupClicked && <div className='flex absolute top-0 bg-white h-full' style={{ right: isSignupClicked ? 0 : -590, transition: '2s' }} onClick={(e) => e.stopPropagation()}>
                            <Signup signInAction={onSignInClicked} />
                        </div>
                    }
                    {
                        forgotPassword && <div className='flex absolute top-0 bg-white h-full' style={{ right: forgotPassword ? 0 : -590, transition: '2s' }} onClick={(e) => e.stopPropagation()}>
                            <ForgotPassword />
                        </div>
                    }
                </div>
            }
            <Footer />
        </div>
    );
};

export default About;