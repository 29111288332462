export default [
"Alabama A & M University",
"University of Alabama at Birmingham",
"Amridge University",
"University of Alabama in Huntsville",
"Alabama State University",
"University of Alabama System Office",
"The University of Alabama",
"Central Alabama Community College",
"Athens State University",
"Auburn University at Montgomery",
"Auburn University",
"Birmingham Southern College",
"Chattahoochee Valley Community College",
"Concordia College Alabama",
"South University Montgomery",
"Enterprise State Community College",
"James H Faulkner State Community College",
"Faulkner University",
"Gadsden State Community College",
"New Beginning College of Cosmetology",
"George C Wallace State Community College Dothan",
"George C Wallace State Community College Hanceville",
"George C Wallace State Community College Selma",
"Herzing University Birmingham",
"Huntingdon College",
"Heritage Christian University",
"J F Drake State Community and Technical College",
"J F Ingram State Technical College",
"Jacksonville State University",
"Jefferson Davis Community College",
"Jefferson State Community College",
"John C Calhoun State Community College",
"Judson College",
"Lawson State Community College Birmingham Campus",
"University of West Alabama",
"Lurleen B Wallace Community College",
"Marion Military Institute",
"Miles College",
"University of Mobile",
"University of Montevallo",
"Northwest Shoals Community College",
"University of North Alabama",
"Northeast Alabama Community College",
"Oakwood University",
"Alabama Southern Community College",
"Prince Institute Southeast",
"Reid State Technical College",
"Bishop State Community College",
"Samford University",
"Selma University",
"Shelton State Community College",
"Snead State Community College",
"University of South Alabama",
"Spring Hill College",
"Southeastern Bible College",
"Stillman College",
"Talladega College",
"H Councill Trenholm State Technical College",
"Troy University",
"Tuskegee University",
"United States Sports Academy",
"Bevill State Community College",
"University of Alaska Anchorage",
"Alaska Bible College",
"University of Alaska Fairbanks",
"University of Alaska Southeast",
"Alaska Pacific University",
"AVTEC Alaskas Institute of Technology",
"Charter College Anchorage",
"Prince William Sound Community College",
"Alaska Career College",
"University of Alaska System of Higher Education",
"Everest College Phoenix",
"Collins College",
"Empire Beauty School Paradise Valley",
"Empire Beauty School Tucson",
"Thunderbird School of Global Management",
"American Indian College of the Assemblies of God Inc",
"American Institute of Technology",
"Carrington College Phoenix",
"Carrington College Mesa",
"Carrington College Tucson",
"College America Flagstaff",
"Arizona Academy of Beauty East",
"Arizona Automotive Institute",
"Brookline College Phoenix",
"Arizona State University Tempe",
"Arizona Western College",
"University of Arizona",
"Southwest University of Visual Arts Tucson",
"Brillare Hairdressing Academy",
"Central Arizona College",
"Brown Mackie College Tucson",
"Charles of Italy Beauty College",
"Cochise College",
"Empire Beauty School Flagstaff",
"Empire Beauty School Chandler",
"Cortiva Institute Tucson",
"DeVry University Arizona",
"Avalon School of Cosmetology Mesa",
"Eastern Arizona College",
"Embry Riddle Aeronautical University Prescott",
"Frank Lloyd Wright School of Architecture",
"Glendale Community College",
"Grand Canyon University",
"Anthem College Phoenix",
"International Academy of Hair Design",
"Kaplan College Phoenix",
"Maricopa Community College System Office",
"GateWay Community College",
"Mesa Community College",
"ITT Technical Institute Tucson",
"ITT Technical Institute Tempe",
"Mohave Community College",
"Universal Technical Institute of Arizona Inc Motorcycle Mechanics Institute Division",
"Dine College",
"Northern Arizona University",
"Northland Pioneer College",
"Ottawa University Phoenix",
"Phoenix College",
"Cortiva Institute Scottsdale",
"University of Phoenix Phoenix Campus",
"Pima Community College",
"Pima Medical Institute Tucson",
"Pima Medical Institute Albuquerque",
"Prescott College",
"Refrigeration School Inc",
"Rio Salado College",
"Roberto Venn School of Luthiery",
"Hair Academy of Safford",
"Scottsdale Community College",
"South Mountain Community College",
"Arizona Christian University",
"Tucson College",
"Universal Technical Institute of Arizona Inc",
"Western International University",
"Empire Beauty School NW Phoenix",
"Yavapai College",
"University of Arkansas at Little Rock",
"University of Arkansas for Medical Sciences",
"ABC Beauty College Inc",
"Arkansas Baptist College",
"Arkansas Beauty School",
"Arkansas Beauty College",
"Lyon College",
"Arkansas College of Barbering and Hair Design",
"Arthurs Beauty College Inc Fort Smith",
"University of Arkansas",
"University of Arkansas at Pine Bluff",
"Arkansas State University Beebe",
"Arkansas State University Main Campus",
"Arkansas Tech University",
"University of Arkansas at Monticello",
"Arthurs Beauty College Inc Jacksonville",
"Baptist Health Schools Little Rock",
"Black River Technical College",
"University of Central Arkansas",
"Central Baptist College",
"Cossatot Community College of the University of Arkansas",
"Crowleys Ridge College",
"Crowleys Ridge Technical Institute",
"East Arkansas Community College",
"Eastern College of Health Vocations Little Rock",
"Imagine Paul Mitchell Partner School",
"National Park Community College",
"University of Arkansas Community College Batesville",
"Harding University",
"Henderson State University",
"Hendrix College",
"Hot Springs Beauty College",
"Jefferson Regional Medical Center School of Nursing",
"John Brown University",
"Lees School of Cosmetology",
"Career Academy of Hair Design",
"Marsha Kay Beauty College",
"Academy of Salon and Spa",
"Mid South Community College",
"Arkansas Northeastern College",
"Margarets Hair Academy Inc",
"New Tyler Barber College Inc",
"North Arkansas College",
"Northwest Technical Institute",
"Ouachita Baptist University",
"College of the Ouachitas",
"Ozarka College",
"University of the Ozarks",
"University of Arkansas Community College Morrilton",
"Philander Smith College",
"Phillips Community College of the University of Arkansas",
"Southeast Arkansas College",
"Professional Cosmetology Education Center",
"Pulaski Technical College",
"University of Arkansas Community College Hope",
"Rich Mountain Community College",
"Paul Mitchell the School Arkansas",
"Searcy Beauty College Inc",
"Shorter College",
"Williams Baptist College",
"South Arkansas Community College",
"Southern Arkansas University Main Campus",
"Southern Arkansas University Tech",
"University of Arkansas System Office",
"Velvatex College of Beauty Culture",
"University of Arkansas Fort Smith",
"Academy of Art University",
"ITT Technical Institute Rancho Cordova",
"Academy of Chinese Culture and Health Sciences",
"The Academy of Radio and TV Broadcasting",
"Avalon School of Cosmetology Alameda",
"College of Alameda",
"Allan Hancock College",
"American Academy of Dramatic Arts Los Angeles",
"American Baptist Seminary of the West",
"American Film Institute Conservatory",
"American Beauty College",
"American Career College Los Angeles",
"American Conservatory Theater",
"American River College",
"Everest College Hayward",
"Antelope Valley College",
"Art Center College of Design",
"Associated Technical College Los Angeles",
"Associated Technical College San Diego",
"Azusa Pacific University",
"Bakersfield College",
"Barstow Community College",
"Bellus Academy National City",
"Bethesda University of California",
"Biola University",
"Brooks Institute",
"Brownson Technical School",
"Bryan University",
"Butte College",
"Phillips Graduate Institute",
"California Institute of Integral Studies",
"Cabrillo College",
"California Baptist University",
"California College of the Arts",
"University of California Hastings College of Law",
"California Institute of Technology",
"California Lutheran University",
"California Polytechnic State University San Luis Obispo",
"Alliant International University",
"CSUB",
"California State University Bakersfield",
"California State University Stanislaus",
"California State University Chancellors Office",
"California State University San Bernardino",
"California State Polytechnic University Pomona",
"California State University Chico",
"CSUDH",
"California State University Dominguez Hills",
"California State University Fresno",
"California State University Fullerton",
"California State University East Bay",
"California State University Long Beach",
"CSULA",
"California State University Los Angeles",
"CSUN",
"California State University Northridge",
"California State University Sacramento",
"UCB",
"University of California Berkeley",
"UCD",
"University of California Davis",
"UCI",
"University of California Irvine",
"UCLA",
"University of California Los Angeles",
"USC",
"University of Southern California",
"UCR",
"University of California Riverside",
"UCSD",
"University of California San Diego",
"UCSF",
"University of California San Francisco",
"UCSB",
"University of California Santa Barbara",
"UCSC",
"University of California Santa Cruz",
"Sofia University",
"California Beauty School",
"California Christian College",
"California College San Diego",
"Le Cordon Bleu College of Culinary Arts San Francisco",
"California Hair Design Academy",
"California Institute of the Arts",
"California Maritime Academy",
"California Western School of Law",
"Canada College",
"College of the Canyons",
"Career Academy of Beauty",
"Casa Loma College Van Nuys",
"CET San Diego",
"CET Sobrato",
"CET El Centro",
"CET San Bernardino",
"CET Watsonville",
"CET Gilroy",
"CET Salinas",
"Cerritos College",
"Cerro Coso Community College",
"Chabot College",
"Chaffey College",
"Chapman University",
"Charles R Drew University of Medicine and Science",
"Concordia University Irvine",
"San Diego Christian College",
"Church Divinity School of the Pacific",
"Citrus College",
"Citrus Heights Beauty College",
"City College of San Francisco",
"Claremont Graduate University",
"Claremont McKenna College",
"Milan Institute Visalia",
"Clovis Adult Education",
"Coast Community College District Office",
"Coastline Community College",
"Cogswell College",
"Coleman University",
"COBA Academy",
"Western University of Health Sciences",
"Columbia College",
"Columbia College Hollywood",
"El Camino College Compton Center",
"Western Beauty Institute",
"Contra Costa Community College District Office",
"Contra Costa College",
"Cosumnes River College",
"Crafton Hills College",
"Cuesta College",
"Cuyamaca College",
"Cypress College",
"De Anza College",
"Marinello Schools of Beauty Hemet",
"International School of Beauty Inc",
"DellArte International School of Physical Theatre",
"College of the Desert",
"Design Institute of San Diego",
"DeVry University California",
"Diablo Valley College",
"Dominican University of California",
"Dominican School of Philosophy & Theology",
"East Los Angeles College",
"Marinello Schools of Beauty Santa Clara",
"San Diego Continuing Education",
"El Camino Community College District",
"Elegance International",
"Marinello Schools of Beauty Burbank",
"Marinello Schools of Beauty Moreno Valley",
"Emperors College of Traditional Oriental Medicine",
"Empire College School of Business",
"Evergreen Valley College",
"Fashion Institute of Design & Merchandising Los Angeles",
"Fashion Careers College",
"Fashion Institute of Design & Merchandising San Francisco",
"Fashion Institute of Design & Merchandising Orange County",
"Feather River Community College District",
"Federico Beauty Institute",
"Fielding Graduate University",
"Five Branches University",
"Hair California Beauty Academy",
"Foothill College",
"Franciscan School of Theology",
"Fredrick and Charles Beauty College",
"Fresno City College",
"Fresno Pacific University",
"Foothill De Anza Community College District",
"Fuller Theological Seminary in California",
"Fullerton College",
"Galen College of Medical and Dental Assistants",
"Gavilan College",
"Gemological Institute of America Carlsbad",
"Glendale Career College",
"Golden Gate University San Francisco",
"Golden West College",
"Graduate Theological Union",
"Grossmont Cuyamaca Community College District",
"Grossmont College",
"Salon Success Academy San Bernardino",
"Hartnell College",
"Harvey Mudd College",
"Heald College Rancho Cordova",
"Heald College Fresno",
"Heald College San Jose",
"Heald College San Francisco",
"Heald College Concord",
"Hilltop Beauty School",
"Holy Names University",
"Pacifica Graduate Institute",
"Humboldt State University",
"Humphreys College Stockton and Modesto Campuses",
"Imperial Valley College",
"Institute for Business and Technology",
"LA College International",
"Interior Designers Institute",
"Toni & Guy Hairdressing Academy Santa Monica",
"Irvine Valley College",
"ITT Technical Institute National City",
"ITT Technical Institute San Dimas",
"ITT Technical Institute Orange",
"Bellus Academy El Cajon",
"Joe Blasco Makeup Artist Training Center",
"John F Kennedy University",
"American Jewish University",
"Reedley College",
"Life Pacific College",
"The Art Institute of California Argosy University San Diego",
"University of La Verne",
"Laguna College of Art and Design",
"Lake Tahoe Community College",
"Lancaster Beauty School",
"Laney College",
"Lassen Community College",
"Life Chiropractic College West",
"Lincoln University",
"Southern California Seminary",
"La Sierra University",
"Loma Linda University",
"Long Beach City College",
"Southern California University of Health Sciences",
"Los Angeles Community College District Office",
"Los Angeles Harbor College",
"Los Angeles Pierce College",
"Los Angeles Southwest College",
"Los Angeles Trade Technical College",
"Los Angeles Valley College",
"The Masters College and Seminary",
"Los Angeles City College",
"Los Angeles County College of Nursing and Allied Health",
"Los Angeles Mission College",
"Los Medanos College",
"Los Rios Community College District Office",
"The Art Institute of California Argosy University San Francisco",
"Loyola Marymount University",
"Lu Ross Academy",
"Manchester Beauty College",
"Tulare Beauty College",
"Lyles Bakersfield College of Beauty",
"Lyles Fresno College of Beauty",
"Lytles Redwood Empire Beauty College Inc",
"MTI Business College Inc",
"MTI College",
"Kaplan College Sacramento",
"Kaplan College San Diego",
"Kaplan College Vista",
"College of Marin",
"Marinello Schools of Beauty Los Angeles",
"Marymount California University",
"Mendocino College",
"Menlo College",
"Merced College",
"Merritt College",
"Mills College",
"MiraCosta College",
"Marinello Schools of Beauty San Francisco",
"Mission College",
"Modern Beauty Academy",
"Kaplan College North Hollywood",
"Modesto Junior College",
"Moler Barber College",
"Monterey Institute of International Studies",
"Monterey Peninsula College",
"Moorpark College",
"Mt San Antonio College",
"Mount St Marys College",
"Mt San Jacinto Community College District",
"Mueller College",
"Musicians Institute",
"Napa Valley College",
"Everest College San Francisco",
"Everest College Reseda",
"Everest College LA Wilshire",
"Everest College San Jose",
"Everest College Gardena",
"Everest College Alhambra",
"Everest College San Bernardino",
"The National Hispanic University",
"National Holistic Institute",
"National University",
"Naval Postgraduate School",
"Newschool of Architecture and Design",
"Newberry School of Beauty",
"Adrians College of Beauty Modesto",
"North Orange County Community College District",
"North West College Pomona",
"North West College West Covina",
"North West College Pasadena",
"Northwestern Polytechnic University",
"Notre Dame de Namur University",
"Occidental College",
"Oceanside College of Beauty",
"Ohlone College",
"Orange Coast College",
"Otis College of Art and Design",
"Oxnard College",
"Hope International University",
"Concorde Career College San Diego",
"Palo Alto University",
"Pacific Lutheran Theological Seminary",
"Pacific Oaks College",
"Pacific School of Religion",
"Pacific States University",
"Pacific Union College",
"University of the Pacific",
"Palo Verde College",
"Palomar College",
"Paris Beauty College",
"Pasadena City College",
"Pepperdine University",
"Peralta Community College System Office",
"Pitzer College",
"Platt College San Diego",
"Point Loma Nazarene University",
"Pomona College",
"Porterville College",
"Westwood College South Bay",
"Professional Institute of Beauty",
"Santa Ana College",
"Pardee RAND Graduate School",
"Shasta School of Cosmetology",
"University of Redlands",
"College of the Redwoods",
"Salon Success Academy Upland",
"Salon Success Academy Corona",
"Rio Hondo College",
"Riverside City College",
"Argosy University San Francisco Bay Area",
"Rosemead Beauty School",
"Dongguk University Los Angeles",
"Sacramento City College",
"Saddleback College",
"Saint Patricks Seminary and University",
"Samuel Merritt University",
"San Diego Community College District District Office",
"San Diego City College",
"Golf Academy of America Carlsbad",
"San Diego Mesa College",
"San Diego Miramar College",
"SDSU",
"San Diego State University",
"USD",
"University of San Diego",
"San Francisco Art Institute",
"San Francisco Conservatory of Music",
"SFSU",
"San Francisco State University",
"San Francisco Theological Seminary",
"USF",
"University of San Francisco",
"San Joaquin College of Law",
"San Joaquin Delta College",
"San Joaquin Valley College Visalia",
"San Joaquin Valley College Bakersfield",
"William Jessup University",
"San Jose Evergreen Community College District",
"San Jose City College",
"SJSU",
"San Jose State University",
"San Mateo County Community College District Office",
"College of San Mateo",
"Santa Barbara Business College Bakersfield",
"Westwood College Los Angeles",
"Santa Barbara Business College Santa Maria",
"Santa Barbara City College",
"Santa Clara University",
"Santa Monica College",
"Santa Rosa Junior College",
"Saybrook University",
"Scripps College",
"Wyotech Fremont",
"College of the Sequoias",
"Shasta Bible College and Graduate School",
"Shasta College",
"Sierra College",
"Sierra College of Beauty",
"Sierra Valley College of Court Reporting",
"Trinity Law School",
"Simpson University",
"College of the Siskiyous",
"Charles A Jones Career and Education Center",
"Skyline College",
"San Bernardino Valley College",
"Saint Marys College of California",
"Solano Community College",
"Sonoma State University",
"Pinnacle College",
"South Baylo University",
"South Coast College",
"Vanguard University of Southern California",
"Concorde Career College Garden Grove",
"Southwestern College",
"St Johns Seminary",
"Starr King School for Ministry",
"State Center Community College District",
"Marshall B Ketchum University",
"Southern California Institute of Architecture",
"Southwestern Law School",
"Taft College",
"Claremont School of Theology",
"Thomas Aquinas College",
"Marinello Schools of Beauty Lake Forest",
"Epic Bible College",
"University of California System Administration Central Office",
"United Education Institute Huntington Park Campus",
"Concorde Career College San Bernardino",
"Universal College of Beauty Inc Los Angeles 1",
"Concorde Career College North Hollywood",
"Ventura County Community College System Office",
"Ventura College",
"The Santa Barbara and Ventura Colleges of Law Ventura",
"Victor Valley Beauty College Inc",
"Victor Valley College",
"Berkeley City College",
"West Valley Mission Community College District Office",
"Walden University",
"Waynes College of Beauty",
"West Hills College Coalinga",
"West Los Angeles College",
"West Valley College",
"Carrington College California Sacramento",
"Westminster Theological Seminary in California",
"Westmont College",
"Whittier College",
"Woodbury University",
"The Wright Institute",
"Western State College of Law at Argosy University",
"Thomas Jefferson School of Law",
"Yeshiva Ohr Elchonon Chabad West Coast Talmudical Seminary",
"Yosemite Community College District Office",
"Yuba College",
"The Salon Professional Academy Grand Junction",
"Adams State University",
"Aims Community College",
"Arapahoe Community College",
"Bel Rea Institute of Animal Technology",
"Everest College Colorado Springs",
"Boulder College of Massage Therapy",
"University of Colorado Denver",
"University of Colorado Colorado Springs",
"Redstone College",
"University of Colorado Boulder",
"Colorado Christian University",
"Colorado College",
"Concorde Career College Aurora",
"The Art Institute of Colorado",
"Colorado Mountain College",
"Colorado Northwestern Community College",
"Colorado School of Mines",
"Colorado School of Trades",
"Colorado State University Fort Collins",
"Colorado Technical University Colorado Springs",
"Empire Beauty School Lakewood",
"Community College of Aurora",
"College America Denver",
"Delta Montrose Technical College",
"Prince Institute Rocky Mountains",
"Community College of Denver",
"Lincoln College of Technology Denver",
"Denver Seminary",
"Westwood College Denver North",
"University of Denver",
"Emily Griffith Technical College",
"Fort Lewis College",
"Front Range Community College",
"Glenwood Beauty Academy",
"Hair Dynamics Education Center",
"Iliff School of Theology",
"International Salon and Spa Academy",
"Lamar Community College",
"Colorado Mesa University",
"Metropolitan State University of Denver",
"Morgan Community College",
"Naropa University",
"National American University Colorado Springs",
"National American University Denver",
"Nazarene Bible College",
"Northeastern Junior College",
"University of Northern Colorado",
"Otero Junior College",
"Everest College Thornton",
"Pikes Peak Community College",
"Intellitec Medical Institute",
"Pueblo Community College",
"Red Rocks Community College",
"Regis University",
"Rocky Mountain College of Art and Design",
"Montessori Education Center of the Rockies",
"Colorado State University Pueblo",
"Pickens Technical College",
"Intellitec College Colorado Springs",
"Intellitec College Grand Junction",
"Trinidad State Junior College",
"University of Colorado System Office",
"United States Air Force Academy",
"Empire Beauty School Thornton",
"Western State Colorado University",
"Yeshiva Toras Chaim Talmudical Seminary",
"Albertus Magnus College",
"Paul Mitchell the School Danbury",
"Asnuntuck Community College",
"Bais Binyomin Academy",
"Branford Hall Career Institute Branford Campus",
"Lincoln College of New England Southington",
"Bridgeport Hospital School of Nursing",
"University of Bridgeport",
"Central Connecticut State University",
"Charter Oak State College",
"Connecticut Center for Massage Therapy Newington",
"Connecticut College",
"Marinello Schools of Beauty East Hartford",
"Porter and Chester Institute of Branford",
"University of Connecticut",
"Goodwin College",
"Lincoln Technical Institute East Windsor",
"Eastern Connecticut State University",
"Fairfield University",
"Northhaven Academy",
"Capital Community College",
"Rensselaer Hartford Graduate Center Inc",
"Fox Institute of Business West Hartford",
"Hartford Seminary",
"University of Hartford",
"Holy Apostles College and Seminary",
"Housatonic Community College",
"Sanford Brown College Farmington",
"The Leon Institute of Hair Design",
"Lyme Academy College of Fine Arts",
"Manchester Community College",
"Naugatuck Valley Community College",
"Middlesex Community College",
"Mitchell College",
"Three Rivers Community College",
"Lincoln Technical Institute New Britain",
"New England Tractor Trailer Training School of Connecticut",
"University of New Haven",
"Ridley Lowell Business & Technical Institute New London",
"Norwalk Community College",
"Northwestern Connecticut Community College",
"Paier College of Art Inc",
"Marinello Schools of Beauty Meriden",
"Porter and Chester Institute of Stratford",
"Post University",
"Quinebaug Valley Community College",
"Quinnipiac University",
"Sacred Heart University",
"University of Saint Joseph",
"Gateway Community College",
"St Vincents College",
"Southern Connecticut State University",
"Stone Academy Hamden",
"Tri State College of Acupuncture",
"Trinity College",
"Tunxis Community College",
"United States Coast Guard Academy",
"Wesleyan University",
"Western Connecticut State University",
"Yale New Haven Hospital Dietetic Internship",
"Yale University",
"Beebe Medical Center Margaret H Rollins School of Nursing",
"Dawn Career Institute Inc",
"Delaware Technical Community College Central Office",
"Delaware Technical Community College Owens",
"Delaware Technical Community College Terry",
"Delaware Technical Community College Stanton/Wilmington",
"Delaware State University",
"University of Delaware",
"Goldey Beacom College",
"Schilling Douglas School of Hair Design",
"Wesley College",
"Wilmington University",
"American University",
"Catholic University of America",
"Corcoran College of Art and Design",
"University of the District of Columbia",
"Pontifical Faculty of the Immaculate Conception at the Dominican House of Studies",
"Gallaudet University",
"George Washington University",
"Georgetown University",
"Howard University",
"Medtech Institute",
"Strayer University District of Columbia",
"National Conservatory of Dramatic Arts",
"Trinity Washington University",
"Wesley Theological Seminary",
"Wyotech Daytona",
"The Art Institute of Fort Lauderdale",
"Atlantic Technical Center",
"The Baptist College of Florida",
"Barry University",
"Gooding Institute of Nurse Anesthesia",
"Bethune Cookman University",
"Lynn University",
"GUTI The Premier Beauty & Wellness Academy",
"Bradford Union Technical Center",
"Eastern Florida State College",
"Broward College",
"Fortis College Winter Park",
"Carlos Albizu University Miami",
"College of Central Florida",
"Johnson University Florida",
"University of Central Florida",
"Charlotte Technical Center",
"Chipola College",
"Clearwater Christian College",
"Lorenzo Walker Institute of Technology",
"Daytona State College",
"South University West Palm Beach",
"Eckerd College",
"Edison State College",
"Edward Waters College",
"Embry Riddle Aeronautical University Daytona Beach",
"Florida Agricultural and Mechanical University",
"Florida Atlantic University",
"Florida State College at Jacksonville",
"Flagler College St Augustine",
"Florida College",
"Concorde Career Institute Jacksonville",
"Concorde Career Institute Miramar",
"Concorde Career Institute Tampa",
"Adventist University of Health Sciences",
"Florida Institute of Technology",
"Florida Institute of Ultrasound Inc",
"Florida International University",
"Florida Keys Community College",
"Florida Memorial University",
"Florida Career College Miami",
"Florida School of Massage",
"Florida Southern College",
"Florida State University",
"Florida Technical College",
"University of Southernmost Florida",
"University of Florida",
"Everest University Pompano Beach",
"Fort Pierce Beauty Academy",
"Full Sail University",
"George Stone Technical Center",
"George T Baker Aviation School",
"Gulf Coast State College",
"Key College",
"Hillsborough Community College",
"Hobe Sound Bible College",
"Hollywood Institute of Beauty Careers",
"Cortiva Institute Florida",
"Indian River State College",
"International Academy of Design and Technology Tampa",
"International Academy",
"AI Miami International University of Art and Design",
"ITT Technical Institute Tampa",
"Jacksonville University",
"Jones College Jacksonville",
"Keiser University Ft Lauderdale",
"Heritage Institute Ft Myers",
"La Belle Beauty School",
"Florida Gateway College",
"Lake Technical Center",
"Lake Sumter State College",
"Fort Myers Institute of Technology",
"Lively Technical Center",
"Lindsey Hopkins Technical Education Center",
"Loraines Academy Inc",
"Luther Rice University & Seminary",
"State College of Florida Manatee Sarasota",
"Manatee Technical Institute",
"Manhattan Hairstyling Academy",
"Traviss Career Center",
"Trinity International University Florida",
"Miami Lakes Educational Center",
"Miami Dade College",
"University of Miami",
"Mid Florida Tech",
"Beauty Schools of America Miami",
"Remington College Tampa Campus",
"Everest Institute North Miami",
"Everest Institute Hialeah",
"Academy of Healing Arts Massage & Facial Skin Care",
"Lincoln College of Technology West Palm Beach",
"Fortis College Tampa",
"Paul Mitchell the School Jacksonville",
"North Florida Community College",
"University of North Florida",
"Northwood University Florida",
"Nova Southeastern University",
"Northwest Florida State College",
"Orange Park International School of Beauty",
"Everest University North Orlando",
"Orlando Tech",
"Palm Beach Atlantic University",
"Palm Beach State College",
"Pasco Hernando Community College",
"Pensacola State College",
"Pinellas Technical Education Center Clearwater",
"Polk State College",
"Radford M Locklin Technical Center",
"Saint Vincent de Paul Regional Seminary",
"Ridge Career Center",
"Ringling College of Art and Design",
"Toni & Guy Hairdressing Academy Jacksonville",
"Robert Morgan Educational Center",
"Rollins College",
"First Coast Technical College",
"Saint Leo University",
"St Petersburg College",
"Pinellas Technical Education Center St Petersburg",
"Santa Fe College",
"Sarasota County Technical Institute",
"Argosy University Sarasota",
"Seminole State College of Florida",
"Sheridan Technical Center",
"Saint John Vianney College Seminary",
"Saint Johns River State College",
"South Florida State College",
"University of South Florida Main Campus",
"St Thomas University",
"Stenotype Institute of Jacksonville Inc Jacksonville",
"Stetson University",
"Southeastern University",
"Benes International School of Beauty",
"Suwannee Hamilton Technical Center",
"Tallahassee Community College",
"Talmudic College of Florida",
"Everest University Tampa",
"Everest University Largo",
"The University of Tampa",
"Taylor Technical Institute",
"Tom P Haney Technical Center",
"Trinity Baptist College",
"Trinity College of Florida",
"Fashion Focus Hair Academy",
"Valencia College",
"Warner University",
"Washington Holmes Technical Center",
"Webber International University",
"Rasmussen College Florida",
"The University of West Florida",
"Westside Tech",
"William T McFatter Technical Center",
"Winter Park Tech",
"Withlacoochee Technical Institute",
"Abraham Baldwin Agricultural College",
"Agnes Scott College",
"Interactive College of Technology Chamblee",
"Interactive College of Technology Morrow",
"Albany Technical College",
"Darton State College",
"Albany State University",
"Andrew College",
"Armstrong Atlantic State University",
"The Art Institute of Atlanta",
"Georgia Career Institute",
"Atlanta Technical College",
"Point University",
"Atlanta Metropolitan State College",
"Atlantas John Marshall Law School",
"Atlanta School of Massage",
"Clark Atlanta University",
"Augusta Technical College",
"Augusta State University",
"Bainbridge State College",
"Bauder College",
"Beauty College of America",
"Berry College",
"Beulah Heights University",
"Brenau University",
"Brewton Parker College",
"Brown College of Court Reporting",
"College of Coastal Georgia",
"West Georgia Technical College",
"Carver Bible College",
"Clayton  State University",
"Columbia Theological Seminary",
"Columbus Technical College",
"Columbus State University",
"Georgia Northwestern Technical College",
"Covenant College",
"Dalton State College",
"Pro Way Hair School",
"DeVry University Georgia",
"South University Savannah",
"East Georgia State College",
"Emmanuel College",
"Emory University",
"Georgia Highlands College",
"Fort Valley State University",
"Grady Health System Professional Schools",
"Georgia Institute of Technology Main Campus",
"Georgia Southwestern State University",
"Gainesville State College",
"Georgia College and State University",
"Georgia Military College",
"Georgia Southern University",
"Georgia State University",
"University of Georgia",
"Gordon State College",
"Southern Crescent Technical College",
"Gupton Jones College of Funeral Service",
"Gwinnett College Lilburn",
"Gwinnett Technical College",
"Middle Georgia Technical College",
"Interdenominational Theological Center",
"Kennesaw State University",
"LaGrange College",
"Lanier Technical College",
"Life University",
"Central Georgia Technical College",
"Macon State College",
"Chattahoochee Technical College",
"Herzing University Atlanta",
"Georgia Health Sciences University",
"Mercer University",
"Middle Georgia College",
"Morehouse College",
"Morehouse School of Medicine",
"Moultrie Technical College",
"University of North Georgia",
"North Georgia Technical College",
"Oglethorpe University",
"Paine College",
"Piedmont College",
"Portfolio Center",
"Reinhardt University",
"Savannah Technical College",
"Savannah College of Art and Design",
"Savannah State University",
"Shorter University",
"South Georgia College",
"South Georgia Technical College",
"The Creative Circus",
"Spelman College",
"Southern Polytechnic State University",
"Southwest Georgia Technical College",
"Thomas University",
"Toccoa Falls College",
"Truett McConnell College",
"Wiregrass Georgia Technical College",
"Valdosta State University",
"Waycross College",
"Wesleyan College",
"University of West Georgia",
"Young Harris College",
"Heald College Honolulu",
"Chaminade University of Honolulu",
"University of Hawaii at Hilo",
"University of Hawaii at Manoa",
"Hawaii Institute of Hair Design",
"Hawaii Pacific University",
"Honolulu Community College",
"Kapiolani Community College",
"Kauai Community College",
"Leeward Community College",
"University of Hawaii Maui College",
"Med Assist School of Hawaii Inc",
"World Medicine Institute",
"Travel Institute of the Pacific",
"University of Hawaii System Office",
"University of Hawaii West Oahu",
"Windward Community College",
"Carrington College Boise",
"Boise Bible College",
"Boise State University",
"Eastern Idaho Technical College",
"Idaho State University",
"University of Idaho",
"The College of Idaho",
"Lewis Clark State College",
"ITT Technical Institute Boise",
"Joseph Charles Institute of Cosmetology",
"Mr Leons School of Hair Design Moscow",
"North Idaho College",
"Northwest Nazarene University",
"The School of Hairstyling",
"Brigham Young University Idaho",
"College of Southern Idaho",
"Adler School of Professional Psychology",
"Alvareitas College of Cosmetology Edwardsville",
"Alvareitas College of Cosmetology Godfrey",
"American Academy of Art",
"School of the Art Institute of Chicago",
"The College of Office Technology",
"Augustana College",
"Aurora University",
"Concept College of Cosmetology",
"Southwestern Illinois College",
"Bethany Theological Seminary",
"Black Hawk College",
"Blackburn College",
"Blessing Rieman College of Nursing",
"Blessing Hospital School of Radiologic Technology",
"Bradley University",
"Paul Mitchell the School Bradley",
"Cameo Beauty Academy",
"Cannella School of Hair Design Villa Park",
"Cannella School of Hair Design Chicago",
"Cannella School of Hair Design Elgin",
"Capri Beauty College",
"Empire Beauty School Hanover Park",
"Carl Sandburg College",
"Catholic Theological Union at Chicago",
"La James International College East Moline",
"Midwestern University Downers Grove",
"The Chicago School of Professional Psychology at Chicago",
"Chicago State University",
"Chicago Theological Seminary",
"University of Chicago",
"City Colleges of Chicago Kennedy King College",
"City Colleges of Chicago Malcolm X College",
"City Colleges of Chicago Olive Harvey College",
"City Colleges of Chicago Harry S Truman College",
"City Colleges of Chicago Richard J Daley College",
"City Colleges of Chicago Harold Washington College",
"City Colleges of Chicago Wilbur Wright College",
"Columbia College Chicago",
"Concordia University Chicago",
"Le Cordon Bleu College of Culinary Arts Chicago",
"Coyne College",
"City Colleges of Chicago District Office",
"Danville Area Community College",
"DePaul University",
"DeVry University Illinois",
"DeVry University Administrative Office",
"Cosmetology & Spa Academy",
"College of DuPage",
"East West University",
"Eastern Illinois University",
"Elgin Community College",
"Elmhurst College",
"Eureka College",
"First Institute Inc",
"Fox College",
"Garrett Evangelical Theological Seminary",
"Gem City College",
"Governors State University",
"Graham Hospital School of Nursing",
"Greenville College",
"Hair Professionals Academy of Cosmetology",
"Hair Professionals Career College",
"Hair Professionals School of Cosmetology",
"Harrington College of Design",
"Hebrew Theological College",
"Highland Community College",
"Rosalind Franklin University of Medicine and Science",
"University of Illinois at Chicago",
"Benedictine University",
"Illinois College of Optometry",
"University of Illinois at Urbana Champaign",
"Illinois Wesleyan University",
"Illinois Central College",
"Illinois College",
"Olney Central College",
"Illinois Institute of Technology",
"Argosy University Chicago",
"Illinois State University",
"Illinois Valley Community College",
"Institute for Clinical Social Work",
"International Academy of Design and Technology Chicago",
"John A Logan College",
"The John Marshall Law School",
"John Wood Community College",
"Joliet Junior College",
"Judson University",
"Kankakee Community College",
"Kaskaskia College",
"DeVry Universitys Keller Graduate School of Management Illinois",
"Kendall College",
"Kishwaukee College",
"Knox College",
"Educators of Beauty College of Cosmetology La Salle",
"College of Lake County",
"Lake Forest College",
"Lake Forest Graduate School of Management",
"Lake Land College",
"Lakeview College of Nursing",
"Lewis and Clark Community College",
"Lewis University",
"Lexington College",
"Lincoln Christian University",
"Lincoln College",
"Lincoln Land Community College",
"Lincoln College of Technology Melrose Park",
"Loyola University Chicago",
"Lutheran School of Theology at Chicago",
"Trinity College of Nursing & Health Sciences",
"CET Chicago",
"Mac Daniels Beauty School",
"MacCormac College",
"MacMurray College",
"McCormick Theological Seminary",
"McHenry County College",
"McKendree University",
"Meadville Lombard Theological School",
"Methodist College",
"Midstate College",
"Millikin University",
"Monmouth College",
"Moody Bible Institute",
"Moraine Valley Community College",
"Morrison Institute of Technology",
"Morton College",
"Mr Johns School of Cosmetology Esthetics & Nails Decatur",
"National Louis University",
"National University of Health Sciences",
"Niles School of Cosmetology",
"North Central College",
"North Park University",
"Northern Baptist Theological Seminary",
"Northern Illinois University",
"Northwestern College Chicago Campus",
"Northwestern University",
"Northeastern Illinois University",
"Oakton Community College",
"Oehrlein School of Cosmetology",
"Olivet Nazarene University",
"Parkland College",
"Pivot Point Academy Evanston",
"Prairie State College",
"Principia College",
"Professionals Choice Hair Design Academy",
"Quincy University",
"Vatterott College Quincy",
"The Illinois Institute of Art Chicago",
"Rend Lake College",
"Richland Community College",
"Robert Morris University Illinois",
"Rock Valley College",
"Madison Media Institute   Rockford Career College",
"Rockford University",
"Roosevelt University",
"Dominican University",
"Rush University",
"Saint Francis Medical Center College of Nursing",
"University of St Francis",
"St Johns College of Nursing",
"Saint Xavier University",
"University of Illinois at Springfield",
"Sauk Valley Community College",
"Seabury Western Theological Seminary",
"Shawnee Community College",
"Shimer College",
"Saint Augustine College",
"University of Saint Mary of the Lake",
"Southeastern Illinois College",
"National Latino Education Institute",
"Spertus College",
"Spoon River College",
"Saint Anthony College of Nursing",
"Educators of Beauty College of Cosmetology Sterling",
"Southern Illinois University Carbondale",
"Southern Illinois University Edwardsville",
"Southern Illinois University System Office",
"Taylor Business Institute",
"Telshe Yeshiva Chicago",
"South Suburban College",
"Tri County Beauty Academy",
"Trinity Christian College",
"Trinity International University Illinois",
"Triton College",
"University of Spa & Cosmetology Arts",
"University of Illinois University Administration",
"VanderCook College of Music",
"Waubonsee Community College",
"Resurrection University",
"Western Illinois University",
"Wheaton College",
"Harper College",
"Ancilla College",
"Anderson University",
"Apex Academy of Hair Design Inc",
"Ball State University",
"Bethel College Indiana",
"Butler University",
"Calumet College of Saint Joseph",
"Christian Theological Seminary",
"College of Court Reporting Inc",
"Concordia Theological Seminary",
"Tricoci University of Beauty Culture Highland",
"DePauw University",
"Don Roberts School of Hair Design",
"Don Roberts Beauty School",
"Earlham College",
"University of Evansville",
"Franklin College",
"Good Samaritan Hospital School of Radiologic Technology",
"Goshen College",
"Grace College and Theological Seminary",
"Hair Fashions By Kaye Beauty College Noblesville",
"Hair Fashions By Kaye Beauty College Indianapolis",
"Hanover College",
"J Michael Harrold Beauty Academy",
"Holy Cross College",
"PJs College of Cosmetology Clarksville",
"Huntington University",
"Ivy Tech Community College",
"Indiana University Purdue University Fort Wayne",
"Indiana University Purdue University Indianapolis",
"Harrison College Indianapolis",
"University of Indianapolis",
"Indiana Institute of Technology",
"University of Southern Indiana",
"Indiana State University",
"Indiana University Kokomo",
"Indiana University South Bend",
"Indiana University Bloomington",
"Indiana University Northwest",
"Indiana University Southeast",
"Indiana University East",
"International Business College Fort Wayne",
"International Business College Indianapolis",
"ITT Educational Services Inc System Office",
"ITT Technical Institute Nashville",
"ITT Technical Institute Fort Wayne",
"ITT Technical Institute Indianapolis",
"J Everett Light Career Center",
"Knox Beauty College",
"Lafayette Beauty Academy",
"Brown Mackie College Merrillville",
"Brown Mackie College Michigan City",
"Empire Beauty School Indianapolis",
"Lincoln College of Technology Indianapolis",
"Manchester University",
"Marian University",
"Indiana Wesleyan University",
"Martin University",
"Masters of Cosmetology College",
"Anabaptist Mennonite Biblical Seminary",
"Merrillville Beauty College",
"Brown Mackie College South Bend",
"Mid America College of Funeral Service",
"West Michigan College of Barbering and Beauty",
"University of Notre Dame",
"Oakland City University",
"PJs College of Cosmetology Richmond",
"Kaplan College Indianapolis",
"Purdue University Calumet Campus",
"Purdue University North Central Campus",
"Ravenscroft Beauty College",
"Rogers Academy of Hair Design",
"Rose Hulman Institute of Technology",
"Rudaes School of Beauty Culture Kokomo",
"University of Saint Francis Fort Wayne",
"Saint Josephs College",
"Saint Mary of the Woods College",
"Saint Marys College",
"Kaplan College Hammond",
"Kaplan College Merrillville",
"Saint Meinrad School of Theology",
"Saint Elizabeth School of Nursing",
"Taylor University",
"Trine University",
"Valparaiso University",
"Vincennes Beauty College",
"Vincennes University",
"Wabash College",
"Allen College",
"AIB College of Business",
"Briar Cliff University",
"Buena Vista University",
"Capri College Dubuque",
"Capri College Cedar Rapids",
"American College of Hairstyling Cedar Rapids",
"Central College",
"Clarke University",
"Coe College",
"Cornell College",
"Iowa School of Beauty Marshalltown",
"Des Moines Area Community College",
"Divine Word College",
"Dordt College",
"Drake University",
"University of Dubuque",
"Ellsworth Community College",
"Emmaus Bible College",
"Eastern Iowa Community College District",
"Faith Baptist Bible College and Theological Seminary",
"Faust Institute of Cosmetology Storm Lake",
"Graceland University Lamoni",
"Grand View University",
"Grinnell College",
"Kaplan University Mason City Campus",
"Kaplan University Cedar Rapids Campus",
"Hamilton Technical College",
"Hawkeye Community College",
"American Hair Academy",
"Indian Hills Community College",
"Iowa Central Community College",
"Iowa Lakes Community College",
"UnityPoint Health Des Moines School of Radiologic Technology",
"American College of Hairstyling Des Moines",
"Iowa School of Beauty Des Moines",
"Iowa State University",
"Iowa Wesleyan College",
"Iowa Western Community College",
"University of Iowa",
"Kirkwood Community College",
"La James International College Cedar Falls",
"La James International College Johnston",
"La James College of Hairstyling and Cosmetology",
"La James International College Ft Dodge",
"Loras College",
"Luther College",
"Maharishi University of Management",
"Marshalltown Community College",
"Mercy College of Health Sciences",
"Morningside College",
"Mount Mercy University",
"Ashford University",
"North Iowa Area Community College",
"University of Northern Iowa",
"Northwestern College",
"Northeast Iowa Community College Calmar",
"Northwest Iowa Community College",
"Des Moines University Osteopathic Medical Center",
"Iowa School of Beauty Ottumwa",
"Palmer College of Chiropractic Davenport",
"PCI Academy Ames",
"Saint Ambrose University",
"St Lukes College",
"La James International College Davenport",
"La James International College Iowa City",
"Simpson College",
"Southeastern Community College",
"Southwestern Community College",
"Capri College Davenport",
"Iowa School of Beauty Sioux City",
"E Q School of Hair Design",
"Upper Iowa University",
"Waldorf College",
"Wartburg College",
"Wartburg Theological Seminary",
"College of Hair Design",
"Western Iowa Tech Community College",
"William Penn University",
"Allen County Community College",
"Baker University",
"Barton County Community College",
"Benedictine College",
"Bethany College",
"Bethel College North Newton",
"Brown Mackie College Kansas City",
"Brown Mackie College Salina",
"Butler Community College",
"Central Baptist Theological Seminary",
"Central Christian College of Kansas",
"Cloud County Community College",
"Coffeyville Community College",
"Colby Community College",
"Cowley County Community College",
"Marinello Schools of Beauty Manhattan",
"Dodge City Community College",
"Donnelly College",
"Emporia State University",
"Flint Hills Technical College",
"Fort Hays State University",
"Barclay College",
"Friends University",
"Fort Scott Community College",
"Garden City Community College",
"Haskell Indian Nations University",
"Hays Academy of Hair Design",
"Hesston College",
"Hutchinson Community College",
"Independence Community College",
"Johnson County Community College",
"Concorde Career Colleges",
"Kansas City Kansas Community College",
"University of Kansas",
"Newman University",
"Old Town Barber College Wichita",
"Kansas State University",
"Kansas Wesleyan University",
"Washburn Institute of Technology",
"La Baron Hairdressing Academy Overland Park",
"Labette Community College",
"Manhattan Area Technical College",
"Manhattan Christian College",
"McPherson College",
"MidAmerica Nazarene University",
"Neosho County Community College",
"North Central Kansas Technical College",
"Northwest Kansas Technical College",
"Ottawa University Ottawa",
"Ottawa University Kansas City",
"Pittsburg State University",
"Pratt Community College",
"University of Saint Mary",
"Salina Area Technical College",
"Seward County Community College and Area Technical School",
"Sidneys Hair Dressing College",
"Sterling College",
"Tabor College",
"Washburn University",
"Wichita Area Technical College",
"Wichita State University",
"Wichita Technical Institute",
"Alice Lloyd College",
"Asbury University",
"Asbury Theological Seminary",
"Ashland Community and Technical College",
"Barrett and Company School of Hair Design",
"Bellarmine University",
"Berea College",
"PJs College of Cosmetology Bowling Green",
"Southcentral Kentucky Community and Technical College",
"Brescia University",
"Campbellsville University",
"Bluegrass Community and Technical College",
"Centre College",
"Clear Creek Baptist Bible College",
"Paul Mitchell the School Lexington",
"Collins School of Cosmetology",
"Galen College of Nursing Louisville",
"University of the Cumberlands",
"Eastern Kentucky University",
"Elizabethtown Community and Technical College",
"Ezells Cosmetology School",
"Frontier Nursing University",
"Georgetown College",
"PJs College of Cosmetology Glasgow",
"Hazard Community and Technical College",
"Paul Mitchell the School Louisville",
"Henderson Community College",
"Hopkinsville Community College",
"Daymar College Paducah Main",
"Jefferson Community and Technical College",
"Jenny Lea Academy of Cosmetology",
"The Salon Professional Academy Lexington",
"National College Lexington",
"Kentucky Mountain Bible College",
"Kentucky State University",
"Kentucky Wesleyan College",
"University of Kentucky",
"Kentucky Christian University",
"Lexington Theological Seminary",
"Lindsey Wilson College",
"Sullivan College of Technology and Design",
"University of Louisville",
"Louisville Presbyterian Theological Seminary",
"Madisonville Community College",
"Maysville Community and Technical College",
"Mid Continent University",
"Midway College",
"Morehead State University",
"Murray State University",
"Gateway Community and Technical College",
"Northern Kentucky University",
"Nutek Academy of Beauty Inc",
"Daymar College Owensboro",
"West Kentucky Community and Technical College",
"Pat Wilsons Beauty College",
"University of Pikeville",
"Big Sandy Community and Technical College",
"Brown Mackie College Louisville",
"Empire Beauty School Chenoweth",
"Saint Catharine College",
"Empire Beauty School Elizabethtown",
"Empire Beauty School Dixie",
"Empire Beauty School Florence",
"Brown Mackie College Northern Kentucky",
"Somerset Community College",
"Southeast Kentucky Community and Technical College",
"The Southern Baptist Theological Seminary",
"Spalding University",
"Spencerian College Louisville",
"Sullivan University",
"Thomas More College",
"Transylvania University",
"Trend Setters Academy of Beauty Culture Louisville",
"Kentucky Community and Technical College System",
"Union College",
"Western Kentucky University",
"Central Louisiana Technical Community College",
"American School of Business",
"Ayers Career College",
"Baton Rouge General Medical Center School of Nursing",
"Baton Rouge General Medical Center Radiologic Technology",
"Baton Rouge School of Computers",
"Capital Area Technical College",
"Bossier Parish Community College",
"Cameron College",
"Centenary College of Louisiana",
"Cloyds Beauty School 1 Inc",
"Crescent City Bartending School",
"DJays School of Beauty Arts and Sciences",
"Delgado Community College",
"Delta School of Business and Technology",
"Northeast Louisiana Technical College",
"Demmons School of Beauty",
"Denham Springs Beauty School",
"Dillard University",
"Nunez Community College",
"Grambling State University",
"Guys Shreveport Academy of Cosmetology Inc",
"ITI Technical College",
"John Jay Beauty College",
"Louisiana State University Health Sciences Center New Orleans",
"Louisiana State University Alexandria",
"Louisiana State University and Agricultural & Mechanical College",
"Louisiana State University Eunice",
"Louisiana State University Shreveport",
"Acadiana Technical College Lafayette Campus",
"Aveda Institute Baton Rouge",
"Aveda Institute Lafayette",
"Louisiana Academy of Beauty",
"Louisiana College",
"Opelousas School of Cosmetology Inc",
"Louisiana State University System Office",
"Louisiana Tech University",
"Loyola University New Orleans",
"McNeese State University",
"University of New Orleans",
"New Orleans Baptist Theological Seminary",
"Nicholls State University",
"University of Louisiana at Monroe",
"Northwest Louisiana Technical College",
"Notre Dame Seminary Graduate School of Theology",
"Northwestern State University of Louisiana",
"Our Lady of Holy Cross College",
"Our Lady of the Lake College",
"Pat Goins Benton Road Beauty School",
"Pat Goins Monroe Beauty School",
"Pat Goins Ruston Beauty School",
"Pineville Beauty School",
"Aveda Institute Covington",
"Saint Joseph Seminary College",
"Vanguard College of Cosmetology Slidell",
"South Louisiana Beauty College",
"L E Fletcher Technical Community College",
"Remington College Lafayette Campus",
"Southern University Board and System",
"SOWELA Technical Community College",
"Stevensons Academy of Hair Design",
"Southeastern Louisiana University",
"Southern University and A & M College",
"Southern University at New Orleans",
"Southern University at Shreveport",
"University of Louisiana at Lafayette",
"Northshore Technical Community College",
"Tulane University of Louisiana",
"Xavier University of Louisiana",
"South Central Louisiana Technical College Young Memorial Campus",
"Kaplan University Maine Campus",
"College of the Atlantic",
"Bangor Theological Seminary",
"Bates College",
"Beal College",
"Bowdoin College",
"Central Maine Medical Center College of Nursing and Health Professions",
"Central Maine Community College",
"Colby College",
"Eastern Maine Community College",
"Husson University",
"Kennebec Valley Community College",
"The Landing School",
"University of Maine at Augusta",
"University of Maine at Farmington",
"University of Maine at Fort Kent",
"University of Maine at Machias",
"University of Maine",
"University of Maine System Central Office",
"Maine Maritime Academy",
"University of Maine at Presque Isle",
"University of New England",
"Northern Maine Community College",
"Empire Beauty School Maine",
"Maine College of Art",
"Saint Josephs College of Maine",
"Spa Tech Institute Westbrook",
"Southern Maine Community College",
"University of Southern Maine",
"Thomas College",
"Unity College",
"Washington County Community College",
"Aarons Academy of Beauty",
"Aesthetics Institute of Cosmetology",
"Allegany College of Maryland",
"Anne Arundel Community College",
"TESST College of Technology Towson",
"Award Beauty School",
"Baltimore Studio of Hair Design",
"Baltimore City Community College",
"University of Baltimore",
"Bowie State University",
"Capitol College",
"Cecil College",
"College of Southern Maryland",
"Chesapeake College",
"Washington Adventist University",
"Coppin State University",
"Delmarva Beauty Academy",
"North American Trade Schools",
"Frederick Community College",
"Frostburg State University",
"Garrett College",
"Goucher College",
"Kaplan University Hagerstown Campus",
"Hagerstown Community College",
"Harford Community College",
"Hood College",
"Howard Community College",
"The Colorlab Academy of Hair",
"Johns Hopkins University",
"LAcademie de Cuisine",
"Lincoln College of Technology Columbia",
"Loyola University Maryland",
"Maryland Beauty Academy of Reisterstown",
"University of Maryland University College",
"University of Maryland Baltimore",
"University of Maryland Baltimore County",
"University of Maryland College Park",
"Maryland Institute College of Art",
"University of Maryland Eastern Shore",
"Fortis Institute Towson",
"Montgomery Beauty School",
"Montgomery College",
"Morgan State University",
"Mount St Marys University",
"Ner Israel Rabbinical College",
"Hair Academy Inc New Carrollton",
"Notre Dame of Maryland University",
"Prince Georges Community College",
"TESST College of Technology Baltimore",
"Robert Paul Academy of Cosmetology Arts & Sciences",
"Maryland Beauty Academy of Essex",
"St Marys Seminary & University",
"Salisbury University",
"St Marys College of Maryland",
"Sojourner Douglass College",
"St Johns College",
"TESST College of Technology Beltsville",
"Towson University",
"Maryland University of Integrative Health",
"International Beauty School",
"University System of Maryland",
"United States Naval Academy",
"Stevenson University",
"Washington Bible College Capital Bible Seminary",
"Washington College",
"Washington Theological Union",
"McDaniel College",
"Wor Wic Community College",
"Hult International Business School",
"New England College of Business and Finance",
"American International College",
"Amherst College",
"Andover Newton Theological School",
"Anna Maria College",
"Assabet Valley Regional Technical School",
"Assumption College",
"Babson College",
"Bancroft School of Massage Therapy",
"Boston Baptist College",
"Bay Path College",
"Bay State College",
"Bay State School of Technology",
"Becker College",
"Bentley University",
"Berklee College of Music",
"Berkshire Community College",
"Empire Beauty School Boston",
"Boston Architectural College",
"Boston Graduate School of Psychoanalysis Inc",
"Boston College",
"The Boston Conservatory",
"Boston University",
"Brandeis University",
"Bridgewater State University",
"Bristol Community College",
"Signature Healthcare Brockton Hospital School of Nursing",
"Bunker Hill Community College",
"Cambridge College",
"Cape Cod Community College",
"Catherine Hinds Institute of Esthetics",
"Laboure College",
"Clark University",
"Conway School of Landscape Design",
"Curry College",
"Lincoln Technical Institute Somerville",
"Dean College",
"Diman Regional Technical Institute",
"National Aviation Academy of New England",
"Eastern Nazarene College",
"Emerson College",
"Endicott College",
"Episcopal Divinity School",
"Rob Roy Academy Fall River",
"Fisher College",
"Fitchburg State University",
"Framingham State University",
"Benjamin Franklin Institute of Technology",
"Gordon College",
"Gordon Conwell Theological Seminary",
"Greenfield Community College",
"Hallmark Institute of Photography",
"Hampshire College",
"Harvard University",
"Hebrew College",
"Hellenic College Holy Cross Greek Orthodox School of Theology",
"Henris School of Hair Design",
"College of the Holy Cross",
"Holyoke Community College",
"Sanford Brown College Boston",
"Kay Harvey Academy of Hair Design",
"La Baron Hairdressing Academy Brockton",
"La Baron Hairdressing Academy New Bedford",
"Lasell College",
"Lawrence Memorial Hospital School of Nursing",
"Lesley University",
"Longy School of Music of Bard College",
"Lowell Academy Hairstyling Institute",
"University of Massachusetts Lowell",
"Mansfield Beauty Schools Springfield",
"Mansfield Beauty Schools Quincy",
"Marian Court College",
"University of Massachusetts Amherst",
"University of Massachusetts Boston",
"Massachusetts Bay Community College",
"MCPHS University",
"University of Massachusetts Central Office",
"Massachusetts College of Art and Design",
"Massachusetts Institute of Technology",
"Massachusetts Maritime Academy",
"University of Massachusetts Medical School Worcester",
"Massachusetts School of Professional Psychology",
"Massachusetts School of Barbering",
"Massasoit Community College",
"Merrimack College",
"MGH Institute of Health Professions",
"Montserrat College of Art",
"Mount Holyoke College",
"Mount Ida College",
"Mount Wachusett Community College",
"Cortiva Institute Boston",
"School of the Museum of Fine Arts Boston",
"Everest Institute Brighton",
"Rob Roy Academy New Bedford",
"The New England Conservatory of Music",
"New England College of Optometry",
"New England School of Acupuncture",
"New England School of Law",
"New England School of Photography",
"Newbury College",
"Nichols College",
"Massachusetts College of Liberal Arts",
"North Bennet Street School",
"North Shore Community College",
"The New England Institute of Art",
"Northeastern University",
"Northern Essex Community College",
"College of Our Lady of the Elms",
"Pine Manor College",
"Blessed John XXIII National Seminary",
"Quincy College",
"Quinsigamond Community College",
"Kaplan Career Institute Charlestown",
"Regis College",
"Roxbury Community College",
"Saint Johns Seminary",
"Salem State University",
"Salter College West Boylston",
"Simmons College",
"Bard College at Simons Rock",
"Smith College",
"Southeastern Technical Institute",
"Springfield College",
"Springfield Technical Community College",
"University of Massachusetts Dartmouth",
"Stonehill College",
"Suffolk University",
"Rob Roy Academy Taunton",
"Tufts University",
"Wellesley College",
"Wentworth Institute of Technology",
"Western New England University",
"Westfield State University",
"Wheelock College",
"Williams College",
"Worcester Polytechnic Institute",
"Worcester State University",
"Adrian College",
"Albion College",
"Hillsdale Beauty College",
"Northwestern Technological Institute",
"Alma College",
"Alpena Community College",
"Andrews University",
"Aquinas College",
"Baker College of Owosso",
"Baker College of Flint",
"Bay de Noc Community College",
"Bayshire Academy of Beauty Craft Inc",
"Cadillac Institute of Cosmetology",
"Calvin College",
"Calvin Theological Seminary",
"Carnegie Institute",
"Michigan School of Professional Psychology",
"Central Michigan University",
"Mott Community College",
"Empire Beauty School Michigan",
"Cleary University",
"Concordia University Ann Arbor",
"Cranbrook Academy of Art",
"College for Creative Studies",
"Davenport University",
"David Pressley School of Cosmetology",
"Delta College",
"Detroit Business Institute Downriver",
"Michigan State University College of Law",
"MIAT College of Technology",
"University of Detroit Mercy",
"Dorsey Business Schools Madison Heights",
"Dorsey Business Schools Southgate",
"Eastern Michigan University",
"Ferris State University",
"Flint Institute of Barbering Inc",
"Glen Oaks Community College",
"Kettering University",
"Gogebic Community College",
"Grace Bible College",
"Cornerstone University",
"Everest Institute Grand Rapids",
"Grand Rapids Community College",
"Grand Valley State University",
"Great Lakes Christian College",
"Henry Ford Community College",
"Hope College",
"ITT Technical Institute Wyoming",
"Jackson College",
"Wright Beauty Academy",
"Kalamazoo College",
"Kalamazoo Valley Community College",
"Kellogg Community College",
"Kirtland Community College",
"Michigan College of Beauty Monroe",
"Lake Michigan College",
"Lake Superior State University",
"Lansing Community College",
"Lawrence Technological University",
"M J Murphy Beauty College of Mount Pleasant",
"Macomb Community College",
"Madonna University",
"Marygrove College",
"Rochester College",
"University of Michigan Ann Arbor",
"Michigan Barber School Inc",
"Michigan State University",
"Michigan Technological University",
"University of Michigan Dearborn",
"University of Michigan Flint",
"Mid Michigan Community College",
"Monroe County Community College",
"Montcalm Community College",
"Mr Belas School of Cosmetology Inc",
"Baker College of Muskegon",
"Muskegon Community College",
"Everest College Skokie",
"Everest Institute Southfield",
"North Central Michigan College",
"Northern Michigan University",
"Paul Mitchell the School Escanaba",
"Northwestern Michigan College",
"Northwood University Michigan",
"Oakland Community College",
"Oakland University",
"Olivet College",
"Port Huron Cosmetology College",
"Kuyper College",
"Ross Medical Education Center Lansing",
"Ross Medical Education Center Madison Heights",
"Ross Medical Education Center Flint",
"Sacred Heart Major Seminary",
"Saginaw Valley State University",
"Schoolcraft College",
"Siena Heights University",
"St Clair County Community College",
"Southwestern Michigan College",
"Specs Howard School of Media Arts",
"Spring Arbor University",
"Michigan Career and Technical Institute",
"Finlandia University",
"Thomas M Cooley Law School",
"Twin City Beauty College",
"Walsh College of Accountancy and Business Administration",
"Washtenaw Community College",
"Wayne County Community College District",
"Wayne State University",
"West Shore Community College",
"Western Michigan University",
"Western Theological Seminary",
"Academy College",
"Alexandria Technical & Community College",
"American Indian OIC Inc",
"Anoka Technical College",
"Anoka Ramsey Community College",
"Apostolic Bible Institute Inc",
"Augsburg College",
"Riverland Community College",
"Northwest Technical College",
"Bemidji State University",
"Bethany Lutheran College",
"Bethel University",
"Bethel Seminary St Paul",
"Central Lakes College Brainerd",
"Carleton College",
"Concordia College at Moorhead",
"Concordia University Saint Paul",
"Dakota County Technical College",
"Martin Luther College",
"Lake Superior College",
"Cosmetology Careers Unlimited College of Hair Skin and Nails",
"Duluth Business University",
"Minnesota State Community and Technical College",
"Globe University Woodbury",
"Minnesota West Community and Technical College",
"Gustavus Adolphus College",
"Hamline University",
"Hazelden Graduate School of Addiction Studies",
"Hennepin Technical College",
"Hibbing Community College",
"Aveda Institute Minneapolis",
"Inver Hills Community College",
"Itasca Community College",
"The Art Institutes International Minnesota",
"Luther Seminary",
"Macalester College",
"South Central College",
"Minnesota State University Mankato",
"Regency Beauty Institute Blaine",
"Mayo Medical School",
"Mayo School of Health Sciences",
"Argosy University Twin Cities",
"Mesabi Range Community and Technical College",
"Metropolitan State University",
"University of Minnesota Twin Cities",
"University of Minnesota Crookston",
"Minneapolis Business College",
"Minneapolis College of Art and Design",
"Minneapolis Community and Technical College",
"Herzing University Minneapolis",
"Minnesota School of Cosmetology Woodbury Campus",
"Crossroads College",
"University of Minnesota Duluth",
"University of Minnesota Morris",
"Minnesota School of Business Richfield",
"Model College of Hair Design",
"Minnesota State University Moorhead",
"North Hennepin Community College",
"National American University Roseville",
"Brown College Mendota Heights",
"Normandale Community College",
"North Central University",
"Northland Community and Technical College",
"University of Northwestern St Paul",
"Northwestern Health Sciences University",
"Oak Hills Christian College",
"Regency Beauty Institute Burnsville",
"Pine Technical College",
"Rainy River Community College",
"Rochester Community and Technical College",
"College of Saint Benedict",
"St Cloud Technical and Community College",
"Regency Beauty Institute St Cloud",
"Saint Cloud State University",
"Saint Johns University",
"Saint Marys University of Minnesota",
"St Olaf College",
"Crown College",
"The College of Saint Scholastica",
"University of St Thomas",
"College of Visual Arts",
"Empire Beauty School Bloomington",
"St Catherine University",
"Rasmussen College Minnesota",
"Saint Paul College",
"Southwest Minnesota State University",
"Summit Academy Opportunities Industrialization Center",
"United Theological Seminary of the Twin Cities",
"Vermilion Community College",
"Dunwoody College of Technology",
"Ridgewater College",
"Minnesota State College Southeast Technical",
"Winona State University",
"William Mitchell College of Law",
"Century College",
"Alcorn State University",
"Belhaven University",
"Blue Mountain College",
"Chris Beauty College",
"Coahoma Community College",
"Antonelli College Jackson",
"Copiah Lincoln Community College",
"Creations College of Cosmetology",
"Delta Beauty College",
"Delta State University",
"East Central Community College",
"East Mississippi Community College",
"Fosters Cosmetology College",
"Academy of Hair Design Grenada",
"Hinds Community College",
"Holmes Community College",
"Itawamba Community College",
"Jackson State University",
"Jones County Junior College",
"Meridian Community College",
"Millsaps College",
"Board of Trustees Mississippi State Institutions of Higher Learning",
"Mississippi Delta Community College",
"University of Mississippi",
"University of Mississippi Medical Center",
"Mississippi University for Women",
"Mississippi Valley State University",
"Mississippi College",
"Mississippi College of Beauty Culture",
"Mississippi Gulf Coast Community College",
"Mississippi State University",
"Northeast Mississippi Community College",
"Northwest Mississippi Community College",
"Pearl River Community College",
"Rust College",
"Southeastern Baptist College",
"Southwest Mississippi Community College",
"University of Southern Mississippi",
"Magnolia College of Cosmetology",
"Tougaloo College",
"Wesley Biblical Seminary",
"William Carey University",
"Anthem College Maryland Heights",
"Aquinas Institute of Theology",
"Assemblies of God Theological Seminary",
"Avila University",
"ITT Technical Institute Earth City",
"Baptist Bible College",
"House of Heavilin Beauty College Blue Springs",
"Boonslick Technical Education Center",
"Cox College",
"Calvary Bible College and Theological Seminary",
"Cape Girardeau Career and Technology Center",
"Central Christian College of the Bible",
"Central Bible College",
"Central Methodist University College of Liberal Arts and Sciences",
"University of Central Missouri",
"Grand River Technical School",
"Chillicothe Beauty Academy Inc",
"Cleveland University Kansas City",
"Columbia Area Career Center",
"Conception Seminary College",
"Concordia Seminary",
"Cottey College",
"Covenant Theological Seminary",
"Crowder College",
"Culver Stockton College",
"DeVry University Missouri",
"Drury University",
"East Central College",
"Eden Theological Seminary",
"Elaine Steven Beauty College",
"Pinnacle Career Institute South Kansas City",
"Evangel University",
"Fontbonne University",
"Forest Institute of Professional Psychology",
"Four Rivers Career Center",
"Ozarks Technical Community College",
"Hannibal LaGrange University",
"Harris Stowe State University",
"Hickey College",
"House of Heavilin Beauty College Kansas City",
"Independence College of Cosmetology",
"Jefferson College",
"Cosmetology Concepts Institute",
"Barnes Jewish College Goldfarb School of Nursing",
"Kansas City Art Institute",
"Kennett Career and Technology Center",
"Kenrick Glennon Seminary",
"A T Still University of Health Sciences",
"Lake Career and Technical Center",
"Lex La Ray Technical Center",
"Lindenwood University",
"Linn State Technical College",
"Logan University",
"Metropolitan Community College Kansas City",
"Lutheran School of Nursing",
"Maryville University of Saint Louis",
"Merrell University of Beauty Arts and Science",
"Metro Business College Cape Girardeau",
"Midwest Institute",
"Midwestern Baptist Theological Seminary",
"Mineral Area College",
"Westwood College OHare Airport",
"Missouri Baptist University",
"National Academy of Beauty Arts Farmington",
"Missouri College",
"Missouri School of Barbering & Hairstyling",
"Missouri Southern State University",
"Missouri Tech",
"Missouri Valley College",
"Missouri Western State University",
"University of Missouri Columbia",
"University of Missouri Kansas City",
"Missouri University of Science and Technology",
"University of Missouri St Louis",
"University of Missouri System Office",
"Moberly Area Community College",
"Hillyard Technical Center",
"National Academy of Beauty Arts St Louis",
"Nazarene Theological Seminary",
"Neosho Beauty College",
"Nichols Career Center",
"Northwest Technical School",
"Truman State University",
"Northwest Missouri State University",
"Ozark Christian College",
"College of the Ozarks",
"Park University",
"Stevens Institute of Business & Arts",
"Poplar Bluff Technical Career Center",
"Ranken Technical College",
"Research College of Nursing",
"Rockhurst University",
"Rolla Technical Institute",
"Everest College Springfield",
"Saint Louis University",
"Salem College of Hairstyling",
"Sanford Brown College Fenton",
"American College of Hair Design Inc",
"Sikeston Career and Technology Center",
"Saint Louis Christian College",
"St Louis College of Pharmacy",
"Saint Louis Community College",
"Saint Paul School of Theology",
"Southwest Baptist University",
"Missouri State University West Plains",
"Mercy Hospital Springfield School of Radiologic Technology",
"Saint Lukes College of Health Sciences",
"St Louis College of Health Careers St Louis",
"State Fair Community College",
"Stephens College",
"Southeast Missouri State University",
"Missouri State University Springfield",
"North Central Missouri College",
"Eldon Career Center",
"Truman Medical Center School of Nurse Anesthesia",
"Kansas City University of Medicine and Biosciences",
"Washington University in St Louis",
"Webster University",
"Wentworth Military Academy & Junior College",
"Westminster College",
"William Jewell College",
"William Woods University",
"Academy of Cosmetology",
"Blackfeet Community College",
"Butte Academy of Beauty Culture",
"Highlands College of Montana Tech",
"Creviers School of Cosmetology",
"Carroll College",
"Dawson Community College",
"Chief Dull Knife College",
"Montana State University Billings",
"Flathead Valley Community College",
"Aaniiih Nakoda College",
"Fort Peck Community College",
"Great Falls College Montana State University",
"University of Great Falls",
"Helena College University of Montana",
"Little Big Horn College",
"Miles Community College",
"Modern Beauty School Inc",
"Montana Tech of the University of Montana",
"Montana State University",
"The University of Montana",
"Montana State University Northern",
"Rocky Mountain College",
"Salish Kootenai College",
"The University of Montana Western",
"LaJames International College",
"Bellevue University",
"Clarkson College",
"Bryan College of Health Sciences",
"Central Community College",
"Chadron State College",
"College of Hair Design Downtown",
"Concordia University Nebraska",
"Creighton University",
"Doane College Crete",
"Grace University",
"Hastings College",
"Fullen School of Hair Design",
"Alegent Creighton Health School of Radiologic Technology",
"Josephs College",
"University of Nebraska at Kearney",
"Kaplan University Lincoln Campus",
"Nebraska Methodist College of Nursing & Allied Health",
"Metropolitan Community College Area",
"Mid Plains Community College",
"Midland University",
"Nebraska Christian College",
"University of Nebraska at Omaha",
"Kaplan University Omaha Campus",
"Nebraska Indian Community College",
"University of Nebraska Medical Center",
"Nebraska Wesleyan University",
"University of Nebraska Lincoln",
"Northeast Community College",
"Peru State College",
"Summit Christian College",
"College of Saint Mary",
"Southeast Community College Area",
"University of Nebraska Central Administration System Office",
"Vatterott College Spring Valley",
"Nebraska College of Technical Agriculture",
"Wayne State College",
"Regional West Medical Center School of Radiologic Technology",
"Western Nebraska Community College",
"York College",
"Academy of Hair Design Las Vegas",
"Career College of Northern Nevada",
"College of Southern Nevada",
"The Art Institute of Las Vegas",
"Everest College Henderson",
"Marinello Schools of Beauty Las Vegas",
"University of Nevada Las Vegas",
"University of Nevada Reno",
"Great Basin College",
"Marinello Schools of Beauty Reno",
"Morrison University",
"Sierra Nevada College",
"Truckee Meadows Community College",
"Nevada System of Higher Education System Office",
"Western Nevada College",
"Colby Sawyer College",
"Continental Academie of Hair Design Hudson",
"Daniel Webster College",
"Dartmouth College",
"Empire Beauty School Laconia",
"Empire Beauty School Somersworth",
"Franklin Pierce University",
"University of New Hampshire School of Law",
"Mount Washington College",
"Keene Beauty Academy",
"Lebanon College",
"College of St Mary Magdalen",
"Michaels School of Hair Design and Esthetics Paul Mitchell Partner School",
"New England College",
"New England School of Hair Design Inc",
"Southern New Hampshire University",
"New Hampshire Institute for Therapeutic Arts",
"University of New Hampshire Main Campus",
"Keene State College",
"University of New Hampshire at Manchester",
"Plymouth State University",
"NHTI Concords Community College",
"White Mountains Community College",
"River Valley Community College",
"Lakes Region Community College",
"Nashua Community College",
"Great Bay Community College",
"Paul Mitchell the School Portsmouth",
"Rivier University",
"Saint Anselm College",
"St Joseph School of Nursing",
"Granite State College",
"Thomas More College of Liberal Arts",
"University System of New Hampshire System Office",
"Eastwick College Hackensack",
"Assumption College for Sisters",
"Atlantic Cape Community College",
"Fortis Institute Wayne",
"Bergen Community College",
"Berkeley College Woodland Park",
"Beth Medrash Govoha",
"Bloomfield College",
"Brookdale Community College",
"Burlington County College",
"Caldwell College",
"Camden County College",
"Centenary College",
"Adult and Continuing Education BCTS",
"Anthem Institute Parsippany",
"Lincoln Technical Institute Edison",
"Fox Institute of Business Clifton",
"Dover Business College",
"Empire Beauty School Ocean",
"Cooper Health System Center for Allied Health Education",
"County College of Morris",
"Cumberland County College",
"DeVry University New Jersey",
"Divers Academy International",
"Drake College of Business Elizabeth",
"Drew University",
"duCret School of Arts",
"Lincoln Technical Institute South Plainfield",
"Essex County College",
"Empire Beauty School Union",
"Fairleigh Dickinson University Metropolitan Campus",
"Felician College",
"Fairleigh Dickinson University College at Florham",
"Hohokus School of Trade and Technical Sciences",
"Georgian Court University",
"Rowan University",
"Gloucester County College",
"Empire Beauty School Cherry Hill",
"Harris School of Business Cherry Hill Campus",
"Eastwick College Ramsey",
"Holy Name Medical Center School of Nursing",
"Hudson County Community College",
"New Jersey City University",
"Joe Kubert School of Cartoon and Graphic Art",
"Kean University",
"Harris School of Business Voorhees Campus",
"Lincoln Technical Institute Union",
"Rizzieri Aveda School for Beauty and Wellness",
"Mercer County Community College",
"Middlesex County College",
"Monmouth University",
"Montclair State University",
"Mountainside School of Nursing",
"Muhlenberg Harold B and Dorothy A Snyder Schools School of Imaging",
"Muhlenberg Harold B and Dorothy A Snyder Schools School of Nursing",
"Hohokus School RETS Nutley",
"Empire Beauty School Jersey City",
"New Brunswick Theological Seminary",
"Robert Fiance Beauty Schools West New York",
"New Jersey Institute of Technology",
"Ocean County College",
"Omega Institute",
"P B Cosmetology Education Center",
"Parisian Beauty School",
"Passaic County Community College",
"Pennco Tech Blackwood",
"Princeton Theological Seminary",
"Princeton University",
"Rabbinical College of America",
"Ramapo College of New Jersey",
"Robert Fiance Beauty Schools Perth Amboy",
"Rider University",
"Roman Academy of Beauty Culture",
"Rutgers University Camden",
"Rutgers University New Brunswick",
"Rutgers University Newark",
"St Francis Medical Center School of Radiologic Technology",
"Saint Francis Medical Center School of Nursing",
"Saint Peters University",
"Salem Community College",
"Seton Hall University",
"Atlantic Beauty & Spa Academy LLC",
"College of Saint Elizabeth",
"Raritan Valley Community College",
"Stevens Institute of Technology",
"The Richard Stockton College of New Jersey",
"Talmudical Academy New Jersey",
"Teterboro School of Aeronautics",
"Thomas Edison State College",
"The College of New Jersey",
"Sanford Brown Institute Iselin",
"Union County College",
"University of Medicine and Dentistry of New Jersey",
"William Paterson University of New Jersey",
"Olympian University of Cosmetology",
"Central New Mexico Community College",
"Navajo Technical University",
"De Wolff College Hair Styling and Cosmetology",
"New Mexico State University Dona Ana",
"Clovis Community College",
"Eastern New Mexico University Main Campus",
"Eastern New Mexico University Roswell Campus",
"Institute of American Indian and Alaska Native Culture",
"New Mexico Highlands University",
"New Mexico Junior College",
"New Mexico Military Institute",
"University of New Mexico Gallup Campus",
"New Mexico Institute of Mining and Technology",
"University of New Mexico Los Alamos Campus",
"University of New Mexico Main Campus",
"New Mexico State University Alamogordo",
"New Mexico State University Carlsbad",
"New Mexico State University Grants",
"New Mexico State University Main Campus",
"University of New Mexico Valencia County Campus",
"Northern New Mexico College",
"San Juan College",
"Santa Fe Community College",
"Santa Fe University of Art and Design",
"University of the Southwest",
"Southwestern Indian Polytechnic Institute",
"University of New Mexico Taos Campus",
"Mesalands Community College",
"Vogue College of Cosmetology Santa Fe",
"Western New Mexico University",
"Vaughn College of Aeronautics and Technology",
"Adelphi University",
"Adirondack Community College",
"Globe Institute of Technology",
"Bryant & Stratton College Albany",
"Albany College of Pharmacy and Health Sciences",
"Albany Law School",
"Albany Medical College",
"Alfred University",
"Allen School Brooklyn",
"The Ailey School",
"American Academy of Dramatic Arts New York",
"American Academy McAllister Institute of Funeral Service",
"Joffrey Ballet School",
"American Musical and Dramatic Academy",
"Apex Technical School",
"Arnot Ogden Medical Center",
"Associated Beth Rivkah Schools",
"Austins School of Spa Technology",
"Bank Street College of Education",
"Bard College",
"Barnard College",
"Berk Trade and Business School",
"Berkeley College New York",
"Beth Hatalmud Rabbinical College",
"Beth Hamedrash Shaarei Yosher Institute",
"Phillips Beth Israel School of Nursing",
"Hair Design Institute at Fifth Avenue Brooklyn",
"Boricua College",
"Bramson ORT College",
"Briarcliffe College",
"Brittany Beauty School",
"American Beauty School",
"Brooklyn Law School",
"SUNY Broome Community College",
"Bryant & Stratton College Amherst",
"Bryant & Stratton College Syracuse North",
"Bryant & Stratton College Syracuse",
"Bryant & Stratton College Buffalo",
"Bryant & Stratton College Greece",
"Business Informatics Center Inc",
"Canisius College",
"Capri School of Hair Design",
"St Pauls School of Nursing Queens",
"Cayuga County Community College",
"Cazenovia College",
"Central Yeshiva Tomchei Tmimim Lubavitz",
"Cheryl Fells School of Business",
"Christ the King Seminary",
"Circle in the Square Theater School",
"CUNY System Office",
"Clarkson University",
"Clinton Community College",
"Cochran School of Nursing",
"Colgate Rochester Crozer Divinity School",
"Colgate University",
"Metropolitan College of New York",
"Columbia University in the City of New York",
"Columbia Greene Community College",
"Empire Beauty School Queens",
"Concordia College New York",
"Continental School of Beauty Culture Buffalo",
"Continental School of Beauty Culture Batavia",
"Continental School of Beauty Culture Rochester",
"Cooper Union for the Advancement of Science and Art",
"Cornell University",
"Weill Cornell Medical College",
"Corning Community College",
"Crouse Hospital College of Nursing",
"Culinary Institute of America",
"CUNY Bernard M Baruch College",
"CUNY Borough of Manhattan Community College",
"CUNY Bronx Community College",
"CUNY Brooklyn College",
"College of Staten Island CUNY",
"CUNY City College",
"CUNY Graduate School and University Center",
"CUNY Hostos Community College",
"CUNY Hunter College",
"CUNY John Jay College of Criminal Justice",
"CUNY Kingsborough Community College",
"CUNY LaGuardia Community College",
"CUNY Lehman College",
"CUNY Medgar Evers College",
"CUNY New York City College of Technology",
"CUNY Queens College",
"CUNY Queensborough Community College",
"CUNY School of Law at Queens College",
"CUNY York College",
"CVPH Medical Center School of Radiologic Technology",
"DYouville College",
"Daemen College",
"Yeshiva of Far Rockaway Derech Ayson Rabbinical Seminary",
"Dominican College of Blauvelt",
"Dowling College",
"Dutchess Community College",
"Belanger School of Nursing",
"Elmira Business Institute",
"Elmira College",
"Erie Community College",
"Fashion Institute of Technology",
"Finger Lakes Community College",
"Five Towns College",
"Fordham University",
"The International Culinary Center",
"Fulton Montgomery Community College",
"Gemological Institute of America New York",
"The General Theological Seminary",
"Genesee Community College",
"Global Business Institute",
"Hamilton College",
"Hartwick College",
"Helene Fuld College of Nursing",
"Herkimer County Community College",
"Hilbert College",
"Hobart William Smith Colleges",
"Hofstra University",
"Houghton College",
"Hudson Valley Community College",
"Hunter Business School",
"Institute of Design and Construction",
"Institute of Audio Research",
"Iona College",
"Isabella Graham Hart School of Practical Nursing",
"Island Drafting and Technical Institute",
"Ithaca College",
"Jamestown Community College",
"Jamestown Business College",
"Jefferson Community College",
"Jewish Theological Seminary of America",
"The Juilliard School",
"SBI Campus An Affiliate of Sanford Brown",
"Kehilath Yakov Rabbinical Seminary",
"Keuka College",
"LIM College",
"Le Moyne College",
"LIU Brooklyn",
"LIU Post",
"LIU University Center Campus",
"Long Island Beauty School Hempstead",
"Long Island Business Institute",
"LIU Hudson at Rockland",
"LIU Brentwood",
"Machzikei Hadath Rabbinical College",
"Mandl School The College of Allied Health",
"Manhattan College",
"Manhattan School of Music",
"Manhattanville College",
"Maria College of Albany",
"Marion S Whelan School of Nursing of Geneva General Hospital",
"Marist College",
"MarJon School of Beauty ltd Tonawanda",
"Marymount Manhattan College",
"Medaille College",
"Memorial School of Nursing",
"Memorial Hospital School of Radiation Therapy Technology",
"Mercy College",
"Mesivta Torah Vodaath Rabbinical Seminary",
"Mesivta of Eastern Parkway Yeshiva Zichron Meilech",
"Mesivtha Tifereth Jerusalem of America",
"Southern Westchester BOCES Practical Nursing Program",
"Midway Paris Beauty School",
"Mildred Elley School Albany Campus",
"Mirrer Yeshiva Cent Institute",
"Modern Welding School",
"Mohawk Valley Community College",
"Molloy College",
"Monroe College",
"Monroe Community College",
"Mount Saint Mary College",
"Dorothea Hopfer School of Nursing Mt Vernon Hospital",
"College of Mount Saint Vincent",
"Icahn School of Medicine at Mount Sinai",
"Nassau Community College",
"Nazareth College",
"Neighborhood Playhouse School of the Theater",
"The College of New Rochelle",
"The New School",
"New School of Radio and Television",
"New York Chiropractic College",
"Star Career Academy New York",
"New York Institute of English and Business",
"New York Law School",
"New York Medical College",
"New York School for Medical and Dental Assistants",
"New York Theological Seminary",
"New York University",
"Niagara County Community College",
"Niagara University",
"Empire Beauty School Peekskill",
"North Country Community College",
"New York College of Podiatric Medicine",
"New York Institute of Technology",
"New York School of Interior Design",
"Nyack College",
"Ohr Hameir Theological Seminary",
"Olean Business Institute",
"Onondaga Community College",
"Orange County Community College",
"Otsego Area BOCES Practical Nursing Program",
"Pace University New York",
"Paul Smiths College of Arts and Science",
"Continental School of Beauty Culture West Seneca",
"Plaza College",
"Polytechnic Institute of New York University",
"Davis College",
"Pratt Institute Main",
"Professional Business College",
"Rabbinical Academy Mesivta Rabbi Chaim Berlin",
"Rabbinical College Bobover Yeshiva Bnei Zion",
"Rabbinical College of Chsan Sofer New York",
"Rabbinical College Beth Shraga",
"Rabbinical Seminary Mkor Chaim",
"Rabbinical College of Long Island",
"Rabbinical Seminary of America",
"Rensselaer Polytechnic Institute",
"Ridley Lowell Business & Technical Institute Binghamton",
"Roberts Wesleyan College",
"Everest Institute Rochester",
"Rochester Institute of Technology",
"University of Rochester",
"Rockefeller University",
"Rockland Community College",
"Empire Beauty School Manhattan",
"The Sage Colleges",
"St Bernards School of Theology and Ministry",
"St Bonaventure University",
"St Francis College",
"St Josephs College of Nursing at St Josephs Hospital Health Center",
"St Lawrence University",
"The College of Saint Rose",
"St Thomas Aquinas College",
"Samaritan Hospital School of Nursing",
"Sarah Lawrence College",
"Schenectady County Community College",
"Shor Yoshuv Rabbinical College",
"Siena College",
"Simmons Institute of Funeral Service Inc",
"Skidmore College",
"Saint Josephs College New York",
"Saint Vladimirs Orthodox Theological Seminary",
"Spanish American Institute",
"Faxton St Lukes Healthcare School of Radiologic Technology",
"Saint Elizabeth College of Nursing",
"Saint Elizabeth Medical Center School of Radiography",
"Saint John Fisher College",
"St Pauls School of Nursing Staten Island",
"St James Mercy Hospital School of Radiologic Science",
"St Johns University New York",
"SUNY System Office",
"New York Career Institute",
"Suburban Technical School",
"Branford Hall Career Institute Bohemia Campus",
"Sullivan County Community College",
"SUNY College of Technology at Alfred",
"SUNY College of Technology at Canton",
"SUNY College of Technology at Delhi",
"SUNY College of Agriculture and Technology at Cobleskill",
"Farmingdale State College",
"Morrisville State College",
"SUNY at Albany",
"SUNY at Binghamton",
"University at Buffalo",
"Stony Brook University",
"SUNY College of Environmental Science and Forestry",
"SUNY Institute of Technology at Utica Rome",
"SUNY College at Brockport",
"Buffalo State SUNY",
"SUNY College at Cortland",
"SUNY at Fredonia",
"SUNY College at Geneseo",
"State University of New York at New Paltz",
"SUNY Oneonta",
"SUNY College at Oswego",
"SUNY College at Potsdam",
"SUNY at Purchase College",
"SUNY College of Optometry",
"SUNY College at Old Westbury",
"SUNY College at Plattsburgh",
"SUNY Downstate Medical Center",
"SUNY Empire State College",
"SUNY Maritime College",
"Upstate Medical University",
"Swedish Institute a College of Health Sciences",
"Syracuse University",
"Talmudical Seminary Oholei Torah",
"Talmudical Institute of Upstate New York",
"Teachers College at Columbia University",
"Technical Career Institutes",
"Tompkins Cortland Community College",
"Torah Temimah Talmudical Seminary",
"Touro College",
"Trocaire College",
"Excelsior College",
"Ulster County Community College",
"Sanford Brown Institute New York",
"Union Theological Seminary in the City of New York",
"United Talmudical Seminary",
"United States Merchant Marine Academy",
"United States Military Academy",
"Utica College",
"Utica School of Commerce",
"Vassar College",
"Villa Maria College",
"School of Visual Arts",
"Wagner College",
"Webb Institute",
"Wells College",
"The College of Westchester",
"SUNY Westchester Community College",
"Westchester School of Beauty Culture",
"Wood Tobe Coburn School",
"Word of Life Bible Institute",
"Yeshiva Karlin Stolin",
"Yeshivat Mikdash Melech",
"Yeshiva Derech Chaim",
"Yeshiva of Nitra Rabbinical College",
"Yeshiva Shaar Hatorah",
"Yeshiva University",
"Yeshivath Viznitz",
"Yeshivath Zichron Moshe",
"College of the Albemarle",
"The Art Institute of Charlotte",
"South Piedmont Community College",
"Appalachian State University",
"Asheville Buncombe Technical Community College",
"Barton College",
"Beaufort County Community College",
"Belmont Abbey College",
"Bennett College",
"Bladen Community College",
"Blue Ridge Community College",
"Brevard College",
"Brookstone College Charlotte",
"Brunswick Community College",
"Cabarrus College of Health Sciences",
"Caldwell Community College and Technical Institute",
"Campbell University",
"Cape Fear Community College",
"Carolina School of Broadcasting",
"Carteret Community College",
"Catawba College",
"Catawba Valley Community College",
"South College Asheville",
"Central Carolina Community College",
"Central Piedmont Community College",
"Chowan University",
"Cleveland Community College",
"Coastal Carolina Community College",
"Craven Community College",
"Davidson County Community College",
"Davidson College",
"Duke University",
"Durham Technical Community College",
"East Carolina University",
"Edgecombe Community College",
"Elizabeth City State University",
"Elon University",
"Fayetteville Beauty College",
"Fayetteville Technical Community College",
"Fayetteville State University",
"Forsyth Technical Community College",
"Gardner Webb University",
"Gaston College",
"Greensboro College",
"Guilford College",
"Guilford Technical Community College",
"Halifax Community College",
"Haywood Community College",
"Heritage Bible College",
"High Point University",
"Isothermal Community College",
"James Sprunt Community College",
"Laurel University",
"Johnson C Smith University",
"Johnston Community College",
"Lees McRae College",
"Lenoir Community College",
"Lenoir Rhyne University",
"Leons Beauty School Inc",
"Livingstone College",
"Louisburg College",
"Mars Hill University",
"Martin Community College",
"Mayland Community College",
"McDowell Technical Community College",
"Mercy School of Nursing",
"Meredith College",
"Methodist University",
"Miller Motte College Wilmington",
"Mitchell Community College",
"Mitchells Hairstyling Academy Greenville",
"Mitchells Hairstyling Academy Wilson",
"Reginas College of Beauty Monroe",
"Montgomery Community College",
"Montreat College",
"Mount Olive College",
"College of Wilmington",
"Nash Community College",
"North Carolina A & T State University",
"University of North Carolina at Asheville",
"University of North Carolina at Chapel Hill",
"University of North Carolina at Charlotte",
"University of North Carolina at Greensboro",
"North Carolina Central University",
"University of North Carolina System Office",
"University of North Carolina School of the Arts",
"North Carolina State University at Raleigh",
"North Carolina Wesleyan College",
"University of North Carolina Wilmington",
"Pamlico Community College",
"William Peace University",
"University of North Carolina at Pembroke",
"Pfeiffer University",
"Piedmont International University",
"Piedmont Community College",
"Pitt Community College",
"Queens University of Charlotte",
"Randolph Community College",
"Richmond Community College",
"Mid Atlantic Christian University",
"Roanoke Chowan Community College",
"Robeson Community College",
"Rockingham Community College",
"Rowan Cabarrus Community College",
"Saint Augustines University",
"Salem College",
"Sampson Community College",
"Sandhills Community College",
"Shaw University",
"Paul Mitchell the School Fayetteville",
"St Andrews University",
"Empire Beauty School Matthews",
"Stanly Community College",
"Southeastern Baptist Theological Seminary",
"Surry Community College",
"Alamance Community College",
"Tri County Community College",
"Vance Granville Community College",
"Wake Forest University",
"Wake Technical Community College",
"Warren Wilson College",
"Watts School of Nursing",
"Wayne Community College",
"Western Piedmont Community College",
"Wilkes Community College",
"Wilson Community College",
"Wingate University",
"Carolina Christian College",
"Winston Salem Barber School",
"Winston Salem State University",
"Western Carolina University",
"Rasmussen College North Dakota",
"Bismarck State College",
"Dickinson State University",
"Fort Berthold Community College",
"Josefs School of Hair Design Inc Grand Forks",
"Headquarters Academy of Hair Design Inc",
"University of Jamestown",
"Josefs School of Hair Design Inc Fargo Downtown",
"Lake Region State College",
"Cankdeska Cikana Community College",
"University of Mary",
"Mayville State University",
"Sanford College of Nursing",
"Minot State University",
"University of North Dakota",
"North Dakota State College of Science",
"Dakota College at Bottineau",
"North Dakota State University Main Campus",
"Williston State College",
"JZ Trend Academy Paul Mitchell Partner School",
"Sitting Bull College",
"Trinity Bible College",
"Turtle Mountain Community College",
"United Tribes Technical College",
"Valley City State University",
"ETI Technical College",
"The Art Institute of Cincinnati",
"Miami Jacobs Career College Independence",
"Air Force Institute of Technology Graduate School of Engineering & Management",
"Akron Institute of Herzing University",
"University of Akron Main Campus",
"Akron School of Practical Nursing",
"University of Akron Wayne College",
"Allegheny Wesleyan College",
"Antonelli College Cincinnati",
"Apollo Career Center",
"Art Academy of Cincinnati",
"Ashland County West Holmes Career Center",
"Ashland University",
"Ashtabula County Technical and Career Campus",
"Athenaeum of Ohio",
"Auburn Career Center",
"Aultman College of Nursing and Health Sciences",
"Baldwin Wallace University",
"Belmont College",
"Bluffton University",
"Fortis College Ravenna",
"Bowling Green State University Firelands",
"Bowling Green State University Main Campus",
"Bryant & Stratton College Parma",
"Buckeye Hills Career Center",
"Butler Tech D Russel Lee Career Center",
"Canton City Schools Adult Career and Technical Education",
"Capital University",
"Valley Beauty School",
"Carousel Beauty College Middletown",
"Carousel Beauty College Dayton",
"Carousel of Miami Valley Beauty College",
"Case Western Reserve University",
"Cedarville University",
"Central Ohio Technical College",
"Central School of Practical Nursing",
"Central State University",
"Chatfield College",
"Choffin Career  and Technical Center",
"The Christ College of Nursing and Health Sciences",
"Cincinnati Christian University",
"Cincinnati College of Mortuary Science",
"University of Cincinnati Main Campus",
"Cincinnati State Technical and Community College",
"University of Cincinnati Clermont College",
"University of Cincinnati Blue Ash College",
"Ohio Christian University",
"Clark State Community College",
"Scott College of Cosmetology",
"Cleveland Institute of Art",
"Cleveland Institute of Dental Medical Assistants Cleveland",
"Cleveland Institute of Music",
"Cleveland State University",
"Columbiana County Career and Technical Center",
"Bradford School",
"Columbus College of Art and Design",
"Kaplan College Columbus",
"Columbus State Community College",
"Cuyahoga Community College District",
"Dayton Barber College",
"University of Dayton",
"Defiance College",
"Denison University",
"DeVry University Ohio",
"Chancellor University",
"Eastern Hills Academy of Hair Design",
"Edison State Community College",
"EHOVE Career Center",
"Bryant & Stratton College Cleveland",
"Regency Beauty Institute Cleveland",
"The University of Findlay",
"Franklin University",
"Gerbers Akron Beauty School",
"Gods Bible School and College",
"Good Samaritan College of Nursing and Health Science",
"Great Oaks Institute of Technology and Career Development",
"Hannah E Mullins School of Practical Nursing",
"Adult and Community Education Hudson",
"Hebrew Union College Jewish Institute of Religion",
"Heidelberg University",
"Hiram College",
"Hobart Institute of Welding Technology",
"Hocking College",
"American School of Technology",
"Ross Medical Education Center Cincinnati",
"International College of Broadcasting",
"ITT Technical Institute Dayton",
"Eastern Gateway Community College",
"John Carroll University",
"Hondros College",
"Casal Aveda Institute",
"Kent State University at Ashtabula",
"Kent State University at East Liverpool",
"Kent State University at Stark",
"Kent State University at Trumbull",
"Kent State University at Tuscarawas",
"Kent State University at Salem",
"Kent State University at Kent",
"Kent State University at Geauga",
"Kenyon College",
"Kettering College",
"Lake Erie College",
"Lakeland Community College",
"O C Collins Career Center",
"James A Rhodes State College",
"Ohio Business College Sheffield",
"Ohio Business College Sandusky",
"Lorain County Community College",
"Lourdes University",
"Malone University",
"Marietta College",
"Marion Technical College",
"Medina County Career Center",
"Mercy College of Ohio",
"Methodist Theological School in Ohio",
"Miami University Hamilton",
"Miami University Middletown",
"Miami University Oxford",
"Miami Jacobs Career College Dayton",
"Moler Hollywood Beauty Academy",
"Miami Valley Career Technology Center",
"Mount Carmel College of Nursing",
"University of Mount Union",
"Mount Vernon Nazarene University",
"College of Mount St Joseph",
"Zane State College",
"Muskingum University",
"Fortis College Cuyahoga Falls",
"Brown Mackie College North Canton",
"National Beauty College",
"North Central State College",
"Northern Institute of Cosmetology",
"Northwest State Community College",
"Toledo Public Schools Adult and Continuing Education",
"Notre Dame College",
"Northeast Ohio Medical University",
"University of Northwestern Ohio",
"Oberlin College",
"Ohio College of Massotherapy Inc",
"Ohio Technical College",
"Ohio Dominican University",
"Kaplan College Dayton",
"Ohio Northern University",
"Ohio State University Agricultural Technical Institute",
"Ohio State University Lima Campus",
"Ohio State University Mansfield Campus",
"Ohio State University Marion Campus",
"Ohio State University Newark Campus",
"Ohio State Beauty Academy",
"Ohio State College of Barber Styling",
"Ohio State School of Cosmetology & Experts Barber School",
"The Spa School",
"Ohio State School of Cosmetology Canal Winchester",
"Ohio State University Main Campus",
"Ohio University Eastern Campus",
"Ohio University Chillicothe Campus",
"Ohio University Southern Campus",
"Ohio University Lancaster Campus",
"Ohio University Main Campus",
"Ohio University Zanesville Campus",
"Ohio Valley College of Technology",
"Trinity Health System School of Nursing",
"Ohio Wesleyan University",
"Otterbein University",
"Owens Community College",
"Paramount Beauty Academy",
"Cuyahoga Valley Career Center",
"Payne Theological Seminary",
"Pontifical College Josephinum",
"Professional Skills Institute",
"Firelands Regional Medical Center School of Nursing",
"Rabbinical College Telshe",
"Raphaels School of Beauty Culture Inc Niles",
"Fortis College Centerville",
"University of Rio Grande",
"Advertising Art Educational Services DBA School of Advertising Art",
"Shawnee State University",
"Sinclair Community College",
"Gallipolis Career College",
"Daymar College New Boston",
"Daymar College Jackson",
"Daymar College Lancaster",
"Daymar College Chillicothe",
"Brown Mackie College Cincinnati",
"Brown Mackie College Akron",
"Lincoln College of Technology Franklin LCT",
"Lincoln College of Technology Tri County",
"Lincoln College of Technology Vine Street",
"Lincoln College of Technology Dayton",
"Stark State College",
"Stautzenberger College Maumee",
"Franciscan University of Steubenville",
"Southern State Community College",
"Ohio Mid Western College",
"Terra State Community College",
"Tiffin Academy of Hair Design",
"Tiffin University",
"University of Toledo",
"Kaplan Career Institute Cleveland",
"Tri State Bible College",
"Tri County Adult Career Center",
"Tri Rivers Career Center",
"Trinity Lutheran Seminary",
"Trumbull Business College",
"Union Institute & University",
"United Theological Seminary",
"Upper Valley Career Center",
"Urbana University",
"Ursuline College",
"Virginia Marti College of Art and Design",
"Cut Beauty School",
"W Howard Nicol School of Practical Nursing",
"Walsh University",
"Washington State Community College",
"Western Hills School of Beauty and Hair Design",
"Wilberforce University",
"Wilmington College",
"Winebrenner Theological Seminary",
"Wittenberg University",
"The College of Wooster",
"Wright State University Main Campus",
"Wright State University Lake Campus",
"Xavier University",
"ITT Technical Institute Youngstown",
"Youngstown State University",
"American Broadcasting School Oklahoma City",
"Bacone College",
"Oklahoma Wesleyan University",
"Southern Nazarene University",
"Broken Arrow Beauty College Broken Arrow",
"Pontotoc Technology Center",
"Cameron University",
"Carl Albert State College",
"Central State Beauty Academy",
"University of Central Oklahoma",
"Claremore Beauty College",
"Connors State College",
"East Central University",
"Eastern Oklahoma State College",
"Redlands Community College",
"Enid Beauty College",
"Eves College of Hairstyling",
"Hillsdale Free Will Baptist College",
"Hollywood Cosmetology Center",
"Langston University",
"Murray State College",
"Spartan College of Aeronautics and Technology",
"Northeastern State University",
"Beauty Technical College Inc",
"Northern Oklahoma College",
"Northeastern Oklahoma A&M College",
"Northwestern Oklahoma State University",
"Oklahoma State University Center for Health Sciences",
"Oklahoma Christian University",
"University of Oklahoma Health Sciences Center",
"Oklahoma Panhandle State University",
"Oklahoma State University Main Campus",
"Oklahoma State University Oklahoma City",
"Oklahoma Baptist University",
"Oklahoma City Community College",
"Oklahoma City University",
"University of Oklahoma Norman Campus",
"Oklahoma School of Photography",
"Oklahoma State University Institute of Technology",
"Oral Roberts University",
"Tulsa Technology Center Peoria Campus",
"Platt College Central OKC",
"Rogers State University",
"Rose State College",
"Saint Gregorys University",
"Standard Beauty College of Oklahoma",
"University of Science and Arts of Oklahoma",
"Seminole State College",
"Southern School of Beauty Inc",
"Southeastern Oklahoma State University",
"Southwestern Christian University",
"Southwestern Oklahoma State University",
"Tulsa Community College",
"Tulsa Welding School Tulsa",
"University of Tulsa",
"Virgils Beauty College",
"Indian Capital Technology Center Tahlequah",
"Western Oklahoma State College",
"Woodward Beauty College",
"High Plains Technology Center",
"Academy of Hair Design Salem",
"Abdill Career College Inc",
"Paul Mitchell the School Portland",
"The Art Institute of Portland",
"Beau Monde College of Hair Design",
"Blue Mountain Community College",
"Central Oregon Community College",
"Chemeketa Community College",
"Clackamas Community College",
"Clatsop Community College",
"College of Hair Design Careers",
"Concorde Career College Portland",
"Concordia University Portland",
"Sumner College",
"East West College of the Healing Arts",
"Eastern Oregon University",
"Phagans School of Hair Design Portland",
"New Hope Christian College Eugene",
"George Fox University",
"Phagans Grants Pass College of Beauty",
"ITT Technical Institute Portland",
"Lane Community College",
"Lewis & Clark College",
"Linfield College McMinnville Campus",
"Linn Benton Community College",
"Northwest College Beaverton",
"Marylhurst University",
"Phagans Medford Beauty School",
"Northwest College Clackamas",
"Mount Angel Seminary",
"Mt Hood Community College",
"Multnomah University",
"National College of Natural Medicine",
"Northwest Nannies Institute",
"Northwest Christian University",
"Oregon University System",
"Oregon Health & Science University",
"Oregon Institute of Technology",
"Oregon College of Art and Craft",
"Oregon State University",
"University of Oregon",
"Pacific Northwest College of Art",
"Pacific University",
"Phagans Beauty College",
"Phagans Central Oregon Beauty College",
"Phagans School of Beauty",
"Phagans School of Hair Design",
"Phagans Tigard Beauty School",
"Portland Community College",
"Portland State University",
"University of Portland",
"Reed College",
"Rogue Community College",
"Roseburg Beauty College",
"Pioneer Pacific College",
"Springfield College of Beauty",
"Southern Oregon University",
"Southwestern Oregon Community College",
"Treasure Valley Community College",
"Umpqua Community College",
"Warner Pacific College",
"Corban University",
"College of Cosmetology",
"Everest College Portland",
"Western Seminary",
"Willamette University",
"Western Oregon University",
"University of Western States",
"Abington Memorial Hospital Dixon School of Nursing",
"Jolie Hair and Beauty Academy Hazleton",
"Keystone Technical Institute",
"Bryn Athyn College of the New Church",
"Academy of Vocal Arts",
"Albright College",
"All State Career School Lester",
"Community College of Allegheny County",
"Allegheny College",
"The Vision Academy",
"DeSales University",
"Altoona Beauty School Inc",
"Alvernia University",
"Magnolia School",
"American College of Financial Services",
"Antonelli Institute",
"The Art Institutes of York PA",
"The Art Institute of Philadelphia",
"Career Training Academy New Kensington",
"The Art Institute of Pittsburgh",
"Automotive Training Center Exton",
"Baptist Bible College & Seminary of Pennsylvania",
"Community College of Beaver County",
"Arcadia University",
"Biblical Theological Seminary",
"Bidwell Training Center Inc",
"Bloomsburg University of Pennsylvania",
"Bryn Mawr College",
"Bucknell University",
"Bucks County Community College",
"Bucks County School of Beauty Culture Inc",
"Butler Beauty Academy",
"Butler County Community College",
"Cabrini College",
"California University of Pennsylvania",
"Calvary Baptist Theological Seminary",
"Cambria Rowe Business College Johnstown",
"Carlow University",
"Carnegie Mellon University",
"Cedar Crest College",
"Central Penn College",
"Chatham University",
"Chestnut Hill College",
"Cheyney University of Pennsylvania",
"Kaplan Career Institute Franklin Mills",
"Citizens School of Nursing",
"Clarion University of Pennsylvania",
"YTI Career Institute Capital Region",
"Conemaugh Valley Memorial Hospital",
"Consolidated School of Business York",
"Curtis Institute of Music",
"Dean Institute of Technology",
"Delaware County Community College",
"Delaware Valley College",
"Dickinson College",
"The Dickinson School of Law of the Pennsylvania State University",
"Douglas Education Center",
"Drexel University",
"Du Bois Business College Du Bois",
"Everest Institute Pittsburgh",
"Duquesne University",
"East Stroudsburg University of Pennsylvania",
"Eastern University",
"Edinboro University of Pennsylvania",
"Elizabethtown College",
"Empire Beauty School State College",
"Empire Beauty School Lebanon",
"Empire Beauty School York",
"Empire Beauty School Lancaster",
"Empire Beauty School Williamsport",
"Empire Beauty School Shamokin Dam",
"Empire Beauty School Pottsville",
"Empire Beauty  School Lehigh Valley",
"Empire Beauty School Gwinnett",
"Empire Beauty School Wyoming Valley",
"Empire Beauty School Warminster",
"Empire Beauty School Harrisburg",
"Empire Beauty School Reading",
"Erie Business Center Erie",
"Erie Institute of Technology Inc",
"Evangelical Theological Seminary",
"Faith Theological Seminary",
"Aria Health School of Nursing",
"Franklin and Marshall College",
"Gannon University",
"Geneva College",
"Gettysburg College",
"Empire Beauty School Exton",
"Empire Beauty School Laurel Springs",
"Empire Beauty School NE Philadelphia",
"Gratz College",
"Grove City College",
"Gwynedd Mercy University",
"Harcum College",
"Harrisburg Area Community College Harrisburg",
"Haverford College",
"Commonwealth Technical Institute",
"Holy Family University",
"Hussian School of Art",
"Kaplan Career Institute Pittsburgh",
"Immaculata University",
"Indiana University of Pennsylvania Main Campus",
"Pennsylvania School of Business",
"Jameson Health System",
"Great Lakes Institute of Technology",
"Jolie Hair and Beauty Academy Wilkes Barre",
"Johnson College",
"Juniata College",
"Keystone College",
"Anthem Institute Springfield",
"Kings College",
"Kittanning Beauty School",
"Kutztown University of Pennsylvania",
"La Roche College",
"La Salle University",
"Lackawanna College",
"Lafayette College",
"Lancaster Bible College",
"Lancaster Theological Seminary",
"Lancaster School of Cosmetology",
"Lansdale School of Business",
"Lansdale School of Cosmetology Inc",
"Lebanon Valley College",
"Lehigh Carbon Community College",
"Berks Technical Institute",
"Lehigh University",
"Levittown Beauty Academy",
"Lincoln Technical Institute Allentown",
"Lincoln Technical Institute Philadelphia",
"Lincoln University of Pennsylvania",
"Lock Haven University",
"St Margaret School of Nursing",
"Lutheran Theological Seminary at Gettysburg",
"Lutheran Theological Seminary at Philadelphia",
"Luzerne County Community College",
"Lycoming College",
"Erie Business Center New Castle",
"Manor College",
"Mansfield University of Pennsylvania",
"Marywood University",
"Vet Tech Institute",
"Roxborough Memorial Hospital School of Nursing",
"Mercyhurst University",
"Messiah College",
"Metropolitan Career Center Computer Technology Institute",
"Millersville University of Pennsylvania",
"Misericordia University",
"Montgomery County Community College",
"Moore College of Art and Design",
"Moravian College",
"Mount Aloysius College",
"Muhlenberg College",
"Kaplan Career Institute Philadelphia",
"Neumann University",
"New Castle School of Trades",
"Newport Business Institute Lower Burrell",
"Northampton County Area Community College",
"Pittsburghs Ohio Valley Hospital School of Nursing",
"Orleans Technical Institute",
"Pennsylvania Academy of Cosmetology Arts and Sciences Johnstown",
"Salus University",
"Pennsylvania Institute of Technology",
"Pennsylvania State University Penn State Erie Behrend College",
"Pennsylvania State University Penn State Great Valley",
"Pennsylvania State University College of Medicine",
"Pennsylvania State University Penn State New Kensington",
"Pennsylvania State University Penn State Shenango",
"Pennsylvania State University Penn State Wilkes Barre",
"Pennsylvania State University Penn State Worthington Scranton",
"Pennsylvania State System of Higher Education Central Office",
"Pennsylvania State University Penn State Lehigh Valley",
"Pennsylvania State University Penn State Altoona",
"Pennsylvania State University Penn State Beaver",
"Pennsylvania State University Penn State Berks",
"Pennsylvania State University Penn State Harrisburg",
"Pennsylvania State University Penn State Brandywine",
"Pennsylvania State University Penn State Dubois",
"Pennsylvania State University Penn State Fayette  Eberly",
"Pennsylvania State University Penn State Hazleton",
"Pennsylvania State University Main Campus",
"Pennsylvania State University Penn State Greater Allegheny",
"Pennsylvania State University Penn State Mont Alto",
"Pennsylvania State University Penn State Abington",
"Pennsylvania State University Penn State Schuylkill",
"Pennsylvania State University Penn State York",
"Pace Institute",
"Peirce College",
"Penn Commercial Business/Technical School",
"Pennco Tech Bristol",
"Pennsylvania Academy of Cosmetology Arts and Sciences Du Bois",
"Pennsylvania Academy of the Fine Arts",
"Pennsylvania Gunsmith School",
"Pennsylvania Institute of Taxidermy Inc",
"Cortiva Institute Pennsylvania School of Muscle Therapy",
"Pennsylvania College of Art and Design",
"University of Pennsylvania",
"Philadelphia University",
"The University of the Arts",
"Cairn University Langhorne",
"Philadelphia College of Osteopathic Medicine",
"University of the Sciences",
"Community College of Philadelphia",
"University of Pittsburgh Bradford",
"University of Pittsburgh Greensburg",
"University of Pittsburgh Johnstown",
"University of Pittsburgh Pittsburgh Campus",
"University of Pittsburgh Titusville",
"Beaver Falls Beauty Academy",
"Pittsburgh Institute of Aeronautics",
"Pittsburgh Institute of Mortuary Science Inc",
"Pittsburgh Technical Institute",
"Pittsburgh Theological Seminary",
"Prism Career Institute Upper Darby",
"Point Park University",
"Joseph F McCloskey School of Nursing at Schuylkill Health",
"The Salon Professional Academy Altoona",
"Reading Area Community College",
"Reading Hospital School of Health Sciences",
"Reconstructionist Rabbinical College",
"Reformed Presbyterian Theological Seminary",
"The Restaurant School at Walnut Hill College",
"Kaplan Career Institute Broomall",
"Robert Morris University",
"Rosedale Technical Institute",
"Rosemont College",
"Saint Francis University",
"Saint Josephs University",
"Saint Vincent College",
"Saint Vincent Seminary",
"Schuylkill Technology Center",
"University of Scranton",
"Seton Hill University",
"Heritage Valley Sewickley School of Nursing",
"University of Pittsburgh Medical Center Shadyside School of Nursing",
"Sharon Regional Health System School of Nursing",
"Laurel Technical Institute",
"Shippensburg University of Pennsylvania",
"Slippery Rock University of Pennsylvania",
"Saint Charles Borromeo Seminary Overbrook",
"South Hills Beauty Academy Inc",
"South Hills School of Business & Technology",
"St Lukes Hospital School of Nursing",
"Stroudsburg School of Cosmetology",
"Susquehanna University",
"Swarthmore College",
"Thaddeus Stevens College of Technology",
"Talmudical Yeshiva of Philadelphia",
"Temple University",
"Theological Seminary of the Reformed Episcopal Church",
"Thiel College",
"Thomas Jefferson University",
"Fortis Institute Erie",
"Triangle Tech Inc Erie",
"Triangle Tech Inc Pittsburgh",
"Triangle Tech Inc Greensburg",
"Triangle Tech Inc Dubois",
"Trinity Episcopal School for Ministry",
"Ursinus College",
"Valley Forge Christian College",
"Valley Forge Military College",
"Venus Beauty Academy",
"Villanova University",
"Washington Hospital School of Nursing",
"Washington Hospital School of Radiography",
"Washington & Jefferson College",
"Waynesburg University",
"Welder Training and Testing Institute",
"West Chester University of Pennsylvania",
"Western Pennsylvania Hospital School of Nursing",
"Sanford Brown Institute Pittsburgh",
"Westminster Theological Seminary",
"Westmoreland County Community College",
"Widener University Main Campus",
"Wilkes University",
"Newport Business Institute Williamsport",
"Wilson College",
"Yeshivath Beth Moshe",
"York College Pennsylvania",
"YTI Career Institute York",
"Yorktowne Business Institute",
"Empire Beauty School Providence",
"Brown University",
"Bryant University",
"Johnson & Wales University Providence",
"Sanford Brown Institute Cranston",
"New England Institute of Technology",
"New England Tractor Trailer Training School of Rhode Island",
"Newport School of Hairdressing Main Campus",
"Providence College",
"Rhode Island College",
"Community College of Rhode Island",
"University of Rhode Island",
"Rhode Island School of Design",
"Roger Williams University",
"Salve Regina University",
"Empire Beauty School Warwick",
"Northpoint Bible College",
"Aiken Technical College",
"Allen University",
"Charleston Southern University",
"Technical College of the Lowcountry",
"Benedict College",
"Bob Jones University",
"Southern Wesleyan University",
"Charleston Cosmetology Institute",
"College of Charleston",
"Charzanne Beauty College",
"Northeastern Technical College",
"Citadel Military College of South Carolina",
"Claflin University",
"Clemson University",
"Clinton College",
"Coker College",
"Columbia International University",
"Converse College",
"Denmark Technical College",
"Erskine College",
"Florence Darlington Technical College",
"Forrest College",
"Francis Marion University",
"Furman University",
"Cathedral Bible College",
"Greenville Technical College",
"Horry Georgetown Technical College",
"Kenneth Shuler School of Cosmetology North Augusta",
"Kenneth Shuler School of Cosmetology and Nails Columbia",
"Lander University",
"Limestone College",
"Lenoir Rhyne University Lutheran Theological Southern Seminary",
"Medical University of South Carolina",
"Midlands Technical College",
"Morris College",
"Newberry College",
"North Greenville University",
"Orangeburg Calhoun Technical College",
"Piedmont Technical College",
"Presbyterian College",
"University of South Carolina Aiken",
"University of South Carolina Beaufort",
"University of South Carolina Columbia",
"University of South Carolina Lancaster",
"University of South Carolina Salkehatchie",
"University of South Carolina Sumter",
"University of South Carolina Union",
"Coastal Carolina University",
"South Carolina State University",
"University of South Carolina Upstate",
"Sherman College of Straight Chiropractic",
"Spartanburg Methodist College",
"Spartanburg Community College",
"Central Carolina Technical College",
"Sumter Beauty College",
"Tri County Technical College",
"Trident Technical College",
"Voorhees College",
"Williamsburg Technical College",
"Winthrop University",
"Wofford College",
"York Technical College",
"Black Hills Beauty College",
"Black Hills State University",
"Kilian Community College",
"Dakota State University",
"Dakota Wesleyan University",
"Headlines Academy Inc",
"Lake Area Technical Institute",
"Avera McKennan Hospital School of Radiologic Technology",
"Mitchell Technical Institute",
"Mount Marty College",
"National American University Rapid City",
"National American University Sioux Falls",
"Sioux Falls Seminary",
"Northern State University",
"Oglala Lakota College",
"Presentation College",
"Avera Sacred Heart Hospital",
"South Dakota School of Mines and Technology",
"South Dakota State University",
"Sinte Gleska University",
"University of Sioux Falls",
"Sanford Medical Center",
"Sisseton Wahpeton College",
"Southeast Technical Institute",
"Stewart School",
"University of South Dakota",
"Western Dakota Technical Institute",
"American Baptist College",
"Arnolds Beauty School",
"Tennessee College of Applied Technology Athens",
"Austin Peay State University",
"Baptist Memorial College of Health Sciences",
"Belmont University",
"Bryan College Dayton",
"Carson Newman University",
"Chattanooga State Community College",
"Christian Brothers University",
"Pentecostal Theological Seminary",
"Cleveland State Community College",
"Columbia State Community College",
"Concorde Career College Memphis",
"Tennessee College of Applied Technology Covington",
"Cumberland University",
"Lipscomb University",
"Tennessee College of Applied Technology Dickson",
"Daymar Institute Nashville",
"Dyersburg State Community College",
"East Tennessee State University",
"Chattanooga College Medical Dental and Technical Careers",
"Tennessee College of Applied Technology Elizabethton",
"Emmanuel Christian Seminary",
"Fayetteville College of Cosmetology Arts and Sciences",
"Fisk University",
"Welch College",
"Freed Hardeman University",
"Tennessee College of Applied Technology Harriman",
"Tennessee College of Applied Technology Hartsville",
"Hiwassee College",
"Tennessee College of Applied Technology Hohenwald",
"Tennessee College of Applied Technology Jacksboro",
"Jackson State Community College",
"John A Gupton College",
"Johnson University",
"Paul Mitchell the School Nashville",
"King University",
"South College",
"Knoxville Institute of Hair Design",
"Lane College",
"Le Moyne Owen College",
"Lee University",
"Lincoln Memorial University",
"Tennessee College of Applied Technology Livingston",
"Martin Methodist College",
"Maryville College",
"Tennessee College of Applied Technology McKenzie",
"Genesis Career College Cookeville",
"Meharry Medical College",
"Memphis College of Art",
"Tennessee College of Applied Technology Memphis",
"University of Memphis",
"Memphis Theological Seminary",
"Victory University",
"Middle Tennessee State University",
"Middle Tennessee School of Anesthesia Inc",
"Milligan College",
"Tennessee College of Applied Technology Morristown",
"Motlow State Community College",
"Tennessee College of Applied Technology Murfreesboro",
"Lincoln College of Technology Nashville",
"Nashville College of Medical Careers",
"Nashville State Community College",
"Tennessee College of Applied Technology Newbern",
"OMore College of Design",
"Tennessee College of Applied Technology Paris",
"Plaza Beauty School",
"Tennessee College of Applied Technology Pulaski",
"Rhodes College",
"Tennessee College of Applied Technology Ripley",
"Roane State Community College",
"Tennessee College of Applied Technology Crump",
"Southwest Tennessee Community College",
"Tennessee College of Applied Technology Shelbyville",
"Sewanee The University of the South",
"Tennessee College of Applied Technology Oneida Huntsville",
"Tennessee College of Applied Technology Crossville",
"Tennessee College of Applied Technology McMinnville",
"Tennessee College of Applied Technology Jackson",
"Tennessee College of Applied Technology Knoxville",
"Tennessee College of Applied Technology Whiteville",
"Pellissippi State Community College",
"Southern Adventist University",
"Southern College of Optometry",
"Tennessee Wesleyan College",
"The University of Tennessee Chattanooga",
"The University of Tennessee Knoxville",
"The University of Tennessee Martin",
"Fountainhead College of Technology",
"Tennessee School of Beauty of Knoxville Inc",
"Tennessee State University",
"Tennessee Technological University",
"Tennessee Temple University",
"Trevecca Nazarene University",
"Northeast State Community College",
"Tusculum College",
"Union University",
"New Concepts School of Cosmetology",
"Vanderbilt University",
"Volunteer Beauty Academy Dyersburg",
"Volunteer State Community College",
"Walters State Community College",
"West Tennessee Business College",
"William Moore College of Technology",
"Abilene Christian University",
"Alamo Community College District Central Office",
"Alvin Community College",
"Amarillo College",
"Amberton University",
"American Commercial College San Angelo",
"American Commercial College Abilene",
"American Commercial College Odessa",
"Angelina College",
"Angelo State University",
"Arlington Baptist College",
"Arlington Career Institute",
"The Art Institute of Houston",
"Austin College",
"Austin Community College District",
"Austin Presbyterian Theological Seminary",
"Baldwin Beauty School South Austin",
"Baptist Health System School of Health Professions",
"Baptist Missionary Association Theological Seminary",
"Texas A & M University Health Science Center",
"Baylor College of Medicine",
"Baylor University",
"Coastal Bend College",
"Blinn College",
"Remington College Dallas Campus",
"Vet Tech Institute of Houston",
"Brazosport College",
"Brookhaven College",
"Capitol City Trade and Technical School",
"Capitol City Careers",
"Cedar Valley College",
"Central Texas College",
"Charles and Sues School of Hair Design",
"Cisco College",
"Clarendon College",
"Concordia University Texas",
"Conlees College of Cosmetology",
"North Central Texas College",
"Texas A & M University Corpus Christi",
"Cosmetology Career Center LLC",
"Court Reporting Institute of Dallas",
"Dallas Baptist University",
"Dallas Christian College",
"Dallas County Community College District",
"Dallas Institute of Funeral Service",
"Dallas Theological Seminary",
"University of Dallas",
"Del Mar College",
"DeVry University Texas",
"Career Point College",
"East Texas Baptist University",
"Texas A & M University Texarkana",
"Texas A & M University Commerce",
"Eastfield College",
"El Centro College",
"El Paso Community College",
"Western Technical College",
"Episcopal Theological Seminary of the Southwest",
"Exposito School of Hair Design",
"South University The Art Institute of Dallas",
"Fort Worth Beauty School",
"Frank Phillips College",
"Franklin Beauty School",
"Galveston College",
"Grayson College",
"Hallmark College",
"Hardin Simmons University",
"Trinity Valley Community College",
"Hill College",
"Houston Baptist University",
"University of Houston Clear Lake",
"Houston Community College",
"University of Houston Downtown",
"University of Houston Victoria",
"University of Houston",
"Howard College",
"Howard Payne University",
"Huston Tillotson University",
"University of the Incarnate Word",
"International Business College El Paso",
"ITT Technical Institute Arlington",
"ITT Technical Institute Houston West",
"Jacksonville College Main Campus",
"Jarvis Christian College",
"KD College Conservatory of Film and Dramatic Arts",
"Kilgore College",
"Lamar University",
"Lamar State College Orange",
"Lamar State College Port Arthur",
"Laredo Beauty College Inc",
"Laredo Community College",
"Texas A & M International University",
"Lee College",
"LeTourneau University",
"Lincoln College of Technology Grand Prairie",
"Lubbock Hair Academy",
"Lubbock Christian University",
"College of the Mainland",
"University of Mary Hardin Baylor",
"McLennan Community College",
"McMurry University",
"Covenant School of Nursing and Allied Health",
"Metroplex Beauty School",
"Midland College",
"Midwestern State University",
"Mims Classic Beauty College",
"Wade College",
"Southern Careers Institute Austin",
"Mountain View College",
"Everest Institute San Antonio",
"Tint School of Makeup and Cosmetology Irving",
"Navarro College",
"Neilson Beauty College",
"Lone Star College System",
"North Lake College",
"University of North Texas",
"Northeast Texas Community College",
"Northwood University Texas",
"Oblate School of Theology",
"Ocean Corporation",
"Odessa College",
"Ogle School Hair Skin Nails Arlington",
"Ogle School Hair Skin Nails Ft Worth",
"Our Lady of the Lake University",
"The University of Texas Pan American",
"The University of Texas at Brownsville",
"Panola College",
"Paris Junior College",
"Paul Quinn College",
"Prairie View A & M University",
"Ranger College",
"Aviation Institute of Maintenance Houston",
"Rice University",
"Richland College",
"Kaplan College McAllen",
"Saint Edwards University",
"St Philips College",
"Sam Houston State University",
"San Antonio College",
"San Jacinto Community College",
"Schreiner University",
"Seguin Beauty School Seguin",
"St Marys University",
"South Plains College",
"South Texas Barber College Inc",
"South Texas College of Law",
"South Texas Vo Tech Institute",
"South Texas Vocational Technical Institute McAllen",
"Southern Methodist University",
"Southwest School of Business and Technical Careers San Antonio",
"Southwest Institute of Technology",
"Southwest Texas Junior College",
"Southwestern Assemblies of God University",
"Southwestern University",
"Stephen F Austin State University",
"Texas State University",
"Southwestern Adventist University",
"Southwestern Christian College",
"Sul Ross State University",
"Tarleton State University",
"Tarrant County College District",
"Temple College",
"University of Texas Southwestern Medical Center",
"The University of Texas Health Science Center at San Antonio",
"The University of Texas Medical Branch",
"Texas State Technical College System",
"Texas State Technical College Waco",
"Texarkana College",
"Texas A & M University Kingsville",
"Texas A & M University Galveston",
"Texas A & M University College Station",
"Texas A & M University System Office",
"The University of Texas at Arlington",
"The University of Texas at Austin",
"The University of Texas at Dallas",
"The University of Texas at El Paso",
"The University of Texas at Tyler",
"Texas Chiropractic College Foundation Inc",
"Texas Christian University",
"Texas College",
"University of North Texas Health Science Center",
"Texas Lutheran University",
"The University of Texas of the Permian Basin",
"The University of Texas at San Antonio",
"Texas School of Business North Campus",
"Texas Southern University",
"The University of Texas System Office",
"Texas Tech University",
"Texas Vocational Schools Inc",
"Texas Wesleyan University",
"Texas Womans University",
"Trinity University",
"The University of Texas Health Science Center at Houston",
"Texas State Technical College Harlingen",
"Texas State Technical College West Texas",
"Texas Tech University Health Sciences Center",
"Tyler Junior College",
"University of Houston System Administration",
"Universal Technical Institute of Texas Inc.",
"University of Cosmetology Arts & Sciences McAllen",
"Vernon College",
"Victoria Beauty College Inc",
"Victoria College",
"Vogue College of Cosmetology McAllen",
"Wayland Baptist University",
"Weatherford College",
"West Texas A & M University",
"Western Texas College",
"Wharton County Junior College",
"Wiley College",
"AmeriTech College Provo",
"Bridgerland Applied Technology College",
"Brigham Young University Provo",
"Brigham Young University Hawaii",
"Broadview University West Jordan",
"Cameo College of Essential Beauty",
"Sherman Kendall Academy Salt Lake City",
"Sherman Kendall Academy Midvale",
"Davis Applied Technology College",
"Dixie State University",
"Evans Hairstyling College Cedar City",
"Evans Hairstyling College St George",
"Paul Mitchell the School Salt Lake City",
"Eagle Gate College Murray",
"ITT Technical Institute Murray",
"Latter day Saints Business College",
"Everest College Salt Lake City",
"Ogden Weber Applied Technology College",
"Snow College",
"Southern Utah University",
"Stevens Henager College Ogden",
"Stevens Henager College of Business Provo",
"Uintah Basin Applied Technology College",
"Utah State University",
"Utah Valley University",
"Salt Lake Community College",
"University of Utah",
"Weber State University",
"Bennington College",
"Burlington College",
"Castleton State College",
"Champlain College",
"Community College of Vermont",
"Goddard College",
"Green Mountain College",
"Johnson State College",
"Lyndon State College",
"Marlboro College",
"Middlebury College",
"New England Culinary Institute",
"Norwich University",
"OBriens Aveda Institute",
"Saint Michaels College",
"SIT Graduate Institute",
"College of St Joseph",
"Southern Vermont College",
"Vermont Law School",
"Vermont State Colleges Office of the Chancellor",
"Vermont Technical College",
"University of Vermont",
"Bar Palma Beauty Careers Academy",
"Advanced Technology Institute",
"Averett University",
"Bluefield College",
"Bridgewater College",
"College of William and Mary",
"Regent University",
"Central Virginia Community College",
"Christopher Newport University",
"Suffolk Beauty Academy",
"Bryant & Stratton College Virginia Beach",
"Bryant & Stratton College Richmond",
"Jefferson College of Health Sciences",
"Dabney S Lancaster Community College",
"Danville Community College",
"Eastern Virginia Medical School",
"Centura College Virginia Beach",
"Emory & Henry College",
"Eastern Mennonite University",
"Eastern Shore Community College",
"Ferrum College",
"George Mason University",
"Germanna Community College",
"Hampden Sydney College",
"Hampton University",
"Hollins University",
"J Sargeant Reynolds Community College",
"James Madison University",
"John Tyler Community College",
"Everest College Newport News",
"Liberty University",
"Longwood University",
"Lord Fairfax Community College",
"Lynchburg College",
"Lynchburg General Hospital School of Nursing",
"Mary Baldwin College",
"University of Mary Washington",
"Marymount University",
"Danville Regional Medical Center School of Health Professions",
"Mountain Empire Community College",
"American National University",
"New River Community College",
"Sentara College of Health Sciences",
"Tidewater Tech Trades",
"Norfolk State University",
"Northern Virginia Community College",
"Old Dominion University",
"Patrick Henry Community College",
"Paul D Camp Community College",
"Southside Regional Medical Center Professional Schools",
"Miller Motte Technical College Lynchburg",
"Piedmont Virginia Community College",
"Radford University",
"Sylvain Melloul International Hair Academy",
"Randolph Macon College",
"Randolph College",
"Rappahannock Community College",
"Fortis College Norfolk",
"Richard Bland College of the College of William and Mary",
"Bon Secours Memorial College of Nursing",
"University of Richmond",
"Riverside School of Health Careers",
"Roanoke College",
"Bon Secours St Marys Hospital School of Medical Imaging",
"Saint Pauls College",
"Shenandoah University",
"Southern Virginia University",
"Southside Virginia Community College",
"Southwest Virginia Community College",
"Springfield Beauty Academy",
"Staunton School of Cosmetology",
"Strayer University Virginia",
"Sweet Briar College",
"Thomas Nelson Community College",
"Tidewater Community College",
"Union Presbyterian Seminary",
"The University of Virginias College at Wise",
"Virginia Highlands Community College",
"Virginia Intermont College",
"Virginia Polytechnic Institute and State University",
"Virginia Western Community College",
"Virginia Commonwealth University",
"University of Virginia Main Campus",
"Virginia Military Institute",
"Virginia School of Hair Design",
"Virginia University of Lynchburg",
"Virginia State University",
"Virginia Union University",
"Virginia Wesleyan College",
"Wards Corner Beauty Academy Norfolk",
"Washington and Lee University",
"Sanford Brown College Tysons Corner",
"Washington County Adult Skill Center",
"Woodrow Wilson Rehabilitation Center",
"Wytheville Community College",
"Beauty Academy",
"The Art Institute of Seattle",
"Evergreen Beauty and Barber College Bellevue",
"Bellevue College",
"Bellingham Beauty School",
"Bellingham Technical College",
"Big Bend Community College",
"Everest College Bremerton",
"Everest College Renton",
"Central Washington University",
"Centralia College",
"City University of Seattle",
"Lucas Marc Academy",
"Clark College",
"Clover Park Technical College",
"Columbia Basin College",
"Cornish College of the Arts",
"Divers Institute of Technology",
"Eastern Washington University",
"Edmonds Community College",
"Everett Community College",
"Paroba College of Cosmetology",
"The Evergreen State College",
"Pierce College at Fort Steilacoom",
"Glen Dow Academy of Hair Design",
"Gonzaga University",
"Grays Harbor College",
"Green River Community College",
"Toni & Guy Hairdressing Academy Shoreline",
"Heritage University",
"Highline Community College",
"Interface College Spokane",
"International Air and Hospitality Academy",
"ITT Technical Institute Seattle",
"ITT Technical Institute Spokane Valley",
"Bastyr University",
"Gene Juarez Academy of Beauty Mountlake Terrace",
"Bates Technical College",
"Lake Washington Institute of Technology",
"Lower Columbia College",
"Trinity Lutheran College",
"Cascade Beauty College",
"BJs Beauty & Barber College",
"Northwest Hair Academy",
"Seattle Community College North Campus",
"Northwest University",
"Olympic College",
"Perry Technical Institute",
"Pacific Lutheran University",
"Peninsula College",
"University of Puget Sound",
"Renton Technical College",
"Saint Martins University",
"Seattle Community College South Campus",
"Seattle Central College",
"Everest College Seattle",
"Seattle Pacific University",
"Seattle University",
"Shoreline Community College",
"Skagit Valley College",
"South Puget Sound Community College",
"Spokane Community College",
"Spokane Falls Community College",
"Stylemaster College of Hair Design",
"Tacoma Community College",
"The Hair School",
"Walla Walla Community College",
"Walla Walla University",
"Washington State University",
"University of Washington Seattle Campus",
"Wenatchee Valley College",
"Everest College Vancouver",
"Western Washington University",
"Whatcom Community College",
"Whitman College",
"Whitworth University",
"Yakima Valley Community College",
"Alderson Broaddus University",
"Appalachian Beauty School",
"Appalachian Bible College",
"B M Spurr School of Practical Nursing",
"Ben Franklin Career Center",
"Bluefield State College",
"Cabell County Career Technology Center",
"Charleston School of Beauty Culture",
"University of Charleston",
"Clarksburg Beauty Academy and School of Massage Therapy",
"Concord University",
"Davis & Elkins College",
"Fairmont State University",
"Glenville State College",
"HRDE Stanley Technical Institute Clarksburg",
"Huntington Junior College",
"James Rumsey Technical Institute",
"Ralph R Willis Career and Technical Center",
"Human Resource Development and Employment Inc",
"Marshall University",
"Mercer County Technical Education Center",
"Meredith Manor International Equestrian Center",
"Monongalia County Technical Education Center",
"Morgantown Beauty College Inc",
"Mountain State College",
"Everest Institute Cross Lanes",
"Opportunities Industrialization Center",
"Ohio Valley University",
"West Virginia University at Parkersburg",
"Potomac State College of West Virginia University",
"Academy of Careers and Technology",
"Salem International University",
"Shepherd University",
"Southern West Virginia Community and Technical College",
"Fred W Eberle Technical Center",
"West Virginia School of Osteopathic Medicine",
"West Virginia State University",
"West Virginia University Hospital Departments of Rad Tech and Nutrition",
"West Liberty University",
"West Virginia University Institute of Technology",
"West Virginia Wesleyan College",
"West Virginia Business College Wheeling",
"West Virginia Junior College Charleston",
"West Virginia Junior College Morgantown",
"International Beauty School 4",
"West Virginia Northern Community College",
"West Virginia University",
"Wheeling Jesuit University",
"Wood County School of Practical Nursing",
"Advanced Institute of Hair Design Glendale",
"VICI Aveda Institute",
"Alverno College",
"Madison Area Technical College",
"Bellin College",
"Beloit College",
"Blackhawk Technical College",
"Cardinal Stritch University",
"Carroll University",
"Carthage College",
"Columbia College of Nursing",
"Concordia University Wisconsin",
"Edgewood College",
"Fox Valley Technical College",
"Gateway Technical College",
"Gill Tech Academy of Hair Design",
"The Salon Professional Academy Eau Claire",
"ITT Technical Institute Greenfield",
"DeVry University Wisconsin",
"Lakeland College",
"Lakeshore Technical College",
"Lawrence University",
"Maranatha Baptist University",
"Marquette University",
"Empire Beauty School Madison",
"Medical College of Wisconsin",
"Mid State Technical College",
"Milwaukee Area Technical College",
"Milwaukee Institute of Art & Design",
"Milwaukee School of Engineering",
"Moraine Park Technical College",
"Mount Mary University",
"Nashotah House",
"Nicolet Area Technical College",
"Northcentral Technical College",
"Northeast Wisconsin Technical College",
"Northland International University",
"Northland College",
"Ripon College",
"Sacred Heart School of Theology",
"Saint Norbert College",
"Silver Lake College of the Holy Family",
"State College of Beauty Culture Inc",
"Southwest Wisconsin Technical College",
"Bryant & Stratton College Milwaukee",
"University of Wisconsin Colleges",
"Viterbo University",
"Chippewa Valley Technical College",
"Waukesha County Technical College",
"University of Wisconsin Whitewater",
"Wisconsin Indianhead Technical College",
"Wisconsin School of Professional Psychology",
"Paul Mitchell the School Green Bay",
"University of Wisconsin Eau Claire",
"University of Wisconsin Green Bay",
"University of Wisconsin La Crosse",
"Wisconsin Lutheran College",
"University of Wisconsin Oshkosh",
"University of Wisconsin Parkside",
"Herzing University Madison",
"University of Wisconsin Stout",
"University of Wisconsin Superior",
"University of Wisconsin System Administration",
"University of Wisconsin Madison",
"University of Wisconsin Milwaukee",
"University of Wisconsin Platteville",
"University of Wisconsin River Falls",
"University of Wisconsin Stevens Point",
"Casper College",
"Central Wyoming College",
"Eastern Wyoming College",
"Laramie County Community College",
"Northwest College",
"Sheridan College",
"Western Wyoming Community College",
"Cheeks International Academy of Beauty Culture Cheyenne",
"Wyotech Laramie",
"University of Wyoming",
"American Samoa Community College",
"Guam Community College",
"University of Guam",
"Northern Marianas College",
"Institute of Beauty Careers",
"Educational Technical College Recinto de Bayamon",
"American University of Puerto Rico",
"American Educational College",
"Antilles School of Technical Careers",
"Universidad Adventista de las Antillas",
"Atlantic University College",
"Bayamon Central University",
"Columbia Centro Universitario Caguas",
"Carlos Albizu University San Juan",
"Caribbean University Bayamon",
"Caribbean University Carolina",
"Pontifical Catholic University of Puerto Rico Arecibo",
"Pontifical Catholic University of Puerto Rico Ponce",
"Centro de Estudios Multidisciplinarios San Juan",
"Universidad Teologica del Caribe",
"Colegio Universitario de San Juan",
"Universidad Metropolitana",
"Puerto Rico Conservatory of Music",
"Center for Advanced Studies On Puerto Rico and the Caribbean",
"EDP University of Puerto Rico Inc San Sebastian",
"Emmas Beauty Academy Mayaguez",
"Escuela de Artes Plasticas de Puerto Rico",
"Instituto Tecnologico de Puerto Rico Recinto de Manati",
"Sistema Universitario Ana G Mendez",
"Huertas College",
"Humacao Community College",
"ICPR Junior College Arecibo",
"ICPR Junior College Mayaguez",
"Instituto de Educacion Tecnica Ocupacional La Reine Manati",
"Hispanic American College",
"Instituto de Banca y Comercio Inc",
"Instituto Tecnologico de Puerto Rico Recinto de Guayama",
"Instituto Tecnologico de Puerto Rico Recinto de Ponce",
"Instituto Tecnologico de Puerto Rico Recinto de San Juan",
"Inter American University of Puerto Rico San German",
"Inter American University of Puerto Rico Aguadilla",
"Inter American University of Puerto Rico Arecibo",
"Inter American University of Puerto Rico Barranquitas",
"Inter American University of Puerto Rico Metro",
"Inter American University of Puerto Rico Ponce",
"Inter American University of Puerto Rico Central Office",
"Inter American University of Puerto Rico Fajardo",
"Inter American University of Puerto Rico Guayama",
"Inter American University of Puerto Rico Bayamon",
"Inter American University of Puerto Rico School of Law",
"International Technical College",
"Liceo de Arte y Tecnologia",
"MBTI Business Training Institute",
"Modern Hairstyling Institute Bayamon",
"Modern Hairstyling Institute Arecibo",
"National University College Bayamon",
"National University College Arecibo",
"Ponce Paramedical College Inc",
"Ponce School of Medicine and Health Sciences",
"University of Puerto Rico Aguadilla",
"University of Puerto Rico Arecibo",
"University of Puerto Rico Bayamon",
"University of Puerto Rico Carolina",
"University of Puerto Rico Cayey",
"University of Puerto Rico Central Administration",
"University of Puerto Rico Humacao",
"University of Puerto Rico Utuado",
"University of Puerto Rico Mayaguez",
"University of Puerto Rico Medical Sciences",
"University of Puerto Rico Ponce",
"University of Puerto Rico Rio Piedras",
"Universidad Del Este",
"Ryder Memorial School for Practical Nursing",
"Rogies School of Beauty Culture",
"Universidad del Sagrado Corazon",
"Seminario Evangelico de Puerto Rico",
"Universidad Central Del Caribe",
"Universidad Politecnica de Puerto Rico",
"Pontifical Catholic University of Puerto Rico Mayaguez",
"Universidad Del Turabo",
"College of Micronesia FSM",
"Palau Community College",
"University of the Virgin Islands",
"Stanford University",
"Purdue University Main Campus",
"Blue Hills Regional Technical School",
"Parker University",
"EDP Univeristy of Puerto Rico Inc San Juan",
"ICPR Junior College General Institutional",
"ITT Technical Institute Sylmar",
"ITT Technical Institute Westminster",
"Widener University Delaware Campus",
"City College Fort Lauderdale",
"Meridian College",
"Georgia Perimeter College",
"Georgia Piedmont Technical College",
"Toni & Guy Hairdressing Academy Coeur D Alene",
"Cortiva Institute Chicago",
"ETI School of Skilled Trades",
"Lincoln College of Technology Florence",
"Trend Setters Academy of Beauty Culture Elizabethtown",
"Traxlers School of Hair",
"Infinity Career College",
"Vatterott College Berkeley",
"Metro Business College Rolla",
"Metro Business College Jefferson City",
"Warren County Community College",
"Yeshiva and Kollel Harbotzas Torah",
"Bais Medrash Elyon",
"Antioch University Los Angeles",
"Antioch University Santa Barbara",
"Antioch University New England",
"Antioch University Seattle",
"Antioch University Midwest",
"Mid America Christian University",
"Platt College Tulsa",
"Ponca City Beauty College",
"Francis Tuttle Technology Center",
"Central Technology Center",
"Carrington College Portland",
"Career Technology Center of Lackawanna County",
"Kaplan Career Institute Nashville",
"Kaplan College El Paso",
"Houston Graduate School of Theology",
"Palo Alto College",
"Sebring Career Schools Huntsville",
"University of Cosmetology Arts & Sciences San Antonio Perrin",
"Central Texas Beauty College Round Rock",
"Empire Beauty School Manitowoc",
"Unification Theological Seminary",
"Athens Technical College",
"Maricopa Skill Center",
"Carrington College California San Leandro",
"Headmasters School of Hair Design",
"Razzle Dazzle College of Hair Design Inc",
"Beckfield College Florence",
"University of Louisiana System Administration",
"Dorsey Business Schools Wayne",
"Ecumenical Theological Seminary",
"Ross Medical Education Center Saginaw",
"Sussex County Community College",
"Landmark College",
"National American University Independence",
"Mississippi Community College Board",
"Yeshiva Gedolah of Greater Detroit",
"Austin Graduate School of Theology",
"Collin County Community College District",
"Owensboro Community and Technical College",
"Heads West Kentucky Beauty College",
"Empire Beauty School Hurstborne",
"Southeast School of Cosmetology",
"Cloyds Beauty School 3 Inc",
"Moore Norman Technology Center",
"Fayette County Career & Technical Institute Practical Nursing Program",
"Franklin County Career and Technology Center",
"Paul Mitchell the School Knoxville",
"Ogle School Hair Skin Nails Hurst",
"Okefenokee Technical College",
"Fashion Institute of Design & Merchandising San Diego",
"National American University Albuquerque",
"Tennessee College of Applied Technology Nashville",
"ECPI University",
"Ms Roberts Academy of Beauty Culture Villa Park",
"Anthem Institute Jersey City",
"Paul Mitchell the School Houston",
"ATI Career Training Center Dallas",
"TDDS Technical Institute",
"Fortis Institute Forty Fort",
"Laurel Business Institute",
"Antonelli Medical and Professional Institute",
"Aviation Institute of Maintenance Philadelphia",
"Sanford Brown Institute Trevose",
"Fortis Institute Mulberry",
"Carrington College Phoenix Westside",
"Dorsey Business Schools Roseville",
"The Salon Professional Academy Williston",
"Moler Pickens Beauty Academy",
"Red River Technology Center",
"Kaplan Career Institute Harrisburg",
"ITT Technical Institute Newburgh",
"Southern Union State Community College",
"South University Columbia",
"Everest University Brandon",
"Clinton Technical School",
"Consolidated School of Business Lancaster",
"Greene County Career and Technology Center",
"Lac Courte Oreilles Ojibwa Community College",
"University of Wisconsin Extension",
"Diesel Driving Academy Shreveport",
"Pima Medical Institute Mesa",
"Platt College Los Angeles",
"Platt College Aurora",
"Kaplan University Davenport Campus",
"Kaplan University Cedar Falls Campus",
"Paul Mitchell the School Boise",
"Christian Life College",
"Knowledge Systems Institute",
"Educators of Beauty College of Cosmetology Rockford",
"ITT Technical Institute Arlington Heights",
"Northwestern College Southwestern Campus",
"Tulsa Technology Center Lemley Campus",
"Tulsa Technology Center Riverside Campus",
"Tulsa Technology Center Broken Arrow Campus",
"Douglas J Aveda Institute",
"ITT Technical Institute Troy",
"Ross Medical Education Center Brighton",
"Ross Medical Education Center Ann Arbor",
"Taylortown School of Beauty Inc",
"Pike Lincoln Technical Center",
"Pennsylvania Institute of Health and Technology",
"Skyline College Roanoke",
"Bethel Seminary San Diego",
"St Charles Community College",
"Brandman University",
"New College of Florida",
"Montana Bible College",
"Arizona Academy of Beauty North",
"Empire Beauty School Prescott",
"Le Cordon Bleu College of Culinary Arts Scottsdale",
"Conservatory of Recording Arts and Sciences",
"Adrians College of Beauty Turlock",
"California Career School",
"San Joaquin Valley College Fresno",
"Rolf Institute of Structural Integration",
"Heritage College Denver",
"Empire Beauty School Highland",
"Sunstate Academy",
"Elizabeth Grady School of Esthetics and Massage Therapy",
"Ross Medical Education Center Port Huron",
"Capri Institute of Hair Design Clifton",
"Raphaels School of Beauty Culture Inc Alliance",
"Metro Technology Centers",
"Heald College Roseville",
"Daymar College Bowling Green",
"Everest College Everett",
"Luna Community College",
"Grabber School of Hair Design",
"Yeshivah Gedolah Rabbinical College",
"University of the District of Columbia David A Clarke School of Law",
"Raphaels School of Beauty Culture Inc Brunswick",
"Caribbean University Ponce",
"Caribbean University Vega Baja",
"University of Advancing Technology",
"Paradise Valley Community College",
"Chandler Gilbert Community College",
"St Johns Hospital School of Clinical Lab Science",
"Madison Media Institute",
"Sanford Brown Institute Landover",
"Sanford Brown Institute White Plains",
"Sanford Brown Institute Garden City",
"Great Plains Technology Center",
"Montebello Beauty College",
"Roane Jackson Technical Center",
"Total Cosmetology Training Center",
"Iverson Business School",
"Lawrence County Career and Technical Center Practical Nursing Program",
"Pioneer Technology Center",
"Portage Lakes Career Center",
"Eastern College of Health Vocations New Orleans",
"Bell Mar Beauty College",
"Caddo Kiowa Technology Center",
"Kaplan College San Antonio San Pedro",
"Oswego County BOCES",
"Le Cordon Bleu College of Culinary Arts Austin",
"The Art Institute of New York City",
"Paris II Educational Center",
"International School of Skin Nailcare & Massage Therapy",
"Palomar Institute of Cosmetology",
"Southern Oklahoma Technology Center",
"Vista College",
"Autry Technology Center",
"Greene County Vocational School District",
"Canadian Valley Technology Center",
"Wilkes Barre Area Career and Technical Center Practical Nursing",
"Mayo Graduate School",
"American Beauty Institute",
"Meridian Technology Center",
"Waynesville Career Center",
"Connecticut Institute of Hair Design",
"Capitol School of Hairstyling and Esthetics",
"Morris County Vocational School District",
"Ross Medical Education Center Roosevelt Park",
"Ross Medical Education Center Kentwood",
"South Florida Bible College and Theological Seminary",
"Cloyds Barber School 2 Inc",
"Universal Technical Institute of Illinois Inc",
"Pennsylvania College of Technology",
"Commonwealth Institute of Funeral Service",
"Delta College of Arts & Technology",
"InterCoast Colleges Orange",
"Stone Child College",
"New York Academy of Art",
"Suffolk County Community College",
"Las Positas College",
"Blades School of Hair Design",
"Altamaha Technical College",
"Ogeechee Technical College",
"Remington College Mobile Campus",
"Everest College Aurora",
"Southwest Florida College",
"Mercy St Lukes School of Radiologic Technology",
"ITT Technical Institute Norwood",
"Southwest Acupuncture College Santa Fe",
"Northwest Technology Center Alva",
"Mercer County Career Center",
"ITT Technical Institute Knoxville",
"ITT Technical Institute Austin",
"ITT Technical Institute Houston North",
"DeVry Universitys Keller Graduate School of Management Arizona",
"California State University San Marcos",
"Argosy University Hawaii",
"DeVry Universitys Keller Graduate School of Management Missouri",
"Baptist Theological Seminary at Richmond",
"Kaplan College Modesto",
"Kaplan University Des Moines Campus",
"Hairmasters Institute of Cosmetology",
"Hair Arts Academy",
"Pinnacle Career Institute Lawrence",
"Interactive College of Technology Newport",
"Career Technical College Monroe",
"Empire Beauty School Lowell",
"Ross Medical Education Center Taylor",
"McNally Smith College of Music",
"Allied Health Careers",
"National Tractor Trailer School Inc Liverpool",
"Interactive Learning Systems Houston",
"Star Career Academy�Brick",
"New Castle Beauty Academy",
"NorthWest Arkansas Community College",
"Madera Beauty College",
"Colorado Heights University",
"Brewster Technical Center",
"Hodges University",
"Everest University Lakeland",
"Argosy University Atlanta",
"University of St Augustine for Health Sciences",
"Capital Area School of Practical Nursing",
"Alexandria School of Scientific Therapeutics",
"Empire Beauty School Bloomfield",
"Southwest Technology Center",
"Western Area Career and Technology Center",
"Daymar Institute Clarksville",
"Nossi College of Art",
"Seguin Beauty School New Braunfels",
"ITT Technical Institute Norfolk",
"Pima Medical Institute Seattle",
"Mineral County Vocational Technical Center",
"Hypnosis Motivation Institute",
"Los Angeles ORT College Los Angeles Campus",
"Everest College West Los Angeles",
"Thanh Le College School of Cosmetology",
"Southeastern Technical College",
"Massachusetts School of Law",
"ITT Technical Institute Albuquerque",
"Community Services Division Alliance City",
"Universal Technical Institute Auto Motorcycle & Marine Mechanics Institute Division Orlando",
"D G Erwin Technical Center",
"Rosel School of Cosmetology",
"Worsham College of Mortuary Science",
"Oregon College of Oriental Medicine",
"Central Pennsylvania Institute of Science and Technology",
"Northwest Educational Center",
"Sebring Career Schools Houston",
"PCI Health Training Center",
"Windham Memorial Hospital Radiologic Technology Program",
"Bethel Seminary of the East",
"Miami Jacobs Career College Columbus",
"Bryant & Stratton College Eastlake",
"Hamrick School",
"Alabama State College of Barber Styling",
"Fortis College Mobile",
"Everest College Ontario",
"Downey Adult School",
"Heald College Stockton",
"Heald College Hayward",
"CRU Institute",
"Modern Technology School",
"National Career Education",
"Everest College Anaheim",
"Thuy Princess Beauty College",
"North West College Glendale",
"Everest College City of Industry",
"Fremont College",
"Pomona Unified School District Adult and Career Education",
"University of Phoenix Online Campus",
"University of Phoenix San Diego Campus",
"Universal College of Beauty Inc Los Angeles 2",
"IBMC College",
"Xenon International Academy Denver",
"ITT Technical Institute Lake Mary",
"Heritage Institute Jacksonville",
"Fortis College Smyrna",
"Remington College Honolulu Campus",
"Vatterott College Des Moines",
"Brown Mackie College Quad Cities",
"Pivot Point Academy Bloomingdale",
"Xenon International Academy Kansas City",
"Baton Rouge College",
"Diesel Driving Academy Baton Rouge",
"Jonesville Beauty School",
"New England Hair Academy",
"Rob Roy Academy Worcester",
"Upper Cape Cod Regional Technical School",
"All State Career Baltimore",
"New England School of Communications",
"Everest Institute Kalamazoo",
"Adler Graduate School",
"Lebanon Technology and Career Center",
"Academy of Hair Design Jackson",
"KCs School of Hair Design",
"Mitchells Hairstyling Academy Goldsboro",
"Lincoln Technical Institute Moorestown",
"Monmouth County Vocational School District",
"Kaplan College Las Vegas",
"Beauty School of Middletown",
"Bryant & Stratton College Southtowns",
"Continental School of Beauty Culture Olean",
"National Tractor Trailer School Inc Buffalo",
"Yeshiva Gedolah Imrei Yosef Dspinka",
"Cleveland Institute of Dental Medical Assistants Mentor",
"Cleveland Institute of Dental Medical Assistants Lyndhurst",
"Knox County Career Center",
"Madison Adult Career Center",
"Remington College Cleveland Campus",
"Brown Mackie College Findlay",
"Toledo Academy of Beauty Culture East",
"U S Grant Joint Vocational School",
"CCs Cosmetology College",
"Chisholm Trail Technology Center",
"Gordon Cooper Technology Center",
"Kiamichi Technology Center Atoka",
"Kiamichi Technology Center Durant",
"Kiamichi Technology Center Hugo",
"Kiamichi Technology Center McAlester",
"Kiamichi Technology Center Idabel",
"Kiamichi Technology Center Poteau",
"Kiamichi Technology Center Spiro",
"Kiamichi Technology Center Talihina",
"Le Cordon Bleu College of Culinary Arts Portland",
"YTI Career Institute Altoona",
"Eastern Center for Arts and Technology",
"Empire Beauty School Center City Philadelphia",
"Oakbridge Academy of Arts",
"Sanford Brown Institute Wilkins Township",
"Centro de Estudios Multidisciplinarios Humacao",
"Colegio Mayor de Tecnologia Inc",
"Institucion Chaviano de Mayaguez",
"EDIC College",
"Liceo de Arte Dise O y Comercio",
"Universal Technology College of Puerto Rico",
"Academy of Hair Technology",
"Mr Waynes School of Unisex Hair Design",
"Southern Institute of Cosmetology",
"Queen City College",
"College of the Marshall Islands",
"American National College",
"ITT Technical Institute San Antonio",
"Remington College Fort Worth Campus",
"University of Cosmetology Arts & Sciences San Antonio Jamar",
"School of Automotive Machinists",
"SW School of Business and Technical Careers Cosmetology",
"SW School of Business and Technical Careers",
"Texas College of Cosmetology Abilene",
"Vista College Online",
"Centura College Newport News",
"Centura College Norfolk",
"Virginia Beach City Public Schools School of Practical Nursing",
"Northwest College of Art & Design",
"University of Washington Bothell Campus",
"University of Washington Tacoma Campus",
"Huntington School of Beauty Culture Main Campus",
"Valley College Beckley",
"Valley College Martinsburg",
"Valley College Princeton",
"Bellus Academy Poway",
"Capstone College",
"Central Coast College",
"ITT Technical Institute Torrance",
"Pacific College of Oriental Medicine San Diego",
"Artistic Nails and Beauty Academy Tampa",
"National Aviation Academy of Tampa Bay",
"Sanford Brown Institute Tampa",
"Cains Barber College Inc",
"Lawton Career Institute Oak Park Campus",
"Madison Oneida BOCES Practical Nursing Program",
"Astrodome Career Centers",
"Center for Advanced Legal Studies",
"Houston Training School Main Campus",
"Remington College Houston Campus",
"Bay Mills Community College",
"Fond du Lac Tribal and Community College",
"Northwest Indian College",
"Marinello Schools of Beauty Provo",
"Provo College",
"Paul Mitchell the School Provo",
"University of Phoenix Utah Campus",
"Utah College of Massage Therapy Salt Lake City",
"CET Coachella",
"CET Oxnard",
"CET Santa Maria",
"CET Rancho Temecula",
"Colorado School of Traditional Chinese Medicine",
"Massage Therapy Institute of Colorado",
"Michigan College of Beauty Troy",
"Ogle School Hair Skin Nails Dallas",
"Phoenix Seminary",
"Faust Institute of Cosmetology Spirit Lake",
"Seattle Vocational Institute",
"Baker College of Port Huron",
"Empire Beauty School Hyannis",
"Brookstone College Greensboro",
"Cheeks International Academy of Beauty Culture Fort Collins",
"Cheeks International Academy of Beauty Culture Greeley",
"Colorado School of Healing Arts",
"Remington College Colorado Springs Campus",
"Empire Beauty School Arvada",
"Westwood College Denver South",
"Kaplan College Denver",
"Healing Arts Institute",
"ITT Technical Institute San Bernardino",
"Los Angeles ORT College Van Nuys Campus",
"Texas School of Business Southwest",
"University of Phoenix Puerto Rico Campus",
"Mt Diablo Adult Education",
"Royale College of Beauty",
"Westech College",
"Greater Lowell Technical School",
"Academy of Hair Design Pearl",
"Miller Motte Technical College Clarksville",
"Genesis Career College Lebanon",
"Kaplan College Dallas",
"Star College of Cosmetology 2",
"Southwest Collegiate Institute for the Deaf",
"Fortis College Richmond",
"Midwest College of Oriental Medicine Racine",
"Hacienda La Puente Adult Education",
"La Belle Beauty Academy",
"Advanced Technical Centers",
"Hawaii Community College",
"Regency Beauty Institute Winston Salem",
"Robert Fiance Beauty Schools North Plainfield",
"Long Island Beauty School Hauppauge",
"Buckeye Joint Vocational School",
"Sandusky Career Center",
"Greater Altoona Career & Technology Center",
"Northeastern Hospital School of Nursing",
"Central Texas Beauty College Temple",
"Dance Theatre of Harlem Inc",
"Erie 2 Chautauqua Cattaraugus BOCES Practical Nursing Program",
"Empire Beauty School Brooklyn",
"Houston Training School Gulfgate",
"Star Career Academy Philadelphia",
"Antonelli College Hattiesburg",
"Eastern New Mexico University Ruidoso Campus",
"Newbridge College Santa Ana",
"Kaplan College Stockton",
"The Bryman School of Arizona",
"Beacon College",
"Acupuncture and Integrative Medicine College Berkeley",
"Estrella Mountain Community College",
"Heartland Community College",
"University of the Potomac Washington DC Campus",
"Rabbi Jacob Joseph School",
"Sanford Brown Institute Ft Lauderdale",
"The Salon Professional Academy Melbourne",
"Fortis Institute Scranton",
"Milan Institute of Cosmetology Amarillo",
"Everglades University",
"Seminar Lmoros Bais Yaakov",
"Herzing University Winter Park",
"Coral Ridge Nurses Assistant Training School Inc",
"Emil Fries Piano Hospital and Training Center",
"Fairview Beauty Academy",
"Florida College of Natural Health Pompano Beach",
"National College Nashville",
"College of Biblical Studies Houston",
"Inner State Beauty School",
"Virginia College Pensacola",
"Mid EastCTC Adult Education",
"Everest University South Orlando",
"Polaris Career Center",
"P&A Scholars Beauty School",
"Rensselaer BOCES Practical Nursing Program",
"ASM Beauty World Academy",
"The Salter School Tewksbury Campus",
"Sanford Brown College Collinsville",
"Sharps Academy of Hair Styling",
"ICC Technical Institute",
"College of Health Care Professions Northwest",
"School of Professional Horticulture at the New York Botanical Garden",
"Fortis College Houston",
"Volunteer Beauty Academy Madison",
"Watkins College of Art Design & Film",
"Chicago ORT Technical Institute",
"Alhambra Beauty College",
"American Auto Institute",
"Asian American International Beauty College",
"Avance Beauty College",
"Colton Redlands Yucaipa Regional Occupational Program",
"Copper Mountain Community College",
"Diversified Vocational College",
"Bristol University",
"UEI College Fresno",
"Mt Sierra College",
"Wyotech Long Beach",
"Santiago Canyon College",
"Southern California Institute of Technology",
"Soka University of America",
"Summit College",
"Advance Beauty College",
"Career Colleges of America",
"World Mission University",
"Yo San University of Traditional Chinese Medicine",
"Coast Career Institute",
"Cannella School of Hair Design Blue Island",
"Delta College Inc",
"Colorado Technical University Sioux Falls",
"Southwest University of Visual Arts Albuquerque",
"Tri State Cosmetology Institute",
"Widener University Harrisburg Campus",
"Volunteer Beauty Academy System Office",
"Volunteer Beauty Academy Nashville",
"Frontier Community College",
"Lincoln Trail College",
"Wabash Valley College",
"American Institute of Interior Design",
"Brookline College Tempe",
"Baker College of Auburn Hills",
"Baker College of Clinton Township",
"Inter American University of Puerto Rico School of Optometry",
"Schiller International University",
"Vatterott College Springfield",
"Vatterott College Joplin",
"Vatterott College Kansas City",
"Coconino Community College",
"University System of Maryland Research Centers",
"Sanford Brown College Houston",
"Sanford Brown Institute Jacksonville",
"Sanford Brown College Dallas",
"Baker College of Cadillac",
"Emmas Beauty Academy Juana Diaz",
"Franklin Academy",
"Columbia Centro Universitario Yauco",
"Pima Medical Institute Denver",
"Fortis College Miami",
"ASA College",
"Ohio State School of Cosmetology Westerville",
"Nationwide Beauty Academy",
"Yeshivas Novominsk",
"Southern Careers Institute Corpus Christi",
"Kiamichi Technology Center Stigler",
"Rabbinical College of Ohr Shimon Yisroel",
"Carroll Community College",
"University of Phoenix Southern Arizona Campus",
"University of Phoenix Colorado Campus",
"University of Phoenix Albuquerque Campus",
"University of Phoenix Bay Area Campus",
"University of Phoenix Southern California Campus",
"Galen College of Nursing Tampa Bay",
"Galen College of Nursing San Antonio",
"Southeastern Beauty School Columbus North Lumpkin",
"Southeastern Beauty School Columbus Midtown",
"Sarasota School of Massage Therapy",
"Westwood College Dupage",
"Wright Career College",
"Daymar College Louisville",
"Sanford Brown College Hazelwood",
"Schuyler Steuben Chemung Tioga Allegany BOCES",
"Delaware Chenango Madison Otsego BOCES Practical Nursing Program",
"DCI Career Institute",
"Central Susquehanna Intermediate Unit LPN Career",
"Myotherapy College of Utah",
"Graham Webb International Academy of Hair",
"City College Gainesville",
"Arizona State University West",
"Connecticut Center for Massage Therapy Westport",
"ITT Technical Institute Jacksonville",
"G Skin & Beauty Institute",
"Cosmetology Training Center",
"Stage One The Hair School",
"Empire Beauty School Malden",
"Minnesota School of Business Brooklyn Center",
"ITT Technical Institute Omaha",
"Omaha School of Massage and Healthcare of Herzing University",
"Xenon International Academy Omaha",
"Capri Institute of Hair Design Brick",
"Capri Institute of Hair Design Kenilworth",
"Capri Institute of Hair Design Paramus",
"Orlo School of Hair Design and Cosmetology",
"Delaware Area Career Center",
"Eastland Fairfield Career and Technical Schools",
"Empire Beauty School Cincinnati",
"Mahoning County Career and Technical Center",
"Ohio Hi Point Joint Vocational School District",
"Pickaway Ross Joint Vocational School District",
"Pioneer Career and Technology Center",
"Raphaels School of Beauty Culture Inc Boardman",
"Scioto County Career Technical Center",
"Trumbull Career & Technical Center",
"Northwest Technology Center Fairview",
"Lake Erie College of Osteopathic Medicine",
"University of Cosmetology Arts & Sciences Harlingen",
"Gene Juarez Academy of Beauty Federal Way",
"Empire Beauty School Appleton",
"Empire Beauty School Green Bay",
"Warren County Career Center",
"Brown Mackie College Fort Wayne",
"Paul Mitchell the School Wichita",
"Pinnacle Institute of Cosmetology",
"Career Training Academy Monroeville",
"Southern Careers Institute Pharr",
"Texas State Technical College Marshall",
"Florida National University Main Campus",
"Georgia Institute of Cosmetology",
"Nouvelle Institute",
"ITT Technical Institute Fort Lauderdale",
"Franklin Technology MSSU",
"Carson City Beauty Academy",
"Professional Technical Institution Inc",
"Erikson Institute",
"South Texas College",
"Tennessee Board of Regents",
"Pike County Joint Vocational School District",
"Lorain County Joint Vocational School District",
"Roger Williams University School of Law",
"Everest Institute Kendall",
"California State University Monterey Bay",
"Sanford Brown College St Peters",
"Colorado Technical University Kansas City",
"Heald College Salinas",
"Academy of Somatic Healing Arts",
"Heritage College Oklahoma City",
"Ann Arbor Institute of Massage Therapy",
"Brown Mackie College Atlanta",
"Bryant & Stratton College Henrietta",
"The Art Institute of California Argosy University Hollywood",
"Sage College",
"Anthem College Denver",
"Lincoln Technical Institute Hartford",
"Ohio Center for Broadcasting Valley View",
"EINE Inc",
"Laurus Technical Institute",
"Illinois School of Health Careers Chicago Campus",
"Marian Health Careers Center Los Angeles Campus",
"Cleveland Clinic Health System School of Diagnostic Imaging",
"National Institute of Massotherapy",
"Academy for Nursing and Health Occupations",
"Shawsheen Valley Regional Vocational Technical School",
"Shear Ego International School of Hair Design",
"Remington College Memphis Campus",
"Star Truck Driving School Hickory Hills",
"Fayette Institute of Technology",
"Professional Beauty School",
"Capella University",
"College of Menominee Nation",
"Leech Lake Tribal College",
"Anthem College Sacramento",
"Southwest Acupuncture College Albuquerque",
"Crimson Technical College",
"Stone Academy Waterbury",
"East San Gabriel Valley Regional Occupational Program",
"Yukon Beauty College Inc",
"ITT Technical Institute Little Rock",
"ITT Technical Institute Oxnard",
"ITT Technical Institute Louisville",
"ITT Technical Institute Greenville",
"ITT Technical Institute Cordova",
"Cairn University Wisconsin Wilderness",
"Toni & Guy Hairdressing Academy Atlanta",
"Mitchells Hair Styling Academy Raleigh",
"Salon Academy",
"Artistic Academy of Hair Design",
"Motoring Technical Training Institute",
"Baker College of Jackson",
"Phagans Newport Academy of Cosmetology Careers",
"Mech Tech College",
"Baldwin Beauty School North Austin",
"ITT Technical Institute Everett",
"Jean Madeline Aveda Institute",
"Cosmetology Schools Inc",
"ITT Technical Institute Bessemer",
"ITT Technical Institute Orland Park",
"Pacific College of Oriental Medicine New York",
"Baker College Center for Graduate Studies",
"Johnson & Wales University North Miami",
"ITT Technical Institute Pittsburgh",
"Tricoci University of Beauty Culture Indianapolis",
"Trine University Regional/Non Traditional Campuses",
"Pennsylvania Highlands Community College",
"Nevada Career Institute",
"Phillips Theological Seminary",
"Empire Beauty School Hanover",
"Industrial Management Training Institute",
"Faris Technical Institute Inc",
"Four D College",
"Allen School Jamaica",
"Premiere Career College",
"Tennessee Academy of Cosmetology Stage Road",
"The University of Texas MD Anderson Cancer Center",
"University of Phoenix Las Vegas Campus",
"Carousel Beauty College Springfield",
"Carousel Beauty College Kettering",
"Paul Mitchell the School Huntsville",
"Estes Institute of Cosmetology Arts and Science",
"Baldwin Park Adult & Community Education",
"Ventura Adult and Continuing Education",
"Marinello Schools of Beauty Sacramento",
"Albert I Prince Technical High School",
"Bullard Havens Technical High School",
"Howell Cheney Technical High School",
"W F Kaynor Technical High School",
"Platt Technical High School",
"College of Business and Technology Kendall",
"City College Altamonte Springs",
"Augusta Area Dietetic Internship University Hospital",
"Empire Beauty School Dunwoody",
"Michaels School of Beauty",
"Cobb Beauty College Inc",
"Career Center of Southern Illinois",
"Xenon International Academy Wichita",
"Camelot College",
"Mr Bernards School of Hair Fashion Inc",
"Hastings Beauty School",
"Kirksville Area Technical Center",
"South Central Career Center",
"Saline County Career Center",
"Warrensburg Area Career Center",
"Nevada Regional Technical Center",
"Cass Career Center",
"Applied Technology Services",
"Central College of Cosmetology",
"Southeast Missouri Hospital College of Nursing and Health Sciences",
"Dallas County Technical Center",
"Messenger College",
"Tennessee Academy of Cosmetology Shelby Drive",
"Cheveux School of Hair Design",
"Durham Beauty Academy",
"Union County Vocational Technical School",
"Prism Career Institute Philadelphia",
"Clinton Essex Warren Washington BOCES Practical Nursing Program",
"Jefferson Lewis BOCES Practical Nursing Program",
"Rochester General Hospital School of Medical Technology",
"Veeb Nassau County School of Practical Nursing",
"Syracuse City Schools Practical Nursing Program",
"Cattaraugus Allegany BOCES Practical Nursing Program",
"Orange Ulster BOCES Practical Nursing Program",
"Wayne Finger Lakes BOCES Practical Nursing Program",
"Broome Delaware Tioga BOCES Practical Nursing Program",
"Washington Saratoga Warren Hamilton Essex BOCES Practical Nursing Program",
"Albany BOCES Adult Practical Nursing Program",
"Ulster County BOCES Practical Nursing Program",
"Eastern Suffolk BOCES Practical Nursing Program",
"Rockland County BOCES Practical Nursing Program",
"New York College of Health Professions",
"Career Institute of Health and Technology",
"Charles Stuart School of Diamond Setting",
"Penta County Joint Vocational School",
"Career and Technology Education Centers of Licking County",
"Willoughby Eastlake School of Practical Nursing",
"Washington County Career Center Adult Technical Training",
"Ohio Center for Broadcasting Cincinnati",
"Vantage Career Center",
"Tri County Technology Center",
"Indian Capital Technology Center Muskogee",
"Western Technology Center",
"Mid America Technology Center",
"Northeast Technology Center Pryor",
"Eastern Oklahoma County Technology Center",
"Wes Watkins Technology Center",
"Technical Institute of Cosmetology Arts and Sciences",
"York County School of Technology Adult & Continuing Education",
"Chester County Intermediate Unit",
"Jefferson County Dubois Area Vocational Technical Practical Nursing Program",
"Greater Johnstown Career and Technology Center",
"Mifflin Juniata Career and Technology Center",
"Hanover Public School District Practical Nursing Program",
"Lenape Technical School Practical Nursing Program",
"Venango County Area Vocational Technical School",
"Lancaster County Career and Technology Center",
"Lebanon County Area Vocational Technical School",
"Indiana County Technology Center",
"Northern Tier Career Center",
"Clarion County Career Center Practical Nursing Program",
"Hazleton Area Career Center",
"All State Career School Pittsburgh",
"Carlos F Daniels Area Vocational School",
"Serbias Technical College",
"Colegio Tecnologico y Comercial de Puerto Rico",
"Quality Technical and Beauty College",
"DMart Institute",
"Automeca Technical College Aguadilla",
"Fortis Institute Cookeville",
"North Central Institute",
"Pipo Academy of Hair Design",
"Tint School of Makeup and Cosmetology Dallas",
"United Technical Center",
"Charles H McCann Technical School",
"Ailano School of Cosmetology",
"Carsten Institute of Cosmetology",
"Central California School",
"Mercy Hospital School of Practical Nursing Plantation General Hospital",
"Marion County Community Technical and Adult Education Center",
"Rivertown School of Beauty Barber Skin Care and Nails",
"Atlanta Institute of Music",
"Illinois Center for Broadcasting",
"Marion Community Schools Tucker Career & Technology Center",
"St Louis Hair Academy",
"New Dimensions School of Hair Design",
"Adirondack Beauty School",
"Forbes Road Career and Technology Center",
"Jna Institute of Culinary Arts",
"Cortiva Institute Seattle",
"Putnam Career and Technical Center",
"Argosy University Washington DC",
"Crawford County Career and Technical Center Practical Nursing Program",
"University of Phoenix Sacramento Valley Campus",
"DeVry Universitys Keller Graduate School of Management Georgia",
"Texas College of Cosmetology San Angelo",
"Beauty Schools of America Hialeah",
"Automeca Technical College Bayamon",
"Automeca Technical College Caguas",
"Automeca Technical College Ponce",
"Everest University Melbourne",
"Centura College Chesapeake",
"University of Phoenix Hawaii Campus",
"Florida College of Natural Health Miami",
"Prism Career Institute Cherry Hill",
"Amerian National College",
"Francois D College of Hair Skin and Nails",
"Southwest College of Naturopathic Medicine & Health Sciences",
"Escuela de Peritos Electricistas de Isabela Inc",
"Virginia College Birmingham",
"Virginia College Huntsville",
"Yeshiva Dmonsey Rabbinical College",
"Lincoln Technical Institute Mahwah",
"Career Technical Institute",
"Northwest Vista College",
"ITT Technical Institute Getzville",
"Oconee Fall Line Technical College",
"York County Community College",
"Northeast Technology Center Afton",
"Western Suffolk BOCES",
"Sanford Brown College Atlanta",
"Sanford Brown College Middleburg Heights",
"Arkansas State University Mountain Home",
"Columbia Gorge Community College",
"Arizona State University Polytechnic",
"Colleen OHaras Beauty Academy",
"Bakke Graduate University",
"Tillamook Bay Community College",
"Arizona Board of Regents",
"Argosy University Schaumburg",
"DeVry Universitys Keller Graduate School of Management California",
"Manhattan School of Computer Technology",
"University of Phoenix Louisiana Campus",
"University of Phoenix Detroit Campus",
"Brown Mackie College Hopkinsville",
"Indian Capital Technology Center Sallisaw",
"Indian Capital Technology Center Stilwell",
"Globelle Technical Institute",
"Hairitage Hair Academy",
"Houghton Lake Institute of Cosmetology",
"Arizona College Glendale",
"Vanguard Sentinel Adult Career and Technology Center",
"House of Heavilin Beauty College Raymore",
"Living Arts College",
"New York Conservatory for Dramatic Arts",
"Eastern International College Jersey City",
"Academia Serrant Inc",
"San Joaquin Valley College Fresno Aviation",
"Champion Institute of Cosmetology",
"Pacific College",
"American College of Healthcare",
"Compu Med Vocational Careers Corp",
"Professional Electrical School Inc",
"American Commercial College Wichita Falls",
"Midwestern University Glendale",
"Oregon Coast Community College",
"Le Cordon Bleu College of Culinary Arts Pasadena",
"Gwinnett College Sandy Springs",
"Central Mass School of Massage and Therapy Inc",
"Lincoln Technical Institute Brockton",
"Mildred Elley Pittsfield Campus",
"Embry Riddle Aeronautical University Worldwide",
"Computer Systems Institute",
"Merkaz Bnos Business School",
"Lia Schorr Institute of Cosmetic Skin Care Training",
"ITT Technical Institute Webster",
"CET Alexandria",
"CET Durham",
"Aviation Institute of Maintenance Chesapeake",
"Centura College Richmond Main",
"Technical Education Center Osceola",
"Miami Ad School Miami Beach",
"Green Country Technology Center",
"Nova College de Puerto Rico",
"Styles and Profiles Beauty College",
"John Wesley International Barber and Beauty College",
"Platt College Lawton",
"Southeastern College Greenacres",
"Ottawa University Milwaukee",
"Argosy University Tampa",
"The Illinois Institute of Art Schaumburg",
"Star Truck Driving School Bensenville",
"Cambria Rowe Business College Indiana",
"Facultad de Derecho Eugenio Maria de Hostos",
"Klamath Community College",
"San Bernardino Community College District",
"The Art Institute of Phoenix",
"Aerosim Flight Academy",
"American College of Acupuncture and Oriental Med",
"AOMA Graduate School of Integrative Medicine",
"Arlington Medical Institute",
"Urban College of Boston",
"ITT Technical Institute Henderson",
"Anthem College Minnesota",
"Colorado Technical University Greenwood Village",
"Branford Hall Career Institute Windsor Campus",
"Heald College Portland",
"Golf Academy of America Altamonte Springs",
"Golf Academy of America Phoenix",
"Everest College Merrillville",
"Strayer University Maryland",
"Empire Beauty School Bordentown",
"Empire Beauty School Monroeville",
"Southern Careers Institute San Antonio",
"Virginia School of Massage",
"ITT Technical Institute Miami",
"Cayce Reilly School of Massotherapy",
"CET Sacramento",
"ITT Technical Institute Harrisburg",
"ITT Technical Institute Tarentum",
"ITT Technical Institute Strongsville",
"Prism Career Institute West Atlantic City",
"Everest Institute Marietta",
"The Creative Center",
"Studio Jewelers",
"Praxis Institute",
"American College of Traditional Chinese Medicine",
"Herndon Career Center",
"San Juan Bautista School of Medicine",
"Texas Health and Science University",
"Carver Career Center",
"New Hampshire Institute of Art",
"StenoTech Career Institute Fairfield",
"Cayuga Onondaga BOCES Practical Nursing Program",
"Clearfield County Career and Technology Center",
"Colegio de Cinematograf�a Artes y Television",
"Delaware County Technical School Practical Nursing Program",
"Everest College Torrance",
"The Institute for Health Education",
"Mid Del Technology Center",
"BIR Training Center",
"New York Institute of Massage Inc",
"Jolie Hair and Beauty Academy Ludlow",
"Dutchess BOCES Practical Nursing Program",
"Escuela Tecnica de Electricidad",
"Institute of Technology Inc",
"Garnet Career Center",
"Bristol Technical Education Center",
"Genesee Valley BOCES Practical Nursing Program",
"Hamilton Fulton Montgomery BOCES Practical Nursing Program",
"Henrico County Saint Marys Hospital School of Practical Nursing",
"Herkimer County BOCES Practical Nursing Program",
"International Beauty College",
"Dewey University Hato Rey",
"Cape Coral Institute of Technology",
"Massachusetts General Hospital Dietetic Internship",
"Mercy Hospital School of Nursing",
"American Beauty Academy",
"Northland Career Center",
"Norwich Technical High School",
"Ocean County Vocational Technical School",
"Putnam Westchester BOCES Practical Nursing Program",
"Sullivan County BOCES Practical Nursing Program",
"Kaplan College San Antonio Ingram",
"Trinity College of Puerto Rico",
"Virginia Sewing Machines and School Center",
"Vogue Beauty and Barber School",
"Yeshiva of the Telshe Alumni",
"Northeast Technology Center Kansas",
"Kenneth Shuler School of Cosmetology Rock Hill",
"South Orange County Community College District",
"DeVry College of New York",
"DeVry University Indiana",
"University of Phoenix Western Washington Campus",
"University of Phoenix Central Florida Campus",
"University of Phoenix Oregon Campus",
"CET El Paso",
"Kaplan College Riverside",
"Appalachian School of Law",
"LIU Hudson at Westchester",
"Platt College Ontario",
"Delaware College of Art and Design",
"The Art Institute of California Argosy University Los Angeles",
"American University of Health Sciences",
"Career Networks Institute",
"Monty Tech",
"CHOICE High School and Technical Center",
"Lincoln Technical Institute Lincoln",
"Wayne County Schools Career Center",
"Blessing Hospital School of Medical Laboratory Technology",
"Carolinas College of Health Sciences",
"Lester E Cox Medical Center School of Medical Technology",
"Western Governors University",
"Santa Barbara Business College Ventura",
"Sullivan and Cogliano Training Center",
"Herzing University Kenner",
"Spencerian College Lexington",
"Florida Gulf Coast University",
"Seacoast Career Schools Sanford Campus",
"Little Priest Tribal College",
"National Labor College",
"ITT Technical Institute Richardson",
"South Louisiana Community College",
"HRDE Stanley Technical Institute Parkersburg",
"Pima Medical Institute Chula Vista",
"Lincoln College of Technology Marietta",
"Academy of Hair Design Lufkin",
"Academy of Hair Design Beaumont",
"Baltimore School of Massage Linthicum",
"Charter College Canyon Country",
"Toni & Guy Hairdressing Academy Worcester",
"Northwest HVAC/R Training Center",
"L T International Beauty School",
"Michigan Jewish Institute",
"Myotherapy Institute",
"Florida College of Integrative Medicine",
"New Professions Technical Institute",
"Strand College of Hair Design",
"Cosmetology Career Institute",
"Branford Hall Career Institute Southington Campus",
"City College Miami",
"ITT Technical Institute Arnold",
"ITT Technical Institute Oak Brook",
"ITT Technical Institute Albany",
"ITT Technical Institute Liverpool",
"Ilisagvik College",
"The Community College of Baltimore County",
"Golf Academy of America Myrtle Beach",
"Florida Coastal School of Law",
"White Earth Tribal and Community College",
"Centura College North Charleston",
"Blue Cliff College Metairie",
"Creative Images Institute of Cosmetology North Dayton",
"Dewey University Carolina",
"Yeshiva College of the Nations Capital",
"University of Phoenix South Florida Campus",
"University of Phoenix North Florida Campus",
"University of Phoenix West Florida Campus",
"University of Phoenix Maryland Campus",
"University of Phoenix Oklahoma City Campus",
"University of Phoenix Tulsa Campus",
"Louisiana State University Health Sciences Center Shreveport",
"Central Career Institute LLC",
"Franklin Career Institute",
"Montessori Institute of Milwaukee",
"Hair Academy II",
"Pryor Beauty College",
"Argosy University Phoenix",
"Mr Leons School of Hair Design Lewiston",
"Vatterott College St Joseph",
"Vatterott College Sunset Hills",
"Anthem College Orlando",
"Southwest Acupuncture College Boulder",
"Utah College of Massage Therapy Utah Valley",
"River Parishes Community College",
"Watson School of Biological Sciences at Cold Spring Harbor Laboratory",
"Los Angeles Film School",
"Argosy University Orange County",
"Dewey University Bayamon",
"National American University Bloomington",
"A.B.I. School of Barbering & Cosmetology of Chelsea Inc.",
"FINE Mortuary College",
"International Baptist College and Seminary",
"The Medical Arts School",
"Fortis College Foley",
"Medical Training College",
"Professional Training Centers",
"Toni & Guy Hairdressing Academy Carrollton",
"University of Connecticut Tri Campus",
"University of Connecticut Avery Point",
"University of Connecticut Stamford",
"ITT Technical Institute Saint Rose",
"ITT Technical Institute Richmond",
"Vatterott College Oklahoma City",
"University of Management and Technology",
"Baton Rouge Community College",
"ITT Technical Institute Lathrop",
"International Yacht Restoration School",
"Linfield College School of Nursing",
"Linfield College Adult Degree Program",
"DeVry Universitys Keller Graduate School of Management Virginia",
"DeVry Universitys Keller Graduate School of Management Wisconsin",
"Academy of Massage Therapy",
"Star Career Academy Syosset",
"Midwest Technical Institute Springfield",
"Advanced Barber College and Hair Design",
"The College of Health Care Professions Austin",
"The English Center",
"ICDC College",
"Monteclaro Escuela de Hoteleria y Artes Culinarias",
"New Community Workforce Development Center",
"Dallas Nursing Institute",
"Professional Golfers Career College",
"Richmond School of Health and Technology",
"Milan Institute San Antonio Ingram",
"Westwood College Anaheim",
"Rudaes School of Beauty Culture Ft Wayne",
"InterCoast Colleges Burbank",
"Carrington College California Antioch",
"Carrington College California San Jose",
"Lincoln Technical Institute Cromwell",
"Stevens Henager College Murray",
"Brookline College Tucson",
"Everest Institute Jonesboro",
"McCann School of Business & Technology",
"Carrington College California Pleasant Hill",
"Ridley Lowell Business & Technical Institute Poughkeepsie",
"Florida College of Natural Health Maitland",
"Florida College of Natural Health Bradenton",
"Modern Hairstyling Institute Carolina",
"University of Phoenix Southern Colorado Campus",
"University of Phoenix West Michigan Campus",
"Stratford University",
"Universal Technical Institute of California Inc",
"American InterContinental University Atlanta",
"American InterContinental University South Florida",
"University of Phoenix Philadelphia Campus",
"University of Phoenix Pittsburgh Campus",
"Everest Institute Decatur",
"Everest College Chesapeake",
"Everest Institute Greenspoint",
"Rancho Santiago Community College District Office",
"The Academy of Hair Design Six",
"Eastern West Virginia Community and Technical College",
"Aviation Institute of Maintenance Indianapolis",
"Branford Hall Career Institute Springfield Campus",
"Remington College Little Rock Campus",
"Newbridge College San Diego East",
"Everest University Jacksonville",
"IVAEM College",
"Midwest College of Oriental Medicine Chicago",
"Lincoln Technical Institute Hamden",
"Argosy University Seattle",
"Carrington College Spokane",
"Texas School of Business Friendswood",
"ITT Technical Institute Wilmington",
"Pierce College at Puyallup",
"Texas Tech University System Administration",
"DeVry University Florida",
"Cascadia Community College",
"University of Phoenix St Louis Campus",
"Johnson & Wales University Denver",
"University of Phoenix Cleveland Campus",
"Du Bois Business College Huntingdon",
"Du Bois Business College Oil City",
"DeVry University Maryland",
"CBD College",
"East West College of Natural Medicine",
"Lincoln Technical Institute Fern Park",
"Atlantic Institute of Oriental Medicine",
"ATS Institute of Technology",
"Bennett Career Institute",
"Blue Cliff College Shreveport",
"Blue Cliff College Lafayette",
"Quest College",
"Central Florida Institute",
"Community Care College",
"Marinello Schools of Beauty Topeka",
"Crossroads Bible College",
"Erie 1 BOCES",
"Fayette Beauty Academy",
"HDS Truck Driving Institute",
"International Training Careers",
"Kings University",
"Lacy Cosmetology School Aiken",
"Fortis College Baton Rouge",
"My Les Beauty College",
"New Concept Massage and Beauty School",
"New York College of Traditional Chinese Medicine",
"Fortis College Orange Park",
"Northeastern Seminary",
"Bryan College Gold River",
"Omega Institute of Cosmetology",
"Pacific Islands University",
"PCI College",
"Rosedale Bible College",
"American Career College at St Francis",
"Seattle Institute of Oriental Medicine",
"Shawnee Beauty College",
"Acupuncture and Massage College",
"Southeastern School of Cosmetology",
"Universal Therapeutic Massage Institute",
"Upper Valley Educators Institute",
"Keck Graduate Institute",
"Aviation Institute of Maintenance Atlanta",
"Star Career Academy Egg Harbor",
"Empire Beauty School Kennesaw",
"Anthem Career College Nashville",
"Anthem College Irving",
"Antioch University System Administration",
"Westwood College River Oaks",
"ITT Technical Institute Green Bay",
"Career Training Academy Pittsburgh",
"Huntingdon County Career and Technology Center",
"Missouri College of Cosmetology North",
"New York Automotive and Diesel Institute",
"Remington College Baton Rouge Campus",
"Everest College Ontario Metro",
"Kaplan College Beaumont",
"Kaplan College Laredo",
"The Art Institute of Washington",
"Everest Institute Dearborn",
"Centura College Richmond Westend",
"New Saint Andrews College",
"Arkansas State University Newport",
"Marlboro College Graduate School",
"University of Phoenix Boston Campus",
"University of Phoenix Dallas Campus",
"University of Phoenix Houston Campus",
"University of Phoenix Milwaukee Campus",
"University of Phoenix Idaho Campus",
"Westwood College Inland Empire",
"DeVry University Virginia",
"DeVry University Washington",
"DeVry Universitys Keller Graduate School of Management Ohio",
"DeVry Universitys Keller Graduate School of Management Texas",
"DeVry University Colorado",
"Louisiana Delta Community College",
"ITT Technical Institute Levittown",
"Atenas College",
"Southwest Skill Center Campus of Estrella Mountain Community College",
"Onondaga Cortland Madison BOCES",
"National American University Ellsworth AFB Extension",
"National American University Albuquerque West",
"National American University Brooklyn Center",
"Interactive Learning Systems Pasadena",
"Pillar College",
"Miami Ad School Minneapolis",
"Miami Ad School San Francisco",
"Oregon State University Cascades Campus",
"Milan Institute Boise",
"Milan Institute Amarillo",
"Vatterott College Dividend",
"Vatterott College Tulsa",
"Vatterott College Wichita",
"Star Career Academy Newark",
"Southern University Law Center",
"International Academy of Design and Technology Orlando",
"Lincoln Technical Institute Lowell",
"Everest College Vancouver Massage Therapy",
"Texas Barber Colleges and Hairstyling Schools",
"Everest Institute Hobby",
"Miller Motte Technical College North Charleston",
"American Career College Anaheim",
"Saginaw Chippewa Tribal College",
"Wyo Tech Blairsville",
"Richmont Graduate University",
"The Seattle School of Theology & Psychology",
"DeVry Universitys Keller Graduate School of Management Washington",
"Northwest College Hillsboro",
"San Diego State University Imperial Valley Campus",
"American Broadcasting School Arlington",
"Miami Jacobs Career College Sharonville",
"East Valley Institute of Technology",
"West Coast Ultrasound Institute",
"Irell & Manella Graduate School of Biological Sciences at City of Hope",
"Designs School of Cosmetology",
"Paul Mitchell the School Santa Barbara",
"University of the Rockies",
"Eli Whitney Technical High School",
"Vinal Technical High School",
"Windham Technical High School",
"Harris School of Business Wilmington Campus",
"Ultimate Medical Academy Clearwater",
"Florida Barber Academy",
"Jacksonville Beauty Institute",
"M DCPS The English Center",
"Florida Education Institute",
"Superior Career Institute",
"Institute of Technical Arts",
"National Graduate School of Quality Management",
"Texas County Technical College",
"Aviation Institute of Maintenance Kansas City",
"Blue Cliff College Gulfport",
"Apex School of Theology",
"Institute for Therapeutic Massage",
"Metropolitan Learning Institute",
"Orleans Niagara BOCES Practical Nursing Program",
"Yeshiva Shaarei Torah of Rockland",
"Leon Studio One School of Hair Design",
"St Josephs Medical Center School of Radiography",
"American Institute of Alternative Medicine",
"Brown Aveda Institute Mentor",
"Susquehanna County Career and Technology Center",
"Centro de Capacitacion y Asesoramiento Vetelba",
"Universidad Pentecostal Mizpa",
"Caribbean Forensic and Technical College",
"Concorde Career College Grand Prairie",
"Kussad Institute of Court Reporting",
"Lamar Institute of Technology",
"MJs Beauty Academy Inc",
"Aviation Institute of Maintenance Dallas",
"Healing Mountain Massage School",
"Career Training Solutions",
"Rudy & Kelly Academy of Hair and Nails",
"South Branch Career and Technical Center",
"Nevada State College",
"Virginia College Jackson",
"Virginia College Austin",
"California State University Channel Islands",
"ITT Technical Institute Springfield",
"ITT Technical Institute Chantilly",
"The Art Institute of California Argosy University Orange County",
"Franklin W Olin College of Engineering",
"Lincoln Technical Institute Northeast Philadelphia",
"Lincoln Technical Institute Center City Philadelphia",
"Won Institute of Graduate Studies",
"Lincoln Technical Institute Paramus",
"Pima Medical Institute Colorado Springs",
"University of Phoenix Kansas City Campus",
"University of Phoenix Atlanta Campus",
"University of Phoenix Chicago Campus",
"University of Phoenix Nashville Campus",
"University of Phoenix Northern Virginia Campus",
"Davids Academy of Beauty",
"Birthwise Midwifery School",
"Argosy University Dallas",
"Le Cordon Bleu College of Culinary Arts Orlando",
"Paul Mitchell the School Costa Mesa",
"Ave Maria School of Law",
"Everest College Arlington",
"Tulsa Welding School Jacksonville",
"ITT Technical Institute Canton",
"ITT Technical Institute Plymouth Meeting",
"Pennsylvania College of Health Sciences",
"DeVry University North Carolina",
"West Virginia Junior College Bridgeport",
"Antioch University PhD Program in Leadership and Change",
"Vatterott College Cleveland",
"St Louis College of Health Careers Fenton",
"Interactive College of Technology Gainesville",
"San Joaquin Valley College Ontario",
"Anthem Institute Cherry Hill",
"Westwood College Ft Worth",
"Westwood College Dallas",
"Alaska Christian College",
"Grantham University",
"Minnesota School of Business Plymouth",
"Carrington College Albuquerque",
"NASCAR Technical Institute",
"Everest Institute Tigard",
"University of the Potomac VA Campus",
"Anthem Institute Las Vegas",
"Milan Institute Palm Desert",
"Stenotype Institute of Jacksonville Inc Orlando",
"National American University Overland Park",
"Everest Institute Austin",
"Everest College Burr Ridge",
"Medvance Institute West Palm",
"Tohono OOdham Community College",
"Everest College Dallas",
"Edward Via College of Osteopathic Medicine",
"The Salter School Malden Campus",
"DeVry University Pennsylvania",
"Pacific College of Oriental Medicine Chicago",
"Ronny Js Barber Styling",
"Southwest Institute of Healing Arts",
"InterCoast Colleges Riverside",
"Ottawa University Jeffersonville",
"University of Antelope Valley",
"Birthingway College of Midwifery",
"Blue Water College of Cosmetology Inc",
"Pima Medical Institute Albuquerque West",
"Brighton Centers Center for Employment Training",
"California Healing Arts College",
"Dallas Barber & Stylist College",
"NorthShore University HealthSystem School of Nurse Anesthesia",
"Faith Evangelical College & Seminary",
"Family of Faith College",
"Hood Theological Seminary",
"Colegio Educativo Tecnologico Industrial Inc",
"International Technological University",
"Irenes Myomassology Institute",
"Jays Technical Institute",
"Mr Johns School of Cosmetology Esthetics & Nails Jacksonville",
"NTMA Training Centers of Southern California",
"P C Age Jersey City",
"P C Age Edison",
"American Institute Margate",
"South Florida Institute of Technology",
"Southeastern Institute Charleston",
"Southeastern College Jacksonville",
"Toni & Guy Hairdressing Academy Colorado Springs",
"Trend Barber College",
"Illinois CareerPath Institute",
"Valley Grande Institute for Academic Studies",
"West Coast University Los Angeles",
"Williamson Christian College",
"International Barber & Style College",
"Illinois Eastern Community College System Office",
"Triangle Tech Inc Sunbury",
"DigiPen Institute of Technology",
"California College of Vocational Careers",
"Fortis Institute Fort Lauderdale",
"University of Phoenix Cincinnati Campus",
"University of Phoenix Wichita Campus",
"University of Phoenix Charlotte Campus",
"Connecticut Center for Massage Therapy Groton",
"Pierpont Community and Technical College",
"ITT Technical Institute Duluth",
"ITT Technical Institute Hilliard",
"University of Phoenix Indianapolis Campus",
"InterCoast Colleges West Covina",
"American College of Healthcare Sciences",
"Texas School of Business East",
"Le Cordon Bleu College of Culinary Arts Atlanta",
"Colorado Media School",
"Paul Mitchell the School Rhode Island",
"Miller Motte Technical College Chattanooga",
"Milan Institute Sparks",
"Empire Beauty School Framingham",
"Westwood College Chicago Loop",
"Interactive Learning Systems North Houston",
"Bexley Hall Episcopal Seminary",
"University of North Texas System",
"Anthem College Fenton",
"Everest Institute Norcross",
"Strayer University Tennessee",
"Strayer University Pennsylvania",
"Kaplan College Brownsville",
"Kaplan College Corpus Christi",
"School of Health",
"University of Phoenix Columbus Georgia Campus",
"University of Phoenix Memphis Campus",
"University of Phoenix Columbus Ohio Campus",
"University of Phoenix Little Rock Campus",
"Toni & Guy Hairdressing Academy Boise",
"Charlies Guard Detective Bureau and Academy Inc",
"PCI Academy Plymouth",
"DeVry Universitys Keller Graduate School of Management Pennsylvania",
"DeVry University Nevada",
"Everest College Tacoma",
"DeVry University Oregon",
"National University College Rio Grande",
"Creative Images Institute of Cosmetology South Dayton",
"Westwood College Houston South",
"Fortis Institute Jacksonville",
"Brookline College Albuquerque",
"Byzantine Catholic Seminary of Saints Cyril and Methodius",
"Remington College Cleveland West Campus",
"Northcentral University",
"Colorado Technical University Online",
"Baker College of Allen Park",
"Bayamon Community College",
"Harris School of Business Dover Campus",
"Folsom Lake College",
"LeGrand Institute of Cosmetology Inc",
"Everest Institute Detroit",
"Daymar Institute Murfreesboro",
"Cambridge Technical Institute",
"Empire Beauty School Midlothian",
"Empire Beauty School Owings Mills",
"Empire Beauty School West Mifflin",
"Bold Beauty Academy",
"ATI College Norwalk",
"Advance Science Institute",
"Advanced College",
"Advance Tech College",
"Advanced Training Associates",
"American Advanced Technicians Institute",
"Anamarc College El Paso Central",
"Baptist University of the Americas",
"The Beauty Institute",
"Beis Medrash Heichal Dovid",
"Bellefonte Academy of Beauty",
"Blue Cliff Career College",
"Career Beauty College",
"Paul Mitchell the School Gastonia",
"CES College",
"Computer Tutor Business and Technical Institute",
"Court Reporting Institute of Louisiana",
"Auguste Escoffier School of Culinary Arts Austin",
"Culinary Institute Inc",
"Dade Medical College Miami",
"Escuela Hotelera de San Juan",
"Expertise Cosmetology Institute",
"Paul Mitchell the School Monroe",
"Health Works Institute",
"In Session Arts of Cosmetology Beauty School",
"Moore Career College",
"Industrial Technical College",
"Institute of Beauty Occupation and Technology Course",
"Institute of Clinical Acupuncture & Oriental Med",
"Institute of Hair Design",
"MedTech Institute Atlanta Campus",
"Jones International University",
"Leston College",
"Lynndale Fundamentals of Beauty School",
"Medical Professional Institute",
"New Life Theological Seminary",
"Nightingale College",
"Omnitech Institute",
"PITC Institute",
"Skinworks School of Advanced Skincare",
"Southeastern Institute Columbia",
"Technical Learning Centers Inc",
"Total Look School of Cosmetology & Massage Therapy",
"The Art Institute of Tucson",
"Turning Point Beauty College",
"Unitech Training Academy Lafayette",
"Mountwest Community and Technical College",
"Centura College Columbia",
"Flagler College Tallahassee",
"Kanawha Valley Community and Technical College",
"American InterContinental University Online",
"Westwood College Atlanta Midtown",
"ITT Technical Institute Eden Prairie",
"Virginia College Mobile",
"Le Cordon Bleu College of Culinary Arts Las Vegas",
"International Academy of Design and Technology Troy",
"American InterContinental University Houston",
"Sanford Brown College Houston North Loop",
"UCM",
"University of California Merced",
"Everest College Fort Worth",
"Remington College North Houston Campus",
"Paul Mitchell the School Orlando",
"Regency Beauty Institute Maplewood",
"Pima Medical Institute Las Vegas",
"Remington College Nashville Campus",
"Platt College North OKC",
"Central Methodist University College of Graduate and Extended Studies",
"Westwood College Northlake",
"University of Phoenix Jersey City Campus",
"University of Phoenix Minneapolis St Paul Campus",
"Kaplan College Fort Worth",
"Kaplan College Midland",
"Kaplan College Lubbock",
"Carrington College California Emeryville",
"North West College Riverside",
"Everest Institute Chelsea",
"Everest Institute Eagan",
"University of Phoenix Louisville Campus",
"DeVry University Minnesota",
"Kaplan University Council Bluffs Campus",
"Everest University Orange Park",
"Wyotech West Sacramento",
"Everest Institute Bissonnet",
"Everest College McLean",
"Colegio Tecnico de Electricidad Galloza",
"Empire Beauty School St Paul",
"Arthurs Beauty School Inc Pine Bluff",
"Arthurs Beauty School Inc Conway",
"Vatterott College St Charles",
"Anthem Institute North Brunswick",
"Anthem Career College Memphis",
"Anthem College Kansas City",
"Spa Tech Institute Ipswich",
"Spa Tech Institute Westboro",
"Spa Tech Institute Plymouth",
"Milan Institute of Cosmetology San Antonio Walzem",
"Bridgemont Community and Technical College",
"Wards Corner Beauty Academy Virginia Beach",
"Neumont University",
"Johnson & Wales University Charlotte",
"University of Phoenix Central Valley Campus",
"LEcole Culinaire St Louis",
"Roseman University of Health Sciences",
"Fortis Institute Port Saint Lucie",
"Fortis Institute Miami",
"Aviation Institute of Maintenance Manassas",
"Everest College North Aurora",
"Cosmetology School of Arts and Sciences",
"Universal Technical Institute of Pennsylvania Inc",
"Heritage College Kansas City",
"Kenneth Shuler School of Cosmetology Spartanburg",
"Kenneth Shuler School of Cosmetology Columbia",
"Institute for the Psychological Sciences",
"Pivot Point Academy Chicago",
"Lincoln Technical Institute Shelton",
"Minnesota School of Business Waite Park",
"Minnesota School of Business Shakopee",
"Northwest Career College",
"Soma Institute The National School of Clinical Massage Therapy",
"Xtreme Career Institute",
"Marinello Schools of Beauty Niantic",
"American Academy of Acupuncture and Oriental Medicine",
"Arizona School of Acupuncture and Oriental Medicine",
"Ave Maria University",
"Blue Sky School of Professional Massage and Therapeutic Bodywork",
"Buchanan Beauty College",
"CALC Institute of Technology",
"Cambridge Junior College Yuba City",
"Cambridge Institute of Allied Health & Technology",
"Career Care Institute",
"ATA Career Education",
"Career Quest Learning Centers Lansing",
"Central State Massage Academy",
"Community Christian College",
"Crossett School of Cosmetology",
"Culpeper Cosmetology Training Center",
"Day Spa Career College",
"Eastern School of Acupuncture and Traditional Medicine",
"Ecclesia College",
"Employment Solutions College for Technical Education",
"European Massage Therapy School Skokie",
"Evergreen Beauty and Barber College Everett",
"Paul Mitchell the School Great Lakes",
"Healthcare Training Institute",
"John D Rockefeller IV Career Center",
"Ladera Career Paths Training Centers",
"Los Angeles Music Academy",
"Maple Springs Baptist Bible College and Seminary",
"MCI Institute of Technology",
"Oregon Career & Technology Center",
"Performance Training Institute",
"Pacific Coast Trade School",
"Centura Institute",
"Precision Manufacturing Institute",
"Professional Massage Training Center",
"Regency School of Hair Design",
"Renaissance College Massage Program",
"Rosslyn Training Academy of Cosmetology",
"SAE Institute of Technology Nashville",
"eClips School of Cosmetology and Barbering",
"Shear Academy",
"Southern Technical College",
"Stanbridge College",
"Styletrends Barber and Hairstyling Academy",
"Universal Career School",
"Universal College of Healing Arts",
"Uta Mesivta of Kiryas Joel",
"W L Bonner College",
"Harrisburg University of Science and Technology",
"The Art Institute of Ohio Cincinnati",
"Stevens Henager College Logan",
"University of Phoenix Cheyenne Campus",
"University of Phoenix Springfield  Campus",
"University of Phoenix Des Moines Campus",
"University of Phoenix San Antonio Campus",
"University of Phoenix Austin Campus",
"University of Phoenix Richmond Virginia Beach Campus",
"University of Phoenix Raleigh Campus",
"Blue Ridge Community and Technical College",
"Lincoln Technical Institute Suffield",
"International Academy of Design and Technology Henderson",
"International Academy of Design and Technology Seattle",
"International Academy of Design and Technology Nashville",
"Le Cordon Bleu College of Culinary Arts Miami",
"Le Cordon Bleu College of Culinary Arts Minneapolis",
"Anthem College Portland",
"Career Technical College Shreveport",
"ITT Technical Institute Kansas City",
"ITT Technical Institute Kennesaw",
"ITT Technical Institute Owings Mills",
"ITT Technical Institute Warrensville Heights",
"Union Graduate College",
"Colorado State University System Office",
"Chambersburg Beauty School",
"Daytona College",
"Delta Technical College",
"United States University",
"Westwood College Arlington Ballston",
"Kaplan College Bakersfield",
"Kaplan College Fresno",
"Empire Beauty School Avondale",
"Empire Beauty School North Tucson",
"Empire Beauty School Littleton",
"Empire Beauty School Aurora",
"Everest Institute Gahanna",
"Everest College Merrionette Park",
"Everest College Earth City",
"Everest College Mesa",
"Everest Institute Silver Spring",
"Alvareitas College of Cosmetology Belleville",
"AmeriTech College Draper",
"Argosy University Los Angeles",
"Brown Mackie College Miami",
"Regency Beauty Institute Darien",
"Regency Beauty Institute Aurora",
"Regency Beauty Institute Madison",
"Regency Beauty Institute Ridgedale",
"San Joaquin Valley College Modesto",
"Fortis College Largo",
"Platt College Moore",
"Platt College Dallas",
"Universal Technical Institute of Massachusetts Inc",
"Eagle Gate College Layton",
"Academy of Hair Design Jasper",
"Expression College for Digital Arts",
"Unitech Training Academy West Monroe",
"Daymar College Bellevue",
"DeVry University Oklahoma",
"Salter School Fall River",
"Seacoast Career School Manchester Campus",
"Instituto de Educacion Tecnica Ocupacional La Reine Aguadilla",
"New River Community and Technical College",
"The College of Health Care Professions Southwest Houston",
"Eagle Gate College Salt Lake City",
"Kaplan College Palm Springs",
"University of Phoenix Savannah Campus",
"University of Phoenix Northern Nevada Campus",
"Minnesota School of Business Rochester",
"Georgia Gwinnett College",
"Phoenix Institute of Herbal Medicine & Acupuncture",
"Tucson College of Beauty",
"California Career College",
"Palladium Technical Academy",
"Palace Beauty College",
"National Polytechnic College",
"American Career College Ontario",
"Asher College",
"American Institute of Massage Therapy",
"University of East West Medicine",
"Blake Austin College",
"Mojave Barber College",
"Aviator College of Aeronautical Science and Technology",
"Total International Career Institute",
"Trendsetters School of Beauty & Barbering",
"CDA Technical Institute",
"American Institute of Beauty",
"North Florida Cosmetology Institute Inc",
"Florida Academy of Health & Beauty",
"Augusta School of Massage",
"ATA College",
"SUM Bible College and Theological Seminary",
"Compass Career College",
"Hair Expressions Academy",
"Nuvo College of Cosmetology",
"WellSpring School of Allied Health Kansas City",
"Hair Academy 110",
"Healing Touch Career College",
"The Salon Professional Academy Fargo",
"Massage Therapy Trainining Institute",
"Institute of Professional Careers",
"Elite Academy of Beauty Arts",
"Aveda Institute New York",
"Brookline College Oklahoma City",
"Northwest Regional Technology Institute",
"National Massage Therapy Institute",
"MyrAngel Beauty Institute",
"Tennessee Career Institute",
"Texas Health School",
"Mai trix Beauty College",
"North West Beauty School",
"Maximum Style Tec School of Cosmetology",
"Careers Unlimited",
"Mountainland Applied Technology College",
"Avi Career Training",
"Yakima Beauty School",
"Mountain State School of Massage",
"Doane College Lincoln Grand Island and Master",
"Shorter University College of Adult & Professional Programs",
"Arkansas State University System Office",
"The Art Institute of Indianapolis",
"Eastern International College Belleville",
"San Joaquin Valley College Rancho Cordova",
"San Joaquin Valley College Central Administrative Office",
"Illinois School of Health Careers OHare Campus",
"MedTech College",
"Broken Arrow Beauty College Tulsa",
"Universal Technical Institute of Northern California Inc",
"ITT Technical Institute Charlotte South",
"ITT Technical Institute Clovis",
"ITT Technical Institute Dunmore",
"ITT Technical Institute Swartz Creek",
"ITT Technical Institute Lexington",
"ITT Technical Institute Maumee",
"ITT Technical Institute Oklahoma City",
"ITT Technical Institute Tulsa",
"University of Phoenix Omaha Campus",
"University of Phoenix Northwest Arkansas Campus",
"University of Phoenix Madison Campus",
"University of Phoenix Columbia Campus",
"The Art Institute of California Argosy University Inland Empire",
"National American University Zona Rosa",
"West Hills College Lemoore",
"Stautzenberger College Brecksville",
"Stone Academy East Hartford",
"Westwood College Annandale",
"West Hills Community College District",
"Unitech Training Academy Houma",
"Kaplan College Milwaukee",
"Miller Motte Technical College Madison",
"Rasmussen College Illinois",
"International Institute for Restorative Practices",
"Educational Technical College Recinto de Coamo",
"Educational Technical College Recinto de san Sebastian",
"Argosy University Denver",
"College America Colorado Springs",
"College America Fort Collins",
"Automotive Training Center Warminster",
"Sanford Brown College West Allis",
"Empire Beauty School Milwaukee",
"The Robert B Miller College",
"University of Phoenix Fairfield County Campus",
"University of Phoenix Harrisburg Campus",
"University of South Florida St Petersburg",
"Triangle Tech Inc Bethlehem",
"DeVry University Utah",
"Arizona State University Downtown Phoenix",
"Milan Institute Clovis",
"Branford Hall Career Institute Albany Campus",
"Harris School of Business Linwood Campus",
"The Art Institute of Tennessee Nashville",
"Strayer University Florida",
"Pima Medical Institute Renton",
"College of Business and Technology Flagler",
"College of Business and Technology Hialeah",
"Miller Motte College Cary",
"Miami Jacobs Career College Springboro",
"Dewey University Juana D�az",
"Dewey University Fajardo",
"Dewey University Arroyo",
"Regency Beauty Institute Independence",
"Regency Beauty Institute St Peters",
"Regency Beauty Institute Peoria",
"Regency Beauty Institute Greenwood",
"Regency Beauty Institute Mehlville",
"Regency Beauty Institute Duluth",
"Regency Beauty Institute Fairview Heights",
"Regency Beauty Institute Champaign",
"Regency Beauty Institute Olathe",
"The College of Health Care Professions San Antonio",
"Everest Institute South Plainfield",
"Kaplan Career Institute Detroit",
"Daymar College Madisonville",
"Lincoln Technical Institute Whitestone",
"American Public University System",
"Huntsville Bible College",
"Beaumont Adult School",
"Make up Designory",
"Video Symphony EnterTraining Inc",
"Gnomon School of Visual Effects",
"Coachella Valley Beauty College",
"Career College of California",
"Valley College of Medical Careers",
"Academy of Natural Therapy Inc",
"Academy Di Capelli School of Cosmetology",
"Delaware Learning Institute of Cosmetology",
"Dragon Rises College of Oriental Medicine",
"Palm Beach Academy of Health & Beauty",
"SABER College",
"Taylor College",
"Pensacola School of Massage Therapy & Health Careers",
"Hawaii College of Oriental Medicine",
"Ideal Beauty Academy",
"Success Schools",
"Louisiana Culinary Institute",
"Bais Medrash Toras Chesed",
"Center for Natural Wellness School of Massage Therapy",
"Ace Computer Training Center",
"New Age Training",
"Dayton School of Medical Massage",
"Northcoast Medical Training Academy",
"American Institute of Medical Technology",
"Carib Technological Institute",
"Visible Music College",
"CCI Training Center Arlington",
"Champion Beauty College",
"Professional Careers Institute",
"Southeast Texas Career Institute",
"Southwest Applied Technology Center",
"Milwaukee Career College",
"University of the West",
"American College of Education",
"South University Tampa",
"Milan Institute of Cosmetology San Antonio Military",
"Appalachian College of Pharmacy",
"Averett University Non Traditional Programs",
"Paul Mitchell the School Tampa",
"Paul Mitchell the School San Diego",
"Paul Mitchell the School Michigan",
"Paul Mitchell the School Sherman Oaks",
"Paul Mitchell the School Mclean",
"Miami Jacobs Career College Troy",
"ATI College Santa Ana",
"Blue Cliff College Houma",
"The Art Institute of Salt Lake City",
"Fortis College Columbus",
"Fortis College Cincinnati",
"Fortis Institute Baltimore",
"The Art Institute of Charleston",
"The Art Institute of California Argosy University Sacramento",
"Blue Cliff College Fayetteville",
"Blue Cliff College Alexandria",
"StenoTech Career Institute Piscataway",
"Anthem College Brookfield",
"Ultimate Medical Academy Tampa",
"Beauty Schools of America North Miami Beach",
"Virginia College Biloxi",
"ITT Technical Institute St Petersburg",
"ITT Technical Institute Baton Rouge",
"ITT Technical Institute Columbia",
"ITT Technical Institute Wichita",
"ITT Technical Institute Atlanta",
"ITT Technical Institute Mobile",
"ITT Technical Institute Chattanooga",
"ITT Technical Institute South Bend",
"Virginia College School of Business and Health Chattanooga",
"Strayer University Delaware",
"Brite Divinity School",
"Strayer University Alabama",
"Brown Aveda Institute Rocky River",
"Clary Sage College",
"Broadview University Layton",
"International Academy of Design and Technology Sacramento",
"University of Phoenix Birmingham Campus",
"International Academy of Design and Technology San Antonio",
"University of Phoenix Augusta Campus",
"University of Phoenix Washington DC Campus",
"University of Phoenix Chattanooga Campus",
"DeVry University Michigan",
"DeVry University Tennessee",
"Argosy University Inland Empire",
"Argosy University Nashville",
"Argosy University San Diego",
"Rasmussen College Wisconsin",
"Empire Beauty School Lisle",
"Empire Beauty School Richmond",
"Empire Beauty School North Hills",
"Empire Beauty School Concord",
"Empire Beauty School Arlington Heights",
"Lexington Healing Arts Academy",
"Empire Beauty School Hooksett",
"The Institute of Beauty and Wellness",
"InterCoast Colleges Carson",
"InterCoast Career Institute South Portland",
"Carrington College California Stockton",
"Carrington College California Citrus Heights",
"Euphoria Institute of Beauty Arts & Sciences Summerlin",
"Euphoria Institute of Beauty Arts & Sciences Green Valley",
"Empire Beauty School Portsmouth",
"LIU Riverhead",
"Newbridge College Long Beach",
"Regency Beauty Institute Lakewood",
"Regency Beauty Institute Westminster",
"Regency Beauty Institute Avon",
"Regency Beauty Institute Joliet",
"Regency Beauty Institute Rockford",
"Regency Beauty Institute Greenfield",
"Regency Beauty Institute Metro Center",
"Regency Beauty Institute East Tucson",
"Regency Beauty Institute Tri County",
"Regency Beauty Institute Eastgate",
"Regency Beauty Institute Topeka",
"Columbia Southern University",
"Arizona Summit Law School",
"Arizona Culinary Institute",
"InfoTech Career College",
"Trident University International",
"Coastline Beauty College",
"Career College Consultants",
"San Diego College",
"Montessori Casa International",
"Branford Academy of Hair and Cosmetology",
"Academy of Massage and Bodywork",
"Digital Media Arts College",
"Academy for Five Element Acupuncture",
"Academy of Career Training",
"Management Resources Institute",
"Paul Mitchell the School Miami",
"Wolford College",
"Aveda Institute South Florida",
"Aveda Institute Tallahassee",
"Shear Excellence Hair Academy",
"Carlson College of Massage Therapy",
"Bio Chi Institute of Massage Therapy",
"SOLEX Medical Academy",
"MyComputerCareer.com Indianapolis",
"Z Hair Academy",
"DiGrigoli School of Cosmetology",
"Millennium Training Institute",
"Gallery College of Beauty",
"Lakewood School of Therapeutic Massage",
"The Salon Spa Academy",
"Trend Setters School of Cosmetology",
"Academy of Hair Design Springfield",
"Corinth Academy of Cosmetology",
"Southeastern Institute Charlotte",
"Gentle Healing School of Massage",
"Yeshivas Beer Yitzchok",
"Yeshiva Toras Chaim",
"Talmudical Seminary of Bobov",
"New York Methodist Hospital Center for Allied Health Education",
"Institute of Allied Medical Professions New York",
"Micropower Career Institute",
"Monroe 2 Orleans BOCES Center for Workforce Development",
"Aveda Fredrics Institute Cincinnati",
"MyComputerCareer.com TechSkills",
"CDE Career Institute",
"Instituto Educativo Premier",
"Charleston School of Law",
"Elite College of Cosmetology",
"Memphis Institute of Barbering",
"Manuel and Theresas School of Hair Design Bryan",
"Southwest University at El Paso",
"Paul Mitchell the School Austin",
"Aveda Institute Provo",
"Dixie Applied Technology College",
"Centura College Alexandria",
"Southeast Culinary & Hospitality College",
"Dominion School of Hair Design",
"Victorias Academy of Cosmetology",
"Inland Massage Institute",
"Sunnyside Beauty Academy",
"Wisconsin Academy",
"The Art Institute of Pittsburgh Online Division",
"University of South Florida Sarasota Manatee",
"Brown Mackie College Indianapolis",
"College America Cheyenne",
"Paul Mitchell the School San Antonio",
"Centro de Estudios Multidisciplinarios Bayamon",
"Bryant & Stratton College Wauwatosa",
"Minnesota School of Business Blaine",
"The Art Institute of Michigan",
"Lacy Cosmetology School Lexington",
"Lacy Cosmetology School Goose Creek",
"The Art Institute of Austin",
"The Art Institute of California Argosy University Silicon Valley",
"Remington College Houston Southeast Campus",
"Remington College Shreveport Campus",
"National American University Austin",
"National American University Wichita",
"Lawton Career Institute Warren Main Campus",
"Fortis College Landover",
"Jenny Lea Academy of Cosmetology and Aesthetics",
"ITT Technical Institute Cary",
"ITT Technical Institute Madison",
"ITT Technical Institute Clive",
"ITT Technical Institute Columbus",
"ITT Technical Institute Phoenix",
"ITT Technical Institute High Point",
"The Hair Academy",
"The Art Institutes International�Kansas City",
"The Art Institute of Raleigh Durham",
"Le Cordon Bleu College of Culinary Arts Sacramento",
"Le Cordon Bleu College of Culinary Arts Seattle",
"Le Cordon Bleu College of Culinary Arts Cambridge",
"Le Cordon Bleu College of Culinary Arts Dallas",
"Ross Medical Education Center New Baltimore",
"Ross College Sylvania",
"Argosy University Salt Lake City",
"Escuela De Troqueleria Y Herramentaje",
"Virginia College Montgomery",
"Fortis Institute Nashville",
"Beckfield College Tri County",
"Community Technology Learning Center of Portage",
"David Curtis School of Floral Design",
"Dental Assistant Pro LLC Columbus",
"Dental Assistant Pro Lebanon",
"Iverson Institute",
"Galen College of Nursing Cincinnati",
"Harmony Path School of Massage Therapy",
"Institute of Therapeutic Massage",
"Pinnacle Career Institute North Kansas City",
"Strayer University Kentucky",
"Strayer University North Carolina",
"Strayer University New Jersey",
"International Culinary Arts and Sciences Institute",
"Kaplan College Cincinnati",
"National College Dayton",
"National College Youngstown",
"National College Cincinnati",
"National College Stow",
"Harris School of Business Hamilton Campus",
"Empire Beauty School Eden Prairie",
"Ohio Business College Hilliard",
"Ohio Center for Broadcasting Columbus",
"Ohio Medical Career Center",
"Ohio Technical College PowerSport Institute",
"Reflexology Certification Institute",
"Toledo Restaurant Training Center",
"Dade Medical College Miami Lakes",
"The King�s College",
"DeVry University Kentucky",
"Chamberlain College of Nursing Administrative Office",
"Chamberlain College of Nursing Illinois",
"Chamberlain College of Nursing Ohio",
"Chamberlain College of Nursing Arizona",
"Career Quest Learning Centers Jackson",
"Regency Beauty Institute Fort Collins",
"Regency Beauty Institute Tolleson",
"Regency Beauty Institute Pasadena",
"Regency Beauty Institute Dayton",
"Regency Beauty Institute Akron",
"Regency Beauty Institute Detroit Southgate",
"Regency Beauty Institute Flint",
"Regency Beauty Institute Grand Rapids",
"Regency Beauty Institute Cypresswood",
"Regency Beauty Institute Austin",
"Regency Beauty Institute Mesa",
"Regency Beauty Institute Nashville",
"Regency Beauty Institute Charlotte",
"Regency Beauty Institute North Olmsted",
"Regency Beauty Institute Columbus",
"Regency Beauty Institute Elgin",
"The Salon Professional Academy Appleton",
"Milan Institute of Cosmetology Fairfield",
"Milan Institute of Cosmetology Reno",
"Milan Institute of Cosmetology Visalia",
"Ottawa University Online",
"Broadview University Orem",
"Paul Mitchell the School Esani",
"Institute of Production and Recording",
"Virginia College Greenville",
"Paul Mitchell the School Birmingham",
"Taft University System",
"Mayfield College",
"International Polytechnic Institute",
"Academy of Esthetics and Cosmetology",
"BioHealth College",
"International Professional School of Bodywork",
"Borners Barber College",
"Paul Mitchell the School Pasadena",
"San Francisco Institute of Esthetics and Cosmetology",
"Laurus College",
"Healing Hands School of Holistic Health",
"Career Development Institute",
"Auguste Escoffier School of Culinary Arts Boulder",
"Aspen University",
"Institute of Taoist Education and Acupuncture",
"Denver School of Nursing",
"Cambridge Institute of Health & Technology",
"Cozmo The School",
"Allied Health Institute",
"North Florida Academy",
"Professional Hands Institute",
"Celebrity School of Beauty",
"Paul Mitchell the School Atlanta",
"Evans Hairstyling College Rexburg",
"Hair Academy Paul Mitchell Partner School",
"University of Aesthetics Chicago",
"University of Aesthetics Downers Grove",
"Tricoci University of Beauty Culture Chicago",
"Tricoci University of Beauty Culture Glendale Heights",
"Tricoci University of Beauty Culture Peoria",
"Tricoci University of Beauty Culture Rockford",
"Frederick School of Cosmetology",
"Omega Studios School of Applied Recording Arts & Sciences",
"Focus Hope Information Technologies Center",
"International Cosmetology Academy",
"French Academy of Cosmetology",
"Healing Arts Center",
"Paul Mitchell the School Springfield",
"Urshan Graduate School of Theology",
"Mississippi Institute of Aesthetics Nails & Cosmetology",
"College of Western Idaho",
"Aveda Institute Chapel Hill",
"Charlotte School of Law",
"Daoist Traditions College of Chinese Medical Arts",
"Total Image Beauty Academy",
"Jersey College",
"American Institute of Medical Sciences & Education",
"Casal Institute of Nevada",
"Career School of NY",
"John Paolos Xtreme Beauty Institute Goldwell Product Artistry",
"Ann Maries World of Beauty School",
"Yeshiva of Machzikai Hadas",
"Aveda Institute Columbus",
"Paul Mitchell the School Cincinnati",
"Cutters Edge School of Cosmetology",
"Hands on Therapy",
"MediaTech Institute Dallas",
"PCCenter",
"Aveda Institute San Antonio",
"Skin Institute",
"Skin Science Institute",
"Global Health College",
"Pacific Northwest University of Health Sciences",
"Visions in Hair Design Institute of Cosmetology",
"Brensten Education",
"The Chicago School of Professional Psychology at Irvine",
"California InterContinental University",
"Fortis College Dothan",
"Fortis Institute Pensacola",
"Fortis College Montgomery",
"Fortis College School of Cosmetology",
"Woodland Community College",
"Dorsey Business Schools Farmington Hills",
"Dorsey Business Schools Roseville Culinary Academy",
"Vatterott College Appling Farms",
"Minnesota School of Business Moorhead",
"Brown Mackie College Boise",
"Brown Mackie College Tulsa",
"Fortis Institute Birmingham",
"Daymar College Scottsville",
"Miller Motte College Greenville",
"The Chicago School of Professional Psychology at Los Angeles",
"Metro Business College Arnold",
"The Chicago School of Professional Psychology at Westwood",
"Cardiac and Vascular Institute of Ultrasound",
"Virginia College Jacksonville",
"Studio Academy of Beauty",
"California Nurses Educational Institute",
"Central Nursing College",
"RWM Fiber Optics",
"Paul Mitchell the School Sacramento",
"Providence Christian College",
"Summit Salon & Beauty School",
"Oxford Academy of Hair Design",
"Institute of World Politics",
"Pontifical John Paul II Institute for Studies on Marriage and Family",
"Medical Institute of Palm Beach",
"Lake Lanier School of Massage",
"Oliver Finley Academy of Cosmetology",
"National Career College",
"Aveda Institute Chicago",
"Tricoci University of Beauty Culture Libertyville",
"Tricoci University of Beauty Culture Bridgeview",
"The Temple A Paul Mitchell Partner School",
"Center for Massage & Natural Health",
"Academy of Hair Design Oklahoma City",
"Education and Technology Institute",
"Pulse Beauty Academy A Paul Mitchell Partner School",
"Liberty Technical College",
"Toni & Guy Hairdressing Academy Cranston",
"South Texas Training Center",
"Vermont College of Fine Arts",
"Advanced Welding Institute",
"The Art Institute of Washington Dulles",
"Paul Mitchell the School Memphis",
"Pima Medical Institute East Valley",
"Virginia College Charleston",
"The Ohio Academy Paul Mitchell Partner School Cleveland",
"The Ohio Academy Paul Mitchell Partner School Columbus",
"Georgia Beauty Academy",
"Stautzenberger Institute Allen Park",
"Empire Beauty School Lauderhill",
"Empire Beauty School Spring Lake Park",
"MediaTech Institute Austin",
"MediaTech Institute Houston",
"Fortis College Phoenix",
"Miller Motte College Raleigh",
"Lamson Institute",
"Euphoria Institute of Beauty Arts & Sciences Las Vegas",
"Lincoln College of Technology Toledo",
"College of Hair Design East Campus",
"International Academy of Design and Technology Online",
"Le Cordon Bleu College of Culinary Arts St Louis",
"Sanford Brown College San Antonio",
"Heritage College Wichita",
"Heritage College Little Rock",
"Argosy University Phoenix Online Division",
"MedTech College Greenwood Campus",
"MedTech College Ft Wayne Campus",
"MedTech College Lexington Campus",
"ITT Technical Institute Huntington",
"ITT Technical Institute Concord",
"ITT Technical Institute Fort Myers",
"ITT Technical Institute Charlotte North",
"Fortis College Salt Lake City",
"Polytechnic University of Puerto Rico Miami",
"Polytechnic University of Puerto Rico Orlando",
"Ross Medical Education Center Fort Wayne",
"Ross Medical Education Center Portage",
"The Commonwealth Medical College",
"Josefs School of Hair Design Inc Fargo West",
"Northeast Technology Center Claremore",
"Salon Success Academy Fontana",
"Salon Success Academy Redlands",
"Brown Mackie College Phoenix",
"Strayer University West Virginia",
"Strayer University Utah",
"Strayer University Ohio",
"Regency Beauty Institute Tinley Park",
"Regency Beauty Institute Columbia",
"Regency Beauty Institute South Bend",
"Regency Beauty Institute Detroit Lakeside",
"Regency Beauty Institute Fort Myers",
"Regency Beauty Institute Arlington",
"Regency Beauty Institute Durham",
"Minnesota School of Business Elk River",
"Brown Mackie College Greenville",
"Globe University Sioux Falls",
"Globe University Eau Claire",
"Vanguard College of Cosmetology Baton Rouge",
"Globe University Minneapolis",
"San Joaquin Valley College Hesperia",
"Beauty Schools of America Homestead",
"South Texas Vocational Technical Institute Brownsville",
"South Texas Vocational Technical Institute Corpus Christi",
"LEcole Culinaire Memphis",
"University of Minnesota Rochester",
"Health And Style Institute",
"CBT College Cutler Bay",
"Provo College�American Fork",
"National College Columbus",
"Kaplan Career Institute Boston",
"Herzing University Toledo",
"Milan Institute of Cosmetology Concord",
"Milan Institute Bakersfield",
"Homestead Schools",
"Carrington College Las Vegas",
"Carrington College Reno",
"Chamberlain College of Nursing Florida",
"Remington College Columbia Campus",
"Remington College of Nursing Orlando",
"Washington Barber College Inc",
"InterCoast Colleges Elk Grove",
"Universal Barber College",
"Horizon University",
"San Diego Culinary Institute",
"Beyond 21st Century Beauty Academy",
"Franklin Career College",
"Academy for Jewish Religion California",
"Hollywood Beauty College",
"Angeles College",
"Angeles Institute",
"Paul Mitchell the School Temecula",
"European Academy of Cosmetology and Hairdressing",
"International Institute of Cosmetology",
"Florida School of Traditional Midwifery",
"Walton Career Development Center",
"Immokalee Technical Center",
"American Academy of Cosmetology",
"University of Fort Lauderdale",
"Aviation Institute of Maintenance Orlando",
"The Salon Professional Academy Tampa",
"The Salon Professional Academy Gainesville",
"The Salon Professional Academy The Villages",
"The Hair Academy Inc",
"Profile Institute of Barber Styling",
"The Process Institute of Cosmetology",
"New Hope Christian College Honolulu",
"D & L Academy of Hair Design",
"Master Educators Beauty School",
"Ambria College of Nursing",
"Midwestern Career College",
"Paul Mitchell the School Normal",
"Innovations Design Academy",
"Aveda Fredrics Institute Indianapolis",
"The Salon Professional Academy Anderson",
"Eric Fisher Academy",
"A & W Healthcare Educators",
"Blackstone Valley Vocational Regional School District",
"Southern Worcester County Regional Voc School District",
"Lesprit Academy",
"Marketti Academy of Cosmetology",
"CenterPoint Massage and Shiatsu Therapy School and Clinic",
"Avalon School of Cosmetology",
"American Business and Technology University",
"City Vision College",
"Transformed Barber and Cosmetology Academy",
"Bitterroot School of Cosmetology",
"Atlanta Beauty & Barber Academy",
"The Salon Professional Academy Great Falls",
"Park West Barber School",
"National Career Institute",
"Toni & Guy Hairdressing Academy Albuquerque",
"International Academy of Style",
"EDP School of Computer Programming",
"New York Medical Career Training Center",
"Institute of Culinary Education",
"New Life Business Institute",
"The Salon Professional Academy Tonawanda",
"Onondaga School of Therapeutic Massage Syracuse",
"Academy of Cosmetology and Esthetics NYC",
"Finger Lakes School of Massage",
"Four County Career Center",
"Vanity School of Cosmetology",
"Tolles Career and Technical Center",
"The Salon Professional Academy Perrysburg",
"Portland Actors Conservatory",
"Somerset County Technology Center",
"Metro Beauty Academy",
"Barone Beauty Academy",
"Academy for Careers and Technology",
"Aiken School of Cosmetology",
"Love Beauty School Inc",
"Professional Career Training Institute",
"Cannon Institute of Higher Learning",
"Cardiotech Ultrasound School",
"DuValls School of Cosmetology",
"Advanced Beauty College",
"Texas Beauty College",
"Avenue Five Institute",
"Corpus Christi Beauty Academy",
"The Salon Professional Academy Lewisville",
"Renaissance Academie",
"Bethel College",
"Institute of Advanced Medical Esthetics",
"Gary Manuel Aveda Institute",
"Northwest School of Wooden Boat Building",
"Bainbridge Graduate Institute",
"The Salon Professional Academy Onalaska",
"Regency Beauty Institute North Tucson",
"Kaplan College Chula Vista",
"InterCoast Colleges Roseville",
"West Coast University Orange County",
"West Coast University Ontario",
"Kaplan College Pembroke Pines",
"Dade Medical College Homestead",
"Virginia College Augusta",
"Midwest Technical Institute East Peoria",
"Paul Mitchell the School Chicago",
"Regency Beauty Institute Evansville",
"Regency Beauty Institute Wichita",
"Regency Beauty Institute Shreveport",
"Regency Beauty Institute Springfield",
"Regency Beauty Institute Canton",
"Regency Beauty Institute Spartanburg",
"Regency Beauty Institute Chattanooga",
"Regency Beauty Institute Mesquite",
"Kaplan Career Institute Dearborn",
"Dorsey Business Schools Waterford Pontiac",
"Reginas College of Beauty High Point",
"Miller Motte College Fayetteville",
"Drake College of Business Newark",
"European Massage Therapy School Las Vegas",
"Harrison College Grove City",
"Miller Motte Technical College Columbus",
"National University College Ponce",
"Fortis Institute Grand Prairie",
"Paul Mitchell the School St George",
"The Art Institute of Virginia Beach",
"Bryant & Stratton College Bayshore",
"Gerstner Sloan Kettering Graduate School of Biomedical Sciences",
"Daymar College Online",
"Virginia College Columbia",
"Sanford Brown College Dearborn",
"Sanford Brown College Grand Rapids",
"Sanford Brown College Indianapolis",
"Sanford Brown College Hillside",
"Sanford Brown College Tinley Park",
"Sanford Brown College Phoenix",
"Sanford Brown Institute Orlando",
"Fortis College Indianapolis",
"Kaplan College Arlington",
"Kaplan College Charlotte",
"Kaplan College Jacksonville",
"Globe University Madison East",
"Minnesota School of Business Lakeville",
"Globe University�Green Bay",
"Globe University�Madison West",
"Globe University�Wausau",
"Dade Medical College Hollywood",
"Empire Beauty School E Memphis",
"Empire Beauty School Nashville",
"Empire Beauty School Jackson",
"Empire Beauty School Springfield",
"Empire Beauty School Paramus",
"Empire Beauty School Speedway",
"Empire Beauty School Morrow",
"Strayer University Arkansas",
"Strayer University Georgia",
"Strayer University Louisiana",
"Strayer University Mississippi",
"Strayer University South Carolina",
"Strayer University Texas",
"The Art Institute of San Antonio",
"Globe University La Crosse",
"The Hair Design School S Memphis",
"The Hair Design School Charlotte",
"The Hair Design School Durham",
"The Hair Design School E Greensboro",
"The Hair Design School Winston Salem",
"Empire Beauty School West Palm",
"Empire Beauty School Pineville",
"The Hair Design School N Memphis",
"Broadview Entertainment Arts University",
"Bryant & Stratton College Hampton",
"Paul Mitchell the School Phoenix",
"All Beauty College",
"Northern California Institute of Cosmetology Inc",
"Golden State College of Court Reporting",
"Preferred College of Nursing Los Angeles",
"Trinity Vocational Center",
"SICE Paul Mitchell Partner School",
"Cosmo Beauty Academy",
"Paul Mitchell the School Fresno",
"Unitek College",
"Gurnick Academy of Medical Arts",
"Southern California University SOMA",
"Paul Mitchell the School East Bay",
"South University�Richmond",
"South University�Virginia Beach",
"Aveda Institute Denver",
"Paul Mitchell the School Colorado Springs",
"The Salon Professional Academy Colorado Springs",
"Paul Mitchell the School Delaware",
"Future Tech Institute",
"Marchman Technical Education Center",
"Eureka Institute of Health and Beauty",
"The Salon Professional Academy Ft Myers",
"SOLEX College",
"Unity Cosmetology College",
"Universal Spa Training Academy",
"The Salon Professional Academy Evansville",
"Tri County Regional Vocational Technical High School",
"Compass College of Cinematic Arts",
"Nova Academy of Cosmetology",
"Victory Trade School",
"Starting Points Inc",
"The Lab Paul Mitchell Partner School",
"SAE Institute of Technology New York",
"The Artisan College of Cosmetology",
"Sage School of Massage",
"Lawyers Assistant School of Dallas",
"ABC Beauty Academy",
"Salon & Spa Institute",
"The Salon Professional Academy Tacoma",
"The Academy Waukesha",
"ITT Technical Institute Merrillville",
"ITT Technical Institute Tallahassee",
"ITT Technical Institute Salem",
"ITT Technical Institute Akron",
"ITT Technical Institute Cedar Rapids",
"ITT Technical Institute Corona",
"ITT Technical Institute Johnson City",
"ITT Technical Institute DeSoto",
"ITT Technical Institute North Charleston",
"ITT Technical Institute Aurora",
"ITT Technical Institute West Covina",
"ITT Technical Institute Culver City",
"ITT Technical Institute Dearborn",
"ITT Technical Institute Las Vegas",
"Touro University Worldwide",
"Touro University California",
"The Chicago School of Professional Psychology at Washington DC",
"Anamarc College Santa Teresa",
"Touro University Nevada",
"National College Willoughby Hills",
"Herzing University Kenosha",
"Herzing University Brookfield",
"Heald College Modesto",
"Fortis Institute Houston",
"Southern Careers Institute Brownsville",
"Southern Careers Institute Corpus Christi 2",
"Southern Careers Institute Harlingen",
"Strayer University Global Region",
"Reginas College of Beauty Charlotte",
"Kenneth Shuler School of Cosmetology Florence",
"Fortis College Columbia",
"Brown Mackie College Albuquerque",
"Brown Mackie College St Louis",
"Avant Gard The School",
"Toni & Guy Hairdressing Academy Bellingham",
"California University of Management and Sciences",
"Ross Medical Education Center Davison",
"Ross Medical Education Center Granger",
"Ross Medical Education Center Niles",
"Ross Medical Education Center Canton",
"Pima Medical Institute Houston",
"Illinois Center for Broadcasting�Chicago Campus",
"South Texas Vocational Technical Institute San Antonio",
"Concorde Career Institute Dallas",
"Concorde Career Institute Orlando",
"Concorde Career Institute San Antonio",
"Ecotech Institute",
"Johnson & Wales University Online",
"Everest College Fort Worth South",
"Everest College Santa Ana",
"Geisinger Lewistown Hospital School of Nursing",
"Moreno Valley College",
"Hair Design Institute at Fifth Avenue New York",
"Onondaga School of Therapeutic Massage Rochester",
"Norco College",
"Dorsey Business Schools Saginaw",
"Milan Institute of Cosmetology La Quinta",
"Milan Institute Nampa",
"Milan Institute of Cosmetology El Paso",
"Universal Technical Institute   Dallas Fort Worth",
"Park Avenue School of Cosmetology",
"Salter School of Nursing and Allied Health",
"Salter School New Bedford",
"Harris School of Business Upper Darby Campus",
"Stratford School of Aviation Maintenance Technicians",
"Brown Mackie College San Antonio",
"Cortiva Institute New Jersey",
"Cambridge Junior College Woodland",
"Anthem College Atlanta",
"ICPR Junior College Manati",
"Ridley Lowell Business & Technical Institute Danbury",
"Artistic Nails and Beauty Academy Lakeland",
"Paul Mitchell the School Murfreesboro",
"Allied American University",
"American Sentinel University",
"Tribeca Flashpoint Media Arts Academy",
"Hawaii Medical College",
"Carolina College of Hair Design",
"Virginia College Baton Rouge",
"Remington College Heathrow Campus",
"College of Massage Therapy",
"Miller Motte College Jacksonville",
"Miller Motte Technical College Augusta",
"Miller Motte Technical College Conway",
"Virginia College Macon",
"Virginia College Spartanburg",
"Aveda Institute Portland",
"Chamberlain College of Nursing Virginia",
"Stevens Henager College Boise",
"The Salon Professional Academy Cincinnati",
"Penrose Academy",
"Virginia College Richmond",
"Northwest Institute of Literary Arts",
"Body Therapy Institute",
"Beauty Academy of South Florida",
"Ogle School Hair Skin Nails North Dallas",
"The Salon Professional Academy North Little Rock",
"The Salon Professional Academy St Charles",
"American Institute",
"Mildred Elley New York Campus",
"National Paralegal College",
"Carolina College of Biblical Studies",
"Star Career Academy Audubon",
"St Luke University",
"Northeast Technology Center System Office",
"Allstate Hairstyling & Barber College",
"Oxford Graduate School",
"Jung Tao School of Classical Chinese Medicine",
"New York Film Academy",
"The Collective School Of Music",
"International College of Cosmetology",
"GP Institute of Cosmetology",
"Bella Capelli Academy",
"Institute of Medical Careers",
"Toni & Guy Hairdressing Academy Modesto",
"Georgia Christian University",
"Flagler Technical Institute",
"Galaxy Medical College",
"American Medical Sciences Center",
"Mandalyn Academy",
"Jose Maria Vargas University",
"Laird Institute of Spa Therapy",
"D A Dorsey Educational Center",
"Keweenaw Bay Ojibwa Community College",
"Meridian Institute of Surgical Assisting",
"Mauna Loa Helicopters",
"Acaydia School of Aesthetics",
"Manhattan Institute",
"Cosmetic Arts Institute",
"Holistic Massage Training Institute",
"Diamonds Cosmetology College",
"Tramy Beauty School",
"Scholars Cosmetology University",
"Northern Virginia School of Therapeutic Massage",
"Paul Mitchell the School St Louis",
"Advanced Training Institute",
"Cosmopolitan Beauty and Tech School",
"The Salon Professional Academy Elgin",
"CCIC Beauty College",
"Salon 496 Barber Academy",
"Shepherds Theological Seminary",
"Taylor Andrews Academy of Hair Design West Jordan",
"Santa Ana Beauty Academy",
"Grace College of Divinity",
"Cosmetology College of Franklin County",
"Rochester School of Hair Design",
"Aveda Institute New Mexico",
"Nashville Barber and Style Academy",
"American Trade School",
"Estelle Skin Care and Spa Institute",
"Paul Mitchell the School Honolulu",
"Elite Cosmetology School",
"Academy of Aesthetic Arts",
"American Technical Institute",
"Twin Rivers Adult School",
"Bergin University of Canine Studies",
"Aveda Institute Boise",
"Progressive Training Centers",
"American Health Institute",
"Pima Medical Institute South Denver",
"Real Barbers College",
"Capilo School of Hair Design",
"Pure Aesthetics",
"Lindsey Institute of Cosmetology",
"The Salon Professional Academy Iowa City",
"Buckner Barber School",
"Simmons College of Kentucky",
"Atelier Esthetique Institute of Esthetics",
"Ashdown College of Health Sciences",
"Advanced College of Cosmetology",
"North American University",
"Entourage Institute of Beauty and Esthetics",
"United Beauty College",
"Cinta Aveda Institute",
"Long Island Nail & Skin Care Institute",
"Mesivta Keser Torah",
"Southern California Health Institute",
"Best Care Training Institute",
"First Class Cosmetology School",
"Millennia Atlantic University",
"Abcott Institute",
"The Salon Professional Academy Kenosha",
"Paul Mitchell the School Indianapolis",
"Taylor Andrews Academy St George",
"Seymour Beauty Academy",
"Protege Academy",
"Colorado Academy of Veterinary Technology",
"Northeast Technical Institute",
"Salinas Beauty College Inc",
"Academy for Salon Professionals",
"Elite School of Cosmetology",
"Paul Mitchell the School Las Vegas",
"The Salon Professional Academy Kokomo",
"Hair Academy",
"Florida Academy",
"Institute for Doctoral Studies in the Visual Arts",
"State Career College",
"Paul Mitchell The School Spokane",
"DeVry Universitys Keller Graduate School of Management Maryland",
"DeVry Universitys Keller Graduate School of Management North Carolina",
"DeVry Universitys Keller Graduate School of Management Florida",
"DeVry College of New Yorks Keller Graduate School of Management",
"DeVry Universitys Keller Graduate School of Management Indiana",
"DeVry Universitys Keller Graduate School of Management Nevada",
"DeVry Universitys Keller Graduate School of Management Colorado",
"DeVry Universitys Keller Graduate School of Management Minnesota",
"DeVry Universitys Keller Graduate School of Management Oklahoma",
"DeVry Universitys Keller Graduate School of Management Utah",
"DeVry Universitys Keller Graduate School of Management Tennessee",
"DeVry Universitys Keller Graduate School of Management Michigan",
"DeVry Universitys Keller Graduate School of Management Kentucky",
"DeVry Universitys Keller Graduate School of Management New Jersey",
"DeVry Universitys Keller Graduate School of Management Oregon",
"Lincoln College of New England Hartford",
"Lincoln College of Technology Columbus",
"Lincoln College of Technology Cleveland",
"Northwestern Institute of Health and Technology",
"J Renee Career Facilitation",
"National Personal Training Institute of Colorado",
"International College of Beauty Arts & Sciences",
"The Salon Professional Academy Shorewood",
"John Paul the Great Catholic University",
"ITT Technical Institute Durham",
"ITT Technical Institute Hanover",
"University of Phoenix Jackson Campus",
"University of Phoenix Delaware Campus",
"Everest College Milwaukee",
"Independence University",
"National American University Tulsa",
"National American University Centennial",
"National American University Lees Summit",
"National American University Colorado Springs South",
"Globe University Appleton",
"Salter College Chicopee",
"Chamberlain College of Nursing Missouri",
"Chamberlain College of Nursing Texas",
"ITT Technical Institute Oakland",
"Continental School of Beauty Culture Mattydale",
"Everest College Bedford Park",
"Fortis College Akron",
"Carrington College California Pomona",
"Regency Beauty Institute Copperwood",
"Regency Beauty Institute Little Rock",
"Regency Beauty Institute Baltimore Golden Ring",
"Regency Beauty Institute El Paso",
"Regency Beauty Institute Jacksonville Regency",
"Regency Beauty Institute Knoxville",
"Regency Beauty Institute Fayetteville",
"Regency Beauty Institute Lansing",
"Regency Beauty Institute Jacksonville Orange Park",
"Regency Beauty Institute Crystal Lake",
"Regency Beauty Institute Manassas",
"Regency Beauty Institute Castleton",
"Regency Beauty Institute North Nashville",
"Regency Beauty Institute Round Rock",
"Regency Beauty Institute Merrillville",
"Regency Beauty Institute Roanoke",
"Regency Beauty Institute Toledo",
"Regency Beauty Institute SW Houston",
"Everest College Atlanta West",
"Broadview University Boise",
"Advanced Institute of Hair Design Madison",
"Paul Mitchell The School Columbia",
"Paul Mitchell The School Charleston",
"Everest College Melrose Park",
"Kenneth Shuler School of Cosmetology Greenville",
"South University Accelerated Graduate Programs",
"South University The Art Institute of Fort Worth",
"South University Novi",
"Northwest College Eugene",
"Northwest College Tualatin",
"Harrison College Morrisville",
"National University College Caguas",
"Beauty Schools of America",
"CET Soledad",
"MarJon School of Beauty ltd Lockport",
"Houston Training Schools Southwest",
"Vanguard College of Cosmetology Metairie",
"New England Tractor Trailer Training School of Massachusetts",
"Heritage College Columbus",
"Utah College of Massage Therapy Vegas",
"Utah College of Massage Therapy Tempe",
"Utah College of Massage Therapy Phoenix",
"Utah College of Massage Therapy Westminster",
"Utah College of Massage Therapy Aurora",
"Sanford Brown College Portland",
"Tint School of Makeup and Cosmetology Grand Prairie",
"Dewey University Yabucoa",
"Dewey University Hatillo",
"Dewey University Manati",
"Sanford Brown College Skokie",
"Baltimore School of Massage York",
"Allen School Phoenix",
"Oklahoma Technical College",
"Midwest Technical Institute Moline",
"Star Career Academy Clifton",
"Unitech Training Academy Alexandria",
"Unitech Training Academy Lake Charles",
"Aveda Institute Los Angeles",
"Career Quest Learning Center Kalamazoo",
"WellSpring School of Allied Health Lawrence",
"The Art Institute of Wisconsin",
"Brown Mackie College Birmingham",
"Reginas College of Beauty",
"Manuel and Theresas School of Hair Design Victoria",
"Tricoci University of Beauty Culture Chicago NE",
"ITT Technical Institute Bradenton",
"ITT Technical Institute Phoenix West",
"ITT Technical Institute Brooklyn Center",
"ITT Technical Institute Orlando",
"ITT Technical Institute Waco",
"ITT Technical Institute Myrtle Beach",
"University of Phoenix Knoxville Campus",
"Azusa Pacific Online University",
"Minneapolis Media Institute",
"College America Phoenix",
"CollegeAmerica Stevens Henager College",
"Avalon School of Cosmetology Phoenix",
"Avalon School of Cosmetology Layton",
"Hair Design Institute",
"University of Phoenix Baton Rouge Campus",
"University of Phoenix Lafayette Campus",
"University of Phoenix Shreveport Bossier Campus",
"All State Career School Allied Health Campus",
"Aveda Institute Des Moines",
"Fortis Institute Lawrenceville",
"Relay Graduate School of Education",
"Brown Mackie College Oklahoma City",
"Sanford Brown College Austin",
"Miller Motte Technical College Macon",
"Miller Motte Technical College Gulfport",
"International Academy of Medical Reflexology",
"International Culinary Center California",
"South University�Savannah Online",
"San Joaquin Valley College Temecula",
"Rio Grande Bible Institute",
"Miller Motte Technical College Roanoke",
"Whitworth University Adult Degree Programs",
"The Santa Barbara and Ventura Colleges of Law�Santa Barbara",
"The University of America",
"Kaplan College Chesapeake",
"American Broadcasting School Online Program",
"Springfield College School of Human Services",
"Marian Health Careers Center Van Nuys Campus",
"Brown College Brooklyn Center",
"Milan Institute Las Vegas",
"Williamson Free School of Mechanical Trades",
"Virginia College Savannah",
"Virginia College Columbus",
"Donnas Academy of Hair Design",
"New York School of Esthetics & Day Spa",
"Panache Academy of Beauty",
"Finger Lakes Health College of Nursing",
"ASI Career Institute",
"Chicago School for Piano Technology",
"Midwest Technical Institute Ridgeland",
"Christine Valmy International School of Esthetics & Cosmetology",
"City College Hollywood",
"Paul Mitchell the School Arlington",
"Rocky Mountain University of Health Professions",
"MediaTech Institute Oceanside",
"Christies Education",
"Prince Institute Great Lakes",
"Palmetto Beauty School",
"Paul Mitchell the School Fort Myers",
"Paul Mitchell The School Raleigh",
"Stella and Charles Guttman Community College",
"Lil Lous Barber College",
"Virginia College Tulsa",
"Criswell College",
"Virginia College Knoxville",
"Columbia Academy of Cosmetology",
"Academy of Couture Art",
"Global Institute",
"Shear Learning Academy of Cosmetology",
"University of Cosmetology Arts & Sciences La Joya",
"Preferred College of Nursing Van Nuys",
"Bryant & Stratton College Akron",
"Annenberg School of Nursing",
"American Medical Academy",
"Tooele Applied Technology College",
"Chamberlain College of Nursing Georgia",
"Chamberlain College of Nursing Indiana",
"Carrington College Mesquite",
"Universidad Internacional Iberoamericana",
"Sessions College for Professional Design",
"Southeastern Technical Careers Institute Inc",
"Virginia College Shreveport Bossier City",
"Strayer University Wisconsin",
"Strayer University Indiana",
"Herzing University",
"Regency Beauty Institute Hoover",
"Regency Beauty Institute San Antonio",
"Regency Beauty Institute Carrollwood",
"Regency Beauty Institute Clearwater",
"Regency Beauty Institute Newport News",
"Regency Beauty Institute Lewisville",
"Regency Beauty Institute Dallas",
"Regency Beauty Institute Plano",
"Empire Beauty School Virginia Beach",
"Sanford Brown College Columbus",
"Ross Medical Education Center Morgantown",
"Paul Mitchell the School Ogden",
"CollegeAmerica Colorado Springs South",
"Everest Institute Bensalem",
"Golf Academy of America Farmers Branch",
"Utah College of Massage Therapy Dallas",
"Miami Ad School New York",
"Lacy Cosmetology School West Ashley",
"Dade Medical College West Palm Beach",
"Dade Medical College Jacksonville",
"WestMed College",
"Larrys Barber College",
"Futura Career Institute",
"Access Careers",
"Flair Beauty College",
"Eternity Cosmetology School",
"Tri State Institute of Hair Design",
"Vogue College of Cosmetology San Antonio Fredericksburg",
"Vogue College of Cosmetology",
"Tomorrows Image Barber Academy of Virginia",
"Excel Learning Center",
"College of International Esthetics Inc",
"Medical Allied Career Center",
"Bais HaMedrash and Mesivta of Baltimore",
"Long Island Barber Institute",
"Toni & Guy Hairdressing Academy Toledo",
"American Institute of Medical Sonography",
"Destination Academy for Spa and Salon Professionals",
"Classic Cooking Academy",
"School of Court Reporting",
"Curve Metric School of Hair Design",
"Athena Career Academy",
"Yeshiva Gedolah Zichron Leyma",
"The Barber School",
"Beer Yaakov Talmudic Seminary",
"RGV Careers",
"Alexander Academy",
"LeMelange Academy of Hair",
"Aveda Institute Tucson",
"Velvet Touch Academy of Cosmetology",
"Ambiance Beauty & Barber Academy Inc",
"Diamond Beauty College",
"NCP College of Nursing South San Francisco",
"University Academy of Hair Design",
"Florida Institute of Recording Sound and Technology",
"New Dimensions Beauty Academy Inc",
"Joleis Hair Institute",
"Paul Mitchell the School Reno",
"Boise Barber College",
"Austin Kade Academy",
"Aesthetic Science Institute",
"Elaine Sterling Institute",
"Wade Gordon Hairdressing Academy",
"Mitsu Sato Hair Academy",
"SAE Institute of Technology Los Angeles",
"Florida Vocational Institute",
"SAE Institute of Technology Atlanta",
"Academy di Firenze",
"SAE Institute of Technology Miami",
"Colorado State University Global Campus",
"Paul Mitchell the School Overland Park",
"Medspa Academies",
"Brighton Institute of Cosmetology",
"Strayer University Minnesota",
"Strayer University Illinois",
"West Coast University Dallas",
"Stevens Henager College St George",
"National American University Bellevue",
"National American University Burnsville",
"National American University Mesquite",
"Empire Beauty School Savannah",
"Ogle School Hair Skin Nails Denton",
"Empire Beauty School West Greensboro",
"Old Town Barber College Topeka",
"Jolie Hair and Beauty Academy Northfield",
"Bellus Academy",
"MotoRing Technical Training Institute",
"Leon Studio One School of Hair Design & Career Training Center",
"NCP College of Nursing Hayward",
"ITT Technical Institute Philadelphia",
"ITT Technical Institute Marlton",
"ITT Technical Institute Germantown",
"ITT Technical Institute Grand Rapids",
"ITT Technical Institute Southfield",
"ITT Technical Institute West Palm Beach",
"ITT Technical Institute Deerfield Beach",
"ITT Technical Institute Indianapolis East",
"ITT Technical Institute Douglasville",
"ITT Technical Institute Overland Park",
"ITT Technical Institute West Chester",
"University of Phoenix McAllen Campus",
"Pennsylvania State University World Campus",
"Medical Career Institute",
"Advance Beauty Techs Academy",
"Longs Peak Academy",
"Brand College",
"Hinton Barber College",
"Advanced Career Institute",
"Digital Film Academy",
"MyComputerCareer.com Raleigh",
"Kaplan University Augusta Campus",
"South University Austin",
"South University Cleveland",
"Bryant & Stratton College Online",
"Virginia College Florence",
"Ogle School Hair Skin Nails San Antonio",
"Elizabethtown College School of Continuing and Professional Studies",
"Fortis College Cutler Bay",
"Anamarc College El Paso East",
"Minnesota School of Cosmetology Plymouth Campus",
"Mercyhurst University North East Campus",
"Warner Pacific College Adult Degree Program",
"Platt College Riverside",
"Savannah Law School",
"Empire Beauty School Glen Burnie",
"Everest College Woodbridge",
"Everest College Kansas City",
"International School of Cosmetology Toni & Guy Hairdressing Academy",
"InterCoast Career Institute Salem",
"International Sports Sciences Association",
"Abraham Lincoln University",
"National College Canton",
"Shiloh University",
"Court Reporting Institute of Arlington",
"Vatterott College Fairview Heights",
"Ross Medical Education Center Dayton",
"Ross Medical Education Center Ontario",
"Ross Medical Education Center Bowling Green",
"Florida Institute of Technology Online",
"Rasmussen College Kansas",
"NewCourtland Education Center",
"Columbia Institute",
"Wadsworth Center NY State Dept of Health",
"EMS Training Institute",
"School of Missionary Aviation Technology",
"Ace Cosmetology and Barber Training Center",
"Salt Lake Baptist College",
"Manthano Christian College",
"California Miramar University",
"Rocky Vista University",
"Virginia College Greensboro",
"The College of Health Care Professions Dallas",
"The College of Health Care Professions Fort Worth",
"Shear Finesse Hairstyling Academy",
"Redstone College Denver East",
"Academy of Salon Professionals",
"CyberTex Institute of Technology",
"UEI College Santa Cruz",
"New England Tractor Trailer Training School of CT Bridgeport",
"Gemini School of Visual Arts & Communication",
"Paul Mitchell the School Woodbridge",
"College of the Muscogee Nation",
"The Salon Professional Academy Huntsville",
"Midwives College of Utah",
"Aspen Beauty Academy of Laurel",
"M T Training Center",
"Berks Career & Technology Center",
"Future Generations Graduate School",
"Carthage R9 School District Carthage Technical Center",
"Grace Mission University",
"World A Cuts Barber Institute",
"Photographic Center Northwest",
"PCCTI IT and Healthcare",
"Sothebys Institute of Art NY",
"Top Nails & Hair Beauty School",
"Radians College",
"Santa Ana Beauty College",
"Shepherds College",
"Woodruff Medical Training and Testing",
"Helms College",
"American Academy of Personal Training",
"Technology Center",
"Ukiah Adult School",
"Riverside County Office of Education",
"Institute of Health Sciences",
"Mid America Baptist Theological Seminary",
"Mid South Christian College",
"Advanced Computing Institute",
"New York Institute of Beauty",
"Ultrasound Medical Institute",
"Beautiful You School of Nail Technology",
"Harmons Beauty School",
"Xavier College School of Nursing",
"Lawrence & Company College of Cosmetology",
"Cactus Academy",
"Kaizen Beauty Academy",
"Southern Texas Careers Academy",
"Sharp Edgez Barber Institute",
"Salon Professional Academy",
"Cosmotech School of Cosmetology",
"Prestige Health & Beauty Sciences Academy",
"Grace College of Barbering",
"California Career Institute",
"Cosmo Factory Cosmetology Academy",
"Grace School of Theology",
"Yeshiva Gedolah Kesser Torah",
"Universal Training Institute",
"Yeshiva Yesodei Hatorah",
"Rizzieri Institute",
"Bonnie Joseph Academy of Cosmetology and Barbering",
"W Academy of Salon and Spa",
"Boca Beauty Academy",
"United Medical and Business Institute",
"Paul Mitchell the School Jersey Shore",
"City Pointe Beauty Academy",
"Chrysm Institute of Esthetics",
"Sanford Burnham Medical Research Institute",
"National Personal Training Institute of Columbus",
"Blys School of Cosmetology",
"Toni & Guy Hairdressing Academy Manteca",
"Belle Academy of Cosmetology",
"Strayer University Missouri",
"Dorsey Business Schools Lansing",
"ITT Technical Institute Pensacola",
"ITT Technical Institute San Antonio East",
"MedTech Institute Orlando Campus",
"Empire Beauty School Augusta",
"South University High Point",
"Empire Beauty School Vernon Hills",
"Empire Beauty School Stone Park",
"Court Reporting Institute of St Louis",
"San Joaquin Valley College Lancaster",
"San Joaquin Valley College San Diego",
"Ohio Business College Dayton",
"Empire Beauty School Rochester",
"American Career College Long Beach",
"Ross Medical Education Center Kokomo",
"Ross Medical Education Center Erlanger",
"Ross Medical Education Center Charleston",
"LEcole Culinaire Kansas City",
"Utah College of Massage Therapy Houston",
"InterCoast Colleges Fairfield",
"Personal Fitness & Nutrition Center",
"College of Business and Technology Miami Gardens",
"Milan Institute of Cosmetology Nampa",
"Milan Institute Merced",
"Georgia Regents University",
"Middle Georgia State College",
"Trenz Beauty Academy",
"Paul Mitchell the School Denver",
"Academy of Interactive Entertainment",
"Arrojo Cosmetology School",
"Cosmetology Academy of Texarkana",
"Virginia Baptist College",
"Lynnes Welding Training",
"Aviation Institute of Maintenance Las Vegas",
"Tulsa Technology Center Owasso Campus",
"Tulsa Technology Center Sand Springs Campus",
"National American University Austin South",
"National American University Lewisville",
"National American University Georgetown",
"National American University Richardson",
"National American University Rochester",
"National American University Weldon Spring",
"National American University Wichita West",
"National American University Indianapolis",
"National American University Tigard",
"The Art Institute of St Louis",
"South Georgia State College",
"Northeastern University Global Network",
"Georgia Military College Distance Learning Campuses",
"Florida Polytechnic University",
"Brown Mackie College Dallas",
"American Institute Toms River",
"Texas Covenant Education",
"Arizona College Mesa",
"Antioch College",
"Texas A & M University Central Texas",
"Barber School of Pittsburgh",
"EDMC Central Administrative Office",
"The Salon Professional Academy South Plainfield",
"Arizona State University Skysong",

];