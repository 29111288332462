import React, { useState, useEffect  } from 'react'
import EditWhiteIcon from '../../assets/images/icons/edit_white.svg';
import BriefCase from '../../assets/images/icons/briefcase.svg';
import Location from '../../assets/images/icons/location.svg';
import Camera from '../../assets/images/icons/camera_white.svg';
import EditIcon from '../../assets/images/icons/edit.svg';
import ImgCancel from '../../assets/images/icons/close_icon.svg';
import UploadCloud from '../../assets/images/icons/Upload_Cloud.svg';
import BrowseFolder from '../../assets/images/icons/Browse_folder.svg';
import { ProfileEdit } from "./ProfileEdit";
import Pro_img from '../../assets/images/de_images.png'
import Re_img from '../../assets/images/re_images.png'
import { useDispatch } from 'react-redux'
import Upload from '../../assets/images/Upload.svg';
import SuccessGreenTick from '../../assets/images/Success_Green.svg';
import FilesImg from '../../assets/images/files.svg';
import Eye from '../../assets/images/Eye.svg';
import Replace from '../../assets/images/Replace.svg';
import Trash from '../../assets/images/Trash.svg';
import {
  updateProfile, viewProfile, updateUserDetails, updateProfileHeaderImage, updateResume, deleteResume
} from '../../store/profile/index'
import { ToastContainer, toast } from 'react-toastify'
import {
  VIEW_PROFILE, UPLOAD_RESUME
} from '../../store/profile/types';
import Dropzone, { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux'
import { PencilAltIcon } from '@heroicons/react/outline';


export const ProfileInfo = ({ match, selectedProfile }) => {
  const [isProfileEditOpen, setIsProfileEditOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [doSave, setDoSave] = React.useState(false);
  const [showUploadResumePopup, setShowUploadResumePopup] = useState(false)
  const [files, setProfileFiles] = useState()
  const [showProgress, setProgress] = useState(true)
  const [progressPercentage, setProgressPercentage] = useState(0)
  const [isUploaded, setUploaded] = useState(false)
  const [showEditPopup, setShowEditPopup] = useState(false)
  const [confirmationPopup, setConfirmationPop] = useState(false)

  const profile = useSelector(state => state.profile.profile)

  const state = useSelector(state => state)
  const resume = useSelector(state => state.profile.resume)

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  React.useEffect(() => {
    if (doSave) {
      setDoSave(false);
      setEditData(null);
      setIsProfileEditOpen(false);
      // call save data api
      // after the edit api complete, fetch new education list or update existing education list in client side
    }
  }, [doSave]);

  useEffect(() => {
    if (resume?.length > 0) {
      setShowUploadResumePopup(false)
    }
  }, [resume])

  const { getRootProps, getInputProps, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/html': ['.txt'],
    },
    acceptedFiles: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/html': ['.txt'],
    },
    onDrop: (acceptedFiles) => {
      setProfileFiles(acceptedFiles)
      startUploadProgress()
    },
    multiple: false
  },)

  useEffect(() => {
    setProfileFiles(acceptedFiles[0])
  }, [acceptedFiles])


  const startUploadProgress = () => {
    var progressTemp = progressPercentage
    let interval = setInterval(() => {
      progressTemp = progressTemp + 1
      if (progressTemp <= 100) {
        setProgressPercentage(oldState => {
          return oldState + 1
        })
      }
      else {
        //enable the save button here
        setUploaded(true)
        setProgress(false)
        clearInterval(interval)
      }
    }, 100)
  }


  const onClose = () => {
    setEditData(null);
    setIsProfileEditOpen(false);
  };

  const onEdit = (data) => {
    setEditData(data);
    setIsProfileEditOpen(true);
  };

  const uploadResume = async (data) => {
    const res = await dispatch(updateResume(acceptedFiles))

    if (res?.success) {
      dispatch({ type: UPLOAD_RESUME, payload: res.data.resume })
      toast.success('successfully uploaded')
      setShowUploadResumePopup(false)
      // dispatch(viewProfile(match.params.id));
    } else {
      toast.error(res?.data?.error)
    }
  }

  const deleteResumeCall = async () => {
    if (resume[0]) {
      const datas = new FormData()
      datas.append('file', resume[0].blobName)

      const res = await dispatch(deleteResume(datas))

      if (res?.success) {
        toast.success('successfully delete')
        setShowEditPopup(false)
        setConfirmationPop(false)
        // dispatch(viewProfile(match.params.id));
      } else {
        toast.error(res?.data?.error)
      }
    }
  }

  const previewResume = () => {
    window.open(resume[0]?.fullUrl, '_blank', 'noopener,noreferrer');
  }

  const onSave = async (data) => {
    const res = await dispatch(updateUserDetails({
      ...data,
      ...{ role: selectedProfile?.userRef.role }
    }));
    if (res?.success) {
      setTimeout(() => {
        toast.success('Profile successfully updated');
        dispatch({
          type: VIEW_PROFILE,
          payload: res.data,
        });
      }, 2000)
    } else {
      toast.error(res?.data?.error);
    }
    setDoSave(true);
  };

  const dispatch = useDispatch();
  const profileChange = async (e) => {
    const data = new FormData()
    data.append('file', e.target.files[0])
    data.append('pid', selectedProfile?._id)

    if (e.target.files.length !== 0) {
      // dispatch(updateProfile(data));
      // setTimeout(() => {
      //   viewProfile(match.params.id);
      // }, 2000);
      const res = await dispatch(updateProfile(data))

      if (res?.success) {
        setTimeout(() => {
          toast.success('successfully uploaded')
          dispatch(viewProfile(selectedProfile?._id))
        }, 2000)
      } else {
        toast.error(res?.data?.error)
      }
    }
  }
  const HeaderImageChange = async (e) => {
    const data = new FormData()
    data.append('file', e.target.files[0])
    data.append('pid', selectedProfile?._id)

    if (e.target.files.length !== 0) {
      // dispatch(updateProfile(data));
      // setTimeout(() => {
      //   viewProfile(match.params.id);
      // }, 2000);
      const res = await dispatch(updateProfileHeaderImage(data))

      if (res?.success) {
        setTimeout(() => {
          toast.success('Image successfully uploaded')
          dispatch(viewProfile(selectedProfile?._id))
        }, 2000)
      } else {
        toast.error(res?.data?.error)
      }
    }
  }
  return (
    <div className="flex flex-1 bg-white shrink-0 border md:rounded-xl md:shadow-lg font-roboto">
      <ToastContainer />
      <div className="relative w-full">
        <div className="absolute xs:top-5 xs:right-5 md:top-11 md:right-11 z-10 text-white">
          <input
            type="file"
            accept="image/*"
            id="header-file-input"
            className="file-input absolute w-px h-px overflow-hidden opacity-0 z-0"
            onChange={HeaderImageChange}
          />
          <label htmlFor="header-file-input">
            <img className="inline-block mr-1 cursor-pointer" src={Camera} alt="img" />
          </label>
        </div>
        <div className={classNames(selectedProfile?.userRef?.role === "recruiter" ? "relative w-full h-180px px-32 bg-black md:rounded-t-xl" : "relative w-full h-180px px-32 bg-teal-600 md:rounded-t-xl")}>
          {selectedProfile?.headerImage && selectedProfile?.headerImage?.fullUrl && (<div
            className="absolute inset-0 object-cover w-full h-full md:rounded-t-xl"
            style={{
              backgroundImage: `url(${selectedProfile?.headerImage?.fullUrl})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}
          >
          </div>)}
        </div>
        <div className="flex py-10 sm:justify-center md:justify-center">
          <div className="absolute top-24 md:left-10 xs:justify-center md:justify-left flex">
            <div className="relative w-40 h-40 rounded-full border-4 border-white overflow-hidden" id="edit-profile">
              <div
                style={{
                  backgroundImage: `url(${selectedProfile?.profileImg &&
                    selectedProfile?.profileImg[0]?.fullUrl
                    ? selectedProfile?.profileImg[0]?.fullUrl
                    : selectedProfile?.userRef?.role === "recruiter" ? Re_img : Pro_img
                    })`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat'
                }}
                className="w-full h-full"
              />
              {/* <span className="absolute -bottom-10 left-0 z-10 bg-black opacity-40 w-full h-20 rounded-bl-full rounded-br-full rotate-180" /> */}
              <input
                type="file"
                accept="image/*"
                id="file-input"
                className="file-input absolute w-px h-px overflow-hidden opacity-0	z-0	"
                onChange={profileChange}
              />
              <label htmlFor="file-input">
                <div className="absolute bottom-0 left-0 z-10 bg-black bg-opacity-70 w-full h-10 rounded-bl-full rounded-br-full rotate-180">
                  <span className="absolute bottom-2 opacity-1 left-12 z-20 text-white cursor-pointer">
                    <img className="inline-block mr-1" src={EditWhiteIcon} alt="edit-icon" />
                    Edit
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>

        {/* for desktop version. */}
        <div className="sm:hidden relative flex flex-1 flex-col xs:py-6 xs:px-4 md:px-12 md:pt-1 md:pb-10 md:shadow-lg md:rounded-xl">
          <div className="flex flex-1 justify-between items-center h-12">
            <h4 className="font-bold mb-2 text-2xl font-nunito">{selectedProfile?.fullName}</h4>
            <button
              className="text-white"
              onClick={() => onEdit(selectedProfile)}
            >
              <PencilAltIcon className={classNames(selectedProfile?.userRef?.role === "recruiter" ? "w-8 h-8 text-black" : "w-8 h-8 text-teal-700")}/>
            </button>
          </div>
          <div className="flex xs:flex-col md:flex-row xs:gap-2 md:gap-4 items-center items-center">
            {selectedProfile?.jobTitle && (<p className="text-black text-lg font-normal">
              <img className="inline-block mr-1 align-text-bottom w-18px h-18px w-18px h-18px" src={BriefCase} alt="brief-icon" />
              {selectedProfile?.jobTitle}
            </p>)}
            {selectedProfile?.streetAddress && (<p className="text-black text-lg font-normal">
              <img className="inline-block mr-1 align-text-bottom w-18px h-18px" src={Location} alt="address-icon" />
              {selectedProfile?.streetAddress}
              {selectedProfile?.remote && (
                <span>, Remote</span>)}
            </p>)}
          </div>
          <div className="flex flex-1 mt-6">
            <p className="text-black text-lg font-normal">
              {selectedProfile?.bio}
            </p>
          </div>

          <div className="w-full border-b py-6" />

          {selectedProfile?.userRef?.role !== "recruiter" && <h6 className="text-black text-lg font-nunito font-bold mt-6">
            Resume
          </h6>}

          {
            // selectedProfile?.resume[0] && selectedProfile?.resume[0].fileName
            resume?.length > 0 ? <div>
              <div className='flex justify-end'>
                <div onClick={() => {
                  setShowEditPopup(true)
                }}>
                  <img src={EditIcon} />
                </div>

              </div>
              <div className='flex flex-row items-center'>
                <img className='w-3 h-4 ' src={FilesImg} />
                <p className='font-nunito font-medium text-bg_gray666 ml-2.5 cursor-pointer hover:underline decoration-1' onClick={() => {
                  previewResume()
                }}>{resume[0].fileName}</p>
              </div>
            </div> : selectedProfile?.userRef?.role !== "recruiter" ? <div className='flex'>
              {
                // isUploaded ? <div className='flex flex-row mt-6 items-center'>
                //   <img className='w-3 h-4 ' src={FilesImg} />
                //   <p className='font-nunito font-medium text-bg_gray666 ml-2.5'>{resume[0].fileName}</p>
                // </div> : 
                <div
                  className="items-center h-40px flex-row flex text-white border-1px rounded-full border-black py-1 px-7 mt-6 cursor-pointer"
                  onClick={() => {
                    setShowUploadResumePopup(true)
                  }}
                >
                  <img src={Upload} className='w-14px h-14px' alt='Upload Resume' />
                  <h6 className="text-black text-18px font-nunito font-medium ml-2">Upload Resume</h6>
                </div>
              }
            </div> : <></>
          }

        </div>

        {/* Desktop version ends here */}

        {/* Mobile version starts here */}

        <div className="lg:hidden md:hidden relative flex flex-1 flex-col xs:py-6 xs:px-4 md:px-12 md:pt-1 md:pb-10 md:shadow-lg md:rounded-xl">
          <div className="flex flex-1 justify-between items-center h-12">
            <h4 className="font-semibold mb-2 text-2xl font-nunito">{selectedProfile?.fullName}</h4>
            <button
              className="text-white"
              onClick={() => onEdit(selectedProfile)}
            >
              <img src={EditIcon} alt="edit-icon" className='w-22.6px h-22.6px' />
            </button>
          </div>
          <div className="lg:hidden md:hidden flex xs:flex-col sm:flex-col md:flex-row xs:gap-2 md:gap-4 md:items-center lg:items-center">
            <div className='xs:flex-column sm:flex-column'>
              {selectedProfile?.jobTitle && (<p className="sm:text-sm md:text-sm text-black text-lg font-normal">
                <img className="inline-block mr-1 align-text-bottom w-18px h-18px" src={BriefCase} alt="brief-icon" />
                {selectedProfile?.jobTitle}
              </p>)}
            </div>
            {selectedProfile?.streetAddress && (<p className="sm:text-sm md:text-sm text-black text-lg font-normal">
              <img className="inline-block mr-1 align-text-bottom w-18px h-18px w-18px h-18px" src={Location} alt="address-icon" />
              {selectedProfile?.streetAddress}
              {selectedProfile?.remote && (
                <span>, Remote</span>)}
            </p>)}
          </div>
          <div className="flex flex-1 mt-6">
            <p className="text-black text-lg font-normal">
              {selectedProfile?.bio}
            </p>
          </div>

          <div className="w-full border-b py-6" />

          {selectedProfile?.userRef?.role !== "recruiter" && 
          
          <><h6 className="text-black text-lg font-nunito font-bold mt-6">
            Resume
          </h6> 

          <div className='flex'>

            <div
              className="items-center h-40px flex-row flex text-white border-1px rounded-full border-black py-1 px-7 mt-6 cursor-pointer"
              onClick={() => { }}
            >
              <img src={Upload} className='w-14px h-14px' alt='Upload Resume' />
              <h6 className="text-black text-18px font-nunito font-medium ml-2">Upload Resume</h6>
            </div>
          </div>


          <div className="w-full border-b py-6" />

          <h6 className="text-black text-lg font-nunito font-bold mt-6">
            Resume
          </h6>
          {
            // selectedProfile?.resume[0] && selectedProfile?.resume[0].fileName
            resume?.length > 0 ? <div>
              <div className='sm:hidden md:hidden flex justify-end'>
                <div onClick={() => {
                  setShowEditPopup(true)
                }}>
                  <img src={EditIcon} />
                </div>

              </div>
              <div className='flex flex-row items-center justify-between w-full mt-5'>
                <div className='w-90% flex items-center'>
                  <img className='w-3 h-4 ' src={FilesImg} />
                  <div className='whitespace-nowrap overflow-hidden'>
                    <p className='font-nunito font-medium text-bg_gray666 ml-2.5 cursor-pointer hover:underline decoration-1' onClick={() => {
                      previewResume()
                    }}>{resume[0].fileName}</p>
                  </div>
                </div>
                <div className=''>
                  <div onClick={() => {
                    setShowEditPopup(true)
                  }}>
                    <img src={EditIcon} className='h-22.6px w-22.6px' />
                  </div>
                </div>
              </div>
            </div> : <div className='flex'>

              {
                // isUploaded ? <div className='flex flex-row mt-6 items-center'>
                //   <img className='w-3 h-4 ' src={FilesImg} />
                //   <p className='font-nunito font-medium text-bg_gray666 ml-2.5'>{resume[0].fileName}</p>
                // </div> : 

                <div
                  className="items-center h-40px flex-row flex text-white border-1px rounded-full border-black py-1 px-7 mt-6 cursor-pointer"
                  onClick={() => {
                    setShowUploadResumePopup(true)
                  }}
                >
                  <img src={Upload} className='w-14px h-14px' alt='Upload Resume' />
                  <h6 className="text-black text-18px font-nunito font-medium ml-2">Upload Resume</h6>
                </div>
              }


            </div>
          }</> }

        </div>

        {isProfileEditOpen && (
          <ProfileEdit onSave={onSave}
            onClose={onClose}
            editData={editData} />
        )}
      </div>

      {
        showUploadResumePopup && <div className='sm:hidden fixed bg-bgBlackTransparent w-full h-full top-0 bottom-0 left-0 right-0 z-20 overscroll-y-none items-center justify-center flex pl-25% pr-25%'>
          <div className='w-full rounded-8px bg-white pb-6'>
            <div className='px-6 py-6 flex flex-row justify-between items-center'>
              <h1 className='font-nunito font-semibold text-2xl'>Upload Resume</h1>
              <div onClick={() => { setShowUploadResumePopup(false) }}>
                <img src={ImgCancel} className='h-18px w-18px' />
              </div>
            </div>
            <div className="w-full border-b" />
            <div className='px-6 pt-6'>
              {
                files && files.size > 0 ?
                  <div className='h-250px items-center flex justify-center px-6'>
                    <div className='flex flex-col w-full bg-grayFAFAFA px-6 pt-7 pb-9'>
                      <div className='flex w-full flex-row justify-between items-center'>
                        <div className='w-90% overflow-hidden'>
                          <p className='font-nunito w-36px font-medium text-bg_gray666'>{acceptedFiles[0].name}</p>
                        </div>

                        {/* <p className='font-nunito font-medium text-bg_gray666'>Names here</p>  */}
                        <img src={ImgCancel} className='h-3 w-3' onClick={() => {
                          acceptedFiles.splice(0, acceptedFiles.length)
                          setUploaded(false)
                          setProfileFiles([])
                        }} />
                      </div>

                      {
                        showProgress ? <div className='w-full h-1 mt-6 flex relative'>
                          <div className='bg-border_gray h-full w-full'></div>
                          <div className='bg-black h-full absolute' style={{ width: `${progressPercentage}%` }}></div>
                        </div> :

                          <div className='w-full h-1 mt-6 flex justify-end'>
                            <img src={SuccessGreenTick} className='h-18px w-18px' />
                          </div>
                      }


                    </div>

                  </div>

                  :
                  <div {...getRootProps()}>
                    <div className='pt-5 pb-5 flex flex-col items-center border-dashed border-1px border_gray_500'>
                      <img src={UploadCloud} className='h-40px w-36px' />
                      <p className='font-roboto font-normal text-sm text-bg_gray666 my-3'>Upload file by drag &amp; drop (or)</p>
                      <div className='flex flex-row items-center'>
                        <img src={BrowseFolder} className='w-4 h-3' />
                        <p className='font-nunito text-lg font-medium ml-2 text-black'>Browse File</p>
                      </div>
                      <p className='font-roboto mt-3.5 font-normal text-bg_gray666 text-xs'>PDF(Preferred), DOCX, DOC, TXT</p>
                      <p className='font-roboto font-normal text-bg_gray666 text-xs'>Maximum file size 2MB</p>
                    </div>
                  </div>
              }

            </div>
            <div className="w-full border-b mt-6" />
            <div className='flex justify-end mt-6 pr-6'>
              <div className='rounded-full px-6 border-1px border-1px border-black mr-4 cursor-pointer' onClick={() => {
                setShowUploadResumePopup(false)
                setProfileFiles('')
              }}>
                <p className='font-nunito text-lg'>Cancel</p>
              </div>
              <div className={classNames(isUploaded ? `rounded-full px-6 disabled bg-black cursor-pointer` : `rounded-full px-6 enabled bg-gray999 cursor-pointer`)} onClick={uploadResume}>
                <p className={classNames(isUploaded ? `font-nunito text-lg text-bg_skyblue font-medium` : `font-nunito text-lg text-white font-medium`)}>Save</p>
              </div>
            </div>
          </div>

        </div>
      }

      {
        showEditPopup && <div className='fixed bg-bgBlackTransparent w-full h-full top-0 bottom-0 left-0 right-0 z-20 overscroll-y-none items-center justify-center flex pl-25% pr-25%'>
          <div className='w-full rounded-8px bg-white pb-6'>
            <div className='px-6 py-6 flex flex-row justify-between items-center'>
              <h1 className='font-nunito font-semibold text-2xl'>Edit Resume</h1>
              <div onClick={() => { setShowEditPopup(false) }}>
                <img src={ImgCancel} className='h-18px w-18px' />
              </div>
            </div>
            <div className="w-full border-b" />
            <div className='px-6 pt-6'>
              {

                <div className='pt-5 pb-5 flex flex-col px-22px py-27px bg-grayFAFAFA'>
                  <div className='w-90% overflow-hidden'>
                    <p className='font-nunito font-medium text-lg text-bg_gray666'>{resume[0].fileName}</p>
                  </div>
                  <p className='font-roboto text-xs text-bg_gray666 mt-2 ml-0.5'>Uploaded on Aug 20, 2022</p>

                  <div className='flex flex-row mt-7'>
                    <div className='flex flex-row items-center cursor-pointer' onClick={() => {
                      previewResume()
                    }}>
                      <img src={Eye} className='h-18px w-18px' />
                      <p className='text-black text-lg font-nunito font-medium ml-2'>Preview</p>
                    </div>

                    <div className='flex flex-row items-center ml-9 cursor-pointer' onClick={() => {
                      setShowUploadResumePopup(true)
                      setShowEditPopup(false)
                      setProfileFiles('')
                    }}>
                      <img src={Replace} className='h-18px w-18px' />
                      <p className='text-black text-lg font-nunito font-medium ml-2'>Replace</p>
                    </div>

                    <div className='flex flex-row items-center ml-9 cursor-pointer' onClick={() => {
                      // deleteResumeCall()
                      setConfirmationPop(true)
                      setShowEditPopup(false)
                    }}>
                      <img src={Trash} className='h-18px w-18px' />
                      <p className='text-black text-lg font-nunito font-medium ml-2 text-error'>Delete</p>
                    </div>

                  </div>

                </div>
              }

            </div>
            <div className="w-full border-b mt-6" />
            <div className='flex justify-end mt-6 pr-6'>
              <div className='rounded-full px-6 border-1px border-1px border-black mr-4 cursor-pointer' onClick={() => {
                setShowEditPopup(false)
              }}>
                <p className='font-nunito text-lg'>Cancel</p>
              </div>
              <div className={classNames(isUploaded ? `rounded-full px-6 disabled bg-black cursor-pointer` : `rounded-full px-6 enabled bg-gray999 cursor-pointer`)} onClick={uploadResume}>
                <p className={classNames(isUploaded ? `font-nunito text-lg text-bg_skyblue font-medium` : `font-nunito text-lg text-white font-medium`)}>Save</p>
              </div>
            </div>
          </div>
        </div>
      }

      {
        confirmationPopup && <div className='sm:hidden fixed bg-bgBlackTransparent w-full h-full top-0 bottom-0 left-0 right-0 z-20 overscroll-y-none items-center justify-center flex pl-25% pr-25%'>
          <div className='w-full rounded-8px bg-white pb-6'>
            <div className='px-6 py-6 flex flex-row justify-between items-center'>
              <h1 className='font-nunito font-semibold text-2xl'>Delete Resume</h1>
              <div onClick={() => { setConfirmationPop(false) }}>
                <img src={ImgCancel} className='h-18px w-18px' />
              </div>
            </div>
            <div className="w-full border-b" />

            <div className='px-6 pt-6'>
              <p className='font-nunito font-normal text-base text-bg_gray666'>Are you sure want to delete the resume?</p>
              {

                // <div className='pt-5 pb-5 flex flex-col px-22px py-27px bg-grayFAFAFA'>


                // </div>
              }

            </div>
            <div className="w-full border-b mt-6" />
            <div className='flex justify-end mt-6 pr-6'>
              <div className='rounded-full px-6 border-1px border-black mr-4 cursor-pointer' onClick={() => {
                setConfirmationPop(false)
              }}>
                <p className='font-nunito text-lg'>Cancel</p>
              </div>
              <div className={classNames(`rounded-full px-6 disabled bg-white cursor-pointer border-1px border-error`)} onClick={() => { deleteResumeCall() }}>
                <p className={classNames(`font-nunito text-lg text-error font-medium`)}>Delete</p>
              </div>
            </div>
          </div>
        </div>
      }

      {/* Section for mobile screens */}

      {
        showUploadResumePopup && <div className='md:hidden lg:hidden fixed bg-bgBlackTransparent w-full h-full top-0 bottom-0 left-0 right-0 z-20 overscroll-y-none items-center justify-center flex'>
          <div className='w-full h-full rounded-8px bg-white pb-6'>
            <div className='px-6 py-6 flex flex-row justify-between items-center'>
              <h1 className='font-nunito font-semibold text-2xl'>Upload Resume</h1>
              <div onClick={() => { setShowUploadResumePopup(false) }}>
                <img src={ImgCancel} className='h-14.4px w-14.4px' />
              </div>
            </div>
            <div className="w-full border-b" />
            <div className='px-6 pt-6'>
              {
                files && files.size > 0 ?
                  <div className='h-250px items-center flex justify-center px-6'>
                    <div className='flex flex-col w-full bg-grayFAFAFA px-6 pt-7 pb-9'>
                      <div className='flex w-full whitespace-nowrap flex-row justify-between items-center'>
                        <div className='w-85% overflow-hidden'>
                          <p className='font-nunito w-36px font-medium text-bg_gray666'>{acceptedFiles[0].name}</p>
                        </div>

                        {/* <p className='font-nunito font-medium text-bg_gray666'>Names here</p>  */}
                        {
                          progressPercentage === 100 ? <img src={SuccessGreenTick} className='h-18px w-18px' /> : <img src={ImgCancel} className='h-10.92px w-3' onClick={() => {
                            acceptedFiles.splice(0, acceptedFiles.length)
                            setUploaded(false)
                            setProfileFiles([])
                          }} />}
                      </div>

                      {
                        showProgress ? <div className='w-full h-1 mt-18px flex relative'>
                          <div className='bg-border_gray h-full w-full'></div>
                          <div className='bg-black h-full absolute' style={{ width: `${progressPercentage}%` }}></div>
                        </div> : null


                      }


                    </div>

                  </div>

                  :
                  <div {...getRootProps()}>
                    <div className='pt-5 pb-5 flex flex-col items-center border-dashed border-1px border_gray_500'>
                      <img src={UploadCloud} className='h-40px w-36px' />
                      <p className='font-roboto font-normal text-sm text-bg_gray666 my-3'>Upload file by drag &amp; drop (or)</p>
                      <div className='flex flex-row items-center'>
                        <img src={BrowseFolder} className='w-4 h-3' />
                        <p className='font-nunito text-lg font-medium ml-2 text-black'>Browse File</p>
                      </div>
                      <p className='font-roboto mt-3.5 font-normal text-bg_gray666 text-xs'>PDF(Preferred), DOCX, DOC, TXT</p>
                      <p className='font-roboto font-normal text-bg_gray666 text-xs'>Maximum file size 2MB</p>
                    </div>
                  </div>
              }

            </div>

            <div className='absolute bottom-0 w-full'>
              <div className='flex justify-end pb-6 px-6'>
                <div className={classNames(isUploaded ? `w-full rounded-full py-2 disabled bg-black cursor-pointer items-center flex justify-center` : `w-full rounded-full py-2 enabled bg-gray999 cursor-pointer items-center flex justify-center`)} onClick={uploadResume}>
                  <p className={classNames(isUploaded ? `font-nunito text-lg text-bg_skyblue font-medium` : `font-nunito text-lg text-white font-medium`)}>Save</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      }

      {/* Edit resume section for mobile screen */}
      {
        showEditPopup && <div className='lg:hidden md:hidden fixed bg-bgBlackTransparent w-full h-full top-0 bottom-0 left-0 right-0 z-20 overscroll-y-none items-center justify-center flex'>
          <div className='w-full h-full bg-white pb-6'>
            <div className='px-4 py-6 flex flex-row justify-between items-center'>
              <h1 className='font-nunito font-semibold text-2xl'>Edit Resume</h1>
              <div onClick={() => { setShowEditPopup(false) }}>
                <img src={ImgCancel} className='h-18px w-18px' />
              </div>
            </div>
            <div className="w-full border-b" />
            <div className='px-4 pt-6'>
              {
                <div className='pt-5 pb-5 flex flex-col px-4 py-27px bg-grayFAFAFA'>
                  <div className='w-90% overflow-hidden'>
                    <p className='font-nunito font-medium text-lg text-bg_gray666'>{resume[0].fileName}</p>
                  </div>
                  <p className='font-roboto text-xs text-bg_gray666 mt-2 ml-0.5'>Uploaded on Aug 20, 2022</p>

                  <div className="w-full border-b mt-6" />

                  <div className='flex flex-col mt-18.5px'>
                    <div className='flex flex-row items-center cursor-pointer' onClick={() => {
                      previewResume()
                    }}>
                      <img src={Eye} className='h-18px w-18px' />
                      <p className='text-black text-lg font-nunito font-medium ml-2'>Preview</p>
                    </div>

                    <div className="w-full border-b mt-18.5px" />

                    <div className='flex flex-col mt-18.5px'>
                      <div className='flex flex-row items-center cursor-pointer' onClick={() => {
                        setShowUploadResumePopup(true)
                        setShowEditPopup(false)
                        setProfileFiles('')
                      }}>
                        <img src={Replace} className='h-18px w-18px' />
                        <p className='text-black text-lg font-nunito font-medium ml-2'>Replace</p>
                      </div>

                    </div>

                    <div className="w-full border-b mt-18.5px" />
                    <div className='flex flex-col mt-18.5px mb-18.5px'>
                      <div className='flex flex-row items-center cursor-pointer' onClick={() => {
                        // deleteResumeCall()
                        setConfirmationPop(true)
                        setShowEditPopup(false)
                      }}>
                        <img src={Trash} className='h-18px w-18px' />
                        <p className='text-black text-lg font-nunito font-medium ml-2 text-error'>Delete</p>
                      </div>
                    </div>

                  </div>

                </div>
              }

            </div>

            <div className='flex mt-6 px-4 absolute bottom-0 w-full pb-6'>

              <div className={classNames(isUploaded ? `py-2 flex w-fill rounded-full px-6 disabled bg-black cursor-pointer justify-center` : `py-2 w-full rounded-full px-6 enabled bg-gray999 cursor-pointer flex justify-center`)} onClick={uploadResume}>
                <p className={classNames(isUploaded ? `font-nunito text-lg text-bg_skyblue font-medium` : `font-nunito text-lg text-white font-medium`)}>Save</p>
              </div>
            </div>
          </div>
        </div>
      }

      {/* confirm delete popup for mobile screens */}
      {
        confirmationPopup && <div className='lg:hidden md:hidden fixed bg-bgBlackTransparent w-full h-full top-0 bottom-0 left-0 right-0 z-20 overscroll-y-none items-center justify-center flex px-4'>
          <div className='w-full rounded-8px bg-white pb-6'>
            <div className='px-6 py-6 flex flex-row justify-between items-center'>
              <h1 className='font-nunito font-semibold text-2xl'>Delete Resume</h1>
              <div onClick={() => { setConfirmationPop(false) }}>
                <img src={ImgCancel} className='h-18px w-18px' />
              </div>
            </div>
            <div className="w-full border-b" />

            <div className='px-6 pt-6'>
              <p className='font-nunito font-normal text-base text-bg_gray666'>Are you sure want to delete the resume?</p>
              {

                // <div className='pt-5 pb-5 flex flex-col px-22px py-27px bg-grayFAFAFA'>


                // </div>
              }

            </div>
            <div className="w-full border-b mt-6" />
            <div className='flex justify-end mt-6 pr-6'>
              <div className='rounded-full px-6 border-1px border-black mr-4 cursor-pointer' onClick={() => {
                setConfirmationPop(false)
              }}>
                <p className='font-nunito text-lg'>Cancel</p>
              </div>
              <div className={classNames(`rounded-full px-6 disabled bg-white cursor-pointer border-1px border-error`)} onClick={() => { deleteResumeCall() }}>
                <p className={classNames(`font-nunito text-lg text-error font-medium`)}>Delete</p>
              </div>
            </div>
          </div>
        </div>
      }


    </div>




  );
};
