/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
// import { Link } from "react-router-dom"
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import Navbar from '../../components/layouts/defaultNavbar'
import Footer from '../../components/layouts/Footer'
import whyNuleep from '../../assets/landingPages/WhyNuleep.png'
import welcomeToNuleep from '../../assets/landingPages/WelcomeToNuleep.png'
import Signup from '../auth/Signup'
import ForgotPassword from '../auth/ForgotPassword'
import jamesCo from '../../assets/landingPages/JamesCo1.png'
import compass from '../../assets/landingPages/compass.png'
import learn from '../../assets/landingPages/learn.png'
import shuttle from '../../assets/landingPages/shuttle.png'
import group from '../../assets/landingPages/group.png'
import att from '../../assets/landingPages/att.png'
import disney from '../../assets/landingPages/disney.png'
import amazon from '../../assets/landingPages/amazon.png'
import facebook from '../../assets/landingPages/facebook.png'
import hero2 from '../../assets/landingPages/hero1.png'
import { useHistory } from 'react-router-dom'
import JoinCommunity from '../../components/JoinCommunity'
import { Field, Form } from 'react-final-form'
import NuleepBlack from '../../../src/assets/SVG/NuleepBlack.svg'
import Meta from '../../../src/assets/SVG/Meta.svg';
import Nike from '../../../src/assets/SVG/Nike.svg';
import { mailChimpApi } from '../../store/auth/index'
import AILA from '../../../src/assets/SVG/AILA.svg';
import Seed from '../../../src/assets/SVG/seed.svg';
import LACI from '../../../src/assets/SVG/LACI.svg';
import WomenEnterpreneurs from '../../../src/assets/SVG/women-enterpreneurs.svg';
import ConnectModels from '../../../src/assets/SVG/ConnectModels.svg';
import ConnectModelsOverlay from '../../../src/assets/SVG/ConnectModelsOverlay.svg';
import Community from '../../../src/assets/SVG/Community.svg';
import JoinConnect from '../../../src/assets/SVG/JoinConnect.svg';
import Cap from '../../../src/assets/SVG/cap.svg';
import Compass from '../../../src/assets/SVG/Compass.svg';
import Jet from '../../../src/assets/SVG/Jet.svg';
import Account from '../../../src/assets/SVG/account.svg';
import Settings from '../../../src/assets/SVG/Settings.svg'
import People from '../../../src/assets/SVG/Peoples.svg';
import Map from '../../../src/assets/SVG/Map.svg';
import Bulb from '../../../src/assets/SVG/Bulb.svg';
import Info from '../../../src/assets/SVG/Info.svg';
import Testimonials from '../../../src/assets/SVG/Testimonial Model1.svg';
import Testimonials2 from '../../../src/assets/SVG/Testimonial Model2.svg';
import CommunityHands from '../../../src/assets/SVG/Community-Hands-1.svg';
import LinearBgLanding from '../../assets/SVG/Linear-bg-landing.svg';
import CommunityHandsOverlay from '../../../src/assets/SVG/Community-Hands-Overlay.svg';
import { singleSignin } from '../../store/auth'

import {
  UserIcon,
  AdjustmentsIcon,
  UserGroupIcon,
  MapIcon,
  LightBulbIcon,
  GlobeIcon,
} from '@heroicons/react/outline'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import BetaSignage from '../../components/layouts/betaSignage'
import LandingModel from '../../../src/assets/images/landing-model.png'
import NuleepWhyModel from '../../../src/assets/SVG/NuleepWhyModel.svg'
import { useDispatch } from 'react-redux'

const Home = () => {
  let history = useHistory()
  const [showTransparentLayer, setTransparentLayer] = useState(false)
  const [loginVisible, setLoginVisible] = useState(false)
  const [isSignupClicked, setSignUpClicked] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm();
  let dispatch = useDispatch()

  const onSubscribeSubmit = (data) => {
     dispatch(mailChimpApi({email_address:data.emailAddress,
    status:"subscribed"})).then((res)=>{
      if(res.data.statusCode === 200){
        toast.success(res.data.success);
      }else toast.error(res.data.message);
    }).catch((res)=>{
      toast.error(res.message)});
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      history.push('/dashboard')
    }
  }, [])
  const pageCall = (type) => {
    history.push({
      pathname: '/signup',
    })
  }

  const onSignInClicked = (e) => {
    setForgotPassword(false)
    setSignUpClicked(false)
    setTransparentLayer(true)
    setLoginVisible(true)
  }

  const onSignUpClicked = () => {
    setForgotPassword(false)
    setTransparentLayer(true)
    setSignUpClicked(true)
  }

  const closeLoginSection = () => {
    setForgotPassword(false)
    setLoginVisible(false)
    setSignUpClicked(false)
    setTransparentLayer(false)
  }

  const forgotPasswordAction = () => {
    setLoginVisible(false)
    setSignUpClicked(false)
    setTransparentLayer(true)
    setForgotPassword(true)
  }

  const renderAlert = () => {
    // if (this.props.errorMessage) {
    //   return (
    //     <p className="mt-4 font-bold tracking-wide text-red-400">
    //       {this.props.errorMessage}
    //     </p>
    //   )
    // }
  }
  return (
    <div style={{ height: '100vh', overflowY: showTransparentLayer ? 'hidden' : 'scroll' }}>
      <Navbar signInEvent={onSignInClicked} singUpEvent={onSignUpClicked} />
      <ToastContainer />
      <div className="max-w-sm mx-auto sm:w-full sm:max-w-full md:max-w-full font-nunito flex flex-col overflow-hidden">
        {/* Welcome to Nuleep section */}
        <section className="sm:h-auto  px-4 mx-auto lg:px-0 max-w-7xl h-screen flex">
          <div className="sm:flex sm:flex-col pt-80px md:flex relative">
            {/* Text */}
            <div className="sm:w-9/12 sm:mb-0 z-10 w-7/12 mb-102px">
              <div className="text-center md:text-left">
                <p className="font-light text-64px font-nunito">
                  Welcome to <span className='font-bold text-64px font-nunito'>Nuleep</span>
                </p>
                <h2 className="mt-8 text-32px font-bold text-black font-nunito">
                  The GPS for your career journey.
                </h2>
                <p className="mt-18px font-roboto font-normal text-2032px text-gray-600">
                  Easily navigate to your desired career. Discover diverse careers that align with your personal
                  vision and gain the needed experience through mentors and workshops.
                </p>

                <p className="mt-18px font-roboto font-normal text-2032px text-gray-600">
                  Nuleep provides tangible resources, such as skill building workshops, and connects you with
                  personal mentors to find the best route to your destination.
                </p>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    //pageCall('jobSeeker')
                    // history.push({
                    //   pathName: "/signup",
                    //   state: { type: "jobSeeker", status: true },
                    // });
                    onSignUpClicked()
                  }}
                  style={{ backgroundColor: '#000000' }}
                  className="inline-block px-16 py-4 mt-50px text-xl font-bold tracking-wider text-bg_skyblue rounded-full"
                >
                  Get Started
                </a>
              </div>
            </div>
            {/* Image */}
            <div className="sm:relative mt-12 md:mt-0 md:w-1/2 md:ml-8 absolute right-0" style={{ top: 0, }}>

              <img
                style={{ width: 500, height: 600 }}
                src={LandingModel}
                className="max-w-sm mx-auto sm:max-w-md md:max-w-full xs:w-full"
                alt=""
              ></img>
            </div>
          </div>
        </section>

        {/* <section className="px-4 mx-auto lg:px-0 max-w-7xl">
          <div className="items-center mt-12 md:flex sm:my-28">
        
            <div
              className="max-w-xs mx-auto mt-12 md:ml-24 md:w-1/2 md:max-w-max"
              style={{ backgroundColor: "#10ACB766" }}
            >
              <div className="m-auto h-1/4">
                <img src={hero2} className="p-4 md:-ml-14 py-14" alt="hero" />
              </div>
            </div>
            <div className="md:w-1/2">
              <div className="mx-auto text-center md:max-w-lg md:text-left">
                <h2 className="mt-3 text-5xl font-bold leading-normal text-gray-600 font-baskerville">
                  <span className="text-yellow-600">Find and hire </span>
                  the talent of tomorrow.
                </h2>
                <p className="mt-3 text-xl leading-normal text-gray-600">
                  Engage and train with the best teams with the top learning
                  resources. Match skills, build experiences, and create growth
                  opportunities with impact and community.
                </p>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    pageCall("recruiter");
                  }}
                  style={{ backgroundColor: "#16ACB2" }}
                  className="inline-block px-16 py-4 mt-5 text-xl font-bold tracking-wider text-white rounded-full"
                >
                  Signup nuleep
                </a>
              </div>
            </div>
          </div>
        </section> */}

        {/* Why Nuleep section */}
        <section className="sm:px-0 sm:mx-0 px-4 mx-auto md:px-0 bg-black">
          <div className="flex-row-reverse items-center w-full mx-auto my-20 md:flex md:my-28">
            <div className="sm:flex-col mx-auto pt-115px pb-115px sm:px-4 lg:pl-72px  flex-row flex">
              <div className='sm:w-full w-55%' style={{ }}>
                <img className='h-[546px] w-[684px]' src={NuleepWhyModel} alt="" style={{ width: 684, height: 546 }}></img>
              </div>
              <div className='sm:w-full sm:items-center w-45% flex flex-col lg:pr-100px lg:pr-72px justify-center' style={{  }}>
                <h1 className="pt-3 text-64px font-bold tracking-wider text-bg_skyblue">
                  Why Us?
                </h1>
                <h2 className="sm:text-center flex mt-3 text-3xl leading-normal text-white font-nunito font-bold">
                  We help you unlock your career potential through actionable steps.
                </h2>
                <p className="mt-3 text-xl leading-normal text-white font-normal">
                  Our focus is to empower by building valuable skills, mentoring with tangible feedback, and connecting you with experts.
				</p>

                <p className="mt-3 text-xl leading-normal text-white font-normal">
                  At Nuleep, let’s dream big together and develop the experience needed to excel in the workforce.
                </p>
                <a
                  href="/about"
                  className="flex pb-2"
                >
                  <div className='text-xl text-black-500 border-b-2 border-black-500 mt-9 rounded-full bg-white px-6 py-3'>
                    <p>Learn more about Nuleep</p>
                  </div>
                </a>
              </div>

            </div>
            {/* <div className="max-w-xs mx-auto mt-12 md:w-1/2 md:mt-0">
              <img src={whyNuleep} alt=""></img>
            </div> */}
          </div>
        </section>

        {/* Partners */}
        <section className="sm:px-0 px-72px mx-auto lg:mt-36  py-16 w-full bg-grayFAFAFA">
          <p className="mx-auto text-5xl tracking-wider text-center text-gray-400">
            Our Partnerships & Collaborations
          </p>
          <p className='mx-auto text-32px mt-22px tracking-wider text-center text-gray-400 max-w-6xl'>
				Trusted by the most innovative and impactful companies and employees.
          </p>
          <div className='flex flex-col'>
            <div className="flex-wrap flex justify-center items-center mt-12 md:flex">
              <div className='h-98px bg-white lock mx-auto mt-12 md:mt-0 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                <img
                  className="block mx-auto md:mt-0"
                  src={Meta}
                  alt=""
                ></img>
              </div>
              <div className='h-98px bg-white lock mx-auto mt-12 md:mt-0 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                <img
                  className="block mx-auto md:mt-0"
                  src={amazon}
                  alt=""
                ></img>
              </div>
              <div className='h-98px bg-white lock mx-auto mt-12 md:mt-0 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                <img
                  className="block mx-auto md:mt-0"
                  src={disney}
                  alt=""
                ></img>
              </div>
              <div className='h-98px bg-white lock mx-auto mt-12 md:mt-0 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                <img className="block mx-auto md:mt-0" src={att} alt=""></img>
              </div>

              <div className='h-98px bg-white lock mx-auto mt-12 md:mt-12 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                <img
                  className="block mx-auto md:mt-0"
                  src={AILA}
                  alt=""
                ></img>
              </div>
              <div className='h-98px bg-white lock mx-auto mt-12 md:mt-12 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                <img
                  className="block mx-auto md:mt-0"
                  src={Seed}
                  alt=""
                ></img>
              </div>
              <div className='h-98px bg-white lock mx-auto mt-12 md:mt-12 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                <img
                  className="block mx-auto md:mt-0"
                  src={LACI}
                  alt=""
                ></img>
              </div>
              <div className='h-98px bg-white lock mx-auto mt-12 md:mt-12 sm:flex-40% flex-20% h-full mx-10px flex justify-center items-center rounded-2xl'>
                <img className="block mx-auto md:mt-0" src={WomenEnterpreneurs} alt=""></img>
              </div>
            </div>
          </div>

        </section>

        {/* Commmunity */}
        <section className="px-4 mt-40 lg:px-0 relative">
          <div
            style={{ height: '739px' }}
            className="max-w-full mx-auto bg-blue-50 relative"
          >
           <div className='absolute top-0 w-full' style={{ display: 'flex', height: 739, backgroundImage: `url(${ConnectModels})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover',  }}>


              <div className='flex absolute top-0 w-full' style={{ height: 739, backgroundImage: `url(${ConnectModelsOverlay})` }}>
                <div className='flex absolute top-0 w-full sm:pl-10px pl-72px pt-45px flex-col md:w-65%'>

                 <p className='text-white font-normal text-6xl'>Connect and build relationships with our <span style={{ fontSize: '64px', fontWeight: 700, background: 'linear-gradient(90deg, #10E9D4 1.14%, #FC52FF 197.16%)', webkitBackgroundClip: 'text', webkitTextFillColor: 'transparent' }}>
                    Community
                  </span></p>

                  <div className='flex w-full flex-col'>
                    <div className='sm: w-full md:w-6/12'>
                      <p className='text-white font-normal text-xl'>Build relationships with valuable industry experts, top peers, and friends. Access VIP events
                        and job openings before they are announced to the public.</p>
                    </div>
                    <a
                    href="javascript:void(0)"
                    onClick={() => {
                      onSignUpClicked()
                    }}
                    style={{ backgroundColor: "#fff" }}
                      className="block w-155px py-2 text-xl font-bold text-black rounded-full mt-25px sm:mt-36 px-5 border-solid border-2 border-black font-roboto"
                    >
                      Join Nuleep
                    </a>
                  </div>


                </div>
              </div>

            </div>

          </div>
        </section>



        {/* How does it work */}
        <section className="px-4 py-72px lg:px-0 relative">
          <p className="font-bold tracking-wider text-center font-normal text-3xl font-roboto">
            How we get you from
          </p>
          <h2 className="font-bold tracking-wider text-center font-normal text-6xl font-roboto">
            Today to your First Day
          </h2>
          <div className="mt-10 md:flex">
            <div className="max-w-sm px-8 py-12 mx-4 mx-auto bg-grayFAFAFA rounded-md h-90 md:w-1/3">
              <img className="absolute mx-auto" src={Cap} alt=""></img>
              <h2 className="pt-10 mt-8 text-3xl font-bold text-black font-nunito ">
                Learn
              </h2>
              <p className="mt-1 text-black font-bold text-18px font-roboto">
                Share what’s important to you and what you need help with to get started. We have
                mentors, classes, and more to support your journey.
              </p>
            </div>
            <div className="flex-auto max-w-sm px-8 py-12 mx-4 mx-auto mt-4 bg-grayFAFAFA rounded-md h-90 md:mt-0 md:w-1/3">
              <img
                className="absolute mx-auto"
                src={Compass}
                alt=""
              ></img>
              <h2 className="pt-10 mt-8 text-3xl font-bold text-black font-nunito">
                Discover
              </h2>
              <p className="mt-1 text-black font-bold text-18px font-roboto">
                Surf our jobs and workshops to find ways to grow your career. Check out tips and
                VIP sessions where you learn from industry experts.
              </p>
            </div>
            <div className="flex-auto max-w-sm px-8 py-12 mx-4 mx-auto mt-4 bg-grayFAFAFA rounded-md h-00 md:mt-0 md:w-1/3">
              <img className="absolute mx-auto" src={Jet} alt=""></img>
              <h2 className="pt-10 mt-8 text-3xl font-bold text-black font-nunito">
                Explore
              </h2>
              <p className="mt-1 text-black font-bold text-18px font-roboto">
                Be creative and adventurous as you explore Nuleep’s community. Meet peers,
                mentors, and Navigators. Explore diverse jobs and find inclusive companies that care about
                making and impact.
              </p>
            </div>
          </div>
          <button
            onClick={() => {
              onSignUpClicked()
            }}
            style={{ backgroundColor: '#000000' }}
            className="block px-16 py-4 mx-auto mt-12 text-xl font-bold tracking-wider text-bg_skyblue rounded-full"
          >
            Get Started
          </button>
        </section>


        {/* Help page */}
        <section className="px-4 pt-72px lg:px-0 relative bg-black">
          <div className='flex flex-col mx-auto w-90%'>
            <p className="font-semibold tracking-wider text-center font-normal text-3xl font-nunito text-white">
              Getting the job you want should be simple
            </p>
            <h2 className="font-semibold tracking-wider text-center font-normal text-3xl font-nunito text-white">
              Here is how we help
            </h2>
            <div className="mt-10 pb-67px md:flex flex flex-wrap justify-center w-full">
              <div className='sm:flex-100% flex-33% p-5px mx-auto'>
                <div className="px-8 py-6 rounded-md w-full h-full" style={{ backgroundColor: '#FFFFFF1A' }}>
                  <div className='flex flex-row items-center'>
                    <img className="" src={Account} alt="" style={{ width: 72, height: 72 }}></img>
                    <h2 className="ml-2 text-2xl font-bold text-bg_skyblue font-nunito ">
                      Goal Setting
                    </h2>
                  </div>

                  <div className='w-full bg-bg_skyblue mt-4' style={{ height: '1px' }}>

                  </div>
                  <p className="mt-1 text-white text-18px font-roboto font-normal">
                  We want to understand what matters most to you in your career. Access purpose-driven companies that align with your vision.
                  </p>
                </div>
              </div>
              <div className='sm:flex-100% flex-33% p-5px mx-auto'>
              <div className="px-8 py-6 mx-auto rounded-md w-full h-full" style={{ backgroundColor: '#FFFFFF1A' }}>
                <div className='flex flex-row items-center'>
                  <img className="" src={Settings} alt="" style={{ width: 72, height: 72 }}></img>
                  <h2 className="ml-2 text-2xl font-bold text-bg_skyblue font-nunito ">
                    Decision Making Benefits
                  </h2>
                </div>

                <div className='w-full bg-bg_skyblue mt-4' style={{ height: '1px' }}>

                </div>

                <p className="mt-1 text-white text-18px font-roboto font-normal">
                Make informed decisions about salary, perks, and benefits with insider knowledge. Understand leadership and teamwork dynamics for your career.
                </p>
              </div>
              </div>
              <div className='sm:flex-100% flex-33% p-5px mx-auto'>
              <div className="px-8 py-6 mx-auto rounded-md w-full h-full" style={{ backgroundColor: '#FFFFFF1A' }}>
                <div className='flex flex-row items-center'>
                  <img className="" src={People} alt="" style={{ width: 72, height: 72 }}></img>
                  <h2 className="ml-2 text-2xl font-bold text-bg_skyblue font-nunito ">
                    People and Community
                  </h2>
                </div>

                <div className='w-full bg-bg_skyblue mt-4' style={{ height: '1px' }}>

                </div>

                <p className="mt-1 text-white text-18px font-roboto font-normal">
                Nuleep’s community extends beyond work. Build a network of lifelong mentors, colleagues, and friends.
                </p>
               </div>

              </div>
              <div className='sm:flex-100% flex-33% p-5px mx-auto mt-6'>
              <div className="px-8 py-6 rounded-md w-full h-full" style={{ backgroundColor: '#FFFFFF1A' }}>
                <div className='flex flex-row items-center'>
                  <img className="" src={Map} alt="" style={{ width: 72, height: 72 }}></img>
                  <h2 className="ml-2 text-2xl font-bold text-bg_skyblue font-nunito ">
                    Roadmap A-Z
                  </h2>
                </div>

                <div className='w-full bg-bg_skyblue mt-4' style={{ height: '1px' }}>

                </div>

                <p className="mt-1 text-white text-18px font-roboto font-normal">
                Create a plan for your career journey with Nuleep. This guide will support you from your first internship to your future promotions.
                </p>
              </div>
              </div>

              <div className='sm:flex-100% flex-33% p-5px mx-auto mt-6'>
              <div className=" px-8 py-6 mx-auto rounded-md w-full h-full" style={{ backgroundColor: '#FFFFFF1A' }}>
                <div className='flex flex-row items-center'>
                  <img className="" src={Bulb} alt="" style={{ width: 72, height: 72 }}></img>
                  <h2 className="ml-2 text-2xl font-bold text-bg_skyblue font-nunito ">
                    Mentors, Experts, & Navigators
                  </h2>
                </div>

                <div className='w-full bg-bg_skyblue mt-4' style={{ height: '1px' }}>

                </div>

                <p className="mt-1 text-white text-18px font-roboto font-normal">
                Connect with expert mentors and peers from Disney, Meta, KPMG, startups, and top companies that care about impact.
                </p>
              </div>
              </div>

              <div className='sm:flex-100% flex-33% p-5px mx-auto mt-6'>
              <div className="px-8 py-6 mx-auto rounded-md w-full h-full" style={{ backgroundColor: '#FFFFFF1A' }}>
                <div className='flex flex-row items-center'>
                  <img className="" src={Info} alt="" style={{ width: 72, height: 72 }}></img>
                  <h2 className="ml-2 text-2xl font-bold text-bg_skyblue font-nunito ">
                    Feedbacks & AMA’s
                  </h2>
                </div>

                <div className='w-full bg-bg_skyblue mt-4' style={{ height: '1px' }}>

                </div>

                <p className="mt-1 text-white text-18px font-roboto font-normal">
                Ask questions and connect with real people. We encourage our companies to provide feedback and champion learning, growth, and opportunity.
                </p>
              </div>
              </div>
            </div>
          </div>
        </section>

        {/* Features*/}
			{/*<section className="px-16 pt-52px flex items-center flex-col">
          <p className="text-3xl font-bold tracking-wider text-black font-nunito font-semibold">
            Don’t take our word for it
          </p>
          <div className='mt-3 mt-5 flex flex-row items-center'>
            <h2 className="text-6xl font-light leading-normal text-left text-black font-nunito mr-18px">
              Listen to what our
            </h2>
            <h2 className='text-6xl font-bold leading-bold text-left text-black font-nunito'>{' '} members</h2>
            <h2 className="ml-18px text-6xl font-light leading-normal text-left text-black font-nunito">
              {' '}say
            </h2>
          </div>

          <div className='w-full bg-disable_bg_color flex py-26px px-26px justify-end mt-40px'>
            <div className='bg-white flex items-center flex-col px-24px py-24px text-2xl rounded-2xl' style={{ width: 450, height: 468 }}>
              <img src={Testimonials} alt='' />
              <p className='text-2xl mt-16px'>
                “The best part is being able to interact & engage with professionals about different companies for work during Connect@Nuleep!”
              </p>
              <div className='flex w-full mt-8px'>
                <p className='font-roboto text-base font-normal'>
                  James, Nuleep Community+ Member
                </p>
              </div>

            </div>

            <div className='bg-white flex items-center flex-col px-24px py-24px text-2xl rounded-2xl ml-16px' style={{ width: 450, height: 468 }}>
              <img src={Testimonials2} alt='' />
              <p className='text-2xl mt-16px'>
                “The best part is being able to interact & engage with professionals about different companies for work during Connect@Nuleep!”
              </p>
              <div className='flex w-full mt-8px'>
                <p className='font-roboto text-base font-normal'>
                  Lise, Nuleep Community+ Member
                </p>
              </div>
            </div>
          </div>
			</section> */}

        {/* Community Partners community */}
        <section className="mt-102px" style={{}}>
          <div
            style={{ height: '739px' }}
            className="relative"
          >
            <div className='w-full' style={{ display: 'flex', height: 739, backgroundImage: `url(${CommunityHands})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} >
              <div className='w-full h-full sm:px-32px sm:flex-col px-72px items-center flex' style={{ backgroundImage: `url(${CommunityHandsOverlay})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                <div className='flex flex-1 flex-col'>
                  <div className='flex-1 sm:pr-0 pr-150px sm:flex sm:flex-col sm:justify-center'>
                    <h2 className='text-white text-5xl font-roboto font-medium'>Join the Nuleep Partner community</h2>
                    <p className='text-white font-roboto font-normal text-3xl mt-16px'>
                      Get updates on latest events, workshops and more!
                    </p>
                  </div>
                  <div className='flex-1 sm:hidden'></div>

                </div>
                <div className='flex flex-1 flex-col sm:w-full'>
                  <div className='flex flex-1 w-full'>
                    <form className="sm:flex"onSubmit={handleSubmit(onSubscribeSubmit)}>
                      <div style={{ backgroundColor: '#FFFFFF66' }} className='px-32px py-32px rounded-2xl flex flex-col sm:w-full'>
                        <div>
                        <input type='email' placeholder='Enter your email' style={{border:`${errors.emailAddress?'red':'white'} solid 2px`}}
                        {...register("emailAddress", { required: true })} id="emailAddress"
                        name="emailAddress" className='text-roboto text-2xl px-24px py-4 rounded-full sm:w-full w-full' />
                        </div>
                        <div className='flex w-full items-center mt-4'>
                          <div className='py-4 w-full bg-black rounded-full flex justify-center font-roboto font-normal '>
                          <button type="submit" className='text-bg_skyblue font-roboto font-normal text-2xl'>Subscribe</button>
                          </div>
                        </div>

                        <p className='text-white font-roboto font-normal text-base mt-4'>We care about protection of your data. Read our <Link
                        to="/privacypolicy"
                        className=" font-medium underline"
                      >
                      Privacy Policy
                    </Link></p>
                      </div>
                    </form>
                  </div>
                  <div className='flex-1 flex sm:hidden'></div>
                </div>
              </div>
            </div>
          </div>


        </section>

        {/* Newsletter */}
        {/* <JoinCommunity /> */}


      </div>

      {
        <div className='flex absolute top-0 w-full h-full z-10 bg-bgBlackTransparent77 overflow-hidden' onClick={closeLoginSection} style={{ right: showTransparentLayer ? 0 : '-100%', width: showTransparentLayer ? '100%' : 0, backgroundColor: '#000000BB' }}>
          {
            loginVisible && <div className='flex absolute top-0 bg-white h-full' style={{ right: loginVisible ? 0 : -590, transition: '2s' }} onClick={(e) => e.stopPropagation()}>
              <div className="flex min-h-screen bg-white justify-center">
                <div className="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                  <div className="w-full max-w-sm mx-auto lg:w-96">
                    <div>
                      <Link to="/">
                        <img className="w-auto h-12" src={NuleepBlack} alt="Nuleep Logo" />
                      </Link>
                      <h2 className="text-3244px font-semibold text-gray-900 font-nunito">
                        Sign in to your account
                      </h2>
                      <p className="mt-2 text-sm text-gray-600 hidden">
                        Or{' '}
                        <Link
                          to="/signup"
                          className="font-medium text-teal-600 hover:text-teal-500 hidden"
                        >
                          Signup for a new account
                        </Link>
                      </p>
                    </div>

                    <div className="">
                      <div className="mt-6">
                        <Form
                          onSubmit={({ email, password }) => {
                            if (!email || !password) {

                              return null
                            }
                            email = email.toLowerCase()
                            //1208 - Allow for credentials to work for all login pages.
                            singleSignin({
                              email,
                              password,
                              history,
                              dispatch
                            })
                          }}
                        >
                          {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Email address
                              </label>
                              <Field name="email">
                                {(props) => (
                                  <div>
                                    <input
                                      id="email"
                                      type="text"
                                      className="block w-full px-3 py-2 mt-1 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
                                      name={props.input.email}
                                      value={props.input.value}
                                      onChange={props.input.onChange}
                                    />
                                    {props.meta.touched && props.meta.error && (
                                      <span className="block mt-2 text-red-500">
                                        {props.meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                              <div className="mt-6">
                                <label
                                  htmlFor="password"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Password
                                </label>
                              </div>

                              <Field name="password">
                                {(props) => (
                                  <div>
                                    <input
                                      id="password"
                                      type="password"
                                      className="w-full p-2 mt-1 border border-gray-300 rounded-lg"
                                      name={props.input.password}
                                      value={props.input.value}
                                      onChange={props.input.onChange}
                                    />
                                    {props.meta.touched && props.meta.error && (
                                      <span className="block mt-2 text-red-500">
                                        {props.meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                              {renderAlert()}
                              <div className="flex items-center justify-between mt-4">
                                <div className="flex items-center">
                                  <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="w-4 h-4 text-teal-600 border-gray-300 rounded focus:ring-teal-500"
                                  />
                                  <label
                                    htmlFor="remember-me"
                                    className="block ml-2 text-sm text-gray-900"
                                  >
                                    Remember me
                                  </label>
                                </div>

                                <div className="text-sm">
                                  <Link
                                    to='/'
                                    onClick={forgotPasswordAction}
                                    className="font-medium text-teal-600 hover:text-teal-500"
                                  >
                                    Forgot your password?
                                  </Link>
                                </div>
                              </div>
                              <button
                                className="flex justify-center w-full px-4 py-2 mt-4 text-sm font-medium text-bg_skyblue bg-black border border-transparent rounded-full shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                type="submit"

                              >
                                Log in
                              </button>
                              <Link
                                to={'/'}
                                className="inline-block pb-2 mt-4 tracking-wide text-center text-gray-400 underline hidden"
                              >
                                Go back
                              </Link>
                            </form>
                          )}
                        </Form>

                        <div className='flex flex-row mt-8 justify-end items-center'>
                          <div>
                            <p className='font-roboto font-semibold text-sm'>Don't have an account?</p>
                          </div>
                          <div className='ml-4 border border-black rounded-full px-6 py-5px cursor-pointer' onClick={onSignUpClicked}>
                            <p className='font-nunito font-medium text-lg'>Sign Up</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden flex-1 w-0">
                  <img
                    className="absolute inset-0 object-cover w-full h-full"
                    src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                    alt=""
                  />
                </div>
              </div>
            </div>
          }
          {
            isSignupClicked && <div className='flex absolute top-0 bg-white h-full' style={{ right: isSignupClicked ? 0 : -590, transition: '2s' }} onClick={(e) => e.stopPropagation()}>
              <Signup signInAction={onSignInClicked} />
            </div>
          }
          {
            forgotPassword && <div className='flex absolute top-0 bg-white h-full' style={{ right: forgotPassword ? 0 : -590, transition: '2s' }} onClick={(e) => e.stopPropagation()}>
              <ForgotPassword />
            </div>
          }
        </div>
      }
      <Footer />
    </div>
  )
}
export default Home
// class Home extends Component {
//   componentDidMount = () => {
//     const history = this.props.history;
//     if (localStorage.getItem("token")) {
//       history.push("/dashboard");
//     }
//   };
//   render() {
//     return (
//       <div>
//         <Navbar />
//         <div className="max-w-sm mx-auto sm:max-w-xl md:max-w-full font-nunito">
//           {/* Welcome to Nuleep section */}
//           <section className="px-4 mx-auto lg:px-0 max-w-7xl">
//             <div className="items-center mt-12 md:flex sm:my-28">
//               {/* Text */}
//               <div className="md:w-1/2">
//                 <div className="mx-auto text-center md:max-w-lg md:text-left">
//                   <p className="text-xl font-bold tracking-wider text-yellow-500">
//                     Welcome to Nuleep
//                   </p>
//                   <h2 className="mt-3 text-4xl leading-normal text-gray-600 font-baskerville">
//                     Create a career journey with mentors.
//                   </h2>
//                   <p className="mt-3 text-xl leading-normal text-gray-600">
//                     Discover diverse careers that you never imagined with
//                     mentors and feedback along your journey. Nuleep isn't just
//                     another job platform but a GPS that shares how you get to
//                     your next career destination.{" "}
//                   </p>
//                   <a
//                     href="javascript:void(0)"
//                     onClick={() => {
//                       let history = this.props.history;
//                       history.push({
//                         pathName: "/signup",
//                         state: { type: "jobSeeker", status: true },
//                       });
//                     }}
//                     style={{ backgroundColor: "#16ACB2" }}
//                     className="inline-block px-16 py-4 mt-5 text-xl font-bold tracking-wider text-white rounded-full"
//                   >
//                     Get Started
//                   </a>
//                 </div>
//               </div>
//               {/* Image */}
//               <div className="mt-12 md:mt-0 md:w-1/2 md:ml-8">
//                 <img
//                   src={welcomeToNuleep}
//                   className="max-w-sm mx-auto sm:max-w-md md:max-w-full"
//                   alt=""
//                 ></img>
//               </div>
//             </div>
//           </section>

//           <section className="px-4 mx-auto lg:px-0 max-w-7xl">
//             <div className="items-center mt-12 md:flex sm:my-28">
//               {/* Text */}
//               <div className="md:w-1/2">
//                 <div className="mx-auto text-center md:max-w-lg md:text-left">
//                   <p className="text-xl font-bold tracking-wider text-yellow-500">
//                     Welcome to Nuleep
//                   </p>
//                   <h2 className="mt-3 text-4xl leading-normal text-gray-600 font-baskerville">
//                     Create a career journey with mentors.
//                   </h2>
//                   <p className="mt-3 text-xl leading-normal text-gray-600">
//                     Discover diverse careers that you never imagined with
//                     mentors and feedback along your journey. Nuleep isn't just
//                     another job platform but a GPS that shares how you get to
//                     your next career destination.{" "}
//                   </p>
//                   <a
//                     href="javascript:void(0)"
//                     onClick={() => {
//                       this.props.history.push({
//                         pathName: "/signup",
//                         state: { type: "recruiter", status: true },
//                       });
//                     }}
//                     style={{ backgroundColor: "#16ACB2" }}
//                     className="inline-block px-16 py-4 mt-5 text-xl font-bold tracking-wider text-white rounded-full"
//                   >
//                     Get Started
//                   </a>
//                 </div>
//               </div>
//               {/* Image */}
//               <div className="mt-12 md:mt-0 md:w-1/2 md:ml-8">
//                 <img
//                   src={welcomeToNuleep}
//                   className="max-w-sm mx-auto sm:max-w-md md:max-w-full"
//                   alt=""
//                 ></img>
//               </div>
//             </div>
//           </section>

//           {/* Why Nuleep section */}
//           <section className="px-4 mx-auto md:px-0 max-w-7xl">
//             <div className="flex-row-reverse items-center w-full mx-auto my-20 md:flex md:my-28">
//               <div className="max-w-md mx-auto md:w-1/2 sm:ml-8">
//                 <p className="pt-3 text-xl font-bold tracking-wider text-yellow-500">
//                   Why Nuleep
//                 </p>
//                 <h2 className="flex mt-3 text-3xl leading-normal text-gray-600 font-baskerville">
//                   Access career developing experiences and jobs.
//                 </h2>
//                 <p className="mt-3 text-xl leading-normal text-gray-600 ">
//                   Build skills and experiences as a cohort. Meet peers and
//                   mentors that champion your voice at work.
//                 </p>
//                 <p className="inline-block pb-2 text-xl text-teal-500 border-b-2 border-teal-500 mt-9">
//                   Learn more about nuleep
//                 </p>
//               </div>
//               <div className="max-w-xs mx-auto mt-12 md:w-1/2 md:mt-0">
//                 <img src={whyNuleep} alt=""></img>
//               </div>
//             </div>
//           </section>

//           {/* Partners */}
//           <section className="px-4 mx-auto lg:px-0 mt-36 max-w-7xl">
//             <p className="max-w-3xl mx-auto text-3xl tracking-wider text-center text-gray-400">
//               Our Partnerships and Collaborations with the most innovative
//               companies and employees
//             </p>
//             <div className="items-center mt-12 md:flex">
//               <img
//                 className="block mx-auto mt-12 md:mt-0"
//                 src={facebook}
//                 alt=""
//               ></img>
//               <img
//                 className="block mx-auto mt-12 md:mt-0"
//                 src={amazon}
//                 alt=""
//               ></img>
//               <img
//                 className="block mx-auto mt-12 md:mt-0"
//                 src={disney}
//                 alt=""
//               ></img>
//               <img
//                 className="block mx-auto mt-12 md:mt-0"
//                 src={att}
//                 alt=""
//               ></img>
//             </div>
//           </section>

//           {/* Commmunity */}
//           <section className="px-4 mt-40 lg:px-0">
//             <div
//               style={{ height: "530px" }}
//               className="max-w-full mx-auto bg-blue-50"
//             >
//               <div className="max-w-lg px-4 mx-auto md:px-0">
//                 <p className="pt-16 text-xl font-bold tracking-wider text-center text-yellow-500">
//                   Community
//                 </p>
//                 <h2 className="mt-3 text-3xl font-bold leading-normal text-center text-gray-600 font-baskerville">
//                   Connect and build relationships with the Nuleep Community
//                 </h2>
//                 <p className="mt-3 text-xl leading-normal text-center text-gray-600 ">
//                   Network, socialize, and build relationships with top peers,
//                   friends, and mentors. Access VIP events and job openings
//                   before they hit the public.{" "}
//                 </p>
//                 <img src={group} className="mx-auto mt-10" alt=""></img>
//               </div>
//             </div>
//           </section>

//           {/*Button  */}
//           <section>
//             <button
//               style={{ backgroundColor: "#A7F3D0" }}
//               className="block py-4 mx-auto mt-48 text-xl font-bold tracking-wider text-green-900 rounded-full sm:mt-36 mt-11 px-11"
//             >
//               Join Connect@Nuleep
//             </button>
//           </section>

//           {/* How does it work */}
//           <section className="px-4 mx-auto mt-32 md:px-4 max-w-7xl">
//             <p className="text-xl font-bold tracking-wider text-center text-yellow-500">
//               How does it work
//             </p>
//             <h2 className="mt-3 text-3xl font-bold leading-normal text-center text-gray-600 font-baskerville">
//               How we get you from today to your first day
//             </h2>
//             <div className="mt-10 md:flex">
//               <div className="max-w-sm px-8 py-12 mx-4 mx-auto bg-red-100 rounded-md h-90 md:w-1/3">
//                 <img className="absolute mx-auto" src={compass} alt=""></img>
//                 <h2 className="pt-10 mt-8 text-4xl font-bold text-gray-500 font-baskerville">
//                   Learn
//                 </h2>
//                 <p className="mt-1 text-gray-600">
//                   Share what's important to you and what you need help with to
//                   get started. We have mentors, guides, classes, and more to
//                   support your journey.
//                 </p>
//               </div>
//               <div className="flex-auto max-w-sm px-8 py-12 mx-4 mx-auto mt-4 bg-green-100 rounded-md h-90 md:mt-0 md:w-1/3">
//                 <img
//                   className="absolute mx-auto opacity-10"
//                   src={learn}
//                   alt=""
//                 ></img>
//                 <h2 className="pt-10 mt-8 text-4xl font-bold text-gray-500 font-baskerville">
//                   Discover
//                 </h2>
//                 <p className="mt-1 text-gray-600">
//                   Surf our jobs and classes to see how you want to grow your
//                   career. Check out tips, tricks, and VIP sessions where you
//                   learn to be the best!
//                 </p>
//               </div>
//               <div className="flex-auto max-w-sm px-8 py-12 mx-4 mx-auto mt-4 bg-teal-100 rounded-md h-00 md:mt-0 md:w-1/3">
//                 <img className="absolute mx-auto" src={shuttle} alt=""></img>
//                 <h2 className="pt-10 mt-8 text-4xl font-bold text-gray-500 font-baskerville">
//                   Explore
//                 </h2>
//                 <p className="mt-1 text-gray-600">
//                   Be creative and adventurous as you explore Nuleep's community.
//                   Meet peers, mentors, and Navigators. Try diverse jobs and find
//                   inclusive companies that care about impact.
//                 </p>
//               </div>
//             </div>
//             <button
//               style={{ backgroundColor: "#16ACB2" }}
//               className="block px-16 py-4 mx-auto mt-12 text-xl font-bold tracking-wider text-white rounded-full"
//             >
//               Get Started
//             </button>
//           </section>

//           {/* Features*/}
//           <section className="px-4 mx-auto mt-40 max-w-7xl">
//             <p className="text-xl font-bold tracking-wider text-yellow-500">
//               Features
//             </p>
//             <h2 className="max-w-lg mt-3 mt-5 text-3xl font-bold leading-normal text-left text-gray-600 font-baskerville">
//               Getting the job you want should be easier. Here is how we help.
//             </h2>
//             <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 mt-11">
//               <div className="relative mt-8 text-center bg-gray-50">
//                 <div className="absolute inset-x-0 flex items-center justify-center w-16 h-16 mx-auto -mt-8 bg-white rounded-full shadow-lg ">
//                   <UserIcon className="w-8 h-8 text-teal-500" />
//                 </div>
//                 <div className="pt-12 mb-12">
//                   <h3 className="text-xl font-bold text-center text-gray-700">
//                     What Matters to You
//                   </h3>
//                   <p className="px-6 mt-2 md:px-8">
//                     Access the purpose of the Company, the vision and mission of
//                     what you'll get to work on.
//                   </p>
//                 </div>
//               </div>
//               <div className="relative mt-8 text-center bg-gray-50">
//                 <div className="absolute inset-x-0 flex items-center justify-center w-16 h-16 mx-auto -mt-8 bg-white rounded-full shadow-lg ">
//                   <AdjustmentsIcon className="w-8 h-8 text-teal-500" />
//                 </div>
//                 <div className="pt-12 mb-12">
//                   <h3 className="text-xl font-bold text-center text-gray-700">
//                     Decision Making Benefits{" "}
//                   </h3>
//                   <p className="px-6 mt-2 md:px-8">
//                     Salary, perks, benefits, and more defined. How is leadership
//                     vs. team? Gain insider knowledge before you even start work.
//                   </p>
//                 </div>
//               </div>
//               <div className="relative mt-8 text-center bg-gray-50">
//                 <div className="absolute inset-x-0 flex items-center justify-center w-16 h-16 mx-auto -mt-8 bg-white rounded-full shadow-lg ">
//                   <UserGroupIcon className="w-8 h-8 text-teal-500" />
//                 </div>
//                 <div className="pt-12 mb-12">
//                   <h3 className="text-xl font-bold text-center text-gray-700">
//                     People, your community
//                   </h3>
//                   <p className="px-6 mt-2 md:px-8">
//                     Whether at work or outside of work, Nuleep's community is
//                     here for you. Build a network of friends and colleagues at
//                     work and through Nuleep. Access real time mentors and
//                     support from experts.
//                   </p>
//                 </div>
//               </div>
//               <div className="relative mt-8 text-center bg-gray-50">
//                 <div className="absolute inset-x-0 flex items-center justify-center w-16 h-16 mx-auto -mt-8 bg-white rounded-full shadow-lg ">
//                   <MapIcon className="w-8 h-8 text-teal-500" />
//                 </div>
//                 <div className="pt-12 mb-12">
//                   <h3 className="text-xl font-bold text-center text-gray-700">
//                     Roadmap A-Z{" "}
//                   </h3>
//                   <p className="px-6 mt-2 md:px-8">
//                     Build a career journey map with Nuleep. Take it with you to
//                     every job and promotion. This guide will support you from
//                     getting your first internship to new jobs to promotions.
//                   </p>
//                 </div>
//               </div>
//               <div className="relative mt-8 text-center bg-gray-50">
//                 <div className="absolute inset-x-0 flex items-center justify-center w-16 h-16 mx-auto -mt-8 bg-white rounded-full shadow-lg ">
//                   <LightBulbIcon className="w-8 h-8 text-teal-500" />
//                 </div>
//                 <div className="pt-12 mb-12">
//                   <h3 className="text-xl font-bold text-center text-gray-700">
//                     Mentors, Experts, & Navigators
//                   </h3>
//                   <p className="px-6 mt-2 md:px-8">
//                     Access mentors, experts, and peers from Disney, FB, Apple,
//                     KPMG, EY, startups, and companies that care about impact.
//                     Nuleep Navigators build relationships with their mentees.
//                   </p>
//                 </div>
//               </div>
//               <div className="relative mt-8 text-center bg-gray-50">
//                 <div className="absolute inset-x-0 flex items-center justify-center w-16 h-16 mx-auto -mt-8 bg-white rounded-full shadow-lg ">
//                   <GlobeIcon className="w-8 h-8 text-teal-500" />
//                 </div>
//                 <div className="pt-12 mb-12">
//                   <h3 className="text-xl font-bold text-center text-gray-700">
//                     Feedback & AMAs
//                   </h3>
//                   <p className="px-6 mt-2 md:px-8">
//                     We encourage our companies to provide feedback and stay in
//                     the conversation. You ask questions and connect with real
//                     people. We're championing learning, growth, and opportunity
//                     with each of the companies. Join the Nuleep Community today.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </section>

//           {/* Testimonials */}
//           <section className="px-4 mx-auto max-w-7xl">
//             <p className="mt-24 text-xl font-bold tracking-wider text-yellow-500">
//               Testimonials
//             </p>
//             <h2 className="max-w-lg mt-3 mt-5 text-3xl font-bold leading-normal text-left text-gray-600 font-baskerville">
//               Hear from some of Nuleep's members
//             </h2>
//             <div className="items-center justify-around max-w-5xl mx-auto mt-16 md:flex">
//               <img className="max-w-md" src={jamesCo} alt=""></img>
//               <div className="mt-4 text-center md:max-w-lg md:text-left">
//                 <p className="text-2xl leading-loose text-gray-600">
//                   The best part is being able to interact & engage with
//                   professionals about different companies for work during
//                   Connect@Nuleep!
//                 </p>
//                 <p className="mt-6 text-gray-600">
//                   James, Nuleep Community+ Member
//                 </p>
//               </div>
//             </div>
//           </section>

//           {/* Newsletter */}
//           <section className="mx-auto mt-16 max-w-7xl">
//             <div className="bg-white">
//               <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
//                 <div className="px-6 py-10 bg-teal-700 rounded-3xl sm:py-16 sm:px-12 lg:p-20 lg:flex lg:items-center">
//                   <div className="text-center lg:w-0 lg:flex-1 md:text-left">
//                     <h2 className="text-2xl font-extrabold leading-relaxed tracking-wide font-baskerville text-teal-50">
//                       Join our Nuleep Community newsletter!
//                     </h2>
//                     <p className="max-w-3xl mt-4 text-md text-teal-50">
//                       Get updates on upcoming events, workshops and more!
//                     </p>
//                   </div>
//                   <div className="mt-12 text-center sm:w-full sm:max-w-md lg:mt-0 lg:ml-8 lg:flex-1">
//                     <form className="sm:flex">
//                       <label htmlFor="emailAddress" className="sr-only">
//                         Email address
//                       </label>
//                       <input
//                         id="emailAddress"
//                         name="emailAddress"
//                         type="email"
//                         autoComplete="email"
//                         required
//                         className="w-full px-5 py-3 text-center placeholder-gray-500 border-white rounded-md md:text-left focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-teal-700 focus:ring-white"
//                         placeholder="Enter your email"
//                       />
//                       <button
//                         type="submit"
//                         className="flex items-center justify-center w-full px-5 py-3 mt-3 text-base font-medium text-white bg-teal-500 border border-transparent rounded-md hover:bg-teal-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-teal-700 focus:ring-white sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
//                       >
//                         Notify me
//                       </button>
//                     </form>
//                     <p className="mt-3 text-sm text-teal-100">
//                       We care about the protection of your data. Read our{" "}
//                       <a
//                         href="/"
//                         className="font-medium underline text-teal-50"
//                       >
//                         Privacy Policy.
//                       </a>
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </div>
//         <Footer />
//       </div>
//     );
//   }
// }
// export default Home;